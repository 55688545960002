<div class="login_page_background d-flex justify-content-center h-100 w-100 m-0">
  <div
    class="col-12 col-md-6 col-xl-4 login_card_background fadeIn rounded d-flex justify-content-center py-5 align-self-center">
    <div class="row w-100">
      <div
        class="h-100 text-right d-flex flex-column justify-content-center align-items-center col-12">
        <ng-template
          *ngIf="actualStep === 0"
          [ngComponentOutlet]="resetSetpsComponents[0]"></ng-template>
        <ng-template
          *ngIf="actualStep === 1"
          [ngComponentOutlet]="resetSetpsComponents[1]"></ng-template>
        <ng-template
          *ngIf="actualStep === 2"
          [ngComponentOutlet]="resetSetpsComponents[2]"></ng-template>
      </div>
    </div>
  </div>
</div>
