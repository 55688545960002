<div class="modal-content modal_export_push_mail p-3 fadeIn">
  <div class="push-mail-toastr" toastContainer></div>
  <div [ngSwitch]="templateToShow" *ngIf="$allTasks | async">
    <app-export-push-mail-empty
      *ngSwitchCase="'empty'"
      (onDismiss)="dismissModal()"
      (onCreate)="templateToShow = 'create'"></app-export-push-mail-empty>
    <app-export-push-mail-list
      *ngSwitchCase="'list'"
      [rows]="userTasks"
      (onDelete)="deleteTasks($event)"
      (onEdit)="editTasks($event)"
      (onDismiss)="dismissModal()"
      (onNewTasks)="newTasks()"></app-export-push-mail-list>
    <app-export-push-mail-form
      *ngSwitchCase="'create'"
      [user]="$userData | async"
      [case]="'create'"
      (onDismiss)="dismissModal()"
      (onCreate)="onCreateTask($event)"
      (onCancel)="showMainList()"
      (onFormError)="onErrorOnFormCreation($event)"></app-export-push-mail-form>
    <app-export-push-mail-form
      *ngSwitchCase="'edit'"
      [user]="$userData | async"
      [case]="'edit'"
      [allTasks]="allTasks"
      [selectedTask]="lastTasksSelected"
      (onDismiss)="dismissModal()"
      (onEdit)="onEditTask($event)"
      (onCancel)="showMainList()"></app-export-push-mail-form>
  </div>
</div>
