<div class="row d-flex overflow_kpi m-0 p-0 align-items-center w-100">
  <div
    class="default-kpi m-0 my-1 p-0 pe-0 w-auto"
    *ngFor="let key of dataKeys; let index = index"
    [class]="index == 0 ? 'ms-0 me-1' : '' || index > 0 ? 'mx-1' : '' || index > 5 ? 'ms-1' : ''"
    [ngClass]="{
      displayNone:
        (key === 'salesRevenue' || key === 'transformRate' || key === 'billTickets') &&
        !financialData
    }">
    <div class="card elevation-static-2 border-0 p-0 m-0 position-relative kpi_min_width">
      <div
        class="card-header m-0 p-0 d-flex flex-row align-items-center bg-transparent border-0 bg-danger">
        <div class="d-flex wrap_kpi_icon bg-transparent position-absolute">
          <img
            class="fadeIn bg-white"
            height="30px"
            src="/assets/svg/{{ kpiData[key].icon }}.svg"
            alt="kpi icon" />
        </div>
        <div class="h6 m-0 p-2 text-dark font-weight-bold w-100 text-start d-flex">
          {{ kpiData[key].title | translate }}
        </div>
      </div>
      <div class="card-body p-0 pt-0 pb-4 px-1 align-items-center">
        <div class="d-flex flex-row align-items-start justify-content-center">
          <div
            class="d-flex flex-1 flex-column card-title mb-1 align-items-center w-max-content _inline-block">
            <div
              [class]="!loading ? 'text_orange' : 'text-muted'"
              class="text_orange px-1 m-0 font-weight-normal h1 text-nowrap"
              placement="bottom-end"
              [ngbTooltip]="
                toolTipData[key]?.first
                  ? (toolTipData[key]?.first | customNumber : decimalPosition[key]) +
                    loadingAffix[key]
                  : null
              ">
              {{
                !loading
                  ? (kpiData[key]?.content?.first
                    | kpiFilter : key : loadingAffix[key] : 999 : decimalPosition[key])
                  : 0
              }}
            </div>
            <div
              [class]="!loading ? 'text_orange' : 'text-muted'"
              class="text_blue m-0 font-weight-normal h6"
              placement="bottom-end"
              [ngbTooltip]="
                toolTipData[key]?.versus
                  ? (toolTipData[key]?.versus | customNumber : decimalPosition[key]) +
                    loadingAffix[key]
                  : null
              ">
              {{
                !loading
                  ? (kpiData[key]?.content?.versus
                    | kpiFilter : key : loadingAffix[key] : 999 : decimalPosition[key])
                  : 0
              }}
            </div>
          </div>
          <div class="d-flex w-max-content align-self-center">
            <div
              class="rounded px-1 py-2 w-100 h-100 d-flex flex-row align-items-center _inline-block">
              <img
                width="20px"
                height="20px"
                alt="progress"
                class="m-1"
                [src]="
                  !loading
                    ? kpiData[key]?.content?.showAlert
                      ? '/assets/svg/Arrow_down.svg'
                      : '/assets/svg/Arrow_up.svg'
                    : '/assets/svg/Equals.svg'
                "
                *ngIf="kpiData[key]?.content?.percent * 100 !== 0; else Equals" />

              <ng-template #Equals>
                <img
                  width="20px"
                  height="20px"
                  [src]="'/assets/svg/Equals.svg'"
                  alt="progress"
                  class="m-1" />
              </ng-template>

              <div class="d-flex flex-column text-center">
                <span
                  [class]="!loading ? '' : 'text-muted'"
                  [ngClass]="{
                    text_red: !loading && kpiData[key]?.content?.showAlert,
                    text_green: !loading && !kpiData[key]?.content?.showAlert,
                    bg_disabled: loading
                  }"
                  *ngIf="key !== 'transformRate' && key !== 'attractivenessRate'">
                  {{
                    !loading ? (kpiData[key]?.content?.percent | limitPercentage : '%') : '0 %'
                  }}</span
                >

                <span
                  [class]="!loading ? 'd-flex flex-row' : 'd-flex flex-row text-muted'"
                  [ngClass]="{
                    text_red: !loading && kpiData[key]?.content?.showAlert,
                    text_green: !loading && !kpiData[key]?.content?.showAlert,
                    bg_disabled: loading
                  }"
                  *ngIf="key === 'transformRate' || key === 'attractivenessRate'">
                  {{
                    !loading ? (kpiData[key].content.percent | kpiFilter : key : '' : 999 : 1) : '0'
                  }}
                  <div class="_text_suffix">&nbsp;Pt</div></span
                >
                <div
                  *ngIf="showVariation[key]"
                  [class]="!loading ? 'fadeIn' : 'fadeIn text-muted'"
                  [ngClass]="{
                    text_red: !loading && kpiData[key]?.content?.showAlert,
                    text_green: !loading && !kpiData[key]?.content?.showAlert,
                    bg_disabled: loading
                  }">
                  {{
                    kpiData[key]?.content?.fluctuation
                      | kpiFilter : key : variationAffix[key] : 999 : decimalPosition[key]
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="kpi_info">
            <button
              type="button"
              class="border-0 bg-transparent p-0 me-2 m-0"
              placement="bottom-end"
              container="body"
              tooltipClass="_tooltip"
              ngbTooltip="{{ 'tooltip.swichOn' + kpiData[key]?.switchOn | translate }}"
              *ngIf="kpiData[key]?.switch && groupEnabled"
              (click)="switchOnEntryData()">
              <img
                width="25px"
                height="25px"
                [src]="'/assets/svg/' + kpiData[key]?.iconSwitchOn + '_buton.svg'"
                alt="info kpi" />
            </button>
            <button
              type="button"
              class="border-0 bg-transparent p-0 m-0"
              placement="bottom-end"
              ngbTooltip="{{ showAlternativeKpi(key) | translate }}"
              container="body"
              tooltipClass="_tooltip">
              <img width="25px" height="25px" src="/assets/svg/InfoKPI.svg" alt="info kpi" />
            </button>
          </div>
          <div class="kpi_stats" *ngIf="!kpiData[key].disabled">
            <button
              type="button"
              (click)="putFirstChartLayout(kpiData[key].title)"
              class="fadeIn border-0 bg-transparent p-0 m-0"
              *ngIf="financialData">
              <img width="25px" height="25px" src="/assets/svg/Stats.svg" alt="chart" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="default-kpi m-0 my-1 p-0 ms-1" [ngClass]="{ displayNone: financialData }">
    <div
      class="card elevation-static-2 border-0 p-0 m-0 position-relative kpi_min_width"
      *ngIf="!financialData">
      <div class="h6 m-0 p-2 text-dark font-weight-bold w-100 text-start">
        {{ 'salesRevenue' | translate }} / {{ 'transformRate' | translate }} /
        {{ 'billTickets' | translate }}
      </div>
      <div class="card-body p-0 pt-0 pb-4 px-1 align-items-center">
        {{ 'noCasshRegisterData' | translate }}
      </div>
    </div>
  </div>
</div>
