import { AfterViewInit, Component, Input } from '@angular/core';
import { DateRange, RangeType } from '@app/models';
import { ChartToDisplay } from '@app/models/Chart';
import { ChartProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { customNumberFilter } from '@app/pipes';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { CustomLabel } from '@app/models/';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements AfterViewInit {
  @Input() data;
  @Input() loading: boolean;
  public unavailable = false;
  public fullUnavailable = false;

  private pluginsOptions;
  private options;
  private rangeType: RangeType;
  private layout: ChartToDisplay[];
  private range: DateRange;
  public charts: Chart;

  private availableCegidId = [232, 233, 234, 235, 239, 240, 241];

  constructor(
    private statsProvider: StatsProvider,
    private chartProvider: ChartProvider,
    private translate: TranslateService,
  ) {}

  // Only for chart who had 2 line
  private formatTooltip = (value, context) => {
    var index = context.dataIndex;
    var datasetIndex = context.datasetIndex;
    var datasets = context.chart.data.datasets;
    if (datasets.length > 1) {
      var v0 = datasets[datasetIndex === 1 ? 1 : 0].data[index];
      var v1 = datasets[datasetIndex === 1 ? 0 : 1].data[index];

      var title;
      var titleVs;

      this.translate
        .get(
          Number(v0) > 1
            ? 'chartTooltip.' + this.data.title + 'Plurial'
            : 'chartTooltip.' + this.data.title,
        )
        .subscribe((_title) => {
          title = ' ' + _title;
        });
      this.translate
        .get(
          Number(v1) > 1
            ? 'chartTooltip.' + this.data.title + 'Plurial'
            : 'chartTooltip.' + this.data.title,
        )
        .subscribe((_titleVs) => {
          titleVs = ' ' + _titleVs;
        });

      if (
        this.data.title === 'transformTime' ||
        this.data.title === 'salesRevenue' ||
        this.data.title === 'attendanceTime'
      ) {
        v0 = customNumberFilter(Number(v0), 2);
        v1 = customNumberFilter(Number(v1), 2);
      } else {
        v0 = customNumberFilter(Math.round(Number(v0)), 0);
        v1 = customNumberFilter(Math.round(Number(v1)), 0);
      }

      return datasetIndex === 1
        ? (v1 ? 'Référence' + ' : ' + v0 + titleVs : 'Aucune donnée') +
            '\n' +
            '\n' +
            (v0 ? 'Comparé' + ' : ' + v1 + title : 'Aucune donnée')
        : (v0 ? 'Référence' + ' : ' + v1 + title : 'Aucune donnée') +
            '\n' +
            '\n' +
            (v1 ? 'Comparé' + ' : ' + v0 + titleVs : 'Aucune donnée');
    } else {
      return '';
    }
  };

  // Only for chart who had 4 line
  private formatDoubleTooltip = (value, context) => {
    var index = context.dataIndex;
    var datasets = context.chart.data.datasets;
    if (datasets.length > 1) {
      if (datasets.length > 2) {
        var title;
        var titleVs;
        var subTitle;
        var subTitleVs;
        var v0 = datasets[2].data[index];
        var v1 = datasets[3].data[index];
        var d0 = datasets[0].data[index];
        var d1 = datasets[1].data[index];
      }
      if (
        this.data.title === 'transformTime' ||
        this.data.title === 'salesRevenue' ||
        this.data.title === 'attendanceTime'
      ) {
        v0 = customNumberFilter(Number(v0), 2);
        v1 = customNumberFilter(Number(v1), 2);
      } else {
        v0 = customNumberFilter(Math.round(Number(v0)), 0);
        v1 = customNumberFilter(Math.round(Number(v1)), 0);
      }
      this.translate
        .get(
          Number(v0) > 1
            ? 'chartTooltip.' + this.data.title + 'Group' + 'Plurial'
            : 'chartTooltip.' + this.data.title + 'Group',
        )
        .subscribe((_title) => {
          subTitle = ' ' + _title;
        });
      this.translate
        .get(
          Number(v1) > 1
            ? 'chartTooltip.' + this.data.title + 'Group' + 'Plurial'
            : 'chartTooltip.' + this.data.title + 'Group',
        )
        .subscribe((_titleVs) => {
          subTitleVs = ' ' + _titleVs;
        });

      this.translate
        .get(
          Number(v0) > 1
            ? 'chartTooltip.' + this.data.title + 'Plurial'
            : 'chartTooltip.' + this.data.title,
        )
        .subscribe((_title) => {
          title = ' ' + _title;
        });
      this.translate
        .get(
          Number(v1) > 1
            ? 'chartTooltip.' + this.data.title + 'Plurial'
            : 'chartTooltip.' + this.data.title,
        )
        .subscribe((_titleVs) => {
          titleVs = ' ' + _titleVs;
        });

      return `Référence : ${v0} ${title} ( ${v1} ${subTitle}) \n \n Comparée : ${d0} ${titleVs} ( ${d1} ${subTitleVs})`;
    }
  };

  ngAfterViewInit(): void {
    this.data.config.type = this.data.type;
    this.pluginsOptions = {
      datalabels: {
        backgroundColor: function (context) {
          return !context.active ? 'context.dataset.backgroundColor' : 'black';
        },
        color: function (context) {
          return !context.active ? 'black' : 'white';
        },
        display: function (context) {
          return context.active;
        },
        anchor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];

          return value < 0 ? 'start' : 'end';
        },
        align: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];

          return value < 0 ? 'end' : 'start';
        },
        // Go here if wanna edit tooltip info
        formatter: (value, context) => {
          if (context.chart.data.datasets.length > 2) {
            return this.formatDoubleTooltip.bind(this)(value, context);
          } else {
            return this.formatTooltip.bind(this)(value, context);
          }
        },
        borderRadius: 4,
        font: {
          weight: 'bold',
        },
        offset: 8,
        padding: 6,
      },
      // Core options
      aspectRatio: 5 / 3,
      layout: {
        padding: {
          top: 32,
          right: 26,
          bottom: 32,
          left: 0,
        },
      },
      elements: {
        line: {
          borderWidth: 2,
          fill: false,
        },
      },
    };

    this.data.options.plugins = this.pluginsOptions;

    this.charts = new Chart(
      document.getElementById('_chart_' + this.data.title) as HTMLCanvasElement,
      this.data.config,
    );
    this.chartProvider.layout().subscribe((layout) => (this.layout = layout));

    this.statsProvider.getOptions().subscribe((options) => {
      this.rangeType = options.rangeType;
      this.range = options.range;
      this.options = options;
      this.refresh();
    });

    this.listenChange();
  }

  private listenChange(): void {
    this.chartProvider.sub().subscribe(() => {
      if (
        (this.data.title === 'billTickets' ||
          this.data.title === 'transformRate' ||
          this.data.title === 'attendanceTime' ||
          this.data.title === 'salesRevenue') &&
        this.rangeType === 'day'
      ) {
        this.unavailable = true;
      } else if (
        this.data.title === 'entryNumber' &&
        this.data.config.datasets[0].data.length === 0
      ) {
        this.unavailable = true;
      } else this.unavailable = false;

      this.refresh();
    });
  }

  private refresh(): void {
    this.charts.stop();
    this.data.options.tooltips = false;
    this.data.options.plugins = this.pluginsOptions;
    this.charts.options = this.data.options;
    this.charts.config.options = this.data.config.options;

    if (this.rangeType === 'custom') {
      const max = Math.max(...this.data.config.datasets);
      this.data.config.datasets.map((dataSets) => {
        dataSets.data = dataSets.data.map((dataSet) => {
          let diff = max - dataSet.length;
          if (dataSet.length < diff) {
            for (let i = 0; i < diff; i++) {
              dataSet.push('');
            }
          }
          return dataSet;
        });

        return dataSets;
      });
    }

    this.charts.data.datasets = this.data.config.datasets;

    this.charts.options.scales.yAxes[0].ticks.callback = (value, index, values) => {
      var decimal;

      if (Number(value) >= 100) decimal = 0;
      else decimal = 1;

      return customNumberFilter(Number(value).toFixed(2), decimal);
    };
    this.isAvailable();

    this.showGoodXAxisLabelByPeriodType();

    this.charts.resize();

    this.charts.update({ duration: 250, lazy: true });
  }

  public isAvailable(): void {
    const cegidChart = ['billTickets', 'salesRevenue', 'transformRate'];
    if (this.options.type === 'business') {
      if (
        !this.availableCegidId.includes(this.options.targetData.id) &&
        cegidChart.includes(this.data.title)
      ) {
        this.fullUnavailable = true;
      } else {
        this.fullUnavailable = false;
      }
    } else {
      this.fullUnavailable = false;
    }
  }

  private setSingleXAxis(labels: string[]) {
    this.charts.options.scales.xAxes = [
      {
        id: 'xAxis1',
        type: 'category',
        labels,
      },
    ];
  }

  private setDualXAxes(labels: CustomLabel) {
    const diff = Math.abs(labels.current.length - labels.versus.length);
    const arrOfEmptyStrings = Array.from({ length: diff }, () => '');

    if (labels.current.length > labels.versus.length) {
      labels.versus = [...labels.versus, ...arrOfEmptyStrings];
    }

    if (labels.versus.length > labels.current.length) {
      labels.current = [...labels.current, ...arrOfEmptyStrings];
    }

    this.charts.options.scales.xAxes = [
      {
        id: 'xAxis1',
        type: 'category',
        labels: labels.current,
      },
      {
        id: 'xAxis2',
        type: 'category',
        position: 'bottom',
        labels: labels.versus,
      },
    ];
  }

  private showGoodXAxisLabelByPeriodType() {
    let { labels } = this.data.config.datasets[0];

    if (this.rangeType === 'custom') {
      labels = {
        current: this.chartProvider.sortLabels(labels.current),
        versus: this.chartProvider.sortLabels(labels.versus),
      };

      this.setDualXAxes(labels);
    } else this.setSingleXAxis(labels);
  }
}
