<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'export-push-mail.mainTitle' | translate }}
  </h4>
  <button type="button" class="btn-close" (click)="dismissModal()"></button>
</div>
<div class="modal-body border-dark border-top">
  <div class="d-flex flex-column border rounded">
    <div class="row">
      <div class="col-12 d-flex mb-3">
        <button
          type="button"
          class="btn btn-outline-dark elevation-static-1 ms-auto me-2"
          (click)="newTasks()">
          <i class="bi bi-plus-lg"></i>
          {{ 'export-push-mail.newTasks' | translate }}
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 mb-3">
        <ngx-datatable
          class="bootstrap h-auto striped fixed-header scroll-vertical _full_cell_width"
          [rows]="rows"
          style="height: 362px !important"
          [columnMode]="ColumnMode.force"
          [columns]="columns"
          [headerHeight]="50"
          [footerHeight]="0"
          [limit]="20"
          [count]="40"
          [limit]="5"
          [summaryHeight]="50"
          [summaryRow]="false"
          [virtualization]="false"
          [scrollbarV]="true"
          [rowHeight]="50"
          [offset]="0"
          [messages]="{
            emptyMessage: 'export-push-mail.maillingListempty' | translate
          }">
          <ngx-datatable-column name="Mes listes" [cellClass]="'w-100 align-items-center d-flex'">
            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              let-row="row"
              class="w-100">
              <div class="d-flex align-items-center w-100">
                <span
                  title="Double click to edit"
                  (dblclick)="editTask(row, rowIndex)"
                  class="col-10 me-auto">
                  {{ row['title'] }}
                </span>
                <button
                  type="button"
                  class="btn btn-outline-grey elevation-static-1 mx-1"
                  aria-label="Edition"
                  (click)="deleteTasks(row, rowIndex)">
                  <img width="20px" src="/assets/svg/Trash.svg" />
                </button>
                <button
                  type="button"
                  class="btn btn-outline-grey elevation-static-1 mx-1"
                  aria-label="Edition"
                  (click)="editTask(row, rowIndex)">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger elevation-static-1"
    aria-label="Close"
    (click)="dismissModal()">
    {{ 'cancel' | translate }}
  </button>
</div>
