import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { customNumberFilter } from '@app/pipes';
import { GeocoderProvider, PapaparseProvider } from '@app/providers';
import {
  latLng,
  tileLayer,
  MapOptions,
  marker,
  Layer,
  icon,
  Map,
  Popup,
  popup,
  LeafletEvent,
} from 'leaflet';

import 'leaflet.smooth_marker_bouncing';
@Component({
  selector: 'app-short-map',
  templateUrl: './short-map.component.html',
  styleUrls: ['./short-map.component.scss'],
})
export class ShortMapComponent implements OnInit, OnDestroy {
  @Input() rows: any[];
  @Input() columns;
  @Input() zippedColumns;
  @Input() actualKpi;
  @Input() loading;

  private map: Map;

  private geoData;

  public _layers: Layer[] = [];

  @Output() onSelectMarker = new EventEmitter();

  constructor(private geocoder: GeocoderProvider, private papaparse: PapaparseProvider) {}

  ngOnDestroy(): void {
    this.rows = [];
    this.columns = [];
    this.zippedColumns = [];
    this.actualKpi = '';
    this.loading = true;

    this._layers = [];
  }

  public options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
      tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/{z}/{x}/{y}{r}.png', {
        subdomains: 'abcd',
        minZoom: 0,
        maxZoom: 28,
      }),
    ],
    preferCanvas: false,
    dragging: true,
    maxZoom: 28,
    bounceAtZoomLimits: true,
    minZoom: 0,
    zoom: 5.7,
    zoomSnap: 0.5,
    zoomDelta: 0.75,
    tap: true,
    zoomControl: true,
    attributionControl: true,
    touchZoom: true,
    doubleClickZoom: true,
    center: latLng(46.71109, 1.7191036),
  };

  public mapSetDefaultCameraState() {
    this.map.flyTo(latLng(46.71109, 1.7191036), 5.7);
    this.map.closePopup();
  }

  public onMapReady(map: Map): void {
    this.map = map;
    this.setMarkerOnChange();

    this.listenOnSelect();
  }
  public popup(content: string): Popup {
    return popup({}).setContent(content);
  }

  ngOnInit(): void {
    this._layers = [];
    this.geocoder.getData().subscribe((data) => {
      if (data) {
        this.transformDataForMapData(data);
      }
    });
  }

  private transformDataForMapData(clusterData: any): void {
    this.geocoder.fromZipCode('FR', clusterData.localBusiness);
  }

  public listenOnSelect(): void {
    this.geocoder.getSelectedData().subscribe((selectedData) => {
      if (selectedData) {
        if (this.geoData) {
          const opacity = 1;
          this._layers = [];
          this.geoData.forEach((businessGeo, index) => {
            const result = this.rows.filter((value) => Number(value.id) === Number(businessGeo.id));
            const _marker = marker([businessGeo.lat, businessGeo.lng], {
              zIndexOffset: Number(selectedData.id) === Number(businessGeo.id) ? 1 : 0,
              riseOnHover: true,
              opacity: opacity,
              alt: '_marker_' + businessGeo.id,
              icon: icon({
                iconSize: [
                  Number(selectedData.id) === Number(businessGeo.id) ? 25 : 20,
                  Number(selectedData.id) === Number(businessGeo.id) ? 45 : 40,
                ],
                iconUrl:
                  Number(selectedData.id) === Number(businessGeo.id)
                    ? 'assets/svg/map/location_hover.svg'
                    : 'assets/svg/map/location.svg',
              }),
            }).bindPopup(this.popup(this.popupTemplate(businessGeo, result[0])), {
              offset: [0, -20],
              autoPan: false,
              keepInView: false,
            });

            _marker.on('click', (event: LeafletEvent) => {
              const id = event.target.options.alt.split('_marker_')[1];
              this.geocoder.setSelectedData(
                this.rows.filter((value) => Number(value.id) === Number(id))[0],
              );
            });
            this._layers.push(_marker);
            if (businessGeo.id === selectedData.id) {
              this.map.flyTo(_marker.getLatLng(), 11, {
                animate: true,
                noMoveStart: true,
                easeLinearity: 0.9,
              });

              this.map.once('zoomend', () => {
                setTimeout(() => {
                  //@ts-ignore
                  _marker.setBouncingOptions({
                    bounceHeight: 20, // height of the bouncing
                    bounceSpeed: 54, // bouncing speed coefficient
                  });
                  //@ts-ignore
                  _marker.bounce(3);
                  this.map.openPopup(
                    //@ts-ignore
                    this._layers[index].getPopup(),
                    {
                      lat: businessGeo.lat,
                      lng: businessGeo.lng,
                    },
                  );
                });
              });
            }
          });
        }
      } else {
        this.mapSetDefaultCameraState();
      }
    });
  }

  private setMarkerOnChange(): void {
    this.papaparse.getGeocoderData().subscribe((data) => {
      if (data) {
        this.geoData = data;
        this._layers = [];
        const opacity = 1;
        data.forEach((businessGeo) => {
          const result = this.rows.filter((value) => Number(value.id) === Number(businessGeo.id));

          const _marker = marker([businessGeo.lat, businessGeo.lng], {
            riseOnHover: true,
            opacity: opacity,
            alt: '_marker_' + businessGeo.id,
            icon: icon({
              iconSize: [25, 25],
              iconUrl: 'assets/svg/map/location.svg',
            }),
          }).bindPopup(this.popup(this.popupTemplate(businessGeo, result[0])), {
            autoPan: false,
            keepInView: false,
          });

          _marker.on('click', (event: LeafletEvent) => {
            const id = event.target.options.alt.split('_marker_')[1];
            this.geocoder.setSelectedData(
              this.rows.filter((value) => Number(value.id) === Number(id))[0],
            );
          });

          this._layers.push(_marker);
          //@ts-ignore
        });
      }
    });
  }

  private popupTemplate(businessGeo: any, businessData: any): string {
    return this.actualKpi && !this.loading
      ? `
       <div class="row">
       <div class="col-12 pb-2">
       <div class="text_bold d-flex flex-row">
            <div>Rang :&nbsp;</div>
            <div class="text_bold">${
              businessData
                ? businessData[
                    'rankFluctuation' + this.actualKpi.Capitalize().replace('Rate', '') ?? ''
                  ] || ''
                : ''
            }</div>

       </div>
       </div>
       <div class="col-12 pb-1">
       <div class="d-flex flex-row">
            <div class="text_bold">Nom :&nbsp;</div>
            <div class="text_bold">${businessGeo.businessName}</div>
       </div>
       <div class="d-flex flex-row">
            <div class="text_bold">Ville :&nbsp;</div>
            <div class="text_bold">${businessGeo.city}</div>
        </div>
       </div>
       <hr class="my-4">
       <div class="col-12">
       <div class="d-flex flex-row">
            <div class="text_bold">Nombre d'entrée :&nbsp;</div>
            <div class="d-flex flex-row">
            <div class="text_bold text_orange">${
              customNumberFilter(businessData?.entryNumber, 0) + ''
            }</div>
                </div>
       <div class="text_bold px-1">|</div>
       <div class="text_bold text_blue">${businessData?.entryNumberVs}</div>
        </div>
                 </div>
                  </div>
       <div class="d-flex flex-row">
            <div class="text_bold">Taux d'attractivité :&nbsp;</div>
            <div class="d-flex flex-row">
            <div class="text_bold text_orange">${
              customNumberFilter(businessData?.attractivenessRate, 0) + ' %'
            }</div>
        </div>
        <div class="text_bold px-1">|</div>

        <div class="text_bold text_blue">${
          customNumberFilter(businessData?.attractivenessRateVs, 0) + ' %'
        }</div>
    </div>
       </div>
        <div class="d-flex flex-row">
            <div class="text_bold">Temps de présence :&nbsp;</div>
            <div class="d-flex flex-row">
            <div class="text_bold text_orange">${businessData?.attendanceTime}</div>
            <div class="text_bold px-1">|</div>
            <div class="text_bold text_blue">${businessData?.attendanceTimeVs}</div>

            </div>
        </div>
        <div class="d-flex flex-row">
            <div class="text_bold">Chiffre d'affaires :&nbsp;</div>
            <div class="d-flex flex-row">
            <div class="text_bold text_orange">${
              customNumberFilter(businessData?.salesRevenue, 0) + ' €'
            }</div>
            <div class="text_bold px-1">|</div>
            <div class="text_bold text_blue">${
              customNumberFilter(businessData?.salesRevenueVs, 0) + ' €'
            }</div>
            </div>
           
    </div>
    <div class="d-flex flex-row">
            <div class="text_bold">Taux de transformation :&nbsp;</div>
            <div class="d-flex flex-row">
            <div class="text_bold text_orange">${
              customNumberFilter(businessData?.transformRate, 0) + ' %'
            }</div>
            <div class="text_bold px-1">|</div>
            <div class="text_bold text_blue">${
              customNumberFilter(businessData?.transformRateVs, 0) + ' %'
            }</div>
            </div>
    </div>

    <div class="d-flex flex-row">
    <div class="text_bold">Ticket de caisse :&nbsp;</div>
    <div class="d-flex flex-row">
    <div class="text_bold text_orange">${
      customNumberFilter(businessData?.billTickets, 0) + ''
    }</div>
    <div class="text_bold px-1">|</div>
    <div class="text_bold text_blue">${
      customNumberFilter(businessData?.billTicketsVs, 0) + ''
    }</div>
    </div>
   
</div>
       </div>
       </div>
       `
      : `<div class="col-12 pb-3">
               <div class="d-flex flex-row">
               <div class="text_bold">Chargement...</div></div></div>
                `;
  }
}
