//@ts-nocheck
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class TableHelperService {
  /**
   * @param data data as type : array of object
   * @param fieldToRank field who sort the array
   * @param opts (OPTIONAL) opts if needed
   * @param opts.sort (OPTIONAL) sort by asc or desc
   * @param opts.forceType (OPTIONAL) force typing
   * @returns
   **/
  public rankByField(
    data: { [key: string]: any }[],
    fieldToRank: string,
    opts?: {
      sort?: string | 'asc' | 'desc';
      forceType?: 'number' | 'string' | 'time';
      withSplit?: boolean;
      MMSSS?: boolean;
      sortByNameToo?: boolean;
      sortByNamedValueIfAllFieldToRankAreEquals?: string;
      optsIfAllFieldToRankAreEquals?: {
        sort?: string | 'asc' | 'desc';
        forceType?: 'number' | 'string' | 'time';
        withSplit?: boolean;
        MMSSS?: boolean;
        sortByNameToo?: boolean;
      };
    },
  ): { [key: string]: any }[] {
    const numberOfValue = data.length;
    const isAllValueEquals = new Array(numberOfValue).fill(false, 0, numberOfValue);
    var index = 0;
    const sortedResult = () => {
      return data.sort((a, b) => {
        if (!opts || (opts.forceType !== 'string' && opts.forceType !== 'time')) {
          var tempX: any = (a[fieldToRank] + '').replace(',', '.').replace(/[^0-9.-]/g, '');
          var tempY: any = (b[fieldToRank] + '').replace(',', '.').replace(/[^0-9.-]/g, '');
        } else {
          var tempX: any = a;
          var tempY: any = b;
        }

        if (opts?.withSplit) {
          tempX = (tempX + '').trim();
          tempY = (tempY + '').trim();
        }

        if (opts?.forceType === 'number') {
          tempX = Number(tempX);
          tempY = Number(tempY);
        }

        if (opts?.MMSSS) {
          tempX = moment(tempX, "mm'ss").valueOf();
          tempY = moment(tempY, "mm'ss").valueOf();
        }
        const x = tempX;
        const y = tempY;

        if (x === y) {
          isAllValueEquals[index] = true;
          isAllValueEquals[index + 1] = true;
        }
        index = index + 1;

        if (!opts || !opts.sort || opts?.sort === 'desc') {
          return x > y ? 1 : -1;
        }
        if (opts.sort === 'asc') {
          return x > y ? -1 : 1;
        }
        return 0;
      });
    };

    var result = sortedResult.bind(this)();

    if (
      !isAllValueEquals.includes(false) &&
      opts.sortByNamedValueIfAllFieldToRankAreEquals &&
      opts.optsIfAllFieldToRankAreEquals
    ) {
      fieldToRank = opts.sortByNamedValueIfAllFieldToRankAreEquals;
      opts = opts.optsIfAllFieldToRankAreEquals;
      result = sortedResult.bind(this)();
    }

    if (opts?.sortByNameToo) {
      result = result.sort((a, b) => {
        if (!opts || !opts.sort || opts?.sort === 'desc') {
          return a.name > b.name ? 1 : -1;
        }
        if (opts.sort === 'asc') {
          return a.name > b.name ? -1 : 1;
        }
      });
    }

    return result;
  }

  /**
   * @param event Html Binding Event like (keyup)
   * @param data data as type : array of object
   * @param field (OPTIONAL) field for filter
   * @returns
   **/
  public filterTableData(
    event: KeyboardEvent,
    data: { [key: string]: any }[],
    field?: string,
    noEvent?: boolean,
  ): { [key: string]: any }[] {
    if (!field) {
      field = 'name';
    }

    const val = !noEvent ? (event.target as HTMLInputElement).value.toLowerCase() : event;
    const holder = data;
    const filteredData = holder.filter(function (d) {
      return d[field].toLowerCase().indexOf((val as string).toLowerCase()) !== -1 || !val;
    });

    return filteredData;
  }
}
