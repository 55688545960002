import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RefreshProvider } from '@app/providers/layout';
import { ApiV2Service } from '@app/services/api/v2';
import { BehaviorSubject } from 'rxjs';

@Component({
  template: '',
})
export class AbstractReset implements OnDestroy {
  public $usernameOrEmail: BehaviorSubject<string> = new BehaviorSubject('');
  public $isError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public $error: BehaviorSubject<string> = new BehaviorSubject('');

  public $showWaitingToken: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public autoBack = true;

  private $token: BehaviorSubject<string> = new BehaviorSubject('');
  private $newPassword: BehaviorSubject<string> = new BehaviorSubject('');
  private $userId: BehaviorSubject<number> = new BehaviorSubject(0);

  private $resetStep: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    public refreshProvider: RefreshProvider,
    public router: Router,
    private apiV2Service: ApiV2Service,
  ) {}

  public goLogin() {
    this.router.navigate(['/login']);
    this.refreshProvider.emit({
      'reset-steps': { props: { steps: 0 } },
    });
  }

  public backStep() {
    this.$resetStep.next(this.$resetStep.value - 1);
    this.refreshProvider.emit({
      'reset-steps': { props: { step: this.$resetStep.value } },
    });
  }

  public setStep(value) {
    this.$resetStep.next(value);
  }

  public quit() {
    this.autoBack = false;
    setTimeout(() => {
      this.autoBack = true;
    }, 6000);
    this.router.navigate(['login']).then(() => {
      this.$usernameOrEmail.next('');
      this.$isError.next(false);
      this.$error.next('');
      this.$showWaitingToken.next(true);
      this.$token.next('');
      this.$newPassword.next('');
      this.$userId.next(0);
      this.$resetStep.next(0);
      this.refreshProvider.emit({
        'reset-steps': { props: { step: 0, token: null, id: null } },
      });
    });
  }

  public nextStep() {
    this.$resetStep.next(this.$resetStep.value + 1);
    this.refreshProvider.emit({
      'reset-steps': {
        props: {
          step: this.$resetStep.value,
          username: this.$usernameOrEmail.value,
          userId: this.$userId.value,
          token: this.$token.value,
        },
      },
    });
  }

  public checkToken() {
    return this.apiV2Service.getNewPasswordToken(this.$userId.value, this.$token.value);
  }

  public askNewPassword() {
    return this.apiV2Service.askForNewPassword(this.$usernameOrEmail.value);
  }

  public updatePassword(newPassword, userId, confirmToken) {
    return this.apiV2Service.setResetPassword(userId, newPassword, confirmToken);
  }

  public setNewPassword(password: string) {
    this.$newPassword.next(password);
  }

  public setToken(token: string) {
    this.$token.next(token);
  }

  public setUserID(id: number) {
    this.$userId.next(id);
  }

  public setError(error: string) {
    this.$isError.next(true);
    this.$error.next(error);
  }

  public removeError() {
    this.$isError.next(false);
    this.$error.next('');
  }

  ngOnDestroy() {
    this.removeError();
  }
}
