<div class="journey-table d-flex flex-row justify-content-center align-items-center w-100">
  <div class="row d-flex m-0 align-items-center w-100 py-2">
    <div class="col-12 pb-1 ps-0 ms-0">
      <div ngbDropdown class="d-inline-block pb-1">
        <button
          class="btn"
          id="dropdownBasic1"
          ngbDropdownToggle
          [class]="dropStyle"
          placement="end"
          [ngbTooltip]="'tooltip.customerJourney.' + activeTab | translate">
          {{ getActiveToHuman() | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            ngbDropdownItem
            [class]="buttonStyle"
            (click)="onKpiSelect(tab)"
            placement="end"
            [ngbTooltip]="'tooltip.customerJourney.' + tab | translate"
            [disabled]="tab === activeTab || tab === activeTabVs"
            *ngFor="let tab of allTabs">
            {{ 'customerJourneyPage.actionBar.' + tab | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="card h-100 w-100" [class]="cardStyle">
      <div class="card-body position-relative p-0 m-0 h-100 w-100">
        <input
          type="text"
          style="
            padding: 0.5em;
            margin-left: auto;
            margin-top: 0.25em;
            margin-right: 0.25em;
            width: 30%;
          "
          placeholder="Filtrer par nom de zone..."
          (keyup)="tableFilter($event)"
          class="form-control text-truncate border-0 rounded text-left text_bold elevation-static-1 bg-white" />
        <ngx-datatable
          [rows]="getData()"
          [loadingIndicator]="loading"
          class="bootstrap h-auto striped fixed-header scroll-vertical"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [limit]="20"
          [count]="40"
          [summaryRow]="false"
          [virtualization]="false"
          [scrollbarV]="true"
          [rowHeight]="50"
          (sort)="onNativeShort($event)"
          [offset]="0"
          [externalSorting]="true"
          [messages]="{
            emptyMessage:
              searchValue !== '' && getData().length === 0
                ? (noResultState | translate)
                : (emptyState | translate)
          }">
          <ngx-datatable-column
            *ngFor="let col of columns"
            [name]="col.name"
            [prop]="col.prop"
            [pipe]="col.pipe"
            [sortable]="col?.sortable"
            [cellClass]="col.cellClass"
            [headerClass]="col.headerClass + ' text_bold'">
            <ng-template ngx-datatable-header-template>
              <span class="text-wrap text-center">
                {{ col.name }}
              </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div
                class="d-flex flex-row"
                [ngClass]="
                  col.prop === 'name' ? 'justify-content-start' : 'justify-content-center'
                ">
                <div class="text-center">
                  {{ col.pipe ? col.pipe.transform(row[col.prop]) : row[col.prop] }}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="zippedColumns[0].name"
            *ngIf="zippedColumns[0]"
            [cellClass]="zippedColumns[0].cellClass"
            [sortable]="false"
            [headerClass]="zippedColumns[0].headerClass + ' text_bold'">
            <ng-template ngx-datatable-header-template>
              <div class="text-center">
                {{ zippedColumns[0].name }}
              </div>
              <div class="d-flex flex-row align-items-center justify-content-center">
                <div
                  *ngFor="let headerCol of zippedColumns; let i = index"
                  (click)="onShort(headerCol.prop)"
                  class="px-2 pointer">
                  {{ customHeaderTranslation[headerCol.prop] || '%' }}
                  <span
                    class="datatable-icon-sort-unset sort-btn"
                    *ngIf="tableFilterState[headerCol.prop] === undefined"></span>
                  <span
                    class="sort-btn datatable-icon-up sort-asc"
                    *ngIf="tableFilterState[headerCol.prop] === true"></span>
                  <span
                    class="sort-btn datatable-icon-down sort-desc"
                    *ngIf="tableFilterState[headerCol.prop] === false"></span>
                </div>
              </div>
            </ng-template>

            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex flex-row align-items-center justify-content-center">
                <div
                  *ngFor="let col of zippedColumns; let i = index"
                  [ngClass]="
                    (i === 0 ? 'border-right _border_light' : '') +
                    ' ' +
                    col.cellClass({ value: row[col.prop] })
                  "
                  class="px-2 w-100">
                  {{
                    zippedColumns[i].pipe
                      ? zippedColumns[i].pipe.transform(row[col.prop])
                      : row[col.prop]
                  }}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
