import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateHelperService } from './../../services/date-helper';
import { StatsOptions } from '@app/models';
import { ChartProvider } from './chart.provider';
import { customNumberFilter } from './../../pipes';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { MathService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class StatsProvider {
  constructor(
    private dateHelper: DateHelperService,
    private chartProvider: ChartProvider,
    private mathService: MathService,
  ) {}

  private cleanOption: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private cleanOption$: Observable<string> = this.cleanOption.asObservable();
  private options: BehaviorSubject<StatsOptions> = new BehaviorSubject<StatsOptions>({
    range: this.dateHelper.getDefaultRange(),
    rangeType: 'day',
    targetData: { id: '', organizationId: '' },
    type: '',
    installationDate: '',
    targetSettings: {},
  });
  private options$: Observable<StatsOptions> = this.options.asObservable();

  public setOptions(option: StatsOptions): void {
    const combined = { ...this.options.value, ...option };
    this.options.next(combined);
  }

  public getOptions(): Observable<StatsOptions> {
    return this.options$;
  }

  public mapDataToTransformRateCluster(
    ticketsData: any[],
    ivsData: any,
    options: StatsOptions,
  ): any[] {
    var _dataHolder: any[] = [];
    var numberOfDay: any[] = this.chartProvider.getChartRange(
      null,
      null,
      options.rangeType,
      options.range,
    )[0];
    var _dataHolderVs: any[] = [];

    numberOfDay.forEach((dataKey, index) => {
      _dataHolder.push(
        this.mathService.getAverage(
          this.mathService.getAttractivityPercent(ticketsData[0][index], ivsData[0][index]),
          options.targetData.localBusiness.length,
        ),
      );
    });
    numberOfDay.forEach((dataKeyVs, index) => {
      _dataHolderVs.push(
        this.mathService.getAverage(
          this.mathService.getAttractivityPercent(ticketsData[1][index], ivsData[1][index]),
          options.targetData.localBusiness.length,
        ),
      );
    });
    return [_dataHolder, _dataHolderVs];
  }

  public mapDataToTransformRate(ticketsData: any[], ivsData: any, options: StatsOptions): any[] {
    var _dataHolder: any[] = this.chartProvider
      .getChartRange(null, null, options.rangeType, options.range)[0]
      .reduce((acc, curr) => ((acc[Number(curr)] = 0), acc), {});

    var _dataHolderVs: any[] = this.chartProvider
      .getChartRange(null, null, options.rangeType, options.range)[1]
      .reduce((acc, curr) => ((acc[Number(curr)] = 0), acc), {});
    const _holder = _dataHolder;
    const _holderVs = _dataHolderVs;
    if (!ivsData[0]) {
      ivsData[0] = _dataHolder;
    }
    if (!ivsData[1]) {
      ivsData[1] = _dataHolderVs;
    }
    Object.keys(ivsData[0]).forEach((key: any) => {
      _holder[key] = Number(ivsData[0][key]) || 0;
    });
    const _ticketsHolder = [];
    Object.keys(ticketsData[0]).forEach((key: any) => {
      _ticketsHolder.push(Number(ticketsData[0][key]['tickets']) || 0);
    });
    Object.keys(ivsData[1]).forEach((key: any) => {
      _holderVs[key] = ivsData[1][key] || 0;
    });
    const _ticketsHolderVs = [];
    Object.keys(ticketsData[1]).forEach((key: any) => {
      _ticketsHolderVs.push(Number(ticketsData[1][key]['tickets']) || 0);
    });

    var i = 0;
    Object.keys(_dataHolder).forEach((dataKey) => {
      _dataHolder[dataKey] = this.mathService.getAttractivityPercent(
        _ticketsHolder[i],
        _dataHolder[dataKey],
      );
      i++;
    });
    var iVs = 0;
    Object.keys(_dataHolderVs).forEach((dataKeyVs) => {
      _dataHolderVs[dataKeyVs] = this.mathService.getAttractivityPercent(
        _ticketsHolderVs[iVs],
        _dataHolderVs[dataKeyVs],
      );
      iVs++;
    });
    return [_dataHolder, _dataHolderVs];
  }

  public truncInferiorToTwenty(value: number) {
    var formattedValue;
    const _number = Number((value + '').replace('.', ',').replace(',', '.').replace("'", ''));
    if (_number && isFinite(_number) && !isNullOrUndefined(_number)) {
      formattedValue = _number;
    } else {
      formattedValue = 0;
    }
    if (Math.abs(formattedValue) < 20) {
      return customNumberFilter(formattedValue, 2, ',', ' ');
    } else {
      return customNumberFilter(formattedValue, 0, ',', ' ');
    }
  }

  public emitCleanOptions(event: string): void {
    this.cleanOption.next(event);
  }

  public getCleanOptions(): Observable<string> {
    return this.cleanOption$;
  }

  public mapTicketsSalesAndTransformForCSV(
    cegidData: any,
    zippedData: any,
    attendanceTimeData: any,
    numberOfBusiness: any,
    isCegidData,
  ): any[] {
    const holder = [];
    for (let i = 0; i < numberOfBusiness; i++) {
      holder.push({
        id: attendanceTimeData[i].id,
        name: attendanceTimeData[i].name,
        rang: 0,
        entryNumber: zippedData[i].current,
        attractivenessRate: customNumberFilter(
          this.getAttractiveness(
            zippedData[i].currentWifiPassages,
            zippedData[i].currentWifiVisits,
            true,
          ),
        ),
        attendanceTime: this.dateHelper.millisecondsToMinutes(
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].current),
        ),
        salesRevenue: customNumberFilter(
          cegidData[0][i]?.ca
            ? this.mathService.upto2Decimal(Number(cegidData[0][i]?.ca), true)
            : 0,
          2,
          ',',
          ' ',
        ),
        transformRate: this.mathService.getAttractivityPercent(
          cegidData[0][i]?.tickets ? cegidData[0][i]?.tickets : 0,
          zippedData[i].current,
        ),
        billTickets: cegidData[0][i]?.tickets
          ? customNumberFilter(Number(cegidData[0][i]?.tickets), 0)
          : 0,
        percent: 0,
        isCegidDataAvailable: isCegidData,
      });
    }

    const toRank = [
      'fluctuationAttractiveness',
      'fluctuationAttendanceTime',
      'fluctuationTransform',
      'fluctuationSalesRevenue',
      'fluctuationBillTickets',
      'fluctuationEntryNumber',
    ];
    toRank.forEach((rankField) => {
      holder.sort(function (a, b) {
        return (
          Number((b[rankField] + '').replace('.', '').replace(',', '.').replace("'", '')) -
          Number((a[rankField] + '').replace('.', '').replace(',', '.').replace("'", ''))
        );
      });

      var rank = 1;
      for (var i = 0; i < holder.length; i++) {
        if (
          i > 0 &&
          (Number(
            (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
          ) ===
            Number(
              (holder[i - 1][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
            ) ||
            Number(
              (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
            ) <
              Number(
                (holder[i - 1][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
              ))
        ) {
          rank++;
        }
        holder[i]['rank' + rankField.Capitalize()] = rank;
      }
    });
    return holder;
  }

  public mapTicketsSalesAndTransform(
    cegidData: any,
    zippedData: any,
    attendanceTimeData: any,
    numberOfBusiness: any,
    isCegidData,
  ): any[] {
    const holder = [];
    for (let i = 0; i < numberOfBusiness; i++) {
      holder.push({
        id: attendanceTimeData[i].id,
        name: attendanceTimeData[i].name,
        rang: 0,
        entryNumber: zippedData[i].current,
        entryNumberVs: zippedData[i].versus,
        attractivenessRate: customNumberFilter(
          this.getAttractiveness(
            zippedData[i].currentWifiPassages,
            zippedData[i].currentWifiVisits,
            true,
          ),
        ),
        attractivenessRateVs: customNumberFilter(
          this.getAttractiveness(
            zippedData[i].versusWifiPassages,
            zippedData[i].versusWifiVisits,
            true,
          ),
        ),
        attendanceTime: this.dateHelper.millisecondsToMinutes(
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].current),
        ),
        attendanceTimeVs: this.dateHelper.millisecondsToMinutes(
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].versus),
        ),
        salesRevenue: customNumberFilter(
          cegidData[0][i]?.ca
            ? this.mathService.upto2Decimal(Number(cegidData[0][i]?.ca), true)
            : 0,
          2,
          ',',
          ' ',
        ),
        salesRevenueVs: customNumberFilter(
          cegidData[1][i]?.ca
            ? this.mathService.upto2Decimal(Number(cegidData[1][i]?.ca), true)
            : 0,
          2,
          ',',
          ' ',
        ),
        transformRate: this.mathService.getAttractivityPercent(
          cegidData[0][i]?.tickets ? cegidData[0][i]?.tickets : 0,
          zippedData[i].current,
        ),
        transformRateVs: this.mathService.getAttractivityPercent(
          cegidData[1][i]?.tickets ? cegidData[1][i]?.tickets : 0,
          zippedData[i].versus,
        ),
        billTickets: cegidData[0][i]?.tickets
          ? customNumberFilter(Number(cegidData[0][i]?.tickets), 0)
          : 0,
        billTicketsVs: cegidData[1][i]?.tickets
          ? customNumberFilter(Number(cegidData[1][i]?.tickets), 0)
          : 0,
        percent: 0,
        percentEntryNumber: this.calcPc(zippedData[i].versus, zippedData[i].current),
        percentAttractiveness: this.calcPc(
          Number(
            this.getAttractiveness(
              zippedData[i].versusWifiPassages,
              zippedData[i].versusWifiVisits,
              true,
            ),
          ),
          Number(
            this.getAttractiveness(
              zippedData[i].currentWifiPassages,
              zippedData[i].currentWifiVisits,
              true,
            ),
          ),
        ),
        percentAttendanceTime: this.calcPc(
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].versus),
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].current),
        ),
        percentSalesRevenue: this.calcPc(
          cegidData[1][i]?.ca ? Number(cegidData[1][i]?.ca) : 0,
          cegidData[0][i]?.ca ? Number(cegidData[0][i]?.ca) : 0,
        ),
        percentTransformRate: this.calcPc(
          this.mathService.getAttractivityPercent(
            cegidData[1][i]?.tickets ? Number(cegidData[1][i]?.tickets) : 0,
            zippedData[i].versus,
          ),
          this.mathService.getAttractivityPercent(
            cegidData[0][i]?.tickets ? Number(cegidData[0][i]?.tickets) : 0,
            zippedData[i].current,
          ),
        ),
        percentBillTickets: this.calcPc(
          cegidData[1][i]?.tickets ? Number(cegidData[1][i]?.tickets) : 0,
          cegidData[0][i]?.tickets ? Number(cegidData[0][i]?.tickets) : 0,
        ),
        fluctuation: 0,
        fluctuationAttractiveness: customNumberFilter(
          Number(
            this.getAttractiveness(
              zippedData[i].currentWifiPassages,
              zippedData[i].currentWifiVisits,
              true,
            ),
          ) -
            Number(
              this.getAttractiveness(
                zippedData[i].versusWifiPassages,
                zippedData[i].versusWifiVisits,
                true,
              ),
            ),
        ),
        fluctuationAttendanceTime: this.dateHelper.millisecondsToMinutes(
          this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].current) -
            this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].versus),
        ),
        fluctuationTransform: customNumberFilter(
          this.mathService.getAttractivityPercent(
            cegidData[0][i]?.tickets ? Number(cegidData[0][i]?.tickets) : 0,
            zippedData[i].current,
          ) -
            this.mathService.getAttractivityPercent(
              cegidData[1][i]?.tickets ? Number(cegidData[1][i]?.tickets) : 0,
              zippedData[i].versus,
            ),
          2,
          ',',
          ' ',
        ),
        fluctuationSalesRevenue: customNumberFilter(
          this.mathService.upto2Decimal(
            (isCegidData ? Number(cegidData[0][i]?.ca) : 0) -
              (isCegidData ? Number(cegidData[1][i]?.ca) : 0),
          ),
          2,
          ',',
          ' ',
        ),
        fluctuationBillTickets: customNumberFilter(
          (cegidData[0][i]?.tickets ? Number(cegidData[0][i]?.tickets) : 0) -
            (cegidData[0][i]?.tickets ? Number(cegidData[1][i]?.tickets) : 0),
          0,
        ),
        fluctuationEntryNumber: customNumberFilter(zippedData[i].current - zippedData[i].versus, 0),
        isCegidDataAvailable: isCegidData,
      });
    }

    const toRank = [
      'fluctuationAttractiveness',
      'fluctuationAttendanceTime',
      'fluctuationTransform',
      'fluctuationSalesRevenue',
      'fluctuationBillTickets',
      'fluctuationEntryNumber',
    ];
    toRank.forEach((rankField) => {
      holder.sort(function (a, b) {
        return (
          Number((b[rankField] + '').replace('.', '').replace(',', '.').replace("'", '')) -
          Number((a[rankField] + '').replace('.', '').replace(',', '.').replace("'", ''))
        );
      });

      var rank = 1;
      for (var i = 0; i < holder.length; i++) {
        if (
          i > 0 &&
          (Number(
            (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
          ) ===
            Number(
              (holder[i - 1][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
            ) ||
            Number(
              (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
            ) <
              Number(
                (holder[i - 1][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
              ))
        ) {
          rank++;
        }
        holder[i]['rank' + rankField.Capitalize()] = rank;
      }
    });
    return holder;
  }

  public mapToCSV(
    allData: any, // > zippedata
    chartData: any,
    cegidData: any,
    attendanceTimeData: any,
    zoneTimeSpent: any,
    options,
    numberOfRows: any,
  ): any[] {
    const holder = [];
    let allZoneIdHolder = [];
    for (let i = 0; i < numberOfRows; i++) {
      const boxData = [];
      chartData[i].forEach((_chartData) => {
        let dataToChart = this.chartProvider.mapApiDataToChartDataByHours(
          _chartData,
          options,
          [[], []],
          true,
        );
        let chartToCsvEntryNumber: any = {};
        dataToChart[0].data.forEach((entry, index) => {
          chartToCsvEntryNumber[dataToChart[0].labels[index]] = dataToChart[0].data[index];
        });
        Object.keys(dataToChart[0].info).forEach((infoKey, index) => {
          chartToCsvEntryNumber[infoKey] = dataToChart[0].info[infoKey];
        });
        boxData.push(chartToCsvEntryNumber);
      });

      const date = attendanceTimeData[i].timeDataCsv;
      let zoneObject = {};

      allData[i].zones.forEach((_zoneData) => {
        var zoneTimeHolder = zoneTimeSpent.find(
          (_zoneTimeSpentData) => _zoneTimeSpentData.id === attendanceTimeData[i].id,
        );
        var __zoneTimeData = zoneTimeHolder?.zones.find(
          (_zoneTimeData) =>
            _zoneTimeData.id === _zoneData.id && _zoneTimeData.box === _zoneData.box,
        );
        let name = _zoneData.name || 'N/A';
        let zoneId = _zoneData.id || null;
        let _zoneHolder = {
          [zoneId + '.' + 'zoneName']: name,
          [zoneId + '.' + 'zoneAudiance']: _zoneData?.currentZonePassage,
          [zoneId + '.' + 'zoneAtractivity']: customNumberFilter(
            this.mathService.upto2Decimal(_zoneData?.currentZonePassage / allData[i]?.current),
          ),
          [zoneId + '.' + 'zoneInteraction']: _zoneData?.currentStopCount,
          [zoneId + '.' + 'zoneAverageStop']: _zoneData?.currentZonePassage,
          [zoneId + '.' + 'zoneSumStopDuration']: Math.trunc(
            __zoneTimeData?.averageStopsDurationCurrent,
          ),
        };
        zoneObject = { ...zoneObject, ..._zoneHolder };
        allZoneIdHolder.push(zoneId);
      });
      boxData.forEach((__chartData, index) => {
        let passages = allData[i].attractivityVideoEnabled
          ? allData[i].currentVideoPassages
          : allData[i].currentWifiPassages / 0.295;

        let visits = allData[i].attractivityVideoEnabled ? allData[i].current : allData[i].current;

        if (index === 0) {
          holder.push({
            id: attendanceTimeData[i].id,
            name: attendanceTimeData[i].name,
            rang: 0,
            date: attendanceTimeData[i].timeData.dateVs,
            timeData: attendanceTimeData[i].timeData,
            day: date.day,
            month: date.month,
            year: date.year,
            entryNumber: allData[i].current,
            attractivenessRate: customNumberFilter(
              this.getAttractiveness(passages, visits, true),
              1,
              ',',
              ' ',
            ),
            attendanceTime: this.dateHelper.millisecondsToSeconds(
              this.dateHelper.secondsRemoveMilliseconds(attendanceTimeData[i].current),
            ),
            salesRevenue: customNumberFilter(cegidData[i]?.ca ? cegidData[i]?.ca : 0, 2, ',', ' '),
            transformRate: customNumberFilter(
              this.mathService.getAttractivityPercent(
                cegidData[i]?.tickets ? cegidData[i]?.tickets : 0,
                allData[i].current,
              ),
              1,
              ',',
              ' ',
            ),
            billTickets: cegidData[i]?.tickets
              ? customNumberFilter(Number(cegidData[i]?.tickets), 0)
              : 0,

            men: allData[i]?.men ? allData[i].men : 0,
            women: allData[i]?.women ? allData[i].women : 0,
            adult: allData[i]?.adult ? allData[i].adult : 0,
            child: allData[i]?.child ? allData[i].child : 0,

            ...__chartData,
            ...zoneObject,
          });
        } else {
          holder.push({
            id: attendanceTimeData[i].id,
            name: attendanceTimeData[i].name,
            rang: 0,
            date: attendanceTimeData[i].timeData.dateVs,
            timeData: attendanceTimeData[i].timeData,
            day: date.day,
            month: date.month,
            year: date.year,
            entryNumber: 0,
            attractivenessRate: 0,
            attendanceTime: 0,
            salesRevenue: 0,
            transformRate: 0,
            billTickets: 0,
            men: 0,
            women: 0,
            adult: 0,
            child: 0,
            ...__chartData,
          });
        }
      });
    }
    return holder;
  }

  public mapJourneyToCsv(allData) {
    const holder = [];
    const keys = Object.keys(allData.zones);
    // date length
    const zoneLength = Object.keys(allData.zones);
    if (zoneLength.length > 0) {
      // Create basic row for each zone
      zoneLength.forEach((zoneArrayId, zoneIndex) => {
        if (allData?.zones) {
          holder[zoneIndex][holder[zoneIndex]._id + '.' + 'zoneAudiance'] +=
            Number(allData?.zones[zoneArrayId]?.currentZonePassage) || 0;

          holder[zoneIndex][holder[zoneIndex]._id + '.' + 'zoneInteraction'] +=
            Number(allData?.zones[zoneArrayId]?.currentStopCount) || 0;

          holder[zoneIndex][holder[zoneIndex]._id + '.' + 'zoneSumStopDuration'] +=
            Number(allData?.zones[zoneArrayId]?.stopsDurationInZone) || 0;
        }
      });

      holder.forEach((zoneArrayId, holderIndex) => {
        holder[holderIndex][holder[holderIndex]._id + '.' + 'zoneAtractivity'] =
          customNumberFilter(
            holder[holderIndex][holder[holderIndex]._id + '.' + 'zoneAudiance'] / allData.current,
            1,
            ',',
            ' ',
          ) || 0;
        holder[holderIndex][holder[holderIndex]._id + '.' + 'zoneAverageStop'] =
          this.dateHelper.roundedSeconds(
            holder[holderIndex][holder[holderIndex]._id + '.' + 'zoneSumStopDuration'] /
              holder[holderIndex][holder[holderIndex]._id + '.' + 'zoneInteraction'],
          );
      });
    }
    return { data: holder, businessId: allData.id };
  }

  public calcPc(n1: any, n2: any, args?: { debug?: boolean; isTimestamp?: boolean }): any {
    const _n1 = Number((n1 + '').replace('.', ',').replace(',', '.').replace("'", ''));

    const _n2 = Number((n2 + '').replace('.', ',').replace(',', '.').replace("'", ''));

    if (
      (Number(_n2) === 0 || !isFinite(Number(_n2)) || isNaN(Number(_n2))) &&
      (Number(_n1) === 0 || !isFinite(Number(_n1)) || isNaN(Number(_n1)))
    ) {
      return '0,00';
    }
    if (Number(_n2) === 0 || !isFinite(Number(_n2)) || isNaN(Number(_n2))) {
      return '-100';
    }
    if (Number(_n1) === 0 || !isFinite(Number(_n1)) || isNaN(Number(_n1))) {
      return '100';
    }

    return customNumberFilter(
      this.mathService.upto2Decimal(
        ((this.mathService.upto2Decimal(_n2) - this.mathService.upto2Decimal(_n1)) /
          this.mathService.upto2Decimal(_n1)) *
          100,
      ),
      2,
      ',',
      ' ',
    ).toString();
  }
  public getAttractiveness(
    wifiPassages: number,
    wifiVisits: number,
    forceNumber?: boolean,
    raw?: boolean,
  ): any {
    const _wifiPassages = Number(
      (wifiPassages + '').replace('.', ',').replace(',', '.').replace("'", ''),
    );

    const _wifiVisit = Number(
      (wifiVisits + '').replace('.', ',').replace(',', '.').replace("'", ''),
    );
    if (_wifiPassages === 0) {
      return 0;
    }
    if (_wifiVisit === 0) {
      return 0;
    }

    if (!forceNumber) {
      return customNumberFilter(
        this.mathService.upto2Decimal((_wifiVisit / _wifiPassages) * 100) || 0,
        2,
      );
    } else if (forceNumber && raw) {
      return _wifiPassages !== 0 || _wifiVisit !== 0 ? (_wifiVisit / _wifiPassages) * 100 || 0 : 0;
    } else {
      return _wifiPassages !== 0 || _wifiVisit !== 0
        ? this.mathService.upto2Decimal((_wifiVisit / _wifiPassages) * 100 || 0)
        : 0;
    }
  }
}
