<div class="w-100 d-flex flex-row align-items-center dropdown">
  <div>Choix de l'enseigne :</div>
  <button
    type="button"
    class="btn btn-light dropdown-toggle"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false">
    {{ orgaSelected.legalName || 'Choisir une organisation' }}
  </button>

  <div class="dropdown-menu">
    <div class="dropdown-item mb-3 p-1">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'searchSign' | translate }}"
        [(ngModel)]="searchValue" />
    </div>
    <div class="scrollable_details" id="scrollable_profil_details_organizations">
      <div
        class="dropdown-item px-3 py-1 bg-dark text-white"
        *ngIf="asSelectedOrga"
        (click)="emitOrga(null)">
        {{ 'orgaDropdownSeeAll' | translate }}
      </div>
      <div
        class="dropdown-item px-3"
        *ngFor="let orga of orgaList | arrayFilter : searchValue : 'legalName'"
        (click)="emitOrga(orga)">
        {{ orga.legalName }}
      </div>
    </div>
  </div>
</div>
