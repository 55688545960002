import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Businesses, UserApi } from '@app/models';
import { environment } from '@env/environment';
import unserialize from '@app/lib/php_unserialize';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  public getUserDetailById(id: string | number): Observable<any> {
    return this.http.get<UserApi>(`${environment.backendDomain}/users/details/${id}`).pipe(
      map((value) => {
        if (value.roles) {
          value.roles = unserialize(value.roles);
        }
        return value;
      }),
    );
  }

  public getUser(): Observable<any> {
    return this.http.get<UserApi>(`${environment.backendDomain}/me`);
  }

  public getUserById(id: number): Observable<any> {
    return this.http.get<UserApi>(`${environment.backendDomain}/user/${id}`);
  }

  public getUserFavoriteLocalBusiness(): Observable<any> {
    return this.http.get<UserApi>(`${environment.backendDomain}/user/localbusiness/favorite`);
  }

  public postUserFavoriteLocalBusiness(user: UserApi, localBusiness: Businesses): Observable<any> {
    const updateFavorite = this.http.put<UserApi>(`${environment.backendDomain}/me`, {
      favoriteLocalBusiness: localBusiness.id,
    });

    const getUpdatedLocalBusiness = this.http.get<UserApi>(
      `${environment.backendDomain}/localbusiness/${localBusiness.id}`,
      {},
    );

    return updateFavorite.pipe(mergeMap(() => getUpdatedLocalBusiness));
  }

  public deleteUserFavoriteLobalBusiness(user: UserApi): Observable<any> {
    return this.http.delete<UserApi>(
      `${environment.backendDomain}/user/${user.id}/settings/localbusiness/favorite`,
    );
  }

  public getUserAndRelation(): Observable<any> {
    return this.http.get<any>(`${environment.backendDomain}/user/relations`).pipe(
      map((value) => {
        value.forEach((_value) => {
          if (_value.users_id) {
            _value.users_id = unserialize(_value.users_id);
          }
          if (_value.local_businesses_id) {
            _value.local_businesses_id = unserialize(_value.local_businesses_id);
          }
        });
        return value;
      }),
    );
  }

  public createUserAndRelation(user: UserApi | any): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/createrelateduser`, user);
  }

  public editUserAndRelation(user: UserApi, id: any): Observable<any> {
    return this.http.put<any>(`${environment.backendDomain}/relateduser/${id}`, user);
  }

  public resetAccountPassword(token: string, newPassword: any): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/password/forgotten/new`, {
      token: null,
      password: newPassword,
    });
  }

  public deleteUserAndRelation(user: UserApi): Observable<any> {
    return this.http.delete<any>(`${environment.backendDomain}/relateduser/${user.id}`);
  }

  public deleteUserRelationWithOrganization(
    username: string,
    organizationId: any,
  ): Observable<any> {
    return this.http.delete<UserApi>(
      `${environment.backendDomain}/users/${username}/organizations/${organizationId}`,
    );
  }
  public deleteUserRelationWithBusiness(username: string, businessId: any): Observable<any> {
    return this.http.delete<UserApi>(
      `${environment.backendDomain}/users/${username}/localbusinesses/${businessId}`,
    );
  }

  public addUserRelationWithOrganization(username: string, organizationId: any): Observable<any> {
    return this.http.post<UserApi>(
      `${environment.backendDomain}/users/${username}/organizations/${organizationId}`,
      {},
    );
  }
  public addUserRelationWithBusiness(username: string, businessId: any): Observable<any> {
    return this.http.post<UserApi>(
      `${environment.backendDomain}/users/${username}/localbusinesses/${businessId}`,
      {},
    );
  }

  public getStatusForResetPassword(username: string, confirmToken: string): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/status/reset/password`, {
      username: username,
      token: confirmToken,
    } as never);
  }
}
