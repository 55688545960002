import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegularExpressionConstant, RoleConstant } from '@app/const';
import { Businesses, Cluster, Organization, UserApi } from '@app/models';
import { DataProvider } from '@app/providers';
import { RefreshProvider } from '@app/providers/layout';
import { ApiService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-edition',
  templateUrl: './user-edition.component.html',
  styleUrls: ['./user-edition.component.scss'],
})
export class UserEditionComponent implements OnInit {
  @Input() userInfo: UserApi;

  public loading = false;
  public userEditionForm: UntypedFormGroup;
  public applyMask = { password: false, confirmPassword: false };
  public organizations: Organization[] = [];
  public businesses: Businesses[] = [];
  public user: UserApi;

  private _businesses: Businesses[];

  public _organizationId: any = [];
  public _businessesId: [] = [];

  public clusterList: any[] = [];
  public _clusterList: any[] = [];

  public holderOrganizationId: [] = [];
  public holderBusinessesId: [] = [];

  public submitted = false;
  public roles = RoleConstant;

  public searchValue: string;
  public editPassword = false;

  private sub: Subscription;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private dataProvider: DataProvider,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    public dectectorRef: ChangeDetectorRef,
    private refreshProvider: RefreshProvider,
  ) {
    this.sub = this.dataProvider.getLastUserSelected().subscribe((userApi: UserApi) => {
      this.user = userApi;
      this.apiService.organisation
        .getOrganizationForUser(userApi.id)
        .pipe(map((value) => value.map((_value) => _value.id)))
        .subscribe((value) => {
          this._organizationId = value as [];
          this.holderOrganizationId = value as [];
        });

      this.apiService.business
        .getBusinessesByUser(userApi.id)
        .pipe(map((value) => value.map((_value) => _value.id)))
        .subscribe((value) => {
          this._businessesId = value as [];
          this.holderBusinessesId = value as [];
          setTimeout(() => {
            this.filterBusinessByOrga();
          });
        });

      this.dataProvider.getOrganization().subscribe((data) => {
        this.organizations = data;
      });

      this._clusterList = this.user.clusters.map((cluster) => cluster.id);

      this.dataProvider.getBusinesses().subscribe((_data) => {
        this._businesses = _data;
        this.filterBusinessByOrga();
      });

      this.dataProvider.getClusters().subscribe((__data) => {
        this.clusterList = __data;
      });
    });
  }

  ngOnInit(): void {
    this.userEditionForm = this.formBuilder.group({
      newUsername: [
        this.user.username,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      newEmail: [
        this.user.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(RegularExpressionConstant.EMAIL),
        ]),
      ],
      roles: [this.user.roles[0] || 'ROLE_USER', Validators.required],
      organization: [this.formBuilder.array(this._organizationId, Validators.required)],
      localBusiness: [this.formBuilder.array(this._businessesId, Validators.required)],
      clusters: [this.formBuilder.array(this._clusterList, Validators.required)],
    });
  }

  public onSubmit(): void {
    if (
      this.userEditionForm.valid &&
      this._organizationId.length > 0 &&
      this._businessesId.length > 0
    ) {
      this.submitted = true;

      const user: any = {
        email: this.userEditionForm.value.newEmail,
        username: this.userEditionForm.value.newUsername,
        roles: [this.userEditionForm.value.roles],
        organizationId: this.userEditionForm.value.organization,
        localBusinessId: this.userEditionForm.value.localBusiness,
        localBusinessClusterId: this.userEditionForm.value.clusters,
      };

      this.apiService.user.editUserAndRelation(user, this.user.id).subscribe(
        () => {
          this.refreshProvider.emit({
            'profil-shared-components': {},
          });
          this.closeModal();
          this.submitted = false;
        },
        () => {
          this.closeModal();
        },
      );
    }
  }

  public selectAllOrganizations(): void {
    this._organizationId = this.organizations.map((orga: any) => orga.id);
    this.filterBusinessByOrga();
  }

  public unselectAllOrganizations(): void {
    this._organizationId = [];
    this.filterBusinessByOrga();
  }

  public unselectAllBusinesses(): void {
    this._businessesId = [];
  }

  public selectAllClusters(): void {
    this._clusterList = this.clusterList.map((cluster) => cluster.id);
  }

  public unselectAllClusters(): void {
    this._clusterList = [];
  }
  public selectAllBusinesses(): void {
    let _holder: any = [];
    this._organizationId.forEach((orgaId) => {
      _holder = [
        ..._holder,
        ...this._businesses.filter((business) => business.organization === orgaId),
      ];
    });
    this._businessesId = _holder.map((value) => value.id);
  }

  public filterBusinessByOrga(): void {
    let _holder = [];
    this._organizationId.forEach((orgaId) => {
      _holder = [
        ..._holder,
        ...this._businesses.filter((business) => business.organization === orgaId),
      ];
    });

    // let __holder = [];
    // this._organizationId.forEach((orgaId) => {
    //     this.apiService.cluster
    //         .getClusterByOrganizationId(orgaId)
    //         .subscribe((cluster) => {
    //             __holder = [...__holder, ...cluster];
    //             this.clusterList = __holder;
    //         });
    // });

    this.businesses = _holder;

    if (this._organizationId.length === 0) {
      this.businesses = [];
      // this.clusterList = [];
    }
  }

  public toogleMask(key: string): void {
    this.applyMask[key] = !this.applyMask[key];
  }
  public closeModal(): void {
    this.sub.unsubscribe();
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.sub.unsubscribe();
    this.activeModal.dismiss();
  }

  public toogleEditPassword(): void {
    this.userEditionForm.value.plainPassword = this.editPassword ? '' : this.getMaskedPassword();
    this.editPassword = !this.editPassword;
  }

  private getMaskedPassword(): void {
    Array(this.userInfo.plainPassword.length).join('*');
  }
}
