import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CronService {
  constructor(private http: HttpClient) {}

  public setSelfUserDashboardReporting(isActive: boolean) {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/user/reporting`, {
      state: isActive,
    });
  }

  public getUserDashboardMaillingList(isActive: boolean) {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/user/reporting`, {
      state: isActive,
    });
  }

  public addUserDashboardMaillingList(isActive: boolean) {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/user/reporting`, {
      state: isActive,
    });
  }

  public editUserDashboardMaillingList(isActive: boolean) {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/user/reporting`, {
      state: isActive,
    });
  }

  public removeUserDashboardMaillingList(isActive: boolean) {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/user/reporting`, {
      state: isActive,
    });
  }
}
