<div class="list-group h-100 mh-30">
  <div class="d-flex flex-row align-items-center pb-2">
    <h5 class="font-weight-bold m-0">
      {{ 'listCluster' | translate }}
    </h5>
    <!-- <div
      class="add_item_action ms-auto pointer"
      (click)="addCluster()"
      placement="top-end"
      ngbTooltip="{{ 'tooltip.newCluster' | translate }}"
      *ngIf="
        user &&
        (user.roles[0] === 'ROLE_SUPER_ADMIN' ||
          user.roles[0] === 'ROLE_ADMIN' ||
          user.roles[0] === 'ROLE_SUPER_USER')
      ">
      <img width="20px" src="/assets/svg/AddItem.svg" />
    </div> -->
  </div>
  <ng-template #loader>
    <ngx-skeleton-loader count="6"></ngx-skeleton-loader>
  </ng-template>
  <div *ngIf="clusterList$ | async; let clusterList; else: loader">
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'searchCluster' | translate }}"
        [(ngModel)]="searchValue" />
      <div class="input-group-append">
        <div class="h-100 px-3 d-flex align-items-center bg-dark rounded-end">
          <img src="/assets/svg/Search.svg" />
        </div>
      </div>
    </div>
    <div class="scrollable_details" id="scrollable_profil_details_clusterlist">
      <div
        class="list-group-item list-group-item-action border-0 d-flex flex-row d-flex pointer"
        *ngFor="let cluster of clusterList | arrayFilter : searchValue : 'name'">
        <div class="content d-flex flex-column px-3 w-100" (click)="openDetails(cluster)">
          <div class="text-start">
            {{ cluster.name }}
          </div>
        </div>
        <!-- <button
          class="btn btn-outline-light p-1 px-2 ms-auto"
          placement="end"
          (click)="deleteCluster(cluster)"
          [ngbTooltip]="('tooltip.deleteCluster' | translate) + cluster.name"
          *ngIf="
            user &&
            (user.roles[0] === 'ROLE_SUPER_ADMIN' ||
              user.roles[0] === 'ROLE_ADMIN' ||
              user.roles[0] === 'ROLE_SUPER_USER')
          ">
          <img width="20px" src="/assets/svg/Trash.svg" />
        </button>
        <button
          class="btn btn-outline-light p-1 px-2"
          placement="end"
          (click)="editCluster(cluster)"
          [ngbTooltip]="('tooltip.editCluster' | translate) + cluster.name"
          *ngIf="
            user &&
            (user.roles[0] === 'ROLE_SUPER_ADMIN' ||
              user.roles[0] === 'ROLE_ADMIN' ||
              user.roles[0] === 'ROLE_SUPER_USER')
          ">
          <img width="20px" src="/assets/svg/Pencil.svg" />
        </button> -->
      </div>
    </div>
  </div>
</div>
