import { Component, OnInit } from '@angular/core';
import { UserProvider } from '@app/providers';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  public $userData: any;

  constructor(private userProvider: UserProvider) {}

  ngOnInit(): void {
    this.$userData = this.userProvider.getData();
  }
}
