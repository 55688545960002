import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table-mailling-list',
  templateUrl: './table-mailling-list.component.html',
  styleUrls: ['./table-mailling-list.component.scss'],
})
export class TableMaillingListComponent {
  @Input() rows;

  editing = {};

  selected = [];

  columns: any[] = [
    {
      prop: 'email',
      name: "Liste d'utilisateur",
    },
  ];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  getCellClass = () => {
    return {
      'd-flex flex-row ': true,
    };
  };

  @Output() valueChange = new EventEmitter();

  constructor() {}

  public onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  public updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    this.valueChange.emit([...this.rows]);
  }

  public deleteValue(item) {
    this.rows.splice(this.rows.indexOf(item as never), 1);
    this.valueChange.emit([...this.rows]);
  }
}
