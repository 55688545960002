<div class="journey-plan d-flex flex-row justify-content-center align-items-center h-100 w-100">
  <div class="row d-flex m-0 align-items-start h-100 w-100 py-2">
    <div class="col-12 pb-1 ps-0 ms-0">
      <div ngbDropdown class="d-inline-block pb-1">
        <button
          id="dropdownBasic1"
          ngbDropdownToggle
          class="btn rounded-end text_bold elevation-static-2 bg-white text-black"
          placement="end"
          [ngbTooltip]="'tooltip.customerJourney.' + activeTab | translate">
          {{ getActiveToHuman() | translate }}
        </button>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            ngbDropdownItem
            (click)="onKpiSelect(tab)"
            placement="end"
            [ngbTooltip]="'tooltip.customerJourney.' + tab | translate"
            [disabled]="tab === activeTab"
            *ngFor="let tab of allTabs">
            {{ 'customerJourneyPage.actionBar.' + tab | translate }}
          </button>
        </div>
      </div>

      <button
        type="button"
        class="btn mx-2 rounded-end text_bold elevation-static-2 bg-white text-black"
        aria-label="Left Align"
        (click)="zoom()">
        Zoomer
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-zoom-in"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          <path
            d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
          <path
            fill-rule="evenodd"
            d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z" />
        </svg>
      </button>
      <button
        type="button"
        class="btn mx-2 rounded-end text_bold elevation-static-2 bg-white text-black"
        aria-label="Left Align"
        (click)="unZoom()">
        Dézoomer

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-zoom-out"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          <path
            d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
          <path
            fill-rule="evenodd"
            d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
        </svg>
      </button>

      <button
        type="button"
        class="btn mx-2 rounded-end text_bold elevation-static-2 bg-white text-black"
        aria-label="Left Align"
        (click)="setDefaultView()">
        Réinitialiser la vue

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-zoom-out"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          <path
            d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
          <path
            fill-rule="evenodd"
            d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
        </svg>
      </button>
    </div>
    <div class="col-8 h-100 pb-1 ps-0 ms-0">
      <div class="card h-100 w-100 position-relative">
        <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0">
          <pan-zoom [config]="panZoomConfig">
            <div
              #planCanvasContainer
              id="_planCanvasContainer"
              class="d-flex align-content-center justify-content-center">
              <canvas #planCanvas style="width: 100%; height: 100%" id="plan_canvas"> </canvas>
              <span #planCanvasToolTip class="_canvas_toolTip">ToolTip</span>
            </div>
          </pan-zoom>
        </div>
        <div class="canvas_wrap_loading" *ngIf="loading">
          <svg-icon
            width="100%"
            height="100%"
            src="/assets/svg/spinner/tail-spin.svg"
            alt="user"></svg-icon>
        </div>
        <div *ngIf="unavailable" class="canvas_wrap_loading">
          {{ 'zone.no2DZone' | translate }}
        </div>
      </div>
    </div>
    <div class="col pb-1 ps-0 ms-0">
      <div class="card cardInfo">
        <div class="card-body">
          <h5 class="card-title">
            {{ isZoneSelected ? selectedzoneInfo['name'] : '' }}
          </h5>
          <h6 *ngIf="isZoneSelected" class="card-subtitle mb-2 text-muted fadeIn">
            {{
              isZoneSelected
                ? ('zone.zoneSelected' | translate)
                : ('zone.zoneUnSelected' | translate)
            }}
          </h6>
          <h6 *ngIf="!isZoneSelected" class="card-subtitle mb-2 text-muted fadeIn">
            {{
              isZoneSelected
                ? ('zone.zoneSelected' | translate)
                : ('zone.zoneUnSelected' | translate)
            }}
          </h6>
          <div *ngIf="isZoneSelected" class="fadeIn">
            <div class="col-12 py-2 px-0 m-0">
              <div class="col-12 p-0 m-0">
                <div class="d-flex flex-row align-items-center">
                  <div
                    class="colorBox"
                    [style.background]="selectedzoneInfo?.canvasColor_audiance"></div>
                  <div class="text_bold">Audience :&nbsp;</div>
                  <div class="d-flex flex-row">
                    <div class="text_bold text_blue">
                      {{ selectedzoneInfo?.audianceVs }}
                    </div>
                    <div class="text_bold px-1">|</div>
                    <div class="text_bold text_orange">
                      {{ selectedzoneInfo?.audiance }}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center">
                  <div
                    class="colorBox"
                    [style.background]="selectedzoneInfo?.canvasColor_atractivity"></div>
                  <div class="text_bold">Attractivité :&nbsp;</div>
                  <div class="d-flex flex-row">
                    <div class="text_bold text_blue">
                      {{ customNumberFilter(selectedzoneInfo?.atractivityVs, 0) + ' %' }}
                    </div>
                  </div>
                  <div class="text_bold px-1">|</div>

                  <div class="text_bold text_orange">
                    {{ customNumberFilter(selectedzoneInfo?.atractivity, 0) + ' %' }}
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center">
                  <div
                    class="colorBox"
                    [style.background]="selectedzoneInfo?.canvasColor_interaction"></div>
                  <div class="text_bold">Interactions :&nbsp;</div>
                  <div class="d-flex flex-row">
                    <div class="text_bold text_blue">
                      {{ selectedzoneInfo?.interactionVs }}
                    </div>
                  </div>
                  <div class="text_bold px-1">|</div>
                  <div class="text_bold text_orange">
                    {{ selectedzoneInfo?.interaction }}
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center">
                  <div
                    class="colorBox"
                    [style.background]="selectedzoneInfo?.canvasColor_averageStop"></div>
                  <div class="text_bold">Durée moyennes d'arrêt :&nbsp;</div>
                  <div class="d-flex flex-row">
                    <div class="text_bold text_blue">
                      {{ selectedzoneInfo?.averageStopVs }}
                    </div>
                    <div class="text_bold px-1">|</div>
                    <div class="text_bold text_orange">
                      {{ selectedzoneInfo?.averageStop }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
