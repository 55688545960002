import { saveAs } from 'file-saver';
import { ConstDataExport } from '@app/const';
import { DateHelperService } from '@app/services';
import { Businesses, Organization } from '@app/models';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataPreprocessProvider, DataProvider, GaProvider, StatsProvider } from '@app/providers';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-selection',
  templateUrl: './export-selection.component.html',
  styleUrls: ['./export-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportSelectionComponent implements OnInit {
  private availableCegidId = [232, 233, 234, 235, 239, 240, 241];

  public organization: Organization;
  public organizations: Organization[];
  public exportSelectionForm: UntypedFormGroup;

  public businesses: Businesses[];
  public businessesHolder: Businesses[];

  public state = '';
  public feedback = '';
  public dataToExport = ConstDataExport;
  public rangeTypes = ['day', 'week', 'month', 'custom'].map((val) => 'shared.' + val);

  constructor(
    public formatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    private statsProvider: StatsProvider,
    private formBuilder: UntypedFormBuilder,
    private dataProvider: DataProvider,
    private calendar: NgbCalendar,
    private dateHelper: DateHelperService,
    private dataPreprocessProvider: DataPreprocessProvider,
    private $ga: GaProvider,
  ) {}

  ngOnInit() {
    this.dataToExport = ConstDataExport;
    this.dataPreprocessProvider.setState('');
    this.dataPreprocessProvider.setPreprocessState('Initialisation...');

    this.dataPreprocessProvider.getState().subscribe((state) => {
      this.state = state;
      if (state === 'done') {
        setTimeout(() => {
          this.activeModal.close();
        }, 500);
      }
    });

    this.dataPreprocessProvider
      .getPreprocessState()
      .subscribe((feedback) => (this.feedback = feedback));

    this.exportSelectionForm = this.formBuilder.group({
      formatFn: ['CSV', Validators.compose([Validators.required])],
      rangeType: [this.rangeTypes[0], Validators.compose([Validators.required])],
      range: [
        {
          start: this.calendar.getToday(),
          end: this.calendar.getToday(),
          startVs: this.calendar.getToday(),
          endVs: this.calendar.getToday(),
        },
        Validators.compose([Validators.required]),
      ],
    });
    this.dataProvider.getBusinesses().subscribe((value) => (this.businessesHolder = value));

    this.dataProvider.getOrganization().subscribe((value) => {
      this.organizations = value;
      this.organization = value[0];
      this.filterBusinessByOrga();
    });
  }

  public cleanRange(): void {
    this.statsProvider.emitCleanOptions('Clean !');
    setTimeout(() => {
      this.statsProvider.emitCleanOptions('Clean !');
    });
  }

  public filterBusinessByOrga(): void {
    this.businesses = this.businessesHolder.filter(
      (business) => business.organization === this.organization.id,
    );
  }

  public dismissModal() {
    this.activeModal.dismiss();
  }

  public updateFormByKey(key: string, value: any, forceRefreshChild?: boolean) {
    this.exportSelectionForm.patchValue({ [key]: value });
    if (forceRefreshChild) {
      setTimeout(() => {
        this.statsProvider.emitCleanOptions(
          'Clean form who need to be when this value is changed !',
        );
      });
      setTimeout(() => {
        this.statsProvider.emitCleanOptions(
          'Clean form who need to be when this value is changed again cause we had an issue with event!',
        );
      }, 250);
    }
  }

  public confirmModal(): void {
    if (this.exportSelectionForm.valid) {
      if (this.exportSelectionForm.value.formatFn === 'CSV') {
        // Need to clean that, now backend send if business had cegid info
        this.businesses.map((business: any) => {
          const range = {
            begin: this.dateHelper.timeStampBeginOfDay(
              this.dateHelper.utcTimeStampToLocalTimestamp(
                this.dateHelper.ngbCalendarToTimestamp(this.exportSelectionForm.value.range.start),
              ),
            ),
            end: this.dateHelper.timeStampEndOfDay(
              this.dateHelper.utcTimeStampToLocalTimestamp(
                this.dateHelper.ngbCalendarToTimestamp(this.exportSelectionForm.value.range.end),
              ),
            ),
          };

          business.organizationName = this.organizations.filter(
            (organization) => organization.id === business.organization,
          )[0].legalName;
          business.organizationId = this.organizations.filter(
            (organization) => organization.id === business.organization,
          )[0].id;
          business.range = range;
          business.isCegid = this.availableCegidId.includes(business.id);
          return business;
        });
      }

      this.businesses = this.businessesHolder.filter(
        (business) => business.organization === this.organization.id,
      );

      this.$ga.sendEvent(
        'file_export',
        `Export ${this.exportSelectionForm.value.formatFn} ${
          this.exportSelectionForm.value.rangeType.split('.')[1]
        }`,
      );

      // this line trigger even generateCSV or generatePDF function below
      this['generate' + this.exportSelectionForm.value.formatFn]();
    }
  }

  private generateCSV(): void {
    const range = this.exportSelectionForm.value.range;
    const beginDate = range.start;
    const endDate = range.end;
    const beginDateString = `${beginDate.year}-${beginDate.month}-${beginDate.day}`;
    const endDateString = `${endDate.year}-${endDate.month}-${endDate.day}`;
    const formattedFileName = `export_ivstore_${beginDateString}_to_${endDateString}.csv`;

    this.dataPreprocessProvider
      .generateCSV(this.exportSelectionForm.value.range, this.organization.id)
      .subscribe(
        (value) => {
          saveAs(value, formattedFileName);
          this.activeModal.close();
        },
        (err) => {
          console.log(err);
        },
      );
  }

  private generatePDF(): void {
    const range = this.exportSelectionForm.value.range;
    const beginDate = range.start;
    const endDate = range.end;
    const beginDateString = `${beginDate.year}-${beginDate.month}-${beginDate.day}`;
    const endDateString = `${endDate.year}-${endDate.month}-${endDate.day}`;
    const formattedFileName = `export_ivstore_${beginDateString}_to_${endDateString}.pdf`;

    this.dataPreprocessProvider
      .generatePDF(this.exportSelectionForm.value.range, this.organization.id)
      .subscribe(
        (value) => {
          saveAs(value, formattedFileName);
          this.activeModal.close();
        },
        (err) => {
          console.log(err);
        },
      );
  }
}
