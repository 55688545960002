<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'userLoggedAccountEdition' | translate }}
  </h4>
  <button type="button" class="btn-close close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column p-1 col-12">
    <div class="h5 d-flex text-center text-dark">
      {{ 'forgot-password.edit' | translate }}
    </div>
    <form
      *ngIf="!isPasswordChanged; else passwordIsChanged"
      [formGroup]="doResetForm"
      (ngSubmit)="onSubmit()"
      autocomplete="new-password"
      class="text-center">
      <div class="row justify-content-start">
        <div class="form-group mb-2 mw-350 position-relative">
          <app-input
            label="password"
            _class="input_default rounded-0"
            [_type]="
              this.doResetForm.controls.newPlainPassword.value.length === 0
                ? 'text'
                : doRestFieldType
            "
            [_appendRight]="true"
            [_appendRightIsActive]="true"
            (inputChange)="updateFormByKey('newPlainPassword', $event)"
            [required]="true"
            [isValid]="!doResetForm.controls.newPlainPassword.valid && alreadySubmited"
            [isInvalid]="!doResetForm.controls.newPlainPassword.valid && alreadySubmited"
            isInvalidFeedBack="invalidPassword"
            [showIsValidLabel]="false"></app-input>
        </div>
      </div>

      <div class="row justify-content-start">
        <div class="form-group mb-2 mw-350 position-relative">
          <app-input
            label="confirmPassword"
            _class="input_default rounded-0"
            [_type]="
              this.doResetForm.controls.newPlainPasswordConfirmation.value.length === 0
                ? 'text'
                : doRestFieldType
            "
            [_appendRight]="true"
            [_appendRightIsActive]="true"
            (inputChange)="updateFormByKey('newPlainPasswordConfirmation', $event)"
            [required]="true"
            [isValid]="!doResetForm.controls.newPlainPasswordConfirmation.valid && alreadySubmited"
            [isInvalid]="
              !doResetForm.controls.newPlainPasswordConfirmation.valid && alreadySubmited
            "
            isInvalidFeedBack="invalidConfirmPassword"
            [showIsValidLabel]="false"></app-input>
        </div>
      </div>
      <div class="py-2">
        <div class="alert alert-secondary text-start" role="alert">
          {{ 'passwordRules' | translate }}
        </div>
      </div>

      <div *ngIf="doResetForm?.errors?.passwordDissmiss" class="row justify-content-start"></div>
      <div class="row justify-content-start">
        <div class="py-1 w-auto">
          <button type="button" class="btn btn_black" (click)="closeModal()">
            {{ 'resetPage.askReset.action.cancel' | translate }}
          </button>
        </div>
        <div class="py-1 w-auto ms-auto">
          <button type="button" class="btn btn_black" (click)="onSubmit()">
            {{ 'resetPage.askReset.action.continue' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <ng-template #passwordIsChanged>
    <div class="d-flex flex-column p-1 w-100 text-center">
      <div class="col-12 py-2">
        {{ 'forgot-password.passwordIsChanged' | translate }}
      </div>

      <div class="col-12 py-2">
        {{ 'forgot-password.BackLoginIn5Seconds' | translate }}
      </div>

      <div class="row justify-content-start">
        <div class="py-1 w-auto ms-auto">
          <button type="button" class="btn btn_black" (click)="closeModal()">
            {{ 'resetPage.confirmReset.action.backLogin' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
