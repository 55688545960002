<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'export-push-mail.mainTitle' | translate }}
  </h4>
  <button type="button" class="btn-close" (click)="dismissModal()"></button>
</div>
<div class="modal-body border-dark border-top">
  <div class="d-flex flex-column border rounded">
    <div class="row justify-content-center">
      <div class="col-12 mb-3">
        <div class="fs-4 d-flex flex-column text-center">
          <i class="bi bi-inboxes fs-3" width="25px" height="25px"></i>
          {{ 'export-push-mail.emptyState' | translate }}
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 mb-3">
        <div class="text-muted d-flex flex-column text-center">
          <div>
            {{ 'export-push-mail.emptyState-2' | translate }}
          </div>
          <div>
            {{ 'export-push-mail.emptyState-3' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 mb-3">
        <div class="text-center">
          <button (click)="createFirst()" type="button" class="btn btn-outline-secondary">
            <i class="bi bi-inbox"></i>
            {{ 'export-push-mail.emptyStateAction' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger elevation-static-1"
    aria-label="Close"
    (click)="dismissModal()">
    {{ 'cancel' | translate }}
  </button>
</div>
