import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { DateHelperService } from '@app/services';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selection-export',
  templateUrl: './selection-export.component.html',
  styleUrls: ['./selection-export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectionExportComponent implements OnInit, OnDestroy {
  @ViewChild('datepicker') datepicker: NgbDatepicker;

  @Output() onSelect = new EventEmitter();

  @Input() rangeType: string;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  yearDate: { year: number };

  public dateLimit: NgbDate = this.calendar.getToday();

  public modelNgDatePicker;

  private subs: Subscription[] = [];

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    private dateHelper: DateHelperService,
    private statsProvider: StatsProvider,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const sub = this.statsProvider.getCleanOptions().subscribe(() => {
      this.cleanDate();
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public cleanDate(): void {
    switch (this.rangeType) {
      case 'day':
        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getToday();
        break;
      case 'week':
        this.toDate = this.dateHelper.getNext(
          this.dateHelper.getStartOfTheMonth(this.calendar.getToday(), 'week'),
          'days',
          6,
        );
        this.fromDate = this.dateHelper.getStartOfTheMonth(this.calendar.getToday(), 'week');

        break;
      case 'custom':
        this.fromDate = this.dateLimit;
        this.toDate = this.dateLimit;
        break;
      case 'month':
        var date = this.calendar.getToday();
        date.day = 1;
        this.fromDate = this.dateHelper.getStartOfTheMonth(date, 'month');
        this.toDate = this.dateHelper.getLimitOfTheMonth(date, 'month');
      default:
        console.warn('@ components(range selection) : no range type provided');
        break;
    }
    setTimeout(() => {
      this.onSelect.emit({
        start: this.fromDate,
        end: this.toDate,
      });
      this.changeDetector.detectChanges();
    });
  }

  public navigate(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  public today() {
    this.datepicker.navigateTo(this.calendar.getToday());
  }

  public isRenderAsDateRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      (this.isInside(date) && this.isHovered(date))
    );
  }

  public formatInput(ngbDate: NgbDate) {
    if (ngbDate?.day) {
      return this.dateHelper.ngbToHuman(ngbDate);
    } else {
      return '';
    }
  }

  public formatByType(date: NgbDate, dateVs: NgbDate): string {
    switch (this.rangeType) {
      case 'day':
        return !date ? "Choix d'un jour" : this.formatter.format(date);
      case 'week':
        if (date) {
          return 'S :' + this.dateHelper.toWeekNumber(date);
        }
        return "Choix d'une semaine";
      case 'custom':
        if (date) {
          return this.formatter.format(date);
        }
        return '';
      default:
        console.warn('@ components(range format by type) : no range type provided');
        break;
    }
  }

  public onDateSelection(date: NgbDate): void {
    switch (this.rangeType) {
      case 'day':
        this.fromDate = date;
        this.toDate = date;
        this.onSelect.emit({
          start: this.fromDate,
          end: this.toDate,
        });
        break;
      case 'week':
        this.fromDate = date;
        this.toDate = this.dateHelper.getNext(date, 'days', 6);
        this.onSelect.emit({
          start: this.fromDate,
          end: this.toDate,
        });
        break;
      case 'custom':
        if (!this.fromDate) {
          this.fromDate = date;
        } else if (!this.toDate) {
          if (date.before(this.fromDate)) {
            this.toDate = this.fromDate;
            this.fromDate = date;
          } else {
            this.toDate = date;
          }
        } else if (this.fromDate && this.toDate) {
          this.fromDate = date;
          this.toDate = null;
        }
        this.onSelect.emit({
          start: this.fromDate,
          end: this.toDate,
        });
        break;

      default:
        console.warn('@ components(range selection) : no range type provided');
        break;
    }
  }

  public fromDateYearSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDate = this.dateHelper.getStartOfTheMonth(date, 'year');
    this.toDate = this.dateHelper.getLimitOfTheMonth(date, 'year');
    this.onSelect.emit({
      start: this.fromDate,
      end: this.toDate,
    });
  }
  public fromDateMonthSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDate = this.dateHelper.getStartOfTheMonth(date, 'month');
    this.toDate = this.dateHelper.getLimitOfTheMonth(date, 'month');
    this.onSelect.emit({
      start: this.fromDate,
      end: this.toDate,
    });
  }

  public editSelectedDate(field: string, secondField?: string): void {
    this[field] = null;
    if (secondField) {
      this[secondField] = null;
    }
  }

  public isHovered(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDate) && date.after(this.fromDate);
    }
  }

  public isInside(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDate) && date.after(this.fromDate);
    }
    return date.after(this.toDate) && date.before(this.fromDate);
  }

  public isDisabled(date: NgbDate, current: { month: number }): boolean {
    if (this.calendar.getToday().before(date)) {
      return true;
    }
    if (this.rangeType === 'week') {
      return !(this.calendar.getWeekday(date) === 1);
    }
    return false;
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  public closeModal(): void {
    if (this.toDate && this.fromDate) {
      this.activeModal.close();
    }
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
