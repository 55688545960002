<div
  class="row d-flex flex-column align-items-center justify-content-center"
  [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="text-dark col-12 d-flex flex-column align-items-center justify-content-center">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="my-2">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      class="my-2"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="col-12 text-center">
    <button type="button" (click)="openChange()" class="w-100 btn btn-primary mb-2">
      Voir les changements
    </button>

    <button type="button" (click)="action($event)" class="w-100 btn btn-danger">
      Ne plus afficher
    </button>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
