import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ConstKpiData } from '@app/const';
import autoTable, { CellHookData } from 'jspdf-autotable';

@Injectable()
export class PdfUtilsService {
  private kpiKeys = Object.keys(ConstKpiData);
  public exportDataToPdf(): void {
    const data = [];
    this.generate(data);
  }

  private generateIVSTemplate(): jsPDF {
    const pdf = new jsPDF();

    return pdf;
  }
  private generate(data: any[]): void {
    var doc = new jsPDF();
    var position = 20;
    var leftPosition = 5;
    var objectPosition = 30;
    var defaultX = 30;
    var lightFontSize = 10;
    var fontSize = 13;
    var middleOfPage = doc.internal.pageSize.width / 2;
    var bolderFontSize = 20;
    var p_3 = 12;
    var p_4 = 20;
    var p_2 = 8;
    var p_1 = 4;

    var colorBlack = { r: 0, g: 0, b: 0 };
    var colorGreyLight = { r: 122, g: 122, b: 122 };
    var colorTableHeader = { r: 8, g: 103, b: 145 };
    var colorTableCell = { r: 130, g: 174, b: 194 };

    doc.setFontSize(fontSize);
    doc.line(objectPosition, position, 170, position);
    position += p_2;
    doc.setFont('helvetica', 'bold');
    doc.text(
      'Extraction de vos données IVStore',
      middleOfPage,
      position,
      { align: 'center' },
      'center',
    );
    position += p_2;
    doc.setFontSize(bolderFontSize);
    doc.text('MILLIM', middleOfPage, position, { align: 'center' }, 'center');
    position += p_1;
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(lightFontSize);
    doc.setTextColor(colorGreyLight.r, colorGreyLight.g, colorGreyLight.b);
    doc.text(
      'Date de reference - 16 Juin 2021',
      middleOfPage,
      position,
      { align: 'center' },
      'center',
    );
    position += p_1;
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(lightFontSize);
    doc.text("Periode d'extraction - Jour", middleOfPage, position, { align: 'center' }, 'center');
    position += p_1;
    doc.line(30, position, 170, position);
    position += p_4;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(fontSize);
    doc.setTextColor(colorBlack.r, colorBlack.g, colorBlack.b);
    doc.text('CLASSEMENT DE MES MAGASINS', leftPosition, position);
    position += p_2;
    autoTable(doc, {
      startY: position,
      didParseCell: (data: CellHookData) => {
        if (data.row.index === 1) {
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fillColor = '#FF5783';
        }
      },
      columnStyles: {
        0: {
          halign: 'center',
          textColor: [colorBlack.r, colorBlack.g, colorBlack.b],
          fillColor: [colorTableCell.r, colorTableCell.g, colorTableCell.b],
        },
      },
      body: [
        { europe: 'Sweden', america: 'Canada', asia: 'China' },
        { europe: 'Norway', america: 'Mexico', asia: 'Japan' },
      ],
      columns: [
        { header: 'Europe', dataKey: 'europe' },
        { header: 'Asia', dataKey: 'asia' },
      ],
    });
    window.open(URL.createObjectURL(doc.output('blob')));
  }
}
