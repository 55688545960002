export const environment = {
  production: false,
  backendDomain: 'https://api.preprod.ivstore.fr',
  soapMapDomain: 'https://soap-map.ivsoftware.fr',
  soapMapApiKey: 'Cm4=3wh/zeXGWw+=pP=t^{?vHwyb^o',
  ga: 'G-EBC9CDXHQE',
  version: '1.0.0',
  secret: 'My mega secret',
  hmr: false,
};
