import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Businesses, Organization, Cluster, UserApi } from '@app/models';
import { DataProvider } from '@app/providers';
import { ApiService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cluster-edition',
  templateUrl: './cluster-edition.component.html',
  styleUrls: ['./cluster-edition.component.scss'],
})
export class ClusterEditionComponent {
  public clusterEditionForm: UntypedFormGroup;

  public submited = false;

  public organizations: Organization[];
  public _organizationId: [] = [];
  public _businessesId: any = [];
  public _businesses: Businesses[];
  public businessesHolder: Businesses[];
  public usersId: UserApi[] | any = [];
  public _usersId: UserApi[] | any = [];
  public searchValueBusinesses: string;
  public searchValueOrga: string;

  public loading = false;
  public organization: Organization;

  private cluster: Cluster | any;

  public user: any;

  constructor(
    private apiService: ApiService,
    public formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProvider,
  ) {
    this.dataProvider.getLastClusterSelected().subscribe((cluster: any) => {
      this.dataProvider.getUser().subscribe((user) => (this.user = user));

      this.cluster = cluster;
      this.organization = cluster.organization[0];
      this._businessesId = cluster.localBusiness.map((value) => value.id);
      this._usersId = cluster.users
        .map((value) => value.id)
        .filter((value) => value !== this.user.id);

      this.dataProvider.getBusinesses().subscribe((_data) => {
        this._businesses = _data;
        this.businessesHolder = _data;
        this.filterBusinessByOrga();
      });

      this.dataProvider.getUserList().subscribe((_value) => {
        this.usersId = _value;
      });

      this.clusterEditionForm = this.formBuilder.group({
        clusterName: [
          cluster.name,
          Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
          ]),
        ],
      });
    });
  }

  public selectAllUsers(): void {
    this._usersId = this.usersId.map((user: any) => user.id);
  }

  public unselectAllUsers(): void {
    this._usersId = [];
  }

  public unselectAllBusinesses(): void {
    this._businessesId = [];
  }

  public selectAllBusinesses(): void {
    this._businessesId = this._businesses
      .filter((business) => business.organization === this.organization.id)
      .map((business) => business.id);
  }

  public onSubmit(): void {
    this.loading = true;
    if (this.clusterEditionForm.valid && this._businessesId.length > 0) {
      this.apiService.cluster
        .editCluster(
          {
            name: this.clusterEditionForm.value.clusterName,
            localBusinessId: this._businessesId,
            organizationId: [this.organization.id],
            userId: this._usersId,
          },
          this.cluster.id,
        )
        .subscribe(() => {
          this.loading = false;
          this.closeModal();
        });
    }
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  public filterBusinessByOrga(): void {
    this._businesses = this.organization.id
      ? this._businesses.filter((business) => business.organization === this.organization.id)
      : this.businessesHolder;
  }

  public closeModal(): void {
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
