<div
  class="comptage_page fadeIn text_grey d-flex flex-column justify-content-start w-100 m-0 col-12 px-1 m-0"
  style="max-height: 90%">
  <div class="row d-flex m-0 align-items-center px-0">
    <div class="col-12 px-1">
      <app-counting-action-bar
        page="comptage"
        [showExport]="true"
        [colAuto]="false"></app-counting-action-bar>
    </div>
  </div>
  <div class="row d-flex m-0 align-items-center px-0">
    <div class="col-12 px-1 mx-0">
      <app-counting-kpi></app-counting-kpi>
    </div>
  </div>

  <div class="row d-flex w-100 p-0 m-0 py-2 justify-content-center">
    <div
      class="p-0 m-0 chart-first elevation-static-1"
      [ngClass]="{ chartFirstTotal: !financialData }">
      <app-charts [data]="chartData[chartsToDisplay[0]]" [loading]="loading"></app-charts>
    </div>

    <div
      class="chart-second me-auto ms-auto position-relative"
      [ngClass]="{ displayNone: !financialData }">
      <div class="row px-0" *ngIf="isAgeGenderEnabled(); else dataWithoutAgeGender">
        <ng-container *ngIf="!loading; else loadingAgeGender">
          <ng-container *ngIf="!isAgeGenderEmpty; else emptyAgeGender">
            <div class="col-12 p-0 m-0 mb-2 elevation-static-1">
              <app-gender [genderData]="$data.current.chartAgeGender.gender"></app-gender>
            </div>

            <div class="col-12 m-0 p-0 elevation-static-1">
              <app-age [ageData]="$data.current.chartAgeGender.age"> </app-age>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #dataWithoutAgeGender>
  <div class="col-12 p-0 m-0 mb-2 elevation-static-1">
    <app-charts [data]="chartData[chartsToDisplay[1]]" [loading]="loading"></app-charts>
  </div>
  <div class="col-12 m-0 p-0 elevation-static-1">
    <app-charts [data]="chartData[chartsToDisplay[2]]" [loading]="loading"></app-charts>
  </div>
</ng-template>

<ng-template #emptyAgeGender>
  <div class="card h-100 p-0 m-0 mb-2">
    <div class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black">
      <div class="d-flex p-right-2 wrap_chart_icon">
        <img height="20px" src="/assets/svg/age-gender/gender/picto-white.svg" alt="gender kpi" />
      </div>

      <div class="h6 m-0 text-white font-weight-normal">
        {{ 'ageGender.genderTitle' | translate }}
      </div>
    </div>

    <div class="card-body position-relative p-0 m-0 h-100 py-6 text-center">
      <div class="h-100 w-100">
        {{ 'ageGender.emptyAgeGender' | translate }}
      </div>
    </div>
  </div>

  <div class="card h-100 p-0 m-0">
    <div
      class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black pe-5">
      <div class="d-flex p-right-2 wrap_chart_icon">
        <img height="20px" src="/assets/svg/age-gender/age/picto-white.svg" alt="age kpi" />
      </div>

      <div class="h6 m-0 text-white font-weight-normal">
        {{ 'ageGender.ageTitle' | translate }}
      </div>
    </div>

    <div class="card-body position-relative p-0 m-0 h-100 py-6 text-center">
      <div class="h-100 w-100">
        {{ 'ageGender.emptyAgeGender' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingAgeGender>
  <div class="card h-100 p-0 m-0 mb-2">
    <div class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black">
      <div class="d-flex p-right-2 wrap_chart_icon">
        <img height="20px" src="/assets/svg/age-gender/gender/picto-white.svg" alt="gender kpi" />
      </div>

      <div class="h6 m-0 text-white font-weight-normal">
        {{ 'ageGender.genderTitle' | translate }}
      </div>
    </div>

    <div class="card-body position-relative p-0 m-0 h-100 py-6 text-center">
      <div class="h-100 w-100">
        <div class="canvas_wrap_loading_age_gender">
          <svg-icon alt="spinner" src="/assets/svg/spinner/tail-spin.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="card h-100 p-0 m-0">
    <div
      class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black pe-5">
      <div class="d-flex p-right-2 wrap_chart_icon">
        <img height="20px" src="/assets/svg/age-gender/age/picto-white.svg" alt="age kpi" />
      </div>

      <div class="h6 m-0 text-white font-weight-normal">
        {{ 'ageGender.ageTitle' | translate }}
      </div>
    </div>

    <div class="card-body position-relative p-0 m-0 h-100 py-6 text-center">
      <div class="h-100 w-100">
        <div class="canvas_wrap_loading_age_gender">
          <svg-icon alt="spinner" src="/assets/svg/spinner/tail-spin.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
