import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organization } from '@app/models';

@Component({
  selector: 'app-user-orga',
  templateUrl: './user-orga.component.html',
  styleUrls: ['./user-orga.component.scss'],
})
export class UserOrgaComponent implements OnInit {
  @Input() orgaList: Organization[];
  @Input() orgaSelected: Organization;
  @Output() onSelect = new EventEmitter();
  @Output() afterInit = new EventEmitter();

  public asSelectedOrga = false;

  public searchValue: string;

  ngOnInit(): void {
    this.afterInit.emit(null);
  }

  emitOrga(organization: Organization): void {
    setTimeout(() => {
      this.asSelectedOrga = organization ? true : false;
    }, 250);
    this.onSelect.emit(organization);
  }
}
