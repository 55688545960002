<div class="list-group mh-30">
  <h5 class="font-weight-bold">
    {{ 'listStores' | translate }}
  </h5>
  <ng-template #loader>
    <ngx-skeleton-loader count="6" class="scrollable_details"></ngx-skeleton-loader>
  </ng-template>
  <div *ngIf="businesses$ | async; let businesses; else: loader">
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'searchStore' | translate }}"
        [(ngModel)]="searchValue" />
      <div class="input-group-append">
        <div class="h-100 px-3 d-flex align-items-center bg-dark rounded-end">
          <img src="/assets/svg/Search.svg" />
        </div>
      </div>
    </div>
    <div class="align-self-center" *ngIf="businesses.length === 0">
      <div class="p-2 text-bold">Cette enseigne ne contient aucun magasin</div>
    </div>
    <div class="scrollable_details" id="scrollable_profil_details_businesses">
      <div
        class="list-group-item list-group-item-action border-0 d-flex flex-row d-flex pointer"
        *ngFor="let business of businesses | arrayFilter : searchValue : 'legalName'">
        <div class="content px-3 text-start w-100" (click)="openDetails(business)">
          {{ business.legalName }}
        </div>
        <div
          class="favorite ms-auto pointer"
          container="body"
          [ngbTooltip]="'tooltip.businessToFavorite' | translate : { business: business.legalName }"
          placement="end"
          (click)="setFavorite(business)">
          <img
            width="20px"
            src="/assets/svg/Star_filled.svg"
            *ngIf="favorite && business.id === favorite.localBusiness.id" />
          <img
            width="20px"
            src="/assets/svg/Star.svg"
            alt="logo user"
            *ngIf="favorite && business.id !== favorite.localBusiness.id" />
        </div>
      </div>
    </div>
  </div>
</div>
