import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateRange, DateBase, StatsOptions, BusinessesSettings, Day } from '@app/models';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(private calendar: NgbCalendar) {}

  public utcTimeStampToLocalTimestamp(timestamp: number): number {
    return moment.utc(timestamp / 1000).valueOf() * 1000;
  }

  public secondsDurationToMinutes(duration: number): any {
    console.warn(duration);
    if (isNaN(duration) || duration === 0) return "00'00";
    return moment.utc(moment.duration(duration).asMilliseconds()).format("mm'ss");
  }

  public minutesAndSecondsToDuration(time: string): any {
    return moment(time, "mm'ss").valueOf() * 1000;
  }

  public timeStampEndOfDay(duration: number): number {
    const time = moment(duration / 1000);
    time.hour(23);
    time.minute(0);
    time.second(2);
    time.millisecond(0);
    return time.valueOf() * 1000;
  }

  public timeStampBeginOfDay(duration: number): number {
    const time = moment(duration / 1000);
    time.hour(0);
    time.minute(0);
    time.second(2);
    time.millisecond(0);

    return time.valueOf() * 1000;
  }

  public ngbToHuman(ngDate: NgbDate): string {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));
    return date.format('DD/MM/YYYY');
  }

  public toNgbCalendar(date: string) {
    const calendarFormat = this.calendar.getToday();

    const dateHolder = moment(new Date(date));
    calendarFormat.day = dateHolder.get('days');
    calendarFormat.month = dateHolder.get('months');
    calendarFormat.year = dateHolder.get('years');
    return calendarFormat;
  }

  public ngbCalendarToTimestamp(ngDate: NgbDate) {
    return moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day)).valueOf() * 1000;
  }

  public timestampToNgbCalendar(date: number) {
    const calendarFormat = { day: '', month: '', year: '' };

    const dateHolder = moment
      .utc(date / 1000)
      .format('DD MM YYYY')
      .split(' ');
    calendarFormat.day = dateHolder[0];
    calendarFormat.month = dateHolder[1];
    calendarFormat.year = dateHolder[2];
    return calendarFormat;
  }

  public timestampToHuman(
    duration: number,
    durationVs: number,
    type: string,
  ): { date: string; dateVs: string; type: string } {
    return {
      date: moment.utc(duration / 1000).format('DD MMMM YYYY'),
      dateVs: moment.utc(durationVs / 1000).format('DD MMMM YYYY'),
      type: type,
    };
  }

  public toHuman(options: StatsOptions): any {
    const start = moment(
      new Date(
        options.range.begin.year + '/' + options.range.begin.month + '/' + options.range.begin.day,
      ),
    );

    const startVs = moment(
      new Date(
        options.range.beginVs.year +
          '/' +
          options.range.beginVs.month +
          '/' +
          options.range.beginVs.day,
      ),
    );

    const end = moment(
      new Date(
        options.range.end.year + '/' + options.range.end.month + '/' + options.range.end.day,
      ),
    );

    const endVs = moment(
      new Date(
        options.range.endVs.year + '/' + options.range.endVs.month + '/' + options.range.endVs.day,
      ),
    );

    if (options.rangeType === 'day') {
      return {
        start: start.format('dd, DD/MM/YYYY').Capitalize(),
        startVs: startVs.format('dd, DD/MM/YYYY').Capitalize(),
      };
    } else if (options.rangeType === 'week') {
      return {
        start: `S ${start.format('WW, YYYY').Capitalize()} (${start.format('DD/MM')} - ${end.format('DD/MM')})`, // prettier-ignore
        startVs: `S ${startVs.format('WW, YYYY').Capitalize()} (${startVs.format('DD/MM')} - ${endVs.format('DD/MM')})`, // prettier-ignore
      };
    } else if (options.rangeType === 'month') {
      return {
        start: start.format('MMMM, YYYY').Capitalize(),
        startVs: startVs.format('MMMM, YYYY').Capitalize(),
      };
    } else if (options.rangeType === 'year') {
      return {
        start: 'Année ' + start.format('YYYY').Capitalize(),
        startVs: 'Année ' + startVs.format('YYYY').Capitalize(),
      };
    } else if (options.rangeType === 'custom') {
      return {
        start:
          start.format('DD/MM/YYYY').Capitalize() + ' Au ' + end.format('DD/MM/YYYY').Capitalize(),
        startVs:
          startVs.format('DD/MM/YYYY').Capitalize() +
          ' Au ' +
          endVs.format('DD/MM/YYYY').Capitalize(),
      };
    }
  }

  public toWeekNumber(ngDate: NgbDate): number {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));
    return date.week();
  }

  public toUnixTimestamp(ngDate: NgbDate): number {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    date.hour(0);
    date.minute(0);
    date.second(0);
    return date.valueOf() * 1000;
  }

  public secondsRemoveMilliseconds(value: number) {
    const duration = moment.duration(Math.abs(value) / 1000, 'seconds');

    return moment.utc(duration.asMilliseconds()).set('milliseconds', 0).startOf('second').valueOf();
  }

  public millisecondsToMinutes(value: number, truncToSuperior?: boolean): string {
    const duration = moment.duration(Math.abs(value) / 1000 || 0);

    if (truncToSuperior && Number(duration.milliseconds().toString()[0]) > 5) {
      duration.add(1, 'seconds');
    }

    return (
      (Math.sign(value) === -1 ? '/' : '') + moment.utc(duration.asMilliseconds()).format("mm'ss")
    );
  }

  public millisecondsToSeconds(value: number): string {
    const duration = moment.duration(Math.abs(value) / 1000 || 0);
    return (Math.sign(value) === -1 ? '/' : '') + Math.trunc(duration.asSeconds());
  }

  public secondsToMinutes(value: number): string {
    const duration = moment.duration(Math.abs(Math.trunc(value)) || 0, 'seconds');
    if (duration.seconds() === 0 && duration.minutes() === 0) {
      return "00'00";
    } else {
      return (
        (Math.sign(value) === -1 ? '/' : '') +
        moment
          .utc(moment(duration.asMilliseconds()).set('millisecond', 0).startOf('second'))
          .format("mm'ss")
      );
    }
  }

  public roundedSeconds(value: number): string {
    const duration = moment.duration(Math.abs(Math.trunc(value)) || 0, 'seconds');
    if (duration.seconds() === 0 && duration.minutes() === 0) {
      return '00';
    } else {
      let result = (Math.sign(value) === -1 ? '/' : '') + Math.trunc(duration.asSeconds());

      return result;
    }
  }

  public toUnixTimestampWithCheck(ngDate: NgbDate): number {
    const now = moment();
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    if (date.isSame(now, 'day')) {
      date.hour(now.get('hours'));
      date.minute(now.get('minutes'));
      date.second(now.get('seconds'));
      date.millisecond(now.get('milliseconds'));
    } else {
      date.hour(0);
      date.minute(0);
      date.second(0);
      date.millisecond();
    }
    return date.valueOf() * 1000;
  }

  public rangeToUnixTimestampWithCheck(
    range: { begin: NgbDate; end: NgbDate },
    rangeVs: { begin: NgbDate; end: NgbDate },
    businessSettings?: BusinessesSettings,
  ): { begin: number; beginVs: number; end: number; endVs: number } {
    var begin = new Date(
      Date.UTC(range.begin.year, range.begin.month - 1, range.begin.day, 0, 0, 0, 0),
    );

    var end = new Date(Date.UTC(range.end.year, range.end.month - 1, range.end.day, 0, 0, 0, 0));

    var beginVs = new Date(
      Date.UTC(rangeVs.begin.year, rangeVs.begin.month - 1, rangeVs.begin.day, 0, 0, 0, 0),
    );

    var endVs = new Date(
      Date.UTC(rangeVs.end.year, rangeVs.end.month - 1, rangeVs.end.day, 0, 0, 0, 0),
    );

    /* --------- set timestamp to opening and closing --------- */
    if (businessSettings) {
      const beginDate = new Date(
        range.begin.year + '/' + range.begin.month + '/' + range.begin.day,
      );
      const beginVsDate = new Date(
        rangeVs.begin.year + '/' + rangeVs.begin.month + '/' + rangeVs.begin.day,
      );
      const endDate = new Date(range.end.year + '/' + range.end.month + '/' + range.end.day);
      const endVsDate = new Date(
        rangeVs.end.year + '/' + rangeVs.end.month + '/' + rangeVs.end.day,
      );

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      const daysOfWork = Object.keys(businessSettings.openingCalendar);
      const settings = ['openingTime', 'closingTime'];

      daysOfWork.forEach((currentDaysOfWork) => {
        settings.forEach((setting) => {
          if (businessSettings.openingCalendar[currentDaysOfWork] !== 'closed') {
            if (setting === 'openingTime') {
              if (daysOfWeek[beginDate.getDay()] == currentDaysOfWork) {
                begin.setUTCHours(
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[0],
                  ),
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[1],
                  ),
                );
              }
              if (daysOfWeek[beginVsDate.getDay()] == currentDaysOfWork) {
                beginVs.setUTCHours(
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[0],
                  ),
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[1],
                  ),
                );
              }
            }

            if (setting === 'closingTime') {
              if (daysOfWeek[endDate.getDay()] == currentDaysOfWork) {
                end.setUTCHours(
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[0],
                  ),
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[1],
                  ),
                );
              }
              if (daysOfWeek[endVsDate.getDay()] == currentDaysOfWork) {
                endVs.setUTCHours(
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[0],
                  ),
                  Number(
                    businessSettings.openingCalendar[currentDaysOfWork][setting].split(':')[1],
                  ),
                );
              }
            }
          }
        });
      });
    } else {
      begin.setUTCHours(0, 0);
      beginVs.setUTCHours(0, 0);

      end.setUTCHours(23, 59);
      endVs.setUTCHours(23, 59);
    }
    return {
      begin: begin.valueOf() * 1000,
      end: end.valueOf() * 1000,
      beginVs: beginVs.valueOf() * 1000,
      endVs: endVs.valueOf() * 1000,
    };
  }

  public getDay(model) {
    let date = new Date(Date.UTC(model.year, model.month - 1, model.day, 0, 0, 0));
    let days = new Array(
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    );
    let day = days[date.getDay() - 1];
    return day;
  }

  public getDefaultRange(ngDate?: NgbDate): DateRange {
    const todayDate = new Date();
    const today = ngDate || this.calendar.getToday();

    const currentDayOfWeek = todayDate.getDay();

    const lastYearDate = new Date(
      todayDate.getFullYear() - 1,
      todayDate.getMonth(),
      todayDate.getDate(),
    );

    const dayDifference = currentDayOfWeek - lastYearDate.getDay();

    const lastYearSameDay = new Date(lastYearDate);
    lastYearSameDay.setDate(lastYearSameDay.getDate() + dayDifference);

    const lastYearNgbDate = new NgbDate(
      lastYearSameDay.getFullYear(),
      lastYearSameDay.getMonth() + 1,
      lastYearSameDay.getDate(),
    );

    return {
      begin: today,
      beginVs: lastYearNgbDate,
      end: today,
      endVs: lastYearNgbDate,
    };
  }

  public getDayRange(ngDate?: NgbDate): DateRange {
    const today = ngDate || this.calendar.getToday();
    return {
      begin: today,
      beginVs: this.getPrev(today, 'days', 1),
      end: today,
      endVs: this.getPrev(today, 'days', 1),
    };
  }

  public getWeekRange(ngDate?: NgbDate): DateRange {
    const today = ngDate || this.calendar.getToday();
    const todayVs = ngDate || this.calendar.getToday();
    return {
      begin: today,
      beginVs: this.getNext(today, 'days', 6),
      end: todayVs,
      endVs: this.getNext(todayVs, 'days', 6),
    };
  }

  public getMonthRange(ngDate?: NgbDate): DateRange {
    const today = ngDate || this.calendar.getToday();
    return {
      begin: this.getStartOfTheMonth(today, 'month'),
      beginVs: this.getStartOfTheMonth(this.getPrev(today, 'month', 1), 'month'),
      end: today,
      endVs: this.getPrev(today, 'month', 1),
    };
  }

  public getYearRange(ngDate?: NgbDate): DateRange {
    const today = ngDate || this.calendar.getToday();
    return {
      begin: this.getStartOfTheMonth(today, 'year'),
      beginVs: this.getStartOfTheMonth(this.getPrev(today, 'y', 1), 'year'),
      end: today,
      endVs: this.getPrev(today, 'y', 1),
    };
  }

  public getPrev(ngDate: NgbDate = null, limit: DateBase, duration: number): NgbDate {
    const calendarFormat = this.calendar.getToday();
    let previousDate: string[];

    if (ngDate) {
      previousDate = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day))
        .subtract(duration, limit)
        .format('YYYY/MM/DD')
        .split('/');
    } else {
      previousDate = moment().subtract(duration, limit).format('YYYY/MM/DD').split('/');
    }

    calendarFormat.day = Number(previousDate[2]);
    calendarFormat.month = Number(previousDate[1]);
    calendarFormat.year = Number(previousDate[0]);

    return calendarFormat;
  }

  public getNext(ngDate: NgbDate = null, limit: DateBase, duration: number): NgbDate {
    const calendarFormat = this.calendar.getToday();
    let previousDate: string[];

    if (ngDate) {
      previousDate = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day))
        .add(duration, limit)
        .format('YYYY/MM/DD')
        .split('/');
    } else {
      previousDate = moment().add(duration, limit).format('YYYY/MM/DD').split('/');
    }

    calendarFormat.day = Number(previousDate[2]);
    calendarFormat.month = Number(previousDate[1]);
    calendarFormat.year = Number(previousDate[0]);

    return calendarFormat;
  }

  public getLimitOfTheMonth(ngDate: NgbDate = null, limit: DateBase): NgbDate {
    const calendarFormat = this.calendar.getToday();
    let endOfMonth: string[];

    if (ngDate) {
      const now = moment();
      const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

      endOfMonth = !date.isAfter(now, 'day')
        ? moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day))
            .endOf(limit)
            .format('YYYY/MM/DD')
            .split('/')
        : now.format('YYYY/MM/DD').split('/');
    } else {
      endOfMonth = moment().endOf(limit).format('YYYY/MM/DD').split('/');
    }

    calendarFormat.day = Number(endOfMonth[2]);
    calendarFormat.month = Number(endOfMonth[1]);
    calendarFormat.year = Number(endOfMonth[0]);

    return calendarFormat;
  }

  public getStartOfTheMonth(ngDate: NgbDate = null, limit: DateBase): NgbDate {
    const calendarFormat = this.calendar.getToday();
    let startOfMonth: string[];

    if (ngDate) {
      startOfMonth = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day))
        .startOf(limit)
        .format('YYYY/MM/DD')
        .split('/');
    } else {
      startOfMonth = moment().startOf(limit).format('YYYY/MM/DD').split('/');
    }

    calendarFormat.day = Number(startOfMonth[2]);
    calendarFormat.month = Number(startOfMonth[1]);
    calendarFormat.year = Number(startOfMonth[0]);

    return calendarFormat;
  }

  public getDayByWeek(ngDate: NgbDate = null, day: Day): NgbDate {
    const calendarFormat = this.calendar.getToday();
    let dayByWeek: string[];

    if (ngDate) {
      dayByWeek = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day))
        .day(day)
        .format('YYYY/MM/DD')
        .split('/');
    } else {
      dayByWeek = moment().day(day).format('YYYY/MM/DD').split('/');
    }

    calendarFormat.day = Number(dayByWeek[2]);
    calendarFormat.month = Number(dayByWeek[1]);
    calendarFormat.year = Number(dayByWeek[0]);

    return calendarFormat;
  }

  public getDaysNumberInMonth(ngDate: NgbDate): number {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    return date.daysInMonth();
  }

  public getDayNumberOfTheWeek(ngDate: NgbDate): number {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    return date.day();
  }

  public cegidFormat(ngDate: NgbDate): string {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    return date.format('YYYY/MM/DD');
  }

  public timestampToCegid(date: number): string {
    return moment(date / 1000).format('YYYY/MM/DD');
  }

  public cegidFormatWithHour(ngDate: NgbDate, end?: boolean): string {
    const date = moment(new Date(ngDate.year + '/' + ngDate.month + '/' + ngDate.day));

    if (end) {
      date.minutes(0);
      date.hours(15);
      date.seconds(0);
    }
    return date.format('YYYY/MM/DD');
  }

  public enumerateDaysBetweenDate(
    startDate: NgbDate,
    endDate: NgbDate,
    format?: string,
    capitalize?: boolean,
  ): string[] {
    const _startDate = moment(
      new Date(startDate.year + '/' + startDate.month + '/' + startDate.day),
    );
    const _endDate = moment(new Date(endDate.year + '/' + endDate.month + '/' + endDate.day));

    var dates = [];

    const currDate = moment(_startDate);
    const lastDate = moment(_endDate);
    dates.push(currDate.clone().format(format || 'DD'));

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().format(format || 'DD'));
    }

    dates.push(
      currDate
        .add(1)
        .clone()
        .format(format || 'DD'),
    );
    if (capitalize) {
      dates = dates.map((value) => value.Capitalize());
    }
    return dates;
  }

  public enumerateDaysBetweenDateTimestamp(
    startDate: number,
    endDate: number,
  ): { start: number; end: number }[] {
    const _startDate = moment.utc(startDate / 1000).local(true);
    const _endDate = moment.utc(endDate / 1000).local(true);
    var dates = [];
    const currDate = _startDate.clone();
    const lastDate = _endDate.clone().add(1, 'days');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push({
        start: this.timeStampBeginOfDay(currDate.clone().valueOf() * 1000),
        end: this.timeStampEndOfDay(currDate.clone().valueOf() * 1000),
      });
    }

    return dates;
  }

  public enumerateMonthBetweenDate(startDate: NgbDate, endDate: NgbDate, format?: string): any {
    const _startDate = moment(
      new Date(startDate.year + '/' + startDate.month + '/' + startDate.day),
    );
    const _endDate = moment(new Date(endDate.year + '/' + endDate.month + '/' + endDate.day));
    const dates = [];

    const currDate = moment(_startDate).startOf('month').startOf('year');
    const lastDate = moment(_endDate).startOf('month').endOf('year');

    dates.push(currDate.clone().format(format || 'MM/DD'));
    while (currDate.add(1, 'months').diff(lastDate) < 0) {
      dates.push(currDate.clone().format(format || 'MM/DD'));
    }

    return dates;
  }

  public stringToEndOfMonth(date: string, format: string, formatReturn: string): string {
    return moment(date, format).endOf('month').format(formatReturn);
  }

  public checkInstalationDate(instalationDate, range, keys: string[]): any {
    var holderDate = this.calendar.getToday();
    const _holderDate = holderDate;
    const _holderInstalation = instalationDate;
    _holderDate.day = 1;
    _holderDate.month = 1;
    _holderDate.year = _holderDate.year - 1;
    _holderInstalation.day = 1;
    _holderInstalation.month = 1;

    if (instalationDate && _holderDate.after(_holderInstalation)) {
      const todayCalendar = moment();

      var pastCalendar = moment()
        .subtract(1, 'year')
        .isoWeek(todayCalendar.isoWeek())
        .isoWeekday(todayCalendar.isoWeekday());

      var pastCalendarVs = moment()
        .subtract(1, 'year')
        .isoWeek(todayCalendar.isoWeek())
        .isoWeekday(todayCalendar.isoWeekday());

      if (pastCalendar.isLeapYear()) {
        pastCalendar = pastCalendar.add(1, 'weeks');
        pastCalendarVs = pastCalendarVs.add(1, 'weeks');
      }

      const pastCalendarSplitted = pastCalendar.format('YYYY/MM/DD').split('/');

      const pastCalendarVsSplitted = pastCalendarVs.format('YYYY/MM/DD').split('/');

      const pastCalendarNgb = this.calendar.getToday();

      const pastCalendarVsNgb = this.calendar.getToday();

      pastCalendarNgb.day = Number(pastCalendarSplitted[2]);
      pastCalendarNgb.month = Number(pastCalendarSplitted[1]);
      pastCalendarNgb.year = Number(pastCalendarSplitted[0]);
      pastCalendarVsNgb.day = Number(pastCalendarVsSplitted[2]);
      pastCalendarVsNgb.month = Number(pastCalendarVsSplitted[1]);
      pastCalendarVsNgb.year = Number(pastCalendarVsSplitted[0]);
      range[keys[0]] = pastCalendarNgb;
      range[keys[1]] = pastCalendarVsNgb;
    } else {
      range[keys[0]] = this.getPrev(range[keys[0]], 'days', 7) as never;
      range[keys[1]] = this.getPrev(range[keys[1]], 'days', 7) as never;
    }
    return range;
  }
}
