<div class="w-100 h-100 bg_black position-relative">
  <div class="position-relative pointer" (click)="redirectToHome()">
    <img class="h-100 w-100 p-4" src="assets/svg/IVSLogoColored.svg" />
  </div>

  <ul class="list-group _nav_scroll">
    <li
      class="bg-transparent p-0"
      *ngFor="let navContent of navContents; let navIndexContent = index">
      <!-- PROFIL -->
      <button
        *ngIf="navContent.title === 'profil'"
        (click)="goPage(navContent.page)"
        class="d-flex d-row p-2 btn btn-transparent"
        [ngClass]="
          router.url === navContent.path || router.url === navContent.optionalPath
            ? 'bg_active_black'
            : ''
        ">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center">
            <div class="d-flex h-100">
              <img
                *ngIf="navContent.svg && navContent.title !== pageActive"
                width="25px"
                [src]="navContent.svg"
                alt="logo user"
                class="pe-2" />
              <img
                *ngIf="navContent.svg && navContent.title === pageActive"
                width="25px"
                [src]="navContent.svgOpen"
                alt="logo user"
                class="pe-2" />
            </div>
            <div class="text-white font-weight-normal h6 m-0 text-left">
              {{ navContent.content }}
            </div>
          </div>
          <div *ngIf="navIndexContent === 0" class="text_clean text_grey h6 m-0 text-left">
            {{ 'local.roles.' + navContent.subtitle | translate }}
          </div>
        </div>
      </button>

      <!-- CHANGER DE MAG -->
      <div
        ngbDropdown
        #Dropdown="ngbDropdown"
        *ngIf="navContent.type === 'dropdown'"
        class="input-group flex-nowrap p-2 d-flex flex-column align-items-start navbar_dropdown"
        [ngClass]="
          router.url === navContent.path || router.url === navContent.optionalPath
            ? 'bg_active_black'
            : ''
        "
        [autoClose]="false"
        [open]="router.url === navContent.path || router.url === navContent.optionalPath">
        <button
          class="btn btn-transparent text-white p-0 w-100"
          type="button"
          [id]="'collapse_button_' + navContent.title"
          ngbDropdownAnchor
          ngbDropdownToggle
          (click)="clickDropDown(Dropdown, navContent?.refreshSomething, navContent.title)">
          <div class="d-flex h-100 w-100 flex-row align-items-center">
            <img
              *ngIf="navContent.svg"
              width="25px"
              [src]="navContent.svg"
              alt="logo user"
              class="pe-2" />
            <div
              class="text_clean text-white font-weight-normal h6 m-0 p-0 w-100 d-flex align-items-center justify-content-between">
              {{ navContent.title | translate }}
              <img
                class="plus"
                [src]="
                  Dropdown.isOpen() == true
                    ? 'assets/svg/Dropdown_down.svg'
                    : 'assets/svg/Dropdown_up.svg'
                " />
            </div>
          </div>
          <div class="d-flex h-100 w-100 flex-row align-items-center pt-1" *ngIf="!changingValue">
            <img width="25px" src="assets/svg/arrowNav.svg" alt="arrow" class="pe-2" />
            <div
              class="text_clean text-white font-weight-normal h6 m-0 p-0 w-100 d-flex align-items-center justify-content-between">
              {{ valueSelected }}
            </div>
          </div>
        </button>
        <div
          class="collapse w-100"
          ngbDropdownMenu
          [id]="'collapse' + navContent.title"
          [attr.aria-labelledby]="'collapse_button_' + navContent.title"
          [attr.test]="'test'">
          <div class="card card-body bg-transparent border-0 p-0 m-0">
            <div class="input-group flex-nowrap p-2">
              <div class="input-group-prepend border-0">
                <img
                  *ngIf="navContent.appendSvg"
                  width="25px"
                  class="input-group-text bg-transparent"
                  id="addon-wrapping"
                  [src]="navContent.appendSvg"
                  alt="logo user"
                  class="pe-2" />
              </div>
              <input
                type="text"
                class="form-control bg-transparent border-0 inputSearchBar"
                placeholder="{{ 'searchStore' | translate }}"
                aria-label="search store"
                aria-describedby="addon-wrapping"
                [(ngModel)]="searchValue" />
            </div>
            <button
              *ngFor="let subContent of navContent.subContent"
              class="d-flex d-row text_clean p-0"
              [ngClass]="''"
              ngbDropdownItem>
              <div class="d-flex flex-column w-100">
                <div class="d-flex flex-row align-items-center">
                  <div
                    ngbDropdown
                    #SubDropdown="ngbDropdown"
                    class="input-group flex-nowrap d-flex flex-column align-items-start navbar_dropdown"
                    [autoClose]="false"
                    [open]="subContent.open">
                    <div
                      class="collapse w-100"
                      ngbDropdownMenu
                      [id]="'collapse' + subContent.title"
                      [attr.aria-labelledby]="'collapse_button_' + subContent.title">
                      <div class="card p-0 m-0 card-body bg-transparent border-0">
                        <app-navbar-scrollable-list
                          *ngIf="Dropdown && Dropdown.isOpen()"
                          [data]="subContent.data$ | async"
                          [fieldValue]="subContent.fieldValue"
                          [searchValue]="searchValue"
                          [dataType]="subContent.dataType"
                          (elementSelected)="selectValue($event)"
                          [page]="subContent.page"></app-navbar-scrollable-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <!-- PAGES -->
      <div
        *ngIf="
          navContent.title !== 'profil' &&
          navContent.title !== 'rechercher' &&
          navContent.type === 'redirect'
        "
        class="p-2 text-light">
        <button
          *ngIf="navContent.withValue"
          class="d-flex align-items-center d-row p-0 ps-4 btn btn-transparent text-white"
          (click)="changePage(navContent.page)">
          <img
            *ngIf="navContent.svg && navContent.page !== pageActive"
            width="25px"
            [src]="navContent.svg"
            alt="logo user"
            class="pe-2" />
          <img
            *ngIf="navContent.svgOpen && navContent.page === pageActive"
            width="25px"
            [src]="navContent.svgOpen"
            alt="logo user"
            class="pe-2" />
          <div class="text-left">
            {{ navContent.title | translate }}
          </div>
        </button>
        <div class="border-top d-flex pt-2" *ngIf="!navContent.withValue">
          <button
            class="d-flex align-items-center d-row p-0 btn btn-transparent text-white"
            (click)="goPage(navContent.page)">
            <img
              *ngIf="navContent.svg && navContent.page !== pageActive"
              width="25px"
              [src]="navContent.svg"
              alt="logo user"
              class="pe-2" />
            <img
              *ngIf="navContent.svg && navContent.page === pageActive"
              width="25px"
              [src]="navContent.svgOpen"
              alt="logo user"
              class="pe-2" />
            <div class="text-left">
              {{ navContent.title | translate }}
            </div>
          </button>
        </div>
      </div>
    </li>
  </ul>
  <ul
    class="list-group _nav_global_action bg_black border-0 m-0 p-0"
    *ngIf="userName && navContents.length > 0">
    <nav class="navbar navbar-dark p-0 mb-2 pe-2">
      <div class="btn-group dropup">
        <button
          type="button"
          class="btn dropdown-toggle border-0"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <img class="arrowTop" src="assets/svg/burger.svg" />
        </button>
        <div class="collapse ms-2 pt-3 dropdown-menu">
          <p class="text-light pointer" (click)="goPage('legalNotice')">
            {{ 'navbar.legals' | translate }}
          </p>
          <p class="text-light pointer" (click)="goPage('about')">
            {{ 'navbar.about' | translate }}
          </p>
        </div>
      </div>
      <img
        class="export pointer"
        (click)="exportData()"
        src="assets/svg/export.svg"
        ngbTooltip="{{ 'exportData' | translate }}"
        container="body"
        tooltipClass="_tooltip" />
      <img
        class="export pointer"
        (click)="logout()"
        src="assets/svg/Logout.svg"
        ngbTooltip="{{ 'logout' | translate }}"
        container="body"
        tooltipClass="_tooltip" />
    </nav>
  </ul>
</div>
