import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Destroy } from '@app/hooklife';
import { RangeType, StatsOptions } from '@app/models';
import { ChartToDisplay } from '@app/models/Chart';
import {
  AgeGenderData,
  ChartPosition,
  ChartsData,
  CountingChartData,
  CustomLabel,
  Range,
} from '@app/models/';
import { ChartProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { CountingService, DateHelperService } from '@app/services';
import { Context } from 'chartjs-plugin-datalabels';
import { switchMap } from 'rxjs/operators';
import { Businesses } from '../../models/Businesses';

@Component({
  selector: 'app-comptage',
  templateUrl: './comptage.component.html',
  styleUrls: ['./comptage.component.scss'],
  viewProviders: [Destroy],
})
export class ComptageComponent implements OnInit, OnDestroy {
  public loading = true;
  public financialData = false;
  public isAgeGenderEmpty = true;

  public localBusiness!: Businesses;
  public $data!: CountingChartData;
  public chartsToDisplay: ChartToDisplay[] = [];
  private rangeType: RangeType;

  private alignTooltip = (context: Context): string => {
    const index = context.dataIndex;
    const datasetIndex = context.datasetIndex;
    const datasets = context.chart.data.datasets;
    const data = datasets[datasetIndex === 1 ? 1 : 0].data as number[];

    if (context.active && data[index]) {
      const max = Math.max(...data);
      const value = data[index];

      if (typeof value === 'number') {
        if (value > 0) return value < max ? 'center' : 'bottom';
        else return 'top';
      }
    }
    return '';
  };

  private commonChartsLabelsOptions = {
    align: this.alignTooltip,
    clip: false,
  };

  private commonChartsOptions: any = {
    legend: {
      label: {
        filter: function (legendItem) {
          return legendItem.datasetIndex > 1 ? false : true;
        },
      },
    },
    layout: { padding: { left: 25, top: 0, bottom: 0, right: 40 } },
    tooltips: {
      mode: 'nearest',
      intersect: true,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      ticks: { min: 0 },
      yAxes: [{ ticks: { beginAtZero: true } }],
      xAxes: [{ ticks: { beginAtZero: true } }],
    },
    responsive: true,
  };

  private noDecimalChartsOptions: any = {
    layout: { padding: { left: 25, top: 0, bottom: 0, right: 40 } },
    tooltips: {
      mode: 'nearest',
      intersect: true,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      ticks: { min: 0 },
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            userCallback(label: number) {
              if (Math.floor(label) === label) return label; // Show if whole number
            },
          },
        },
      ],
      xAxes: [{ ticks: { beginAtZero: true } }],
    },
    responsive: true,
  };

  private commonChartsDatasetsOptions = {
    lineTension: 0.2,
    pointRadius: 2,
    pointHoverRadius: 5,
    pointBorderWidth: 3,
    pointHoverBorderWidth: 10,
    pointHitRadius: 10,
    borderWidth: 2.5,
  };

  private commonChartsRefererAxisOptions = {
    pointBorderColor: ['rgba(74, 131, 139, 1)'],
    backgroundColor: ['rgba(0, 0, 0, 0)'],
    borderColor: ['rgba(74, 131, 139, 1)'],
  };

  private commonChartsVersusAxisOptions = {
    pointBorderColor: ['rgba(255, 205, 86, 1)'],
    backgroundColor: ['rgba(0, 0, 0, 0)'],
    borderColor: ['rgba(255, 205, 86, 1)'],
  };

  private chartDataDefault = {
    entryNumber: {
      title: 'entryNumber',
      icon: 'EntryNumber',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.noDecimalChartsOptions,
    },
    attractivenessRate: {
      title: 'attractivenessRate',
      icon: 'AttractivenessRate',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.commonChartsOptions,
    },
    attendanceTime: {
      title: 'attendanceTime',
      icon: 'AttendanceTime',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.commonChartsOptions,
    },
    salesRevenue: {
      title: 'salesRevenue',
      icon: 'Euro',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.commonChartsOptions,
    },
    transformRate: {
      title: 'transformRate',
      icon: 'TransformTime',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.commonChartsOptions,
    },
    billTickets: {
      title: 'billTickets',
      icon: 'Bill',
      type: 'line',
      config: {
        labels: [],
        datasets: [
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsRefererAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
          {
            ...this.commonChartsDatasetsOptions,
            ...this.commonChartsVersusAxisOptions,
            datalabels: this.commonChartsLabelsOptions,
          },
        ],
      },
      options: this.noDecimalChartsOptions,
    },
  };
  public chartData: ChartsData = this.chartDataDefault;

  constructor(
    public route: ActivatedRoute,
    private statsProvider: StatsProvider,
    private dateHelper: DateHelperService,
    private chartProvider: ChartProvider,
    private countingService: CountingService,
  ) {}

  ngOnInit(): void {
    this.initCounting();
  }

  private initCounting() {
    this.chartProvider
      .layout()
      .pipe(
        switchMap((charts) => {
          this.chartsToDisplay = charts;
          return this.statsProvider.getOptions();
        }),
      )
      .subscribe((options) => {
        this.clean();
        this.rangeType = options.rangeType;
        this.localBusiness = options.targetData;

        const range: Range = this.dateHelper.rangeToUnixTimestampWithCheck(
          { begin: options.range.begin, end: options.range.end },
          {
            begin: options.range.beginVs,
            end: options.range.endVs,
          },
        );

        this.handleCountingChart(options.rangeType, range, options);
      });
  }

  private setDataChart(data: CountingChartData, options: StatsOptions): void {
    this.loading = true;
    const { start, startVs } = this.dateHelper.toHuman(options);

    const blueObject = {
      ...this.commonChartsDatasetsOptions,
      ...this.commonChartsRefererAxisOptions,
    };
    const orangeObject = {
      ...this.commonChartsDatasetsOptions,
      ...this.commonChartsVersusAxisOptions,
    };

    const doubleChartPosition = {
      first: ChartPosition.first,
      second: ChartPosition.second,
    };
    const fourLineChartPosition = {
      first: ChartPosition.first,
      second: ChartPosition.second,
      firstDashed: ChartPosition.firstDashed,
      secondDashed: ChartPosition.secondDashed,
    };

    /*********************  SALES REVENUE *********************/
    const caCurrent: number[] = Object.values(data.current.chartCa);
    const caVersus: number[] = Object.values(data.versus.chartCa);
    let caLabels: string[] | CustomLabel = Object.keys(data.versus.chartCa);

    if (this.rangeType === 'custom') {
      caLabels = {
        current: Object.keys(data.current.chartCa),
        versus: Object.keys(data.versus.chartCa),
      };
    } else {
      caLabels = Object.keys(data.versus.chartCa);
    }

    this.chartData.salesRevenue.config.datasets[doubleChartPosition.second] = {
      ...orangeObject,
      data: caCurrent,
      labels: caLabels,
      label: start,
    };
    this.chartData.salesRevenue.config.datasets[doubleChartPosition.first] = {
      ...blueObject,
      data: caVersus,
      labels: caLabels,
      label: startVs,
    };

    /*********************  BILL TICKET *********************/
    const ticketCurrent: number[] = Object.values(data.current.chartTicket);
    const ticketVersus: number[] = Object.values(data.versus.chartTicket);
    let ticketLabels: string[] | CustomLabel = Object.keys(data.versus.chartTicket);

    if (this.rangeType === 'custom') {
      ticketLabels = {
        current: Object.keys(data.current.chartTicket),
        versus: Object.keys(data.versus.chartTicket),
      };
    } else {
      ticketLabels = Object.keys(data.versus.chartTicket);
    }

    this.chartData.billTickets.config.datasets[doubleChartPosition.second] = {
      ...orangeObject,
      data: ticketCurrent,
      labels: ticketLabels,
      label: start,
    };
    this.chartData.billTickets.config.datasets[doubleChartPosition.first] = {
      ...blueObject,
      data: ticketVersus,
      labels: ticketLabels,
      label: startVs,
    };

    /*********************  TRANSFORM RATE *********************/
    const transformRateCurrent: number[] = Object.values(data.current.chartTransformRate);
    const transformRateVersus: number[] = Object.values(data.versus.chartTransformRate);
    let transformRateLabels: string[] | CustomLabel = Object.keys(data.versus.chartTransformRate);

    if (this.rangeType === 'custom') {
      transformRateLabels = {
        current: Object.keys(data.current.chartTransformRate),
        versus: Object.keys(data.versus.chartTransformRate),
      };
    } else {
      transformRateLabels = Object.keys(data.versus.chartTransformRate);
    }

    this.chartData.transformRate.config.datasets[doubleChartPosition.second] = {
      ...orangeObject,
      data: transformRateCurrent,
      labels: transformRateLabels,
      label: start,
    };
    this.chartData.transformRate.config.datasets[doubleChartPosition.first] = {
      ...blueObject,
      data: transformRateVersus,
      labels: transformRateLabels,
      label: startVs,
    };

    /*********************  ENTRY NUMBER *********************/
    if (!data.groupEnabled) {
      this.chartData.entryNumber.config.datasets = [{}, {}];
      let labels: string[] | CustomLabel = Object.keys(data.versus.chartVisits);

      if (this.rangeType === 'custom') {
        labels = {
          current: Object.keys(data.current.chartVisits),
          versus: Object.keys(data.versus.chartVisits),
        };
      } else {
        labels = Object.keys(data.versus.chartVisits);
      }

      const customersCurrent: number[] = [];
      const customersVersus: number[] = [];
      Object.values(data.current.chartVisits).map((value) => {
        customersCurrent.push(value[0]);
      });
      Object.values(data.versus.chartVisits).map((value) => {
        customersVersus.push(value[0]);
      });
      this.chartData.entryNumber.config.datasets[doubleChartPosition.first] = {
        ...blueObject,
        data: customersVersus,
        labels: labels,
        label: startVs,
      };
      this.chartData.entryNumber.config.datasets[doubleChartPosition.second] = {
        ...orangeObject,
        data: customersCurrent,
        labels: labels,
        label: start,
      };

      this.loading = false;
      this.reload();
    } else {
      this.chartData.entryNumber.config.datasets = [{}, {}, {}, {}];
      let labels: string[] | CustomLabel = Object.keys(data.versus.chartVisits);

      if (this.rangeType === 'custom') {
        labels = {
          current: Object.keys(data.current.chartVisits),
          versus: Object.keys(data.versus.chartVisits),
        };
      } else {
        labels = Object.keys(data.versus.chartVisits);
      }

      const customersCurrent: number[] = [];
      const customersVersus: number[] = [];
      const groupCurrent: number[] = [];
      const groupVersus: number[] = [];

      Object.values(data.current.chartVisits).map((value) => {
        customersCurrent.push(value[0]);
        groupCurrent.push(value[1]);
      });
      Object.values(data.versus.chartVisits).map((value) => {
        customersVersus.push(value[0]);
        groupVersus.push(value[1]);
      });

      this.chartData.entryNumber.config.datasets[fourLineChartPosition.first] = {
        ...blueObject,
        data: customersVersus,
        labels: labels,
        label: startVs,
      };

      this.chartData.entryNumber.config.datasets[fourLineChartPosition.second] = {
        ...blueObject,
        data: groupVersus,
        labels: labels,
        label: 'Groupe',
        borderDash: [5, 5],
      };
      this.chartData.entryNumber.config.datasets[fourLineChartPosition.secondDashed] = {
        ...orangeObject,
        data: groupCurrent,
        labels: labels,
        label: 'Groupe',
        borderDash: [5, 5],
      };

      this.chartData.entryNumber.config.datasets[fourLineChartPosition.firstDashed] = {
        ...orangeObject,
        data: customersCurrent,
        labels: labels,
        label: start,
      };

      this.loading = false;
      this.reload();
    }
  }

  /**
   * Find a solution to force reload. (detect change ?)
   */
  private reload(): void {
    this.chartProvider.update();
  }

  private clean() {
    this.chartData = this.chartDataDefault;
  }

  ngOnDestroy(): void {
    this.clean();
  }

  public isAgeGenderEnabled(): boolean {
    return this.localBusiness.ageGenderEnabled;
  }

  public isAgeGenderDataEmpty(current: AgeGenderData) {
    const currentValues = Object.values(current)
      .map((subObj) => Object.values(subObj))
      .flat();

    return currentValues.every((el) => el === 0);
  }

  public handleCountingChart(rangeType: RangeType, range: Range, options: any) {
    this.loading = true;
    this.isAgeGenderEmpty = true;

    if (this.localBusiness.id) {
      this.countingService
        .getChartData(this.localBusiness.id, rangeType, range)
        .subscribe((chartData) => {
          this.$data = chartData;
          this.financialData = !!chartData.localBusiness;
          this.isAgeGenderEmpty = this.isAgeGenderDataEmpty(chartData.current.chartAgeGender);
          this.setDataChart(chartData, options);
          this.loading = false;
        });
    }
  }
}
