import { Injectable } from '@angular/core';
import _ColorMap from 'colormap';
@Injectable({ providedIn: 'root' })
export class ColorMap {
  private heatmap;

  constructor() {
    this.heatmap = _ColorMap({
      colormap: 'jet',
      nshades: 256,
      format: 'hex',
      alpha: 1,
    }).splice(0, 225);
  }

  public convertValueToHeatmapColor(data: any[]) {
    return data.toCrossProductResult(this.heatmap.length);
  }

  public getHeatmap() {
    return this.heatmap;
  }

  public getHeatmapById(id) {
    const color = this.heatmap[id];
    return color?.length > 0 ? color : this.heatmap[0];
  }
}
