import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Box } from '@app/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BoxService {
  constructor(private http: HttpClient) {}
  public getBoxesByBusiness(id: string | number): Observable<Box[]> {
    return this.http.get<Box[]>(`${environment.backendDomain}/localbusiness/${id}/boxes`);
  }

  public getFluctuationByBox(boxId: number) {
    return this.http.get<Box[]>(`${environment.backendDomain}/boxes/${boxId}/boxes`);
  }
}
