<div class="journey-action-bar d-flex flex-row justify-content-center align-items-center w-100">
  <div class="row d-flex m-0 align-items-center w-100 py-2">
    <div class="col">
      <button
        type="button"
        class="btn w-100 btn-outline-dark p-3 position-relative"
        (click)="onSelect('atractivity')"
        [class]="
          activeTab === 'atractivity' ? '_parcours_active_button elevation-1' : 'elevation-0'
        ">
        {{ 'customerJourneyPage.actionBar.atractivity' | translate }}
        <div class="parcours_info">
          <button
            type="button"
            class="btn elevation-0 rounded btn-transparent bg-transparent p-0 m-0 elevation-0"
            placement="start"
            ngbTooltip=""
            container="body"
            tooltipClass="_tooltip"
            ngbTooltip="{{ 'tooltip.customerJourney.atractivity' | translate }}">
            <img
              width="25px"
              height="25px"
              [src]="
                activeTab === 'atractivity'
                  ? '/assets/svg/InfoKPIWhite.svg'
                  : '/assets/svg/InfoKPI.svg'
              "
              alt="" />
          </button>
        </div>
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn w-100 btn-outline-dark p-3 position-relative"
        (click)="onSelect('audiance')"
        [class]="activeTab === 'audiance' ? '_parcours_active_button elevation-1' : 'elevation-0'">
        {{ 'customerJourneyPage.actionBar.audiance' | translate }}
        <div class="parcours_info">
          <button
            type="button"
            class="btn elevation-0 rounded btn-transparent bg-transparent p-0 m-0 elevation-0"
            placement="bottom"
            ngbTooltip=""
            container="body"
            tooltipClass="_tooltip"
            ngbTooltip="{{ 'tooltip.customerJourney.audiance' | translate }}">
            <img
              width="25px"
              height="25px"
              [src]="
                activeTab === 'audiance'
                  ? '/assets/svg/InfoKPIWhite.svg'
                  : '/assets/svg/InfoKPI.svg'
              "
              alt="" />
          </button>
        </div>
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn w-100 btn-outline-dark p-3 position-relative"
        (click)="onSelect('interaction')"
        [class]="
          activeTab === 'interaction' ? '_parcours_active_button elevation-1' : 'elevation-0'
        ">
        {{ 'customerJourneyPage.actionBar.interaction' | translate }}
        <div class="parcours_info">
          <button
            type="button"
            class="btn elevation-0 rounded btn-transparent bg-transparent p-0 m-0 elevation-0"
            placement="bottom"
            ngbTooltip=""
            container="body"
            tooltipClass="_tooltip"
            ngbTooltip="{{ 'tooltip.customerJourney.interaction' | translate }}">
            <img
              width="25px"
              height="25px"
              [src]="
                activeTab === 'interaction'
                  ? '/assets/svg/InfoKPIWhite.svg'
                  : '/assets/svg/InfoKPI.svg'
              "
              alt="" />
          </button>
        </div>
      </button>
    </div>

    <div class="col">
      <button
        type="button"
        class="btn w-100 btn-outline-dark p-3 position-relative"
        (click)="onSelect('averageStop')"
        [class]="
          activeTab === 'averageStop' ? '_parcours_active_button elevation-1' : 'elevation-0'
        ">
        {{ 'customerJourneyPage.actionBar.averageStop' | translate }}
        <div class="parcours_info">
          <button
            type="button"
            class="btn elevation-0 rounded btn-transparent bg-transparent p-0 m-0 elevation-0"
            placement="end"
            ngbTooltip=""
            container="body"
            tooltipClass="_tooltip"
            ngbTooltip="{{ 'tooltip.customerJourney.averageStop' | translate }}">
            <img
              width="25px"
              height="25px"
              [src]="
                activeTab === 'averageStop'
                  ? '/assets/svg/InfoKPIWhite.svg'
                  : '/assets/svg/InfoKPI.svg'
              "
              alt="" />
          </button>
        </div>
      </button>
    </div>
  </div>
</div>
