<div class="row d-flex flex-row w-100">
  <div class="col-12 d-flex flex-row align-items-start">
    <div class="col-6 form-floating mb-3 border-0 bg-transparent align-items-center">
      <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartVsInput"
        >{{ 'entryNumber' | translate }} :</label
      >
      <div
        class="d-flex form-control flex-row border-0 rounded text-center text_bold elevation-static-2 bg-white justify-content-center">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="h5 m-0 my-auto font-weight-bold text_blue">
              {{ summaryDataVs | customNumber : 0 : ',' : ' ' }}
            </div>
            <div class="h5 ps-2 my-auto font-weight-bold">/</div>
            <div class="h5 m-0 my-auto font-weight-bold text_orange px-2">
              {{ summaryData | customNumber : 0 : ',' : ' ' }}
            </div>

            <div class="d-flex flex-row">
              <img
                width="20px"
                height="20px"
                [src]="
                  !loading
                    ? (summaryDataPercent + '').includes('-')
                      ? '/assets/svg/Arrow_down.svg'
                      : '/assets/svg/Arrow_up.svg'
                    : '/assets/svg/Equals.svg'
                "
                alt="progress"
                *ngIf="summaryDataPercent !== 0; else Equals"
                class="m-1" />

              <ng-template #Equals>
                <img
                  width="20px"
                  height="20px"
                  [src]="'/assets/svg/Equals.svg'"
                  alt="progress"
                  class="m-1" />
              </ng-template>
              <div
                class="h5 m-0 my-auto font-weight-bold px-2 text_green"
                [class]="!loading ? '' : 'text-muted'"
                [ngClass]="{
                  text_red: (summaryDataPercent + '').includes('-') && summaryDataPercent !== 0,
                  text_green: !(summaryDataPercent + '').includes('-') && summaryDataPercent !== 0
                }">
                {{ summaryDataPercent | customNumber : 0 : ',' : ' ' }}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4 form-floating mb-3 border-0 bg-transparent align-items-center ms-auto me-auto">
      <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartVsInput"
        >{{ 'changeView' | translate }} :</label
      >
      <div
        class="form-control flex-row border-0 rounded text-center text_bold elevation-2 bg-white justify-content-center">
        <button
          *ngIf="!isOnZone2D"
          type="button"
          (click)="accessZone2D()"
          tooltipClass="_tooltip"
          ngbTooltip="{{ 'tooltip.open2D' | translate }}"
          class="fadeIn btn rounded btn-transparent bg-transparent w-100 p-0 m-0 d-flex align-items-center justify-content-center"
          [disabled]="loading">
          {{ (loading ? 'loading' : 'rendering2D') | translate }}
          <img
            width="25px"
            height="25px"
            class="mx-2"
            src="/assets/svg/view_plan_2d.svg"
            alt="Accéder au plan 2D" />
        </button>

        <button
          *ngIf="isOnZone2D"
          type="button"
          (click)="leftZone2D()"
          tooltipClass="_tooltip"
          ngbTooltip="{{ 'tooltip.left2D' | translate }}"
          class="fadeIn btn rounded btn-transparent bg-transparent w-100 p-0 m-0 d-flex align-items-center justify-content-center">
          {{ 'table' | translate }}
          <img
            width="25px"
            height="25px"
            class="mx-2"
            src="/assets/svg/view_table.svg"
            alt="Accéder au Tableau" />
        </button>
      </div>
    </div>
  </div>
</div>
