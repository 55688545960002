import { Component, AfterViewInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { Gender } from '@app/models/';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.scss'],
})
export class GenderComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() genderData!: Gender;
  private genderChart: Chart;

  ngAfterViewInit() {
    this.initGenderChart();
  }

  private initGenderChart() {
    this.genderChart = new Chart(document.getElementById('gender_canvas') as HTMLCanvasElement, {
      type: 'pie',
      data: {
        labels: ['Pourcentage de femme', "Pourcentage d'homme"],
        datasets: [
          {
            data: [this.genderData.women, this.genderData.men],
            backgroundColor: ['#C75F9B', '#024F5D'],
            borderColor: ['#C75F9B', '#024F5D'],
            borderWidth: 1,
            datalabels: {
              labels: {
                value: {
                  color: 'white',
                },
              },
            },
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        // transform pie into a donut
        cutoutPercentage: 50,
        responsive: true,
        circumference: Math.PI,
        rotation: Math.PI,
        plugins: {
          datalabels: {
            font: {
              weight: 'bold',
              size: 20,
              family: 'Lato',
            },
            align:
              (this.genderData.men >= 0 && this.genderData.men <= 1) ||
              (this.genderData.women >= 0 && this.genderData.women <= 1)
                ? 'top'
                : 'center',
            formatter: (value: any) => value + '%',
          },
        },
      },
    });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.genderChart.update();
    }, 50);
  }

  ngOnDestroy() {
    this.genderChart.destroy();
  }
}
