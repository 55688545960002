import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// primitives extends
import '@app/extends/index';

import { UserProvider } from './providers';
import { ToastContainerDirective } from 'ngx-toastr';
import { ToastService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  constructor(
    public translate: TranslateService,
    private userProvider: UserProvider,
    private toastrService: ToastService,
  ) {
    this.i18n();
    this.userProvider.updateUserData({ isFirstStart: true });
  }

  ngAfterViewInit() {
    this.toastrService.setMainToaster(this.toastContainer);
  }

  private i18n(): void {
    this.translate.addLangs(['fr']);
    this.translate.setDefaultLang('fr');
  }

  @HostListener('window:beforeunload', ['$event']) unloadHandler() {
    this.userProvider.updateUserData({
      isFirstStart: false,
      isAllMainDataFetched: false,
      isLogged: false,
    });
  }
}
