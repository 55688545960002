import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cluster } from '@app/models';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import unserialize from '@app/lib/php_unserialize';

@Injectable({ providedIn: 'root' })
export class ClusterService {
  constructor(private http: HttpClient) {}

  public getCluster(): Observable<Cluster[]> {
    return this.http
      .get<Cluster[]>(`${environment.backendDomain}/localbusinesscluster`)
      .pipe(this.mapClusters);
  }

  public getClusterById(id: string): Observable<Cluster> {
    return this.http
      .get<Cluster>(`${environment.backendDomain}/cluster/${id}`)

      .pipe(this.mapCluster);
  }
  public getClusterByOrganizationId(id: number | string): Observable<Cluster[]> {
    return this.http
      .get<Cluster[]>(`${environment.backendDomain}/localbusinesscluster/organization/${id}`)
      .pipe(this.mapClusters);
  }

  public editCluster(cluster: any, id: any): Observable<Cluster> {
    return this.http.put<Cluster>(
      `${environment.backendDomain}/localbusinesscluster/${id}`,
      cluster,
    );
  }

  public addCluster(cluster: any): Observable<Cluster> {
    return this.http.post<Cluster>(`${environment.backendDomain}/localbusinesscluster`, cluster);
  }

  public deleteCluster(id: number | string): Observable<Cluster> {
    return this.http.delete<Cluster>(`${environment.backendDomain}/localbusinesscluster/${id}`);
  }

  private mapCluster = map((value): any => {
    if (value['local_businesses_id']) {
      value['local_businesses_id'] = unserialize(value['local_businesses_id']);
    }
    if (value['users_id']) {
      value['users_id'] = unserialize(value['users_id']);
    }
    return value;
  });

  private mapClusters = map((value: any[]): any[] => {
    value.forEach((_value) => {
      if (_value['local_businesses_id']) {
        _value['local_businesses_id'] = unserialize(_value['local_businesses_id']);
      }
      if (_value['users_id']) {
        _value['users_id'] = unserialize(_value['users_id']);
      }
    });
    return value;
  });
}
