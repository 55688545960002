import { Injectable } from '@angular/core';
import { DateRange, RangeType } from '@app/models';
import { ChartToDisplay } from '@app/models/Chart';
import { DateHelperService } from './../../services/date-helper';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChartProvider {
  constructor(private dateHelper: DateHelperService) {}
  private emitUpdate: BehaviorSubject<any> = new BehaviorSubject(null);
  private emitUpdate$: Observable<any> = this.emitUpdate.asObservable();

  private chartToDisplay: BehaviorSubject<ChartToDisplay[]> = new BehaviorSubject([
    'entryNumber',
    'billTickets',
    'salesRevenue',
  ]);
  private chartToDisplay$: Observable<ChartToDisplay[]> = this.chartToDisplay.asObservable();

  public update(): void {
    this.emitUpdate.next('update !');
  }

  public sub(): Observable<any> {
    return this.emitUpdate$;
  }

  public setLayout(value: ChartToDisplay): void {
    const _holder = this.chartToDisplay.value;
    if (value !== _holder[0]) {
      if (_holder.includes(value)) {
        const _valueIndex = _holder.indexOf(value);
        _holder[_valueIndex] = _holder[0];
        _holder[0] = value;
        this.chartToDisplay.next(_holder);
        this.update();
        setTimeout(() => {
          this.update();
        });
        setTimeout(() => {
          this.update();
        }, 2000);
        setTimeout(() => {
          this.update();
        }, 4000);
        setTimeout(() => {
          this.update();
        }, 6000);
      } else {
        _holder.pop();
        _holder.unshift(value);
        this.chartToDisplay.next(_holder);
        this.update();
        setTimeout(() => {
          this.update();
        });
        setTimeout(() => {
          this.update();
        }, 2000);
        setTimeout(() => {
          this.update();
        }, 4000);
        setTimeout(() => {
          this.update();
        }, 6000);
      }
    }
  }

  public layout(): Observable<ChartToDisplay[]> {
    return this.chartToDisplay$;
  }

  public getRange(rangeType: RangeType, range?: DateRange, hackWhitespace?: boolean): any[] {
    const _holder = [];

    switch (rangeType) {
      case 'day':
        const hourRange = 14;
        if (hackWhitespace) {
          let _hour = 0;
          for (let i = 0; i <= 23; i++) {
            _holder.push(_hour.toString() + 'h');
            _hour++;
          }
        } else {
          let hour = 1;
          for (let i = 0; i <= hourRange; i++) {
            _holder.push(hour.toString() + 'h');
            hour++;
          }
        }
        return _holder;
      case 'week':
        return ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
      case 'month':
        const dayInMonth = this.dateHelper.getDaysNumberInMonth(range.begin);
        const dayInMonthVs = this.dateHelper.getDaysNumberInMonth(range.beginVs);

        return dayInMonth > dayInMonthVs
          ? this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end)
          : this.dateHelper.enumerateDaysBetweenDate(range.beginVs, range.endVs);
      case 'year':
        const monthRange = 12;
        for (let i = 0; i < monthRange; i++) {
          _holder.push((i + 1).toString());
        }
        return _holder;
      case 'custom':
        const customDayInRange = this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end);
        const customDayInRangeVs = this.dateHelper.enumerateDaysBetweenDate(
          range.beginVs,
          range.endVs,
        );

        return customDayInRange.length > customDayInRangeVs.length
          ? this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end)
          : this.dateHelper.enumerateDaysBetweenDate(range.beginVs, range.endVs);

      default:
        break;
    }
  }

  public getChartRange(
    hourStartAt: number,
    hourEndAt: number,
    rangeType: RangeType,
    range?: DateRange,
    hackWhitespace?: boolean,
  ): any[] {
    const _holder = [];

    switch (rangeType) {
      case 'day':
        let hour = Number(hourStartAt);
        const hourRange = Number(hourEndAt) - Number(hourStartAt);
        if (hackWhitespace) {
          for (let i = 0; i <= hourRange; i++) {
            _holder.push(hour.toString() + 'h\u0020');
            hour++;
          }
        } else {
          for (let i = 0; i <= hourRange; i++) {
            _holder.push(hour.toString() + 'h - ' + (hour + 1) + 'h');
            hour++;
          }
        }
        return _holder;
      case 'week':
        return [
          this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end),
          this.dateHelper.enumerateDaysBetweenDate(range.beginVs, range.endVs),
        ];
      case 'month':
        return [
          this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end),
          this.dateHelper.enumerateDaysBetweenDate(range.beginVs, range.endVs),
        ];
      case 'custom':
        var ref = this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end);
        var versus = this.dateHelper.enumerateDaysBetweenDate(range.beginVs, range.endVs);

        const oldLength = {
          ref: ref.length,
          versus: versus.length,
        };

        const length = Math.max(ref.length, versus.length);
        ref.length = length;
        versus.length = length;
        ref = ref.fill('', oldLength.ref, length);
        versus = versus.fill('', oldLength.versus, length);
        return [ref, versus];
      case 'year':
        const monthRange = 12;
        for (let i = 0; i < monthRange; i++) {
          _holder.push(i);
        }
        return [_holder, _holder];

      default:
        break;
    }
  }

  public mapApiDataToChartDataByHours(
    data: any,
    options,
    dataConfig: any[],
    hackWhiteSpace?: boolean,
  ): any[] {
    let i = 0;
    var config: any = {};
    const vsKeys = ['current', 'versus'];
    const uniqKey = ['current'];

    if (hackWhiteSpace) {
      uniqKey.forEach((vsKey) => {
        if (data && Object.keys(data).length > 0) {
          var _holder = new Array(24).fill(0, 0, 25);
          Object.keys(data).forEach((objectKey: any) => {
            if (
              objectKey !== 'name' &&
              objectKey !== 'id' &&
              objectKey !== 'boxId' &&
              objectKey !== 'boxName' &&
              objectKey !== 'enabled' &&
              objectKey !== 'date' &&
              objectKey !== 'day' &&
              objectKey !== 'month' &&
              objectKey !== 'timeDataCsv' &&
              objectKey !== 'year' &&
              objectKey !== 'timeData'
            ) {
              _holder[Number(objectKey)] = data[Number(objectKey)][vsKey] || 0;
            }
          });
          config.data = _holder.slice(0, 24);

          config.labels = this.getRange('day', options.range, true);
          dataConfig[i].data = config.data;
          dataConfig[i].labels = config.labels;
          dataConfig[i].info = {
            businessName: data.name,
            businessId: data.id,
            boxId: data.boxId,
            boxName: data.boxName,
            timeData: data.timeData,
            year: data.timeDataCsv.year,
            day: data.timeDataCsv.day,
            month: data.timeDataCsv.month,
          };
        } else {
          dataConfig[i].data = new Array(24).fill(0, 0, 25).slice(6, 24);
          dataConfig[i].labels = this.getRange('day', options.range, false);
          dataConfig[i].info = {
            businessName: data.name,
            businessId: data.id,
            boxId: data.boxId,
            boxName: data.boxName,
            timeData: data.timeData,
            year: data.timeDataCsv.year,
            day: data.timeDataCsv.day,
            month: data.timeDataCsv.month,
          };
        }
        i++;
      });
      return dataConfig;
    } else {
      vsKeys.forEach((vsKey) => {
        if (data && Object.keys(data).length > 0 && data[Object.keys(data)[0]]) {
          var _holder = new Array(24).fill(0, 0, 25);
          Object.keys(data).forEach((objectKey: any) => {
            if (objectKey !== 'name' && objectKey !== 'id') {
              _holder[Number(objectKey) + 1] = data[Number(objectKey)][vsKey] || 0;
            }
          });
          config.data = _holder.slice(6, 24);

          config.labels = this.getRange(options.rangeType, options.range);
          dataConfig[i].data = config.data;
          dataConfig[i].labels = config.labels;
        } else {
          dataConfig[i].data = this.getChartRange(
            null,
            null,
            options.rangeType,
            options.range,
          ).fill(0);
          dataConfig[i].labels = this.getRange(options.rangeType, options.range);
        }
        i++;
      });
      return dataConfig;
    }
  }

  /**
   * Utilise le format ISO 8601 pour créer une nouvelle date
   */
  public convertToISODate(dateFr: string) {
    const [day, month, year] = dateFr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  public getTimeStamps(label: string) {
    return new Date(this.convertToISODate(label)).getTime();
  }

  public sortLabels(labels: string[]) {
    return labels.sort((a, b) => this.getTimeStamps(a) - this.getTimeStamps(b));
  }
}
