import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegularExpressionConstant } from '@app/const';
import { Businesses, Organization } from '@app/models';
import { DataProvider } from '@app/providers';
import { TasksService } from '@app/services/api/v2/tasks.service';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { takeUntil, switchMap } from 'rxjs/operators';
import { Destroy } from '@app/hooklife';

@Component({
  selector: 'app-export-push-mail-form',
  templateUrl: './export-push-mail-form.component.html',
  styleUrls: ['./export-push-mail-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [Destroy],
})
export class ExportPushMailFormComponent implements OnInit, OnDestroy {
  @Input() user;
  @Input() case;
  @Input() allTasks;
  @Input() selectedTask;

  public exportSelectionForm: UntypedFormGroup;

  public organization: Organization;
  public organizations: Organization[];

  public businessesHolder: Businesses[];
  public businesses: Businesses[];

  public rangeTypes = ['daily', 'weekly'];

  public options = {
    rangeType: 'day',
    kpiList: [],
    range: { start: '', end: '' },
    mailList: [],
  };

  public feedback = '';

  public state = '';

  public formResult = {};

  public localBusinesses = null;
  public localBusinessesId = [];
  public _localBusinesses = null;

  public emailregex = (value) => (value ? RegularExpressionConstant.EMAIL.test(value) : false);

  @Output() onDismiss = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onFormError = new EventEmitter();

  constructor(
    public formatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private dataProvider: DataProvider,
    private tasksService: TasksService,
    private readonly destroy$: Destroy,
  ) {}

  ngOnInit() {
    this.dataProvider
      .getOrganization()
      .pipe(
        switchMap((organizations) => {
          this.organizations = organizations;
          this.organization = organizations[0];
          return this.dataProvider.getBusinesses();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((business) => {
        this._localBusinesses = business;
        this.filterBusinessByOrga();
      });

    this.initEmptyForm();
    if (this.case === 'edit') {
      setTimeout(() => {
        this.updateFormWithPushMailValue();
      });
    }
  }

  ngOnDestroy() {
    this.case = '';
  }

  private initEmptyForm() {
    this.exportSelectionForm = this.formBuilder.group({
      rangeType: ['', Validators.compose([Validators.required])],
      taskTitle: ['', Validators.compose([Validators.required])],
      mailInput: [''],
      mailList: [[], Validators.compose([Validators.required])],
      kpiList: [[], Validators.compose([Validators.required])],
    });
  }

  private updateFormWithPushMailValue() {
    this.localBusinessesId = this.selectedTask.row.dataSource.localBusinesses;
    this.organization = this.organizations.filter(
      (value) => value.id === this.selectedTask.row.dataSource.organization,
    )[0];

    this.updateFormByKey('rangeType', this.selectedTask.row.maillingFrequency);
    this.updateFormByKey('taskTitle', this.selectedTask.row.title);
    this.updateFormByKey('mailList', [...this.selectedTask.row.mailList]);
    this.updateFormByKey('kpiList', [...this.selectedTask.row.kpiList]);
  }

  public updateFormByKey(key: string, value: any) {
    this.exportSelectionForm.patchValue({ [key]: value });
  }

  public addToArrayFormByKey(key: string, value: any) {
    const holder: any[] = [...this.exportSelectionForm.value.mailList];
    holder.push({ email: value });
    this.exportSelectionForm.patchValue({ [key]: holder });
  }

  public removeToArrayFormByKey(key: string, value: any) {
    const holder: string[] = [...this.exportSelectionForm.value.mailList];
    holder.RemoveByValue({ email: value });
    this.exportSelectionForm.patchValue({ [key]: holder });
  }

  public setRange(range: { start: any; end: any; startVs: any; endVs: any }): void {
    if (range.start && range.end) {
      this.exportSelectionForm.value.range = range;
    } else {
      this.exportSelectionForm.value.range = '';
    }
  }

  public unselectAllBusinesses(): void {
    this.localBusinessesId = [];
  }

  public selectAllBusinesses(): void {
    this.localBusinessesId = this.organization.id
      ? this.localBusinesses
          .filter((business) => business.organization === this.organization.id)
          .map((business: any) => business.id)
      : this.localBusinesses.map((business: any) => business.id);
  }

  public filterBusinessByOrga(): void {
    this.localBusinessesId = [];
    this.localBusinesses = this.organization.id
      ? this._localBusinesses.filter((business) => business.organization === this.organization.id)
      : this._localBusinesses;
  }

  public dismissModal(): void {
    this.onDismiss.emit('Click on dismiss');
  }

  public cancel(): void {
    this.onCancel.emit('Click on cancel');
  }

  public createTask() {
    return this.onCreate.emit({
      $: this.tasksService.postTasks(
        {
          service: 'dashboard_ivstore',
          settings: {
            title: this.exportSelectionForm.value.taskTitle,
            mailList: this.exportSelectionForm.value.mailList,
            maillingFrequency: this.exportSelectionForm.value.rangeType,
            dataSource: {
              organization: this.organization.id,
              localBusinesses: this.localBusinessesId,
            },
            kpiList: this.exportSelectionForm.value.kpiList,
          },
        },
        this.user.id,
      ),
      title: this.exportSelectionForm.value.taskTitle,
    });
  }
  public editTask() {
    return this.onEdit.emit({
      $: this.tasksService.putTasks(
        {
          service: 'dashboard_ivstore',
          settings: {
            id: this.selectedTask.row.id,
            title: this.exportSelectionForm.value.taskTitle,
            mailList: this.exportSelectionForm.value.mailList,
            maillingFrequency: this.exportSelectionForm.value.rangeType,
            dataSource: {
              organization: this.organization.id,
              localBusinesses: this.localBusinessesId,
            },
            kpiList: this.exportSelectionForm.value.kpiList,
          },
        },
        this.user.id,
      ),
      title: this.exportSelectionForm.value.taskTitle,
    });
  }

  public submit(): void {
    if (this.exportSelectionForm.valid) {
      if (this.case === 'edit') {
        this.editTask();
      }
      if (this.case === 'create') {
        this.createTask();
      }
    } else {
      this.onFormError.emit({ error: this.exportSelectionForm });
    }
  }
}
