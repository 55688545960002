<nav aria-label="breadcrumb" class="px-1 py-2 bg-light">
  <div
    class="breadcrumb m-o me-2 p-o mb-0"
    class="d-flex align-items-center justify-content-between">
    <div class="d-flex flex-row align-items-center" *ngIf="organisation">
      <img width="30px" src="/assets/svg/House.svg" alt="logo user" class="pe-2" />
      <div *ngFor="let breadcrumb of breadcrumbs">
        <a
          [routerLink]="breadcrumb.url"
          routerLinkActive="router-link-active"
          class="h5 text_clean text-dark p-0 m-0 d-flex flex-row align-items-center">
          {{ breadcrumb.label }}
          <div
            *ngIf="breadcrumb.label === 'Comptage'"
            class="h5 text_clean text-dark p-0 m-0 d-flex flex-row align-items-center">
            &nbsp;>
            {{
              options.type === 'business'
                ? 'Comptage magasin > ' +
                  organisation.legalName +
                  ' > ' +
                  options.targetData.legalName
                : 'Comptage cluster > ' + options.targetData.name
            }}
          </div>

          <div
            *ngIf="breadcrumb.label === 'Parcours Client'"
            class="h5 text_clean text-dark p-0 m-0 d-flex flex-row align-items-center">
            &nbsp;>
            {{
              options.type === 'business'
                ? 'Magasin > ' + organisation.legalName + ' > ' + options.targetData.legalName
                : 'Cluster > ' + options.targetData.name
            }}
          </div>
        </a>
      </div>
    </div>
  </div>
</nav>
