import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { Businesses, Cluster, Organization, StatsOptions } from '@app/models';
import { UserProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { RefreshProvider } from '@app/providers/layout';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar-scrollable-list',
  templateUrl: './navbar-scrollable-list.component.html',
  styleUrls: ['./navbar-scrollable-list.component.scss'],
})
export class NavbarScrollableListComponent implements OnChanges, OnDestroy {
  @Input() data: Businesses[] | Cluster[] | Organization[];
  @Input() searchValue: string;
  @Input() fieldValue: string;
  @Input() dataType: 'business' | 'cluster' | 'organization';
  @Input() page: 'comptage' | 'parcours';

  public selectedValue: Businesses | Cluster | Organization | any = {
    id: '',
    organizationId: '',
  };

  public options: StatsOptions;

  public favoriteBusiness;

  public showFavorite = false;

  @Output() elementSelected = new EventEmitter();

  private scrollTo$: Subscription;

  constructor(
    private statsProvider: StatsProvider,
    private userProvider: UserProvider,
    private refreshProvider: RefreshProvider,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.statsProvider.getOptions().subscribe((options) => {
      this.selectedValue = options.targetData;
      this.options = options;
    });
    this.userProvider.getFavorite().subscribe((favorite) => {
      if (favorite) {
        this.favoriteBusiness = favorite.localBusiness.id;
        setTimeout(() => {
          this.scrollToFavorite();
        });
      }
    });

    this.scrollTo$ = this.refreshProvider
      .listen('is-needed-to-scrool-to-selected')
      .subscribe((value) => {
        if (value.forceRefresh) {
          setTimeout(() => {
            this.scrollToSelected();
          });
        }
      });
  }

  ngOnChanges(): void {
    if (this.searchValue === '') {
      setTimeout(() => {
        this.scrollToSelected();
      });
    } else {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.scrollTo$) {
      this.scrollTo$.unsubscribe();
    }
  }

  public onSelect(value: Businesses | Cluster | Organization): void {
    this.selectedValue = value;
    this.elementSelected.emit({ value, type: this.dataType });
  }

  private scrollToFavorite(): void {
    document
      ?.getElementById(
        this.selectedValue.id + '_' + this.dataType + '_scrollItem' + '_' + this.page,
      )
      ?.scrollIntoView({ behavior: 'smooth' });
    this.showFavorite = true;
    this.changeDetector.detectChanges();
  }

  private scrollToSelected(): void {
    document
      ?.getElementById(
        this.selectedValue.id + '_' + this.dataType + '_scrollItem' + '_' + this.page,
      )
      ?.scrollIntoView({ behavior: 'smooth' });
    this.changeDetector.detectChanges();
  }
}
