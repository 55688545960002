<div
  class="help_page fadeIn d-flex flex-column justify-content-start w-100 h-100 m-0 col-12 px-1 m-0">
  <h2>Aide</h2>
  <h4>Comprendre mes données</h4>
  <div id="accordion" class="w-100">
    <div class="card w-100">
      <!-- Nombre d'entrée -->
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button
            class="btn btn-link d-flex p-0"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne">
            <img class="h-100 pe-3" src="assets/svg/EntryNumberBlack.svg" />
            <p class="m-0">Nombre d’entrée</p>
          </button>
        </h5>
      </div>

      <div
        id="collapseOne"
        class="collapse show"
        aria-labelledby="headingOne"
        data-parent="#accordion">
        <div class="card-body">
          <i>Cette donnée correspond au nombre d’entrée dans le point de vente.  </i>
        </div>
      </div>
    </div>

    <!-- Taux d'attractivité -->
    <div class="card">
      <div class="card-header" id="headingTwo">
        <h5 class="mb-0">
          <button
            class="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo">
            Collapsible Group Item #2
          </button>
        </h5>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
        <div class="card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
          squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
          nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
          beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
          you probably haven't heard of them accusamus labore sustainable VHS.
        </div>
      </div>
    </div>
  </div>
</div>
