import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { DataProvider, UserProvider } from '@app/providers';
import { Businesses, Organization, UserData, Cluster } from '@app/models';
import { ApiService } from '@app/services';
import { RefreshProvider } from '@app/providers/layout';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
})
export class ProfilComponent implements OnInit {
  public user: UserData;
  public organizations: Organization[];

  public orgaSelected: Organization = {};
  public orgaSelected$: BehaviorSubject<Organization> = new BehaviorSubject({});
  public businesses$: Observable<Businesses[]>;

  public clusterList$: Observable<Cluster[]>;

  public businessFavorite: {
    settings: any;
    localBusiness: Businesses;
  };

  constructor(
    private userProvider: UserProvider,
    private dataProvider: DataProvider,
    private apiservice: ApiService,
    private refreshProvider: RefreshProvider,
  ) {}

  ngOnInit(): void {
    this.userProvider.getData().subscribe((value) => {
      this.user = value;
    });

    this.userProvider.getFavorite().subscribe((favorite) => (this.businessFavorite = favorite));
    this.dataProvider.getOrganization().subscribe((value) => {
      this.organizations = value;
    });

    this.orgaSelected$.subscribe((value) => {
      if (value && Object.getOwnPropertyNames(value).length > 0) {
        this.dataProvider.setLastOrganizationSelected(value);
        this.orgaSelected = value;
        this.businesses$ = this.apiservice.business.getBusinessesByOrganization(value.id);
        this.clusterList$ = this.apiservice.cluster.getClusterByOrganizationId(value.id);
      } else {
        this.businesses$ = this.dataProvider.getBusinesses();
        this.clusterList$ = this.apiservice.cluster.getCluster();
        this.orgaSelected = {};
      }
    });

    this.refreshProvider.listen('profil-shared-components').subscribe(() => {
      this.clusterList$ = this.orgaSelected?.id
        ? this.apiservice.cluster.getClusterByOrganizationId(this.orgaSelected.id)
        : this.apiservice.cluster.getCluster();
    });
  }

  public onOrgaSelected(orgaSelected: Organization): void {
    this.orgaSelected$.next(orgaSelected);
  }
}
