<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'clusterAdd' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="clusterCreationForm">
    <div class="row d-flex flex-row">
      <div class="col-12">
        <div class="form-group align-items-center rounded-right">
          <input
            type="text"
            formControlName="clusterName"
            class="form-control bg_transparent radius border"
            placeholder="{{ 'clusterName' | translate }}"
            [ngClass]="{
              'is-invalid':
                clusterCreationForm.controls.clusterName.dirty &&
                clusterCreationForm.controls.clusterName.errors
            }" />
          <div class="invalid-feedback">
            {{ 'feedback.nameRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
          <label class="m-2 text-bold"
            >{{ 'actions.data.selectOrganization' | translate }}
            <img src="/assets/svg/Sign.svg" width="25px" height="25px"
          /></label>

          <ng-select
            class="w-100"
            [multiple]="false"
            [(ngModel)]="organization"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="legalName"
            (change)="filterOnAlternativeMode()"
            [items]="organizations">
            <ng-template ng-header-tmp>
              <button (click)="selectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button (click)="unselectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label"
                ><img
                  src="/assets/mockedLogo/Millim.png"
                  width="20px"
                  height="20px"
                  *ngIf="item.id === 92" />
                <img
                  src="/assets/mockedLogo/Ivs.png"
                  width="20px"
                  height="20px"
                  *ngIf="item.id === 3" />
                {{ item.legalName }}</span
              >
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
          <div class="_invalid" *ngIf="submitted && organization === null">
            {{ 'feedback.organizationRequired' | translate }}
          </div>
        </div>

        <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
          <label class="m-2 text-bold"
            >{{ 'clusterStore' | translate }}
            <img src="/assets/svg/Store.svg" width="25px" height="25px"
          /></label>

          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="_businessesId"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="legalName"
            bindValue="id"
            [items]="businesses">
            <ng-template ng-header-tmp>
              <button (click)="selectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button (click)="unselectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value"
                  ><img
                    src="/assets/mockedLogo/Millim.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 92" />
                  <img
                    src="/assets/mockedLogo/Ivs.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 3" />
                  {{ item.legalName }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
          <div class="_invalid" *ngIf="submitted && _businessesId.length === 0">
            {{ 'feedback.businessRequired' | translate }}
          </div>
        </div>

        <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
          <label class="m-2 text-bold"
            >{{ 'clusterUserList' | translate }}
            <img src="/assets/svg/Store.svg" width="25px" height="25px"
          /></label>

          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="_usersId"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="username"
            bindValue="id"
            [items]="usersId">
            <ng-template ng-header-tmp>
              <button (click)="selectAllUsers()" class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button (click)="unselectAllUsers()" class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value-label"> {{ item.username }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item.username }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
    {{ 'validate' | translate }}
  </button>
</div>
