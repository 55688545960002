import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastIsUpdateComponent } from '..';

@Injectable({ providedIn: 'root' })
export class ChangeLogProvider {
  constructor(private toastr: ToastrService) {}
  public show() {
    this.toastr.show('2.1.0', 'Mise a jour !', {
      toastComponent: ToastIsUpdateComponent,
      disableTimeOut: true,
      toastClass: 'text-center rounded radius',
    });
  }
}
