import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
})
export class InputGroupComponent {
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() value: any;
  @Input() disabled = false;
  @Input() maskOption: boolean;
  @Output() valueChange = new EventEmitter();
  @Input() showError = false;

  public applyMask = false;

  public onChange(value: any): void {
    this.valueChange.emit(value);
  }

  public toogleMask(): void {
    this.applyMask = !this.applyMask;
  }
}
