<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'userCreation' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="userCreationForm" (ngSubmit)="onSubmit()" autocomplete="none">
    <div class="row d-flex flex-row">
      <div class="col-12">
        <div class="form-group p-0 m-0 d-flex flex-column align-items-center rounded-right py-1">
          <input
            type="text"
            formControlName="newUsername"
            autocomplete="false"
            class="form-control border border-bottom bg_transparent no-radius no-border"
            placeholder="{{ 'username' | translate }}"
            [ngClass]="{
              'is-invalid':
                (submitted || userCreationForm.controls.newUsername.dirty) &&
                userCreationForm.controls.newUsername.errors,
              'is-valid':
                userCreationForm.controls.newUsername.dirty &&
                !userCreationForm.controls.newUsername.errors
            }" />
          <div class="invalid-feedback">
            {{ 'feedback.usernameRequired' | translate }}
          </div>
        </div>

        <div class="form-group p-0 m-0 d-flex flex-column align-items-center rounded-right py-1">
          <input
            type="text"
            formControlName="newEmail"
            id="none"
            autocomplete="false"
            class="form-control bg_transparent no-radius border border-bottom"
            placeholder="{{ 'email' | translate }}"
            [ngClass]="{
              'is-invalid':
                (submitted || userCreationForm.controls.newEmail.dirty) &&
                userCreationForm.controls.newEmail.errors,
              'is-valid':
                userCreationForm.controls.newEmail.dirty &&
                !userCreationForm.controls.newEmail.errors
            }" />
          <div class="invalid-feedback">
            {{ 'feedback.emailRequired' | translate }}
          </div>
        </div>

        <div class="form-group p-0 m-0">
          <div class="align-items-center d-flex flex-column rounded-right py-1">
            <div class="d-flex d-column w-100">
              <input
                [type]="!applyMask.password ? 'password' : 'text'"
                class="form-control bg_transparent no-radius border border-bottom"
                formControlName="newPlainPassword"
                placeholder="{{ 'password' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    (submitted || userCreationForm.controls.newPlainPassword.dirty) &&
                    userCreationForm.controls.newPlainPassword.errors,
                  'is-valid':
                    userCreationForm.controls.newPlainPassword.dirty &&
                    !userCreationForm.controls.newPlainPassword.errors
                }" />
              <div class="input-group-append">
                <div class="p-1" (click)="toogleMask('password')">
                  <img
                    width="20px"
                    src="{{
                      !applyMask.password ? '/assets/svg/See.svg' : '/assets/svg/Unsee.svg'
                    }}" />
                </div>
              </div>
            </div>

            <div
              class="_invalid"
              *ngIf="
                submitted &&
                userCreationForm.controls.newPlainPassword.value.length === 0 &&
                userCreationForm.controls.newPlainPassword.errors
              ">
              {{ 'feedback.passwordRequired' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group p-0 m-0">
          <div class="d-flex flex-column align-items-center rounded-right py-1">
            <div class="d-flex d-column w-100">
              <input
                [type]="!applyMask.confirmPassword ? 'password' : 'text'"
                class="form-control bg_transparent no-radius border border-bottom"
                placeholder="{{ 'confirmPassword' | translate }}"
                formControlName="newPlainPasswordConfirmation"
                [ngClass]="{
                  'is-invalid':
                    (submitted || userCreationForm.controls.newPlainPasswordConfirmation.dirty) &&
                    userCreationForm.controls.newPlainPasswordConfirmation.errors,
                  'is-valid':
                    userCreationForm.controls.newPlainPasswordConfirmation.dirty &&
                    !userCreationForm.controls.newPlainPasswordConfirmation.errors
                }" />
              <div class="input-group-append">
                <div class="p-1" (click)="toogleMask('confirmPassword')">
                  <img
                    width="20px"
                    src="{{
                      !applyMask.confirmPassword ? '/assets/svg/See.svg' : '/assets/svg/Unsee.svg'
                    }}" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="_invalid"
            *ngIf="
              submitted &&
              userCreationForm.controls.newPlainPasswordConfirmation.value.length === 0 &&
              userCreationForm.controls?.newPlainPasswordConfirmation?.errors
            ">
            {{ 'feedback.passwordConfirmationRequired' | translate }}
          </div>
          <small id="passwordHelpInline" class="text-muted">
            {{ 'passwordRules' | translate }}
          </small>

          <div class="_invalid" *ngIf="userCreationForm?.errors?.passwordDissmiss">
            {{ 'feedback.passwordAreNotSame' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div
          class="form-group p-0 m-0 d-flex flex-column bg_transparent align-items-center rounded-right py-1">
          <label class="my-1 me-2" for="inlineFormCustomSelectPref">{{
            'rolesChoice' | translate
          }}</label>
          <select
            class="custom-select my-1"
            id="inlineFormCustomSelectPref"
            formControlName="roles">
            <option value="ROLE_SUPER_USER">
              {{ 'local.roles.' + roles.SUPER_USER | translate }}
            </option>
            <option value="ROLE_USER">
              {{ 'local.roles.' + roles.USER | translate }}
            </option>
          </select>
          <div
            class="_invalid"
            *ngIf="
              (submitted || userCreationForm.controls.roles.dirty) &&
              userCreationForm.controls.roles.errors
            ">
            {{ 'feedback.roleRequired' | translate }}
          </div>
        </div>

        <div
          class="form-group p-0 m-0 d-flex flex-column bg_transparent align-items-center rounded-right py-1">
          <label class="m-2 text-bold"
            >{{ 'orgaChoice' | translate }}
            <img src="/assets/svg/Sign.svg" width="25px" height="25px"
          /></label>
          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="_organizationId"
            [ngModelOptions]="{ standalone: true }"
            (add)="filterBusinessByOrga()"
            (remove)="filterBusinessByOrga()"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="legalName"
            bindValue="id"
            [items]="organizations"
            [ngClass]="{
              'is-invalid': submitted && _organizationId.length === 0,
              'is-valid': submitted && _organizationId.length > 0
            }">
            <ng-template ng-header-tmp>
              <button
                (click)="selectAllOrganizations(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button
                (click)="unselectAllOrganizations(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value-label"
                  ><img
                    src="/assets/mockedLogo/Millim.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.id === 92" />

                  <img
                    src="/assets/mockedLogo/Ivs.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.id === 3" />
                  {{ item.legalName }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
          <div class="_invalid" *ngIf="submitted && _organizationId.length === 0">
            {{ 'feedback.organizationRequired' | translate }}
          </div>
        </div>

        <div
          class="form-group p-0 m-0 d-flex flex-column bg_transparent align-items-center rounded-right py-1">
          <label class="m-2 text-bold"
            >{{ 'storeAccess' | translate }}
            <img src="/assets/svg/Store.svg" width="25px" height="25px"
          /></label>

          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="_businessesId"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="legalName"
            bindValue="id"
            [items]="businesses"
            [ngClass]="{
              'is-invalid': submitted && _businessesId.length === 0,
              'is-valid': submitted && _businessesId.length > 0
            }">
            <ng-template ng-header-tmp>
              <button
                (click)="selectAllBusinesses(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button
                (click)="unselectAllBusinesses(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value-label"
                  ><img
                    src="/assets/mockedLogo/Millim.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 92" />
                  <img
                    src="/assets/mockedLogo/Ivs.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 3" />
                  {{ item.legalName }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
          <div class="_invalid" *ngIf="submitted && _businessesId.length === 0">
            {{ 'feedback.businessRequired' | translate }}
          </div>
        </div>

        <div
          class="form-group p-0 m-0 d-flex flex-column bg_transparent align-items-center rounded-right py-1">
          <label class="m-2 text-bold"
            >{{ 'clusterAccess' | translate }}
            <img src="/assets/svg/Cluster.svg" width="25px" height="25px"
          /></label>
          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="_clusterList"
            [ngModelOptions]="{ standalone: true }"
            (add)="filterBusinessByOrga()"
            (remove)="filterBusinessByOrga()"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="name"
            bindValue="id"
            [items]="clusterList">
            <ng-template ng-header-tmp>
              <button
                (click)="selectAllClusters(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button
                (click)="unselectAllClusters(); $event.preventDefault()"
                class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value-label"
                  ><img
                    src="/assets/mockedLogo/Millim.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization_id === 92" />

                  <img
                    src="/assets/mockedLogo/Ivs.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization_id === 3" />
                  {{ item.name }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization_id === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization_id === 3" />
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
    {{ 'validate' | translate }}
  </button>
</div>
