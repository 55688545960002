import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegularExpressionConstant, RoleConstant } from '@app/const';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { crossFieldsValidatorPassword } from '@app/services/validators.service';
import { ApiV2Service } from '@app/services/api/v2';
import { UserProvider } from '@app/providers';
import { UserData } from '@app/models';
import { decrypt, encrypt, ToastService } from '@app/services';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-edition',
  templateUrl: './account-edition.component.html',
  styleUrls: ['./account-edition.component.scss'],
})
export class AccountEditionComponent implements OnInit, OnDestroy {
  public doResetForm: UntypedFormGroup;
  public alreadySubmited = false;
  public submitted = false;
  public userData: UserData;
  public isPasswordChanged = false;
  public doRestFieldType = 'password';

  public roles = RoleConstant;

  private sub: Subscription;

  constructor(
    private apiV2Service: ApiV2Service,
    public formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private userProvider: UserProvider,
    private toastrService: ToastService,
  ) {}

  ngOnInit() {
    this.sub = this.userProvider.getData().subscribe((_userData) => {
      this.userData = _userData;
    });

    this.doResetForm = this.formBuilder.group(
      {
        newPlainPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
        newPlainPasswordConfirmation: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
      },
      { validators: crossFieldsValidatorPassword },
    );
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  public updateFormByKey(key: string, value: any) {
    this.doResetForm.patchValue({ [key]: value });
  }

  public onSubmit(): void {
    this.alreadySubmited = true;
    if (this.alreadySubmited) {
      setTimeout(() => {
        this.alreadySubmited = false;
      }, 6000);
    }
    if (this.doResetForm.valid) {
      this.apiV2Service.setNewPassword(this.doResetForm.value.newPlainPassword).subscribe(
        () => {
          this.alreadySubmited = false;

          this.userProvider.updateUserData({
            password: encrypt(this.doResetForm.value.newPlainPassword),
          });
          this.closeModal();
          this.toastrService.info('Édition du compte', 'Votre mot de passe a bien été modifié !');
        },
        () => {
          this.closeModal();
          this.toastrService.error(
            'Édition du compte',
            "Il y a eu une erreur lors de l'édition mot de passe a bien été modifié !",
          );
        },
      );
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
