import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserProvider } from '@app/providers';
import { environment } from '@env/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private userProvider: UserProvider) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.userProvider.isUserDataExist().pipe(
      switchMap((user) => {
        if (user && user['jwt']) {
          if (request.url.includes(environment.backendDomain)) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${user['jwt']}`,
              },
            });
          }
        }
        return next.handle(request);
      }),
    );
  }
}
