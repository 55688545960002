import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxFormComponent implements OnInit, AfterViewInit {
  @Input() choices: string[] | undefined;
  @Input() label: string | undefined;
  @Input() _index: number | undefined;
  @Input() mode: '' | 'single' | 'multiple' = '';
  @Input() initSelectAll: boolean = false;
  @Input() defaultSelected: any = null;
  @Input() explaination: string[] | undefined = null;
  @Input() value: any | undefined = '';
  @Input() valueMultiple: any[] | undefined = [];
  @Input() isOneIsDisabled = false;
  public _mode = '';
  public fn = this.emitSingle;

  private _setCheckboxValue = (value, _choice, index) => {
    (document.getElementById(_choice + '_' + index + '_' + this._index) as any)['checked'] = value;
  };

  @Output() choicesSelected = new EventEmitter<any>();

  constructor(private detectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this._index) {
      this._index = 0;
    }
    switch (this.mode) {
      case 'single':
        this._mode = 'single';
        this.fn = this.emitSingle;
        break;
      case 'multiple':
        this._mode = 'multiple';
        this.fn = this.emitMultiple;
        break;
      default:
        console.log('CheckBox @ component : Start with no mode, default (single) is selected');
        break;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this._mode === 'multiple') {
        this.onInitMultiple();
      }

      if (this._mode === 'single') {
        this.onInitSingle();
      }
    });
  }

  // ONLY0 IF _mode = "multiple"
  public onInitMultiple() {
    if (this.initSelectAll) {
      this.valueMultiple = [...this.choices];
    }
    this.choices?.forEach((_choice, index) => {
      this._setCheckboxValue(this.valueMultiple.includes(_choice), _choice, index);
    });
    this.choicesSelected.emit(this.valueMultiple);
  }

  public onInitSingle() {
    if (this.defaultSelected !== null && this.defaultSelected !== false) {
      this.choices?.forEach((_choice, index) => {
        if (this.defaultSelected === index + 1) {
          this._setCheckboxValue(true, _choice, index);
          this.value = _choice;
        }
      });
    } else if (!this.defaultSelected) {
      this.choices?.forEach((_choice, index) => {
        this._setCheckboxValue(this.value === _choice, _choice, index);
      });
    }
    this.choicesSelected.emit(this.value);
  }

  private emitSingle(event: any, choice: any): void {
    this.value = event.target.checked ? choice : '';
    this.choices?.forEach((_choice, index) => {
      this._setCheckboxValue(this.value === _choice, _choice, index);
    });
    this.choicesSelected.emit(this.value);
  }

  private emitMultiple(event: { target: HTMLInputElement }, choice: any): void {
    const elementIndex = this.valueMultiple.indexOf(choice);
    if (event.target.checked) {
      elementIndex > -1 ? null : this.valueMultiple.push(choice);
    } else {
      elementIndex > -1 ? (this.valueMultiple = this.valueMultiple.RemoveByValue(choice)) : null;
    }

    this.choices?.forEach((_choice, index) => {
      this._setCheckboxValue(this.valueMultiple.includes(_choice), _choice, index);
    });
    this.choicesSelected.emit(this.valueMultiple);
  }
}
