import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TableHelperService } from '@app/services';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-journey-table',
  templateUrl: './journey-table.component.html',
  styleUrls: ['./journey-table.component.scss'],
})
export class JourneyTableComponent {
  @Input() rows;
  @Input() columns;
  @Input() zippedColumns;
  @Input() loading;
  @Input() allTabs;
  @Input() activeTab;
  @Input() activeTabVs;

  @Input() dropStyle;
  @Input() buttonStyle;
  @Input() cardStyle;

  @Input() tableFilterState;

  @Output() select = new EventEmitter();

  @Output() shortEvent = new EventEmitter();
  @Output() nativeTableShortEvent = new EventEmitter();

  public emptyState = 'actions.search.noZone';
  public noResultState = 'actions.search.noResult';
  public searchValue: string = '';

  public customHeaderTranslation = {
    fluctuation: 'Pt',
    fluctuationAudiance: 'Pt',
    fluctuationAtractivity: 'Pt',
    fluctuationInteraction: 'Pt',
    fluctuationAverageStop: 'Temps',
  };

  @ViewChild(DatatableComponent)
  private readonly table: DatatableComponent;

  public dataToShow: any;
  public ColumnMode = ColumnMode;

  public rankAscDesc = {};

  constructor(private tableHelperService: TableHelperService) {}

  public getData(): any {
    return this.searchValue !== ''
      ? this.tableHelperService.filterTableData(this.searchValue as never, this.rows, 'name', true)
      : this.rows;
  }

  public tableFilter(event: KeyboardEvent): void {
    if (typeof event.target['value'] === 'string' && typeof event.target['value'] !== 'object') {
      this.searchValue = event.target['value'];
    } else {
      this.searchValue = '';
    }
  }

  public getRowIndex(row: any): number {
    return this.table.bodyComponent.getRowIndex(row); // row being data object passed into the template
  }

  public onKpiSelect(item: string) {
    this.select.emit(item);
  }
  public onShort(item: string) {
    this.table.sorts = [];
    this.shortEvent.emit(item);
  }

  public onNativeShort(item: any) {
    this.table.sorts = [];
    this.nativeTableShortEvent.emit(item.column.prop);
  }

  public getActiveToHuman(): string {
    if (this.activeTab) {
      return 'customerJourneyPage.actionBar.' + this.activeTab;
    } else {
      return 'loading';
    }
  }

  public getActive(): string {
    if (this.activeTab) {
      return this.activeTab;
    } else {
      return 'loading';
    }
  }
}
