import { of } from 'rxjs';

const API_MILLIM_DAY = of([
  [
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 41,
      versus: 0,
      currentWifiVisits: 20,
      currentWifiPassages: 157,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 41,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 40,
          currentStopCount: 6,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 40,
          currentStopCount: 8,
          ratioToExitCurrent: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 9,
          currentStopCount: 4,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 64,
          currentStopCount: 33,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 33,
          currentStopCount: 13,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 111,
          currentStopCount: 70,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 91,
          currentStopCount: 58,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 31,
          currentStopCount: 13,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 48,
      versus: 0,
      currentWifiVisits: 78,
      currentWifiPassages: 561,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 48,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 16,
          currentStopCount: 6,
          ratioToExitCurrent: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 8,
          currentStopCount: 7,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 42,
          currentStopCount: 40,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 3,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 8,
          currentStopCount: 1,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 14,
          currentStopCount: 8,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 6,
          currentStopCount: 4,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 7,
          currentStopCount: 2,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 6,
          currentStopCount: 6,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 6,
      versus: 0,
      currentWifiVisits: 5,
      currentWifiPassages: 136,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 6,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 46,
      versus: 0,
      currentWifiVisits: 21,
      currentWifiPassages: 192,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 46,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 35,
          currentStopCount: 34,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 20,
          currentStopCount: 6,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 17,
          currentStopCount: 4,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 29,
          currentStopCount: 4,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 28,
          currentStopCount: 11,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 25,
          currentStopCount: 11,
          ratioToExitCurrent: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 59,
      versus: 0,
      currentWifiVisits: 13,
      currentWifiPassages: 123,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 59,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 7,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 9,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 1,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 28,
          currentStopCount: 9,
          ratioToExitCurrent: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 22,
          currentStopCount: 17,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 5,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 14,
          currentStopCount: 3,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 5,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 3,
          currentStopCount: 1,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 25,
          currentStopCount: 2,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 22,
          currentStopCount: 3,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 20,
          currentStopCount: 15,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 14,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 14,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0 },
        '10': { current: 18 },
        '11': { current: 23 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 2 },
        '10': { current: 27 },
        '11': { current: 19 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0 },
        '10': { current: 5 },
        '11': { current: 1 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        '10': { current: 0 },
        '11': { current: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 11 },
        '10': { current: 19 },
        '11': { current: 16 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0 },
        '9': { current: 0 },
        '10': { current: 25 },
        '11': { current: 35 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '10': { current: 8 },
        '11': { current: 6 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        '10': { current: 0 },
        '11': { current: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
  ],
  [
    {
      tickets: '8.00',
      ca: '629.00',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: '3.00',
      ca: '134.00',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: '2.00',
      ca: '169.00',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: 0,
      ca: 0,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: '4.00',
      ca: '129.00',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: '2.00',
      ca: '88.90',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      tickets: '2.00',
      ca: '58.00',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 858202848,
      versus: 0,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 515889018,
      versus: 0,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 618645808,
      versus: 0,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 514868170,
      versus: 0,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 498030296,
      versus: 0,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    { '2021-07-23': { zones: [] } },
    { '2021-07-23': { zones: [] } },
    {
      '2021-07-23': {
        zones: [
          {
            id: 19654,
            name: 'Univers Junior Gauche',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 40,
            timeInZone: 438,
            currentStopCount: 6,
            stopsDurationInZone: 223,
          },
          {
            id: 19655,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 426,
            currentZonePassage: 40,
            timeInZone: 241,
            currentStopCount: 8,
            stopsDurationInZone: 187,
          },
          {
            id: 19656,
            name: 'Univers Enfant',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 9,
            timeInZone: 205,
            currentStopCount: 4,
            stopsDurationInZone: 171,
          },
          {
            id: 19657,
            name: 'Univers Homme',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 64,
            timeInZone: 1120,
            currentStopCount: 33,
            stopsDurationInZone: 1035,
          },
          {
            id: 19659,
            name: 'Table Enfant',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 33,
            timeInZone: 281,
            currentStopCount: 13,
            stopsDurationInZone: 323,
          },
          {
            id: 19660,
            name: 'Univers Femme',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 111,
            timeInZone: 2010,
            currentStopCount: 70,
            stopsDurationInZone: 1471,
          },
          {
            id: 19661,
            name: 'Table Femme',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 91,
            timeInZone: 1831,
            currentStopCount: 58,
            stopsDurationInZone: 1311,
          },
          {
            id: 19662,
            name: 'Univers Junior Droit',
            flags: 0,
            type: 0,
            box: 426,
            currentZonePassage: 31,
            timeInZone: 339,
            currentStopCount: 13,
            stopsDurationInZone: 354,
          },
        ],
      },
    },
    {
      '2021-07-23': {
        zones: [
          {
            id: 19642,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 456,
            currentZonePassage: 16,
            timeInZone: 179,
            currentStopCount: 6,
            stopsDurationInZone: 160,
          },
          {
            id: 19643,
            name: 'Univers Homme',
            flags: 0,
            type: 0,
            box: 456,
            currentZonePassage: 8,
            timeInZone: 350,
            currentStopCount: 7,
            stopsDurationInZone: 357,
          },
          {
            id: 19644,
            name: 'Univers Femme 1',
            flags: 0,
            type: 0,
            box: 456,
            currentZonePassage: 42,
            timeInZone: 1563,
            currentStopCount: 40,
            stopsDurationInZone: 1388,
          },
          {
            id: 19650,
            name: 'Mural Verriere',
            flags: 0,
            type: 0,
            box: 456,
            currentZonePassage: 3,
            timeInZone: 16,
          },
          {
            id: 19645,
            name: 'Univers Femme 2',
            flags: 0,
            type: 0,
            box: 457,
            currentZonePassage: 8,
            timeInZone: 17,
            currentStopCount: 1,
            stopsDurationInZone: 6,
          },
          {
            id: 19646,
            name: 'Univers Femme 3',
            flags: 0,
            type: 0,
            box: 457,
            currentZonePassage: 14,
            timeInZone: 343,
            currentStopCount: 8,
            stopsDurationInZone: 396,
          },
          {
            id: 19647,
            name: 'Univers Enfant',
            flags: 0,
            type: 0,
            box: 457,
            currentZonePassage: 6,
            timeInZone: 46,
            currentStopCount: 4,
            stopsDurationInZone: 59,
          },
          {
            id: 19648,
            name: 'Univers Junior',
            flags: 0,
            type: 0,
            box: 457,
            currentZonePassage: 7,
            timeInZone: 46,
            currentStopCount: 2,
            stopsDurationInZone: 43,
          },
          {
            id: 19649,
            name: 'Univers Bébé Chaussons',
            flags: 0,
            type: 0,
            box: 457,
            currentZonePassage: 6,
            timeInZone: 129,
            currentStopCount: 6,
            stopsDurationInZone: 100,
          },
        ],
      },
    },
    { '2021-07-23': { zones: [] } },
    {
      '2021-07-23': {
        zones: [
          {
            id: 19556,
            name: 'Femme',
            flags: 0,
            type: 0,
            box: 427,
            currentZonePassage: 35,
            timeInZone: 1069,
            currentStopCount: 34,
            stopsDurationInZone: 1031,
          },
          {
            id: 19557,
            name: 'Enfant',
            flags: 0,
            type: 0,
            box: 427,
            currentZonePassage: 20,
            timeInZone: 219,
            currentStopCount: 6,
            stopsDurationInZone: 206,
          },
          {
            id: 19558,
            name: 'Junior',
            flags: 0,
            type: 0,
            box: 427,
            currentZonePassage: 17,
            timeInZone: 63,
            currentStopCount: 4,
            stopsDurationInZone: 40,
          },
          {
            id: 19559,
            name: 'Homme',
            flags: 0,
            type: 0,
            box: 427,
            currentZonePassage: 29,
            timeInZone: 147,
            currentStopCount: 4,
            stopsDurationInZone: 80,
          },
          {
            id: 19560,
            name: 'Table',
            flags: 0,
            type: 0,
            box: 427,
            currentZonePassage: 28,
            timeInZone: 333,
            currentStopCount: 11,
            stopsDurationInZone: 405,
          },
          {
            id: 19561,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 427,
            currentZonePassage: 25,
            timeInZone: 493,
            currentStopCount: 11,
            stopsDurationInZone: 365,
          },
        ],
      },
    },
    {
      '2021-07-23': {
        zones: [
          {
            id: 19534,
            name: 'Vitrine droite',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 7,
            timeInZone: 3,
          },
          {
            id: 19535,
            name: 'Vitrine gauche',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 9,
            timeInZone: 8,
          },
          {
            id: 19536,
            name: 'Femme gauche',
            flags: 0,
            type: 0,
            box: 421,
            timeInZone: 23,
            currentStopCount: 1,
            stopsDurationInZone: 9,
          },
          {
            id: 19537,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 421,
            currentZonePassage: 28,
            timeInZone: 248,
            currentStopCount: 9,
            stopsDurationInZone: 256,
          },
          {
            id: 19538,
            name: 'Femme droite',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 22,
            timeInZone: 349,
            currentStopCount: 17,
            stopsDurationInZone: 272,
          },
          {
            id: 19539,
            name: 'Homme',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 5,
            timeInZone: 8,
          },
          {
            id: 19548,
            name: 'Ilot central droit',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 14,
            timeInZone: 92,
            currentStopCount: 3,
            stopsDurationInZone: 34,
          },
          {
            id: 19549,
            name: 'Ilot central gauche',
            flags: 0,
            type: 0,
            box: 421,
            currentZonePassage: 5,
            timeInZone: 28,
          },
          {
            id: 19540,
            name: 'Bébé',
            flags: 0,
            type: 0,
            box: 422,
            timeInZone: 3,
          },
          {
            id: 19541,
            name: 'Enfants 1',
            flags: 0,
            type: 0,
            box: 422,
            currentZonePassage: 3,
            timeInZone: 9,
            currentStopCount: 1,
            stopsDurationInZone: 8,
          },
          {
            id: 19542,
            name: 'Junior',
            flags: 0,
            type: 0,
            box: 422,
            currentZonePassage: 25,
            timeInZone: 48,
            currentStopCount: 2,
            stopsDurationInZone: 10,
          },
          {
            id: 19543,
            name: 'Pantoufle',
            flags: 0,
            type: 0,
            box: 422,
            currentZonePassage: 22,
            timeInZone: 161,
            currentStopCount: 3,
            stopsDurationInZone: 58,
          },
          {
            id: 19544,
            name: 'Enfants 2',
            flags: 0,
            type: 0,
            box: 422,
            currentZonePassage: 20,
            timeInZone: 601,
            currentStopCount: 15,
            stopsDurationInZone: 571,
          },
        ],
      },
    },
  ],
]);
const API_MILLIM_WEEK = of([
  [
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 5115,
      versus: 5115,
      currentWifiVisits: 2182,
      currentWifiPassages: 23011,
      versusWifiVisits: 2182,
      versusWifiPassages: 23011,
      currentVideoVisits: 5115,
      currentVideoPassages: 0,
      versusVideoVisits: 5115,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 773,
      versus: 773,
      currentWifiVisits: 366,
      currentWifiPassages: 3505,
      versusWifiVisits: 366,
      versusWifiPassages: 3505,
      currentVideoVisits: 773,
      currentVideoPassages: 0,
      versusVideoVisits: 773,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 694,
      versus: 694,
      currentWifiVisits: 316,
      currentWifiPassages: 3301,
      versusWifiVisits: 316,
      versusWifiPassages: 3301,
      currentVideoVisits: 694,
      currentVideoPassages: 0,
      versusVideoVisits: 694,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 763,
      versus: 763,
      currentWifiVisits: 311,
      currentWifiPassages: 3757,
      versusWifiVisits: 311,
      versusWifiPassages: 3757,
      currentVideoVisits: 763,
      currentVideoPassages: 0,
      versusVideoVisits: 763,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 697,
      versus: 697,
      currentWifiVisits: 310,
      currentWifiPassages: 3574,
      versusWifiVisits: 310,
      versusWifiPassages: 3574,
      currentVideoVisits: 697,
      currentVideoPassages: 0,
      versusVideoVisits: 697,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 645,
      versus: 645,
      currentWifiVisits: 319,
      currentWifiPassages: 3518,
      versusWifiVisits: 319,
      versusWifiPassages: 3518,
      currentVideoVisits: 645,
      currentVideoPassages: 0,
      versusVideoVisits: 645,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [],
      current: 1543,
      versus: 1543,
      currentWifiVisits: 560,
      currentWifiPassages: 5356,
      versusWifiVisits: 560,
      versusWifiPassages: 5356,
      currentVideoVisits: 1543,
      currentVideoPassages: 0,
      versusVideoVisits: 1543,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 2591,
          currentStopCount: 635,
          versusZonePassage: 2591,
          versusStopCount: 635,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 3504,
          currentStopCount: 731,
          ratioToExitCurrent: 0,
          versusZonePassage: 3504,
          versusStopCount: 731,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1681,
          currentStopCount: 889,
          versusZonePassage: 1681,
          versusStopCount: 889,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 6239,
          currentStopCount: 3066,
          versusZonePassage: 6239,
          versusStopCount: 3066,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 3249,
          currentStopCount: 963,
          versusZonePassage: 3249,
          versusStopCount: 963,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 9967,
          currentStopCount: 6958,
          versusZonePassage: 9967,
          versusStopCount: 6958,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 7513,
          currentStopCount: 3027,
          versusZonePassage: 7513,
          versusStopCount: 3027,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 3140,
          currentStopCount: 1242,
          versusZonePassage: 3140,
          versusStopCount: 1242,
        },
      ],
      current: 4648,
      versus: 4648,
      currentWifiVisits: 3260,
      currentWifiPassages: 24332,
      versusWifiVisits: 3260,
      versusWifiPassages: 24332,
      currentVideoVisits: 4648,
      currentVideoPassages: 0,
      versusVideoVisits: 4648,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 347,
          currentStopCount: 101,
          versusZonePassage: 347,
          versusStopCount: 101,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 496,
          currentStopCount: 98,
          ratioToExitCurrent: 0,
          versusZonePassage: 496,
          versusStopCount: 98,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 235,
          currentStopCount: 105,
          versusZonePassage: 235,
          versusStopCount: 105,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 815,
          currentStopCount: 402,
          versusZonePassage: 815,
          versusStopCount: 402,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 444,
          currentStopCount: 132,
          versusZonePassage: 444,
          versusStopCount: 132,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1481,
          currentStopCount: 1017,
          versusZonePassage: 1481,
          versusStopCount: 1017,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 979,
          currentStopCount: 418,
          versusZonePassage: 979,
          versusStopCount: 418,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 439,
          currentStopCount: 174,
          versusZonePassage: 439,
          versusStopCount: 174,
        },
      ],
      current: 668,
      versus: 668,
      currentWifiVisits: 549,
      currentWifiPassages: 3775,
      versusWifiVisits: 549,
      versusWifiPassages: 3775,
      currentVideoVisits: 668,
      currentVideoPassages: 0,
      versusVideoVisits: 668,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 457,
          currentStopCount: 99,
          versusZonePassage: 457,
          versusStopCount: 99,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 507,
          currentStopCount: 110,
          ratioToExitCurrent: 0,
          versusZonePassage: 507,
          versusStopCount: 110,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 316,
          currentStopCount: 169,
          versusZonePassage: 316,
          versusStopCount: 169,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 981,
          currentStopCount: 483,
          versusZonePassage: 981,
          versusStopCount: 483,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 561,
          currentStopCount: 204,
          versusZonePassage: 561,
          versusStopCount: 204,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1592,
          currentStopCount: 1168,
          versusZonePassage: 1592,
          versusStopCount: 1168,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1164,
          currentStopCount: 494,
          versusZonePassage: 1164,
          versusStopCount: 494,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 511,
          currentStopCount: 212,
          versusZonePassage: 511,
          versusStopCount: 212,
        },
      ],
      current: 635,
      versus: 635,
      currentWifiVisits: 575,
      currentWifiPassages: 3857,
      versusWifiVisits: 575,
      versusWifiPassages: 3857,
      currentVideoVisits: 635,
      currentVideoPassages: 0,
      versusVideoVisits: 635,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 352,
          currentStopCount: 68,
          versusZonePassage: 352,
          versusStopCount: 68,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 512,
          currentStopCount: 128,
          ratioToExitCurrent: 0,
          versusZonePassage: 512,
          versusStopCount: 128,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 226,
          currentStopCount: 127,
          versusZonePassage: 226,
          versusStopCount: 127,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 873,
          currentStopCount: 382,
          versusZonePassage: 873,
          versusStopCount: 382,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 471,
          currentStopCount: 128,
          versusZonePassage: 471,
          versusStopCount: 128,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1448,
          currentStopCount: 942,
          versusZonePassage: 1448,
          versusStopCount: 942,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1112,
          currentStopCount: 419,
          versusZonePassage: 1112,
          versusStopCount: 419,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 510,
          currentStopCount: 193,
          versusZonePassage: 510,
          versusStopCount: 193,
        },
      ],
      current: 733,
      versus: 733,
      currentWifiVisits: 530,
      currentWifiPassages: 3903,
      versusWifiVisits: 530,
      versusWifiPassages: 3903,
      currentVideoVisits: 733,
      currentVideoPassages: 0,
      versusVideoVisits: 733,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 357,
          currentStopCount: 58,
          versusZonePassage: 357,
          versusStopCount: 58,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 490,
          currentStopCount: 136,
          ratioToExitCurrent: 0,
          versusZonePassage: 490,
          versusStopCount: 136,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 172,
          currentStopCount: 97,
          versusZonePassage: 172,
          versusStopCount: 97,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 820,
          currentStopCount: 346,
          versusZonePassage: 820,
          versusStopCount: 346,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 421,
          currentStopCount: 112,
          versusZonePassage: 421,
          versusStopCount: 112,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1357,
          currentStopCount: 1040,
          versusZonePassage: 1357,
          versusStopCount: 1040,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1002,
          currentStopCount: 397,
          versusZonePassage: 1002,
          versusStopCount: 397,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 405,
          currentStopCount: 177,
          versusZonePassage: 405,
          versusStopCount: 177,
        },
      ],
      current: 616,
      versus: 616,
      currentWifiVisits: 514,
      currentWifiPassages: 3932,
      versusWifiVisits: 514,
      versusWifiPassages: 3932,
      currentVideoVisits: 616,
      currentVideoPassages: 0,
      versusVideoVisits: 616,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 364,
          currentStopCount: 81,
          versusZonePassage: 364,
          versusStopCount: 81,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 489,
          currentStopCount: 96,
          ratioToExitCurrent: 0,
          versusZonePassage: 489,
          versusStopCount: 96,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 247,
          currentStopCount: 128,
          versusZonePassage: 247,
          versusStopCount: 128,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 867,
          currentStopCount: 423,
          versusZonePassage: 867,
          versusStopCount: 423,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 475,
          currentStopCount: 161,
          versusZonePassage: 475,
          versusStopCount: 161,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1410,
          currentStopCount: 935,
          versusZonePassage: 1410,
          versusStopCount: 935,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1054,
          currentStopCount: 415,
          versusZonePassage: 1054,
          versusStopCount: 415,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 455,
          currentStopCount: 179,
          versusZonePassage: 455,
          versusStopCount: 179,
        },
      ],
      current: 622,
      versus: 622,
      currentWifiVisits: 509,
      currentWifiPassages: 4270,
      versusWifiVisits: 509,
      versusWifiPassages: 4270,
      currentVideoVisits: 622,
      currentVideoPassages: 0,
      versusVideoVisits: 622,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19654,
          name: 'Univers Junior Gauche',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 714,
          currentStopCount: 228,
          versusZonePassage: 714,
          versusStopCount: 228,
        },
        {
          id: 19655,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 426,
          currentZonePassage: 1010,
          currentStopCount: 163,
          ratioToExitCurrent: 0,
          versusZonePassage: 1010,
          versusStopCount: 163,
          ratioToExitVersus: 0,
        },
        {
          id: 19656,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 485,
          currentStopCount: 263,
          versusZonePassage: 485,
          versusStopCount: 263,
        },
        {
          id: 19657,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 1883,
          currentStopCount: 1030,
          versusZonePassage: 1883,
          versusStopCount: 1030,
        },
        {
          id: 19659,
          name: 'Table Enfant',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 877,
          currentStopCount: 226,
          versusZonePassage: 877,
          versusStopCount: 226,
        },
        {
          id: 19660,
          name: 'Univers Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 2679,
          currentStopCount: 1856,
          versusZonePassage: 2679,
          versusStopCount: 1856,
        },
        {
          id: 19661,
          name: 'Table Femme',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 2202,
          currentStopCount: 884,
          versusZonePassage: 2202,
          versusStopCount: 884,
        },
        {
          id: 19662,
          name: 'Univers Junior Droit',
          flags: 0,
          type: 0,
          box: 426,
          currentZonePassage: 820,
          currentStopCount: 307,
          versusZonePassage: 820,
          versusStopCount: 307,
        },
      ],
      current: 1374,
      versus: 1374,
      currentWifiVisits: 583,
      currentWifiPassages: 4595,
      versusWifiVisits: 583,
      versusWifiPassages: 4595,
      currentVideoVisits: 1374,
      currentVideoPassages: 0,
      versusVideoVisits: 1374,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 1475,
          currentStopCount: 439,
          ratioToExitCurrent: 0,
          versusZonePassage: 1475,
          versusStopCount: 439,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 165,
          currentStopCount: 65,
          versusZonePassage: 165,
          versusStopCount: 65,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 2880,
          currentStopCount: 1541,
          versusZonePassage: 2880,
          versusStopCount: 1541,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 159,
          currentStopCount: 37,
          versusZonePassage: 159,
          versusStopCount: 37,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 794,
          currentStopCount: 126,
          versusZonePassage: 794,
          versusStopCount: 126,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 744,
          currentStopCount: 285,
          versusZonePassage: 744,
          versusStopCount: 285,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 893,
          currentStopCount: 533,
          versusZonePassage: 893,
          versusStopCount: 533,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 580,
          currentStopCount: 129,
          versusZonePassage: 580,
          versusStopCount: 129,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 758,
          currentStopCount: 263,
          versusZonePassage: 758,
          versusStopCount: 263,
        },
      ],
      current: 727,
      versus: 727,
      currentWifiVisits: 183,
      currentWifiPassages: 3446,
      versusWifiVisits: 183,
      versusWifiPassages: 3446,
      currentVideoVisits: 727,
      currentVideoPassages: 0,
      versusVideoVisits: 727,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 100,
          currentStopCount: 19,
          ratioToExitCurrent: 0,
          versusZonePassage: 100,
          versusStopCount: 19,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 12,
          currentStopCount: 6,
          versusZonePassage: 12,
          versusStopCount: 6,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 223,
          currentStopCount: 103,
          versusZonePassage: 223,
          versusStopCount: 103,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 11,
          currentStopCount: 0,
          versusZonePassage: 11,
          versusStopCount: 0,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 57,
          currentStopCount: 5,
          versusZonePassage: 57,
          versusStopCount: 5,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 68,
          currentStopCount: 39,
          versusZonePassage: 68,
          versusStopCount: 39,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 87,
          currentStopCount: 54,
          versusZonePassage: 87,
          versusStopCount: 54,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 52,
          currentStopCount: 12,
          versusZonePassage: 52,
          versusStopCount: 12,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 65,
          currentStopCount: 15,
          versusZonePassage: 65,
          versusStopCount: 15,
        },
      ],
      current: 54,
      versus: 54,
      currentWifiVisits: 20,
      currentWifiPassages: 257,
      versusWifiVisits: 20,
      versusWifiPassages: 257,
      currentVideoVisits: 54,
      currentVideoPassages: 0,
      versusVideoVisits: 54,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 248,
          currentStopCount: 79,
          ratioToExitCurrent: 0,
          versusZonePassage: 248,
          versusStopCount: 79,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 48,
          currentStopCount: 30,
          versusZonePassage: 48,
          versusStopCount: 30,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 467,
          currentStopCount: 274,
          versusZonePassage: 467,
          versusStopCount: 274,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 14,
          currentStopCount: 2,
          versusZonePassage: 14,
          versusStopCount: 2,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 131,
          currentStopCount: 18,
          versusZonePassage: 131,
          versusStopCount: 18,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 117,
          currentStopCount: 41,
          versusZonePassage: 117,
          versusStopCount: 41,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 148,
          currentStopCount: 86,
          versusZonePassage: 148,
          versusStopCount: 86,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 115,
          currentStopCount: 25,
          versusZonePassage: 115,
          versusStopCount: 25,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 142,
          currentStopCount: 40,
          versusZonePassage: 142,
          versusStopCount: 40,
        },
      ],
      current: 82,
      versus: 82,
      currentWifiVisits: 26,
      currentWifiPassages: 616,
      versusWifiVisits: 26,
      versusWifiPassages: 616,
      currentVideoVisits: 82,
      currentVideoPassages: 0,
      versusVideoVisits: 82,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 332,
          currentStopCount: 101,
          ratioToExitCurrent: 0,
          versusZonePassage: 332,
          versusStopCount: 101,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 33,
          currentStopCount: 9,
          versusZonePassage: 33,
          versusStopCount: 9,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 587,
          currentStopCount: 287,
          versusZonePassage: 587,
          versusStopCount: 287,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 33,
          currentStopCount: 10,
          versusZonePassage: 33,
          versusStopCount: 10,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 167,
          currentStopCount: 25,
          versusZonePassage: 167,
          versusStopCount: 25,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 155,
          currentStopCount: 58,
          versusZonePassage: 155,
          versusStopCount: 58,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 225,
          currentStopCount: 146,
          versusZonePassage: 225,
          versusStopCount: 146,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 144,
          currentStopCount: 36,
          versusZonePassage: 144,
          versusStopCount: 36,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 185,
          currentStopCount: 58,
          versusZonePassage: 185,
          versusStopCount: 58,
        },
      ],
      current: 188,
      versus: 188,
      currentWifiVisits: 32,
      currentWifiPassages: 572,
      versusWifiVisits: 32,
      versusWifiPassages: 572,
      currentVideoVisits: 188,
      currentVideoPassages: 0,
      versusVideoVisits: 188,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 206,
          currentStopCount: 59,
          ratioToExitCurrent: 0,
          versusZonePassage: 206,
          versusStopCount: 59,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 26,
          currentStopCount: 5,
          versusZonePassage: 26,
          versusStopCount: 5,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 494,
          currentStopCount: 300,
          versusZonePassage: 494,
          versusStopCount: 300,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 35,
          currentStopCount: 7,
          versusZonePassage: 35,
          versusStopCount: 7,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 119,
          currentStopCount: 21,
          versusZonePassage: 119,
          versusStopCount: 21,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 104,
          currentStopCount: 41,
          versusZonePassage: 104,
          versusStopCount: 41,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 119,
          currentStopCount: 70,
          versusZonePassage: 119,
          versusStopCount: 70,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 69,
          currentStopCount: 19,
          versusZonePassage: 69,
          versusStopCount: 19,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 90,
          currentStopCount: 35,
          versusZonePassage: 90,
          versusStopCount: 35,
        },
      ],
      current: 123,
      versus: 123,
      currentWifiVisits: 32,
      currentWifiPassages: 797,
      versusWifiVisits: 32,
      versusWifiPassages: 797,
      currentVideoVisits: 123,
      currentVideoPassages: 0,
      versusVideoVisits: 123,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 312,
          currentStopCount: 98,
          ratioToExitCurrent: 0,
          versusZonePassage: 312,
          versusStopCount: 98,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 24,
          currentStopCount: 11,
          versusZonePassage: 24,
          versusStopCount: 11,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 627,
          currentStopCount: 361,
          versusZonePassage: 627,
          versusStopCount: 361,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 39,
          currentStopCount: 13,
          versusZonePassage: 39,
          versusStopCount: 13,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 142,
          currentStopCount: 33,
          versusZonePassage: 142,
          versusStopCount: 33,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 144,
          currentStopCount: 74,
          versusZonePassage: 144,
          versusStopCount: 74,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 74,
          currentStopCount: 38,
          versusZonePassage: 74,
          versusStopCount: 38,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 55,
          currentStopCount: 13,
          versusZonePassage: 55,
          versusStopCount: 13,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 86,
          currentStopCount: 35,
          versusZonePassage: 86,
          versusStopCount: 35,
        },
      ],
      current: 134,
      versus: 134,
      currentWifiVisits: 33,
      currentWifiPassages: 646,
      versusWifiVisits: 33,
      versusWifiPassages: 646,
      currentVideoVisits: 134,
      currentVideoPassages: 0,
      versusVideoVisits: 134,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19642,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 456,
          currentZonePassage: 277,
          currentStopCount: 83,
          ratioToExitCurrent: 0,
          versusZonePassage: 277,
          versusStopCount: 83,
          ratioToExitVersus: 0,
        },
        {
          id: 19643,
          name: 'Univers Homme',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 22,
          currentStopCount: 4,
          versusZonePassage: 22,
          versusStopCount: 4,
        },
        {
          id: 19644,
          name: 'Univers Femme 1',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 482,
          currentStopCount: 216,
          versusZonePassage: 482,
          versusStopCount: 216,
        },
        {
          id: 19650,
          name: 'Mural Verriere',
          flags: 0,
          type: 0,
          box: 456,
          currentZonePassage: 27,
          currentStopCount: 5,
          versusZonePassage: 27,
          versusStopCount: 5,
        },
        {
          id: 19645,
          name: 'Univers Femme 2',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 178,
          currentStopCount: 24,
          versusZonePassage: 178,
          versusStopCount: 24,
        },
        {
          id: 19646,
          name: 'Univers Femme 3',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 156,
          currentStopCount: 32,
          versusZonePassage: 156,
          versusStopCount: 32,
        },
        {
          id: 19647,
          name: 'Univers Enfant',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 240,
          currentStopCount: 139,
          versusZonePassage: 240,
          versusStopCount: 139,
        },
        {
          id: 19648,
          name: 'Univers Junior',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 145,
          currentStopCount: 24,
          versusZonePassage: 145,
          versusStopCount: 24,
        },
        {
          id: 19649,
          name: 'Univers Bébé Chaussons',
          flags: 0,
          type: 0,
          box: 457,
          currentZonePassage: 190,
          currentStopCount: 80,
          versusZonePassage: 190,
          versusStopCount: 80,
        },
      ],
      current: 146,
      versus: 146,
      currentWifiVisits: 40,
      currentWifiPassages: 558,
      versusWifiVisits: 40,
      versusWifiPassages: 558,
      currentVideoVisits: 146,
      currentVideoPassages: 0,
      versusVideoVisits: 146,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 4277,
      versus: 4277,
      currentWifiVisits: 1485,
      currentWifiPassages: 11966,
      versusWifiVisits: 1485,
      versusWifiPassages: 11966,
      currentVideoVisits: 4277,
      currentVideoPassages: 0,
      versusVideoVisits: 4277,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 1200,
      versus: 1200,
      currentWifiVisits: 197,
      currentWifiPassages: 2018,
      versusWifiVisits: 197,
      versusWifiPassages: 2018,
      currentVideoVisits: 1200,
      currentVideoPassages: 0,
      versusVideoVisits: 1200,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 1054,
      versus: 1054,
      currentWifiVisits: 240,
      currentWifiPassages: 1820,
      versusWifiVisits: 240,
      versusWifiPassages: 1820,
      currentVideoVisits: 1054,
      currentVideoPassages: 0,
      versusVideoVisits: 1054,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 459,
      versus: 459,
      currentWifiVisits: 239,
      currentWifiPassages: 1900,
      versusWifiVisits: 239,
      versusWifiPassages: 1900,
      currentVideoVisits: 459,
      currentVideoPassages: 0,
      versusVideoVisits: 459,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 462,
      versus: 462,
      currentWifiVisits: 219,
      currentWifiPassages: 1651,
      versusWifiVisits: 219,
      versusWifiPassages: 1651,
      currentVideoVisits: 462,
      currentVideoPassages: 0,
      versusVideoVisits: 462,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 398,
      versus: 398,
      currentWifiVisits: 246,
      currentWifiPassages: 1885,
      versusWifiVisits: 246,
      versusWifiPassages: 1885,
      currentVideoVisits: 398,
      currentVideoPassages: 0,
      versusVideoVisits: 398,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19663,
          name: 'Femme Vitrine',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19664,
          name: 'Femme Mural',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19665,
          name: 'Femme Table Centrale',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19666,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19667,
          name: 'Enfants 35+',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19668,
          name: 'Enfants',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19669,
          name: 'DOC',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19670,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19671,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 430,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 704,
      versus: 704,
      currentWifiVisits: 344,
      currentWifiPassages: 2692,
      versusWifiVisits: 344,
      versusWifiPassages: 2692,
      currentVideoVisits: 704,
      currentVideoPassages: 0,
      versusVideoVisits: 704,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 7245,
          currentStopCount: 5625,
          versusZonePassage: 7245,
          versusStopCount: 5625,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 2618,
          currentStopCount: 1004,
          versusZonePassage: 2618,
          versusStopCount: 1004,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 2351,
          currentStopCount: 397,
          versusZonePassage: 2351,
          versusStopCount: 397,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 3233,
          currentStopCount: 1349,
          versusZonePassage: 3233,
          versusStopCount: 1349,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 5714,
          currentStopCount: 2383,
          versusZonePassage: 5714,
          versusStopCount: 2383,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 2242,
          currentStopCount: 643,
          ratioToExitCurrent: 0,
          versusZonePassage: 2242,
          versusStopCount: 643,
          ratioToExitVersus: 0,
        },
      ],
      current: 8489,
      versus: 8489,
      currentWifiVisits: 1647,
      currentWifiPassages: 17844,
      versusWifiVisits: 1647,
      versusWifiPassages: 17844,
      currentVideoVisits: 8489,
      currentVideoPassages: 0,
      versusVideoVisits: 8489,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 1268,
          currentStopCount: 984,
          versusZonePassage: 1268,
          versusStopCount: 984,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 484,
          currentStopCount: 159,
          versusZonePassage: 484,
          versusStopCount: 159,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 441,
          currentStopCount: 88,
          versusZonePassage: 441,
          versusStopCount: 88,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 513,
          currentStopCount: 173,
          versusZonePassage: 513,
          versusStopCount: 173,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 974,
          currentStopCount: 373,
          versusZonePassage: 974,
          versusStopCount: 373,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 403,
          currentStopCount: 117,
          ratioToExitCurrent: 0,
          versusZonePassage: 403,
          versusStopCount: 117,
          ratioToExitVersus: 0,
        },
      ],
      current: 1423,
      versus: 1423,
      currentWifiVisits: 308,
      currentWifiPassages: 3089,
      versusWifiVisits: 308,
      versusWifiPassages: 3089,
      currentVideoVisits: 1423,
      currentVideoPassages: 0,
      versusVideoVisits: 1423,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 957,
          currentStopCount: 820,
          versusZonePassage: 957,
          versusStopCount: 820,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 360,
          currentStopCount: 120,
          versusZonePassage: 360,
          versusStopCount: 120,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 314,
          currentStopCount: 39,
          versusZonePassage: 314,
          versusStopCount: 39,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 404,
          currentStopCount: 145,
          versusZonePassage: 404,
          versusStopCount: 145,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 775,
          currentStopCount: 318,
          versusZonePassage: 775,
          versusStopCount: 318,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 309,
          currentStopCount: 95,
          ratioToExitCurrent: 0,
          versusZonePassage: 309,
          versusStopCount: 95,
          ratioToExitVersus: 0,
        },
      ],
      current: 1290,
      versus: 1290,
      currentWifiVisits: 254,
      currentWifiPassages: 2270,
      versusWifiVisits: 254,
      versusWifiPassages: 2270,
      currentVideoVisits: 1290,
      currentVideoPassages: 0,
      versusVideoVisits: 1290,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 1121,
          currentStopCount: 843,
          versusZonePassage: 1121,
          versusStopCount: 843,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 379,
          currentStopCount: 193,
          versusZonePassage: 379,
          versusStopCount: 193,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 334,
          currentStopCount: 77,
          versusZonePassage: 334,
          versusStopCount: 77,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 474,
          currentStopCount: 227,
          versusZonePassage: 474,
          versusStopCount: 227,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 829,
          currentStopCount: 370,
          versusZonePassage: 829,
          versusStopCount: 370,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 337,
          currentStopCount: 104,
          ratioToExitCurrent: 0,
          versusZonePassage: 337,
          versusStopCount: 104,
          ratioToExitVersus: 0,
        },
      ],
      current: 1365,
      versus: 1365,
      currentWifiVisits: 271,
      currentWifiPassages: 3057,
      versusWifiVisits: 271,
      versusWifiPassages: 3057,
      currentVideoVisits: 1365,
      currentVideoPassages: 0,
      versusVideoVisits: 1365,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 1086,
          currentStopCount: 835,
          versusZonePassage: 1086,
          versusStopCount: 835,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 325,
          currentStopCount: 77,
          versusZonePassage: 325,
          versusStopCount: 77,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 344,
          currentStopCount: 31,
          versusZonePassage: 344,
          versusStopCount: 31,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 456,
          currentStopCount: 135,
          versusZonePassage: 456,
          versusStopCount: 135,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 832,
          currentStopCount: 411,
          versusZonePassage: 832,
          versusStopCount: 411,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 314,
          currentStopCount: 80,
          ratioToExitCurrent: 0,
          versusZonePassage: 314,
          versusStopCount: 80,
          ratioToExitVersus: 0,
        },
      ],
      current: 1284,
      versus: 1284,
      currentWifiVisits: 242,
      currentWifiPassages: 3016,
      versusWifiVisits: 242,
      versusWifiPassages: 3016,
      currentVideoVisits: 1284,
      currentVideoPassages: 0,
      versusVideoVisits: 1284,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 890,
          currentStopCount: 674,
          versusZonePassage: 890,
          versusStopCount: 674,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 356,
          currentStopCount: 113,
          versusZonePassage: 356,
          versusStopCount: 113,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 319,
          currentStopCount: 40,
          versusZonePassage: 319,
          versusStopCount: 40,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 408,
          currentStopCount: 148,
          versusZonePassage: 408,
          versusStopCount: 148,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 689,
          currentStopCount: 306,
          versusZonePassage: 689,
          versusStopCount: 306,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 262,
          currentStopCount: 82,
          ratioToExitCurrent: 0,
          versusZonePassage: 262,
          versusStopCount: 82,
          ratioToExitVersus: 0,
        },
      ],
      current: 1210,
      versus: 1210,
      currentWifiVisits: 191,
      currentWifiPassages: 2339,
      versusWifiVisits: 191,
      versusWifiPassages: 2339,
      currentVideoVisits: 1210,
      currentVideoPassages: 0,
      versusVideoVisits: 1210,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19556,
          name: 'Femme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 1923,
          currentStopCount: 1469,
          versusZonePassage: 1923,
          versusStopCount: 1469,
        },
        {
          id: 19557,
          name: 'Enfant',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 714,
          currentStopCount: 342,
          versusZonePassage: 714,
          versusStopCount: 342,
        },
        {
          id: 19558,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 599,
          currentStopCount: 122,
          versusZonePassage: 599,
          versusStopCount: 122,
        },
        {
          id: 19559,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 978,
          currentStopCount: 521,
          versusZonePassage: 978,
          versusStopCount: 521,
        },
        {
          id: 19560,
          name: 'Table',
          flags: 0,
          type: 0,
          box: 427,
          currentZonePassage: 1615,
          currentStopCount: 605,
          versusZonePassage: 1615,
          versusStopCount: 605,
        },
        {
          id: 19561,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 427,
          currentZonePassage: 617,
          currentStopCount: 165,
          ratioToExitCurrent: 0,
          versusZonePassage: 617,
          versusStopCount: 165,
          ratioToExitVersus: 0,
        },
      ],
      current: 1917,
      versus: 1917,
      currentWifiVisits: 381,
      currentWifiPassages: 4073,
      versusWifiVisits: 381,
      versusWifiPassages: 4073,
      currentVideoVisits: 1917,
      currentVideoPassages: 0,
      versusVideoVisits: 1917,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 945,
          currentStopCount: 135,
          versusZonePassage: 945,
          versusStopCount: 135,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 1108,
          currentStopCount: 100,
          versusZonePassage: 1108,
          versusStopCount: 100,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 489,
          currentStopCount: 299,
          versusZonePassage: 489,
          versusStopCount: 299,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 2069,
          currentStopCount: 641,
          ratioToExitCurrent: 0,
          versusZonePassage: 2069,
          versusStopCount: 641,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 3361,
          currentStopCount: 2677,
          versusZonePassage: 3361,
          versusStopCount: 2677,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 1276,
          currentStopCount: 638,
          versusZonePassage: 1276,
          versusStopCount: 638,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 2142,
          currentStopCount: 760,
          versusZonePassage: 2142,
          versusStopCount: 760,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 1433,
          currentStopCount: 529,
          versusZonePassage: 1433,
          versusStopCount: 529,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 59,
          currentStopCount: 35,
          versusZonePassage: 59,
          versusStopCount: 35,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 103,
          currentStopCount: 28,
          versusZonePassage: 103,
          versusStopCount: 28,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 897,
          currentStopCount: 217,
          versusZonePassage: 897,
          versusStopCount: 217,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 287,
          currentStopCount: 30,
          versusZonePassage: 287,
          versusStopCount: 30,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 497,
          currentStopCount: 400,
          versusZonePassage: 497,
          versusStopCount: 400,
        },
      ],
      current: 1280,
      versus: 1280,
      currentWifiVisits: 270,
      currentWifiPassages: 3617,
      versusWifiVisits: 270,
      versusWifiPassages: 3617,
      currentVideoVisits: 1280,
      currentVideoPassages: 0,
      versusVideoVisits: 1280,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 101,
          currentStopCount: 13,
          versusZonePassage: 101,
          versusStopCount: 13,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 127,
          currentStopCount: 23,
          versusZonePassage: 127,
          versusStopCount: 23,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 44,
          currentStopCount: 25,
          versusZonePassage: 44,
          versusStopCount: 25,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 193,
          currentStopCount: 61,
          ratioToExitCurrent: 0,
          versusZonePassage: 193,
          versusStopCount: 61,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 358,
          currentStopCount: 268,
          versusZonePassage: 358,
          versusStopCount: 268,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 164,
          currentStopCount: 81,
          versusZonePassage: 164,
          versusStopCount: 81,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 214,
          currentStopCount: 69,
          versusZonePassage: 214,
          versusStopCount: 69,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 151,
          currentStopCount: 54,
          versusZonePassage: 151,
          versusStopCount: 54,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 9,
          currentStopCount: 2,
          versusZonePassage: 9,
          versusStopCount: 2,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 11,
          currentStopCount: 7,
          versusZonePassage: 11,
          versusStopCount: 7,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 94,
          currentStopCount: 11,
          versusZonePassage: 94,
          versusStopCount: 11,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 34,
          currentStopCount: 4,
          versusZonePassage: 34,
          versusStopCount: 4,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 72,
          currentStopCount: 51,
          versusZonePassage: 72,
          versusStopCount: 51,
        },
      ],
      current: 126,
      versus: 126,
      currentWifiVisits: 22,
      currentWifiPassages: 451,
      versusWifiVisits: 22,
      versusWifiPassages: 451,
      currentVideoVisits: 126,
      currentVideoPassages: 0,
      versusVideoVisits: 126,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 183,
          currentStopCount: 45,
          versusZonePassage: 183,
          versusStopCount: 45,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 178,
          currentStopCount: 14,
          versusZonePassage: 178,
          versusStopCount: 14,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 85,
          currentStopCount: 47,
          versusZonePassage: 85,
          versusStopCount: 47,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 301,
          currentStopCount: 93,
          ratioToExitCurrent: 0,
          versusZonePassage: 301,
          versusStopCount: 93,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 483,
          currentStopCount: 382,
          versusZonePassage: 483,
          versusStopCount: 382,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 205,
          currentStopCount: 91,
          versusZonePassage: 205,
          versusStopCount: 91,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 323,
          currentStopCount: 117,
          versusZonePassage: 323,
          versusStopCount: 117,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 229,
          currentStopCount: 76,
          versusZonePassage: 229,
          versusStopCount: 76,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 7,
          currentStopCount: 1,
          versusZonePassage: 7,
          versusStopCount: 1,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 5,
          currentStopCount: 0,
          versusZonePassage: 5,
          versusStopCount: 0,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 144,
          currentStopCount: 40,
          versusZonePassage: 144,
          versusStopCount: 40,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 40,
          currentStopCount: 2,
          versusZonePassage: 40,
          versusStopCount: 2,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 65,
          currentStopCount: 30,
          versusZonePassage: 65,
          versusStopCount: 30,
        },
      ],
      current: 163,
      versus: 163,
      currentWifiVisits: 49,
      currentWifiPassages: 648,
      versusWifiVisits: 49,
      versusWifiPassages: 648,
      currentVideoVisits: 163,
      currentVideoPassages: 0,
      versusVideoVisits: 163,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 174,
          currentStopCount: 19,
          versusZonePassage: 174,
          versusStopCount: 19,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 216,
          currentStopCount: 13,
          versusZonePassage: 216,
          versusStopCount: 13,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 86,
          currentStopCount: 44,
          versusZonePassage: 86,
          versusStopCount: 44,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 400,
          currentStopCount: 100,
          ratioToExitCurrent: 0,
          versusZonePassage: 400,
          versusStopCount: 100,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 674,
          currentStopCount: 586,
          versusZonePassage: 674,
          versusStopCount: 586,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 244,
          currentStopCount: 129,
          versusZonePassage: 244,
          versusStopCount: 129,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 453,
          currentStopCount: 168,
          versusZonePassage: 453,
          versusStopCount: 168,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 274,
          currentStopCount: 110,
          versusZonePassage: 274,
          versusStopCount: 110,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 17,
          currentStopCount: 13,
          versusZonePassage: 17,
          versusStopCount: 13,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 33,
          currentStopCount: 5,
          versusZonePassage: 33,
          versusStopCount: 5,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 185,
          currentStopCount: 36,
          versusZonePassage: 185,
          versusStopCount: 36,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 67,
          currentStopCount: 9,
          versusZonePassage: 67,
          versusStopCount: 9,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 142,
          currentStopCount: 134,
          versusZonePassage: 142,
          versusStopCount: 134,
        },
      ],
      current: 264,
      versus: 264,
      currentWifiVisits: 55,
      currentWifiPassages: 545,
      versusWifiVisits: 55,
      versusWifiPassages: 545,
      currentVideoVisits: 264,
      currentVideoPassages: 0,
      versusVideoVisits: 264,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 145,
          currentStopCount: 19,
          versusZonePassage: 145,
          versusStopCount: 19,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 198,
          currentStopCount: 20,
          versusZonePassage: 198,
          versusStopCount: 20,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 77,
          currentStopCount: 51,
          versusZonePassage: 77,
          versusStopCount: 51,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 372,
          currentStopCount: 105,
          ratioToExitCurrent: 0,
          versusZonePassage: 372,
          versusStopCount: 105,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 636,
          currentStopCount: 518,
          versusZonePassage: 636,
          versusStopCount: 518,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 166,
          currentStopCount: 73,
          versusZonePassage: 166,
          versusStopCount: 73,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 387,
          currentStopCount: 125,
          versusZonePassage: 387,
          versusStopCount: 125,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 222,
          currentStopCount: 58,
          versusZonePassage: 222,
          versusStopCount: 58,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 9,
          currentStopCount: 5,
          versusZonePassage: 9,
          versusStopCount: 5,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 21,
          currentStopCount: 8,
          versusZonePassage: 21,
          versusStopCount: 8,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 164,
          currentStopCount: 59,
          versusZonePassage: 164,
          versusStopCount: 59,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 52,
          currentStopCount: 5,
          versusZonePassage: 52,
          versusStopCount: 5,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 53,
          currentStopCount: 48,
          versusZonePassage: 53,
          versusStopCount: 48,
        },
      ],
      current: 243,
      versus: 243,
      currentWifiVisits: 43,
      currentWifiPassages: 706,
      versusWifiVisits: 43,
      versusWifiPassages: 706,
      currentVideoVisits: 243,
      currentVideoPassages: 0,
      versusVideoVisits: 243,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 130,
          currentStopCount: 12,
          versusZonePassage: 130,
          versusStopCount: 12,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 167,
          currentStopCount: 11,
          versusZonePassage: 167,
          versusStopCount: 11,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 83,
          currentStopCount: 62,
          versusZonePassage: 83,
          versusStopCount: 62,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 423,
          currentStopCount: 151,
          ratioToExitCurrent: 0,
          versusZonePassage: 423,
          versusStopCount: 151,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 616,
          currentStopCount: 437,
          versusZonePassage: 616,
          versusStopCount: 437,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 243,
          currentStopCount: 130,
          versusZonePassage: 243,
          versusStopCount: 130,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 404,
          currentStopCount: 153,
          versusZonePassage: 404,
          versusStopCount: 153,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 272,
          currentStopCount: 125,
          versusZonePassage: 272,
          versusStopCount: 125,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 16,
          currentStopCount: 13,
          versusZonePassage: 16,
          versusStopCount: 13,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 28,
          currentStopCount: 7,
          versusZonePassage: 28,
          versusStopCount: 7,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 169,
          currentStopCount: 36,
          versusZonePassage: 169,
          versusStopCount: 36,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 50,
          currentStopCount: 8,
          versusZonePassage: 50,
          versusStopCount: 8,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 95,
          currentStopCount: 95,
          versusZonePassage: 95,
          versusStopCount: 95,
        },
      ],
      current: 205,
      versus: 205,
      currentWifiVisits: 53,
      currentWifiPassages: 705,
      versusWifiVisits: 53,
      versusWifiPassages: 705,
      currentVideoVisits: 205,
      currentVideoPassages: 0,
      versusVideoVisits: 205,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19534,
          name: 'Vitrine droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 212,
          currentStopCount: 27,
          versusZonePassage: 212,
          versusStopCount: 27,
        },
        {
          id: 19535,
          name: 'Vitrine gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 222,
          currentStopCount: 19,
          versusZonePassage: 222,
          versusStopCount: 19,
        },
        {
          id: 19536,
          name: 'Femme gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 114,
          currentStopCount: 70,
          versusZonePassage: 114,
          versusStopCount: 70,
        },
        {
          id: 19537,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 421,
          currentZonePassage: 380,
          currentStopCount: 131,
          ratioToExitCurrent: 0,
          versusZonePassage: 380,
          versusStopCount: 131,
          ratioToExitVersus: 0,
        },
        {
          id: 19538,
          name: 'Femme droite',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 594,
          currentStopCount: 486,
          versusZonePassage: 594,
          versusStopCount: 486,
        },
        {
          id: 19539,
          name: 'Homme',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 254,
          currentStopCount: 134,
          versusZonePassage: 254,
          versusStopCount: 134,
        },
        {
          id: 19548,
          name: 'Ilot central droit',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 361,
          currentStopCount: 128,
          versusZonePassage: 361,
          versusStopCount: 128,
        },
        {
          id: 19549,
          name: 'Ilot central gauche',
          flags: 0,
          type: 0,
          box: 421,
          currentZonePassage: 285,
          currentStopCount: 106,
          versusZonePassage: 285,
          versusStopCount: 106,
        },
        {
          id: 19540,
          name: 'Bébé',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 1,
          currentStopCount: 1,
          versusZonePassage: 1,
          versusStopCount: 1,
        },
        {
          id: 19541,
          name: 'Enfants 1',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 5,
          currentStopCount: 1,
          versusZonePassage: 5,
          versusStopCount: 1,
        },
        {
          id: 19542,
          name: 'Junior',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 141,
          currentStopCount: 35,
          versusZonePassage: 141,
          versusStopCount: 35,
        },
        {
          id: 19543,
          name: 'Pantoufle',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 44,
          currentStopCount: 2,
          versusZonePassage: 44,
          versusStopCount: 2,
        },
        {
          id: 19544,
          name: 'Enfants 2',
          flags: 0,
          type: 0,
          box: 422,
          currentZonePassage: 70,
          currentStopCount: 42,
          versusZonePassage: 70,
          versusStopCount: 42,
        },
      ],
      current: 279,
      versus: 279,
      currentWifiVisits: 48,
      currentWifiPassages: 562,
      versusWifiVisits: 48,
      versusWifiPassages: 562,
      currentVideoVisits: 279,
      currentVideoPassages: 0,
      versusVideoVisits: 279,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Boulogne',
        id: 239,
        boxId: 429,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
      {
        name: 'Boulogne',
        id: 239,
        boxId: 431,
        boxName: 'IVSCount_d',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 266, versus: 266 },
        '11': { current: 470, versus: 470 },
        '12': { current: 351, versus: 351 },
        '13': { current: 391, versus: 391 },
        '14': { current: 670, versus: 670 },
        '15': { current: 833, versus: 833 },
        '16': { current: 830, versus: 830 },
        '17': { current: 653, versus: 653 },
        '18': { current: 439, versus: 439 },
        '19': { current: 212, versus: 212 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 36, versus: 36 },
        '11': { current: 70, versus: 70 },
        '12': { current: 43, versus: 43 },
        '13': { current: 38, versus: 38 },
        '14': { current: 132, versus: 132 },
        '15': { current: 176, versus: 176 },
        '16': { current: 104, versus: 104 },
        '17': { current: 93, versus: 93 },
        '18': { current: 51, versus: 51 },
        '19': { current: 30, versus: 30 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 32, versus: 32 },
        '11': { current: 45, versus: 45 },
        '12': { current: 36, versus: 36 },
        '13': { current: 61, versus: 61 },
        '14': { current: 97, versus: 97 },
        '15': { current: 116, versus: 116 },
        '16': { current: 102, versus: 102 },
        '17': { current: 91, versus: 91 },
        '18': { current: 70, versus: 70 },
        '19': { current: 44, versus: 44 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 50, versus: 50 },
        '11': { current: 112, versus: 112 },
        '12': { current: 29, versus: 29 },
        '13': { current: 76, versus: 76 },
        '14': { current: 93, versus: 93 },
        '15': { current: 117, versus: 117 },
        '16': { current: 119, versus: 119 },
        '17': { current: 81, versus: 81 },
        '18': { current: 63, versus: 63 },
        '19': { current: 23, versus: 23 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 57, versus: 57 },
        '11': { current: 75, versus: 75 },
        '12': { current: 56, versus: 56 },
        '13': { current: 49, versus: 49 },
        '14': { current: 78, versus: 78 },
        '15': { current: 112, versus: 112 },
        '16': { current: 87, versus: 87 },
        '17': { current: 95, versus: 95 },
        '18': { current: 58, versus: 58 },
        '19': { current: 30, versus: 30 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 45, versus: 45 },
        '11': { current: 32, versus: 32 },
        '12': { current: 64, versus: 64 },
        '13': { current: 53, versus: 53 },
        '14': { current: 87, versus: 87 },
        '15': { current: 90, versus: 90 },
        '16': { current: 111, versus: 111 },
        '17': { current: 91, versus: 91 },
        '18': { current: 42, versus: 42 },
        '19': { current: 30, versus: 30 },
        '20': { current: 0, versus: 0 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 46, versus: 46 },
        '11': { current: 136, versus: 136 },
        '12': { current: 123, versus: 123 },
        '13': { current: 114, versus: 114 },
        '14': { current: 183, versus: 183 },
        '15': { current: 222, versus: 222 },
        '16': { current: 307, versus: 307 },
        '17': { current: 202, versus: 202 },
        '18': { current: 155, versus: 155 },
        '19': { current: 55, versus: 55 },
        name: 'Coquelles',
        id: 240,
        boxId: 428,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 54, versus: 54 },
        '10': { current: 266, versus: 266 },
        '11': { current: 408, versus: 408 },
        '12': { current: 357, versus: 357 },
        '13': { current: 368, versus: 368 },
        '14': { current: 488, versus: 488 },
        '15': { current: 701, versus: 701 },
        '16': { current: 629, versus: 629 },
        '17': { current: 586, versus: 586 },
        '18': { current: 497, versus: 497 },
        '19': { current: 294, versus: 294 },
        '20': { current: 0, versus: 0 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 6, versus: 6 },
        '10': { current: 53, versus: 53 },
        '11': { current: 52, versus: 52 },
        '12': { current: 73, versus: 73 },
        '13': { current: 54, versus: 54 },
        '14': { current: 65, versus: 65 },
        '15': { current: 77, versus: 77 },
        '16': { current: 84, versus: 84 },
        '17': { current: 70, versus: 70 },
        '18': { current: 97, versus: 97 },
        '19': { current: 37, versus: 37 },
        '20': { current: 0, versus: 0 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 11, versus: 11 },
        '10': { current: 30, versus: 30 },
        '11': { current: 38, versus: 38 },
        '12': { current: 56, versus: 56 },
        '13': { current: 47, versus: 47 },
        '14': { current: 76, versus: 76 },
        '15': { current: 114, versus: 114 },
        '16': { current: 68, versus: 68 },
        '17': { current: 62, versus: 62 },
        '18': { current: 71, versus: 71 },
        '19': { current: 62, versus: 62 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 14, versus: 14 },
        '10': { current: 36, versus: 36 },
        '11': { current: 58, versus: 58 },
        '12': { current: 49, versus: 49 },
        '13': { current: 77, versus: 77 },
        '14': { current: 80, versus: 80 },
        '15': { current: 102, versus: 102 },
        '16': { current: 104, versus: 104 },
        '17': { current: 83, versus: 83 },
        '18': { current: 70, versus: 70 },
        '19': { current: 60, versus: 60 },
        '20': { current: 0, versus: 0 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 4, versus: 4 },
        '10': { current: 43, versus: 43 },
        '11': { current: 76, versus: 76 },
        '12': { current: 37, versus: 37 },
        '13': { current: 46, versus: 46 },
        '14': { current: 55, versus: 55 },
        '15': { current: 105, versus: 105 },
        '16': { current: 77, versus: 77 },
        '17': { current: 65, versus: 65 },
        '18': { current: 84, versus: 84 },
        '19': { current: 24, versus: 24 },
        '20': { current: 0, versus: 0 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 6, versus: 6 },
        '10': { current: 29, versus: 29 },
        '11': { current: 70, versus: 70 },
        '12': { current: 51, versus: 51 },
        '13': { current: 64, versus: 64 },
        '14': { current: 64, versus: 64 },
        '15': { current: 88, versus: 88 },
        '16': { current: 87, versus: 87 },
        '17': { current: 77, versus: 77 },
        '18': { current: 54, versus: 54 },
        '19': { current: 32, versus: 32 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 13, versus: 13 },
        '10': { current: 75, versus: 75 },
        '11': { current: 114, versus: 114 },
        '12': { current: 91, versus: 91 },
        '13': { current: 80, versus: 80 },
        '14': { current: 148, versus: 148 },
        '15': { current: 215, versus: 215 },
        '16': { current: 209, versus: 209 },
        '17': { current: 229, versus: 229 },
        '18': { current: 121, versus: 121 },
        '19': { current: 79, versus: 79 },
        name: 'Englos',
        id: 233,
        boxId: 426,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 44, versus: 44 },
        '11': { current: 83, versus: 83 },
        '12': { current: 48, versus: 48 },
        '13': { current: 77, versus: 77 },
        '14': { current: 90, versus: 90 },
        '15': { current: 122, versus: 122 },
        '16': { current: 100, versus: 100 },
        '17': { current: 88, versus: 88 },
        '18': { current: 74, versus: 74 },
        '19': { current: 1, versus: 1 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 44, versus: 44 },
        '11': { current: 83, versus: 83 },
        '12': { current: 48, versus: 48 },
        '13': { current: 77, versus: 77 },
        '14': { current: 90, versus: 90 },
        '15': { current: 122, versus: 122 },
        '16': { current: 100, versus: 100 },
        '17': { current: 88, versus: 88 },
        '18': { current: 74, versus: 74 },
        '19': { current: 1, versus: 1 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
      {
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '13': { current: 0, versus: 0 },
        '14': { current: 8, versus: 8 },
        '15': { current: 21, versus: 21 },
        '16': { current: 7, versus: 7 },
        '17': { current: 7, versus: 7 },
        '18': { current: 11, versus: 11 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
      {
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 13, versus: 13 },
        '11': { current: 10, versus: 10 },
        '12': { current: 11, versus: 11 },
        '13': { current: 6, versus: 6 },
        '14': { current: 8, versus: 8 },
        '15': { current: 7, versus: 7 },
        '16': { current: 9, versus: 9 },
        '17': { current: 13, versus: 13 },
        '18': { current: 4, versus: 4 },
        '19': { current: 1, versus: 1 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 4, versus: 4 },
        '11': { current: 21, versus: 21 },
        '12': { current: 10, versus: 10 },
        '13': { current: 22, versus: 22 },
        '14': { current: 24, versus: 24 },
        '15': { current: 37, versus: 37 },
        '16': { current: 24, versus: 24 },
        '17': { current: 31, versus: 31 },
        '18': { current: 15, versus: 15 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
      {
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 8, versus: 8 },
        '11': { current: 11, versus: 11 },
        '12': { current: 10, versus: 10 },
        '13': { current: 17, versus: 17 },
        '14': { current: 21, versus: 21 },
        '15': { current: 18, versus: 18 },
        '16': { current: 24, versus: 24 },
        '17': { current: 5, versus: 5 },
        '18': { current: 9, versus: 9 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 6, versus: 6 },
        '11': { current: 10, versus: 10 },
        '12': { current: 12, versus: 12 },
        '13': { current: 12, versus: 12 },
        '14': { current: 25, versus: 25 },
        '15': { current: 28, versus: 28 },
        '16': { current: 15, versus: 15 },
        '17': { current: 8, versus: 8 },
        '18': { current: 18, versus: 18 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
      {
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 13, versus: 13 },
        '11': { current: 31, versus: 31 },
        '12': { current: 5, versus: 5 },
        '13': { current: 20, versus: 20 },
        '14': { current: 4, versus: 4 },
        '15': { current: 11, versus: 11 },
        '16': { current: 21, versus: 21 },
        '17': { current: 24, versus: 24 },
        '18': { current: 17, versus: 17 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 456,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Lille',
        id: 232,
        boxId: 457,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 115, versus: 115 },
        '10': { current: 340, versus: 340 },
        '11': { current: 320, versus: 320 },
        '12': { current: 327, versus: 327 },
        '13': { current: 317, versus: 317 },
        '14': { current: 513, versus: 513 },
        '15': { current: 608, versus: 608 },
        '16': { current: 585, versus: 585 },
        '17': { current: 563, versus: 563 },
        '18': { current: 461, versus: 461 },
        '19': { current: 128, versus: 128 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 36, versus: 36 },
        '10': { current: 107, versus: 107 },
        '11': { current: 80, versus: 80 },
        '12': { current: 73, versus: 73 },
        '13': { current: 90, versus: 90 },
        '14': { current: 158, versus: 158 },
        '15': { current: 189, versus: 189 },
        '16': { current: 129, versus: 129 },
        '17': { current: 145, versus: 145 },
        '18': { current: 145, versus: 145 },
        '19': { current: 48, versus: 48 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 34, versus: 34 },
        '10': { current: 93, versus: 93 },
        '11': { current: 101, versus: 101 },
        '12': { current: 96, versus: 96 },
        '13': { current: 82, versus: 82 },
        '14': { current: 138, versus: 138 },
        '15': { current: 168, versus: 168 },
        '16': { current: 142, versus: 142 },
        '17': { current: 96, versus: 96 },
        '18': { current: 77, versus: 77 },
        '19': { current: 27, versus: 27 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 16, versus: 16 },
        '10': { current: 33, versus: 33 },
        '11': { current: 27, versus: 27 },
        '12': { current: 36, versus: 36 },
        '13': { current: 42, versus: 42 },
        '14': { current: 74, versus: 74 },
        '15': { current: 49, versus: 49 },
        '16': { current: 63, versus: 63 },
        '17': { current: 62, versus: 62 },
        '18': { current: 48, versus: 48 },
        '19': { current: 9, versus: 9 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 12, versus: 12 },
        '10': { current: 30, versus: 30 },
        '11': { current: 34, versus: 34 },
        '12': { current: 53, versus: 53 },
        '13': { current: 29, versus: 29 },
        '14': { current: 50, versus: 50 },
        '15': { current: 56, versus: 56 },
        '16': { current: 81, versus: 81 },
        '17': { current: 52, versus: 52 },
        '18': { current: 62, versus: 62 },
        '19': { current: 3, versus: 3 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 5, versus: 5 },
        '10': { current: 34, versus: 34 },
        '11': { current: 38, versus: 38 },
        '12': { current: 34, versus: 34 },
        '13': { current: 37, versus: 37 },
        '14': { current: 35, versus: 35 },
        '15': { current: 38, versus: 38 },
        '16': { current: 50, versus: 50 },
        '17': { current: 63, versus: 63 },
        '18': { current: 49, versus: 49 },
        '19': { current: 15, versus: 15 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 12, versus: 12 },
        '10': { current: 43, versus: 43 },
        '11': { current: 40, versus: 40 },
        '12': { current: 35, versus: 35 },
        '13': { current: 37, versus: 37 },
        '14': { current: 58, versus: 58 },
        '15': { current: 108, versus: 108 },
        '16': { current: 120, versus: 120 },
        '17': { current: 145, versus: 145 },
        '18': { current: 80, versus: 80 },
        '19': { current: 26, versus: 26 },
        name: 'Longuenesse',
        id: 241,
        boxId: 430,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 454, versus: 454 },
        '11': { current: 754, versus: 754 },
        '12': { current: 684, versus: 684 },
        '13': { current: 698, versus: 698 },
        '14': { current: 911, versus: 911 },
        '15': { current: 1155, versus: 1155 },
        '16': { current: 1172, versus: 1172 },
        '17': { current: 1051, versus: 1051 },
        '18': { current: 935, versus: 935 },
        '19': { current: 674, versus: 674 },
        '20': { current: 1, versus: 1 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 82, versus: 82 },
        '11': { current: 141, versus: 141 },
        '12': { current: 122, versus: 122 },
        '13': { current: 115, versus: 115 },
        '14': { current: 171, versus: 171 },
        '15': { current: 172, versus: 172 },
        '16': { current: 160, versus: 160 },
        '17': { current: 178, versus: 178 },
        '18': { current: 144, versus: 144 },
        '19': { current: 138, versus: 138 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 77, versus: 77 },
        '11': { current: 102, versus: 102 },
        '12': { current: 99, versus: 99 },
        '13': { current: 108, versus: 108 },
        '14': { current: 130, versus: 130 },
        '15': { current: 196, versus: 196 },
        '16': { current: 173, versus: 173 },
        '17': { current: 166, versus: 166 },
        '18': { current: 131, versus: 131 },
        '19': { current: 107, versus: 107 },
        '20': { current: 1, versus: 1 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 90, versus: 90 },
        '11': { current: 106, versus: 106 },
        '12': { current: 115, versus: 115 },
        '13': { current: 106, versus: 106 },
        '14': { current: 155, versus: 155 },
        '15': { current: 186, versus: 186 },
        '16': { current: 188, versus: 188 },
        '17': { current: 156, versus: 156 },
        '18': { current: 158, versus: 158 },
        '19': { current: 105, versus: 105 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 47, versus: 47 },
        '11': { current: 139, versus: 139 },
        '12': { current: 99, versus: 99 },
        '13': { current: 112, versus: 112 },
        '14': { current: 114, versus: 114 },
        '15': { current: 183, versus: 183 },
        '16': { current: 211, versus: 211 },
        '17': { current: 149, versus: 149 },
        '18': { current: 146, versus: 146 },
        '19': { current: 84, versus: 84 },
        '20': { current: 0, versus: 0 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 57, versus: 57 },
        '11': { current: 113, versus: 113 },
        '12': { current: 71, versus: 71 },
        '13': { current: 105, versus: 105 },
        '14': { current: 125, versus: 125 },
        '15': { current: 156, versus: 156 },
        '16': { current: 178, versus: 178 },
        '17': { current: 157, versus: 157 },
        '18': { current: 136, versus: 136 },
        '19': { current: 112, versus: 112 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 101, versus: 101 },
        '11': { current: 153, versus: 153 },
        '12': { current: 178, versus: 178 },
        '13': { current: 152, versus: 152 },
        '14': { current: 216, versus: 216 },
        '15': { current: 262, versus: 262 },
        '16': { current: 262, versus: 262 },
        '17': { current: 245, versus: 245 },
        '18': { current: 220, versus: 220 },
        '19': { current: 128, versus: 128 },
        '20': { current: 0, versus: 0 },
        name: 'Noyelles Godault',
        id: 234,
        boxId: 427,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 5, versus: 5 },
        '10': { current: 86, versus: 86 },
        '11': { current: 115, versus: 115 },
        '12': { current: 68, versus: 68 },
        '13': { current: 86, versus: 86 },
        '14': { current: 125, versus: 125 },
        '15': { current: 219, versus: 219 },
        '16': { current: 228, versus: 228 },
        '17': { current: 173, versus: 173 },
        '18': { current: 175, versus: 175 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        '9': { current: 5, versus: 5 },
        '10': { current: 86, versus: 86 },
        '11': { current: 115, versus: 115 },
        '12': { current: 68, versus: 68 },
        '13': { current: 86, versus: 86 },
        '14': { current: 125, versus: 125 },
        '15': { current: 219, versus: 219 },
        '16': { current: 228, versus: 228 },
        '17': { current: 173, versus: 173 },
        '18': { current: 175, versus: 175 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
      {
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '03 juillet 2021',
          dateVs: '04 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '04', month: '07', year: '2021' },
      },
    ],
    [
      {
        '13': { current: 0, versus: 0 },
        '14': { current: 14, versus: 14 },
        '15': { current: 28, versus: 28 },
        '16': { current: 41, versus: 41 },
        '17': { current: 25, versus: 25 },
        '18': { current: 18, versus: 18 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
      {
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '04 juillet 2021',
          dateVs: '05 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '05', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 13, versus: 13 },
        '11': { current: 10, versus: 10 },
        '12': { current: 2, versus: 2 },
        '13': { current: 18, versus: 18 },
        '14': { current: 15, versus: 15 },
        '15': { current: 29, versus: 29 },
        '16': { current: 28, versus: 28 },
        '17': { current: 19, versus: 19 },
        '18': { current: 29, versus: 29 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '05 juillet 2021',
          dateVs: '06 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '06', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 13, versus: 13 },
        '11': { current: 26, versus: 26 },
        '12': { current: 14, versus: 14 },
        '13': { current: 19, versus: 19 },
        '14': { current: 33, versus: 33 },
        '15': { current: 41, versus: 41 },
        '16': { current: 35, versus: 35 },
        '17': { current: 38, versus: 38 },
        '18': { current: 45, versus: 45 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
      {
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '06 juillet 2021',
          dateVs: '07 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '07', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 18, versus: 18 },
        '11': { current: 28, versus: 28 },
        '12': { current: 12, versus: 12 },
        '13': { current: 18, versus: 18 },
        '14': { current: 16, versus: 16 },
        '15': { current: 61, versus: 61 },
        '16': { current: 40, versus: 40 },
        '17': { current: 26, versus: 26 },
        '18': { current: 24, versus: 24 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
      {
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '07 juillet 2021',
          dateVs: '08 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '08', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 19, versus: 19 },
        '11': { current: 17, versus: 17 },
        '12': { current: 14, versus: 14 },
        '13': { current: 10, versus: 10 },
        '14': { current: 23, versus: 23 },
        '15': { current: 32, versus: 32 },
        '16': { current: 35, versus: 35 },
        '17': { current: 34, versus: 34 },
        '18': { current: 21, versus: 21 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
      {
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '08 juillet 2021',
          dateVs: '09 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '09', month: '07', year: '2021' },
      },
    ],
    [
      {
        '9': { current: 5, versus: 5 },
        '10': { current: 23, versus: 23 },
        '11': { current: 34, versus: 34 },
        '12': { current: 26, versus: 26 },
        '13': { current: 21, versus: 21 },
        '14': { current: 24, versus: 24 },
        '15': { current: 28, versus: 28 },
        '16': { current: 49, versus: 49 },
        '17': { current: 31, versus: 31 },
        '18': { current: 38, versus: 38 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 421,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
      {
        '9': { current: 0, versus: 0 },
        '10': { current: 0, versus: 0 },
        '11': { current: 0, versus: 0 },
        '12': { current: 0, versus: 0 },
        '13': { current: 0, versus: 0 },
        '14': { current: 0, versus: 0 },
        '15': { current: 0, versus: 0 },
        '16': { current: 0, versus: 0 },
        '17': { current: 0, versus: 0 },
        '18': { current: 0, versus: 0 },
        '19': { current: 0, versus: 0 },
        name: 'Saint-Omer',
        id: 235,
        boxId: 422,
        boxName: 'IVSteps',
        enabled: true,
        timeData: {
          date: '09 juillet 2021',
          dateVs: '10 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '10', month: '07', year: '2021' },
      },
    ],
  ],
  [
    {
      tickets: '242.00',
      ca: '14511.03',
      name: 'Boulogne',
      id: 239,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: 0,
      ca: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '37.00',
      ca: '2241.80',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '48.00',
      ca: '2895.93',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '40.00',
      ca: '2387.85',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '38.00',
      ca: '1834.70',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '44.00',
      ca: '2315.90',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '35.00',
      ca: '2834.85',
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '127.00',
      ca: '6428.47',
      name: 'Coquelles',
      id: 240,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: '22.00',
      ca: '1117.90',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '19.00',
      ca: '1134.85',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '14.00',
      ca: '725.00',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '11.00',
      ca: '644.90',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '11.00',
      ca: '687.50',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '17.00',
      ca: '682.50',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '19.00',
      ca: '744.92',
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '234.00',
      ca: '15229.53',
      name: 'Englos',
      id: 233,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: '8.00',
      ca: '459.95',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '26.00',
      ca: '1484.00',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '34.00',
      ca: '2219.75',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '42.00',
      ca: '2797.35',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '40.00',
      ca: '2452.58',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '29.00',
      ca: '1728.75',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '55.00',
      ca: '4087.15',
      name: 'Englos',
      id: 233,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '70.00',
      ca: '3580.95',
      name: 'Lille',
      id: 232,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: 0,
      ca: 0,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '10.00',
      ca: '343.50',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '10.00',
      ca: '574.00',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '12.00',
      ca: '736.75',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '10.00',
      ca: '431.00',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '10.00',
      ca: '535.90',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '18.00',
      ca: '959.80',
      name: 'Lille',
      id: 232,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '289.00',
      ca: '18758.05',
      name: 'Longuenesse',
      id: 241,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: 0,
      ca: 0,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '40.00',
      ca: '2473.40',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '41.00',
      ca: '2769.60',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '50.00',
      ca: '3479.31',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '47.00',
      ca: '2933.89',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '46.00',
      ca: '2913.55',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '65.00',
      ca: '4188.30',
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '188.00',
      ca: '11398.24',
      name: 'Noyelles Godault',
      id: 234,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: '16.00',
      ca: '982.89',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '24.00',
      ca: '1622.90',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '22.00',
      ca: '1212.74',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '23.00',
      ca: '1386.90',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '29.00',
      ca: '1578.01',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '24.00',
      ca: '1431.90',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '50.00',
      ca: '3182.90',
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      tickets: '141.00',
      ca: '8854.49',
      name: 'Saint-Omer',
      id: 235,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      tickets: 0,
      ca: 0,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      tickets: '12.00',
      ca: '644.00',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      tickets: '20.00',
      ca: '1491.91',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      tickets: '22.00',
      ca: '1273.77',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      tickets: '21.00',
      ca: '1271.50',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      tickets: '37.00',
      ca: '2289.60',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      tickets: '29.00',
      ca: '1883.71',
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
  ],
  [
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Boulogne',
      id: 239,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 554201929,
      versus: 554201929,
      name: 'Coquelles',
      id: 240,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 551968679,
      versus: 551968679,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 602061644,
      versus: 602061644,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 548708401,
      versus: 548708401,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 543300997,
      versus: 543300997,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 600972064,
      versus: 600972064,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 506352865,
      versus: 506352865,
      name: 'Coquelles',
      id: 240,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 479654402,
      versus: 479654402,
      name: 'Englos',
      id: 233,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 468938353,
      versus: 468938353,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 511343254,
      versus: 511343254,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 466294137,
      versus: 466294137,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 487585430,
      versus: 487585430,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 478569112,
      versus: 478569112,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 465951660,
      versus: 465951660,
      name: 'Englos',
      id: 233,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 583758694,
      versus: 583758694,
      name: 'Lille',
      id: 232,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 387367503,
      versus: 387367503,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 540145358,
      versus: 540145358,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 666865015,
      versus: 666865015,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 638713294,
      versus: 638713294,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 535454957,
      versus: 535454957,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 621553360,
      versus: 621553360,
      name: 'Lille',
      id: 232,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 504314416,
      versus: 504314416,
      name: 'Longuenesse',
      id: 241,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 552845592,
      versus: 552845592,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 495138766,
      versus: 495138766,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 508722240,
      versus: 508722240,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 512475218,
      versus: 512475218,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 476509697,
      versus: 476509697,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 490134352,
      versus: 490134352,
      name: 'Longuenesse',
      id: 241,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 478377305,
      versus: 478377305,
      name: 'Noyelles Godault',
      id: 234,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 486055204,
      versus: 486055204,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 490375617,
      versus: 490375617,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 479385042,
      versus: 479385042,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 488647468,
      versus: 488647468,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 470267793,
      versus: 470267793,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 458425962,
      versus: 458425962,
      name: 'Noyelles Godault',
      id: 234,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
    {
      current: 411176585,
      versus: 411176585,
      name: 'Saint-Omer',
      id: 235,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '03 juillet 2021',
        dateVs: '04 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '04', month: '07', year: '2021' },
    },
    {
      current: 398079349,
      versus: 398079349,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '04 juillet 2021',
        dateVs: '05 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '05', month: '07', year: '2021' },
    },
    {
      current: 471788817,
      versus: 471788817,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '05 juillet 2021',
        dateVs: '06 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '06', month: '07', year: '2021' },
    },
    {
      current: 423408435,
      versus: 423408435,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '06 juillet 2021',
        dateVs: '07 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '07', month: '07', year: '2021' },
    },
    {
      current: 337730552,
      versus: 337730552,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '07 juillet 2021',
        dateVs: '08 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '08', month: '07', year: '2021' },
    },
    {
      current: 408376075,
      versus: 408376075,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '08 juillet 2021',
        dateVs: '09 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '09', month: '07', year: '2021' },
    },
    {
      current: 404196563,
      versus: 404196563,
      name: 'Saint-Omer',
      id: 235,
      timeData: {
        date: '09 juillet 2021',
        dateVs: '10 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '10', month: '07', year: '2021' },
    },
  ],
]);
const API_RENAULT_DAY = of([
  [
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 17,
          currentStopCount: 2,
          versusZonePassage: 78,
          versusStopCount: 16,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 0,
          currentStopCount: 3,
          versusZonePassage: 12,
          versusStopCount: 38,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 0,
          currentStopCount: 5,
          versusZonePassage: 18,
          versusStopCount: 23,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 13,
          currentStopCount: 6,
          versusZonePassage: 256,
          versusStopCount: 51,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 126,
          currentStopCount: 12,
          versusZonePassage: 2093,
          versusStopCount: 169,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 28,
          currentStopCount: 8,
          versusZonePassage: 344,
          versusStopCount: 67,
        },
      ],
      current: 2,
      versus: 21,
      currentWifiVisits: 3,
      currentWifiPassages: 49,
      versusWifiVisits: 58,
      versusWifiPassages: 887,
      currentVideoVisits: 2,
      currentVideoPassages: 0,
      versusVideoVisits: 21,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 50,
          versusStopCount: 8,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 158,
          versusStopCount: 116,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 688,
          versusStopCount: 41,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 17,
          versusStopCount: 4,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 65,
          versusStopCount: 57,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 21,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 31,
          versusStopCount: 4,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 81,
          versusStopCount: 48,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 32,
          versusStopCount: 4,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 238,
          versusStopCount: 157,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 595,
          versusStopCount: 51,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 57,
          versusStopCount: 11,
        },
      ],
      current: 0,
      versus: 162,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 44,
      versusWifiPassages: 769,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 162,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        '3': { versus: 0, current: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 2, versus: 2 },
        '11': { current: 0, versus: 2 },
        '12': { versus: 1 },
        '13': { versus: 1 },
        '14': { versus: 1 },
        '15': { versus: 2 },
        '16': { versus: 4 },
        '17': { versus: 6 },
        '18': { versus: 1 },
        '19': { versus: 1 },
        '20': { versus: 0 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { versus: 0 },
        '9': { versus: 0 },
        '10': { versus: 18 },
        '11': { versus: 16 },
        '12': { versus: 10 },
        '13': { versus: 28 },
        '14': { versus: 16 },
        '15': { versus: 17 },
        '16': { versus: 25 },
        '17': { versus: 17 },
        '18': { versus: 6 },
        '19': { versus: 9 },
        '20': { versus: 0 },
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
  ],
  [{ cegidDataNotAvailable: true }, { cegidDataNotAvailable: true }],
  [
    {
      current: 891979906,
      versus: 667405815,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 583308844,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    {
      '2021-07-23': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 131,
            currentStopCount: 2,
            stopsDurationInZone: 117,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 164,
            currentStopCount: 3,
            stopsDurationInZone: 120,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 7,
            currentStopCount: 5,
            stopsDurationInZone: 78,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 410,
            currentStopCount: 6,
            stopsDurationInZone: 384,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 126,
            timeInZone: 617,
            currentStopCount: 12,
            stopsDurationInZone: 102,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 496,
            currentStopCount: 8,
            stopsDurationInZone: 74,
          },
        ],
      },
    },
    {
      '2021-07-23': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
    },
  ],
]);
const API_RENAULT_WEEK = of([
  [
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 3524,
          currentStopCount: 680,
          versusZonePassage: 3524,
          versusStopCount: 680,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 92,
          currentStopCount: 1124,
          versusZonePassage: 92,
          versusStopCount: 1124,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 115,
          currentStopCount: 444,
          versusZonePassage: 115,
          versusStopCount: 444,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 2654,
          currentStopCount: 962,
          versusZonePassage: 2654,
          versusStopCount: 962,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 19417,
          currentStopCount: 1620,
          versusZonePassage: 19417,
          versusStopCount: 1620,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 5317,
          currentStopCount: 795,
          versusZonePassage: 5317,
          versusStopCount: 795,
        },
      ],
      current: 551,
      versus: 551,
      currentWifiVisits: 283,
      currentWifiPassages: 4486,
      versusWifiVisits: 283,
      versusWifiPassages: 4486,
      currentVideoVisits: 551,
      currentVideoPassages: 0,
      versusVideoVisits: 551,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 859,
          currentStopCount: 151,
          versusZonePassage: 859,
          versusStopCount: 151,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 19,
          currentStopCount: 219,
          versusZonePassage: 19,
          versusStopCount: 219,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 17,
          currentStopCount: 51,
          versusZonePassage: 17,
          versusStopCount: 51,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 789,
          currentStopCount: 187,
          versusZonePassage: 789,
          versusStopCount: 187,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 5493,
          currentStopCount: 450,
          versusZonePassage: 5493,
          versusStopCount: 450,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1293,
          currentStopCount: 152,
          versusZonePassage: 1293,
          versusStopCount: 152,
        },
      ],
      current: 105,
      versus: 105,
      currentWifiVisits: 77,
      currentWifiPassages: 1378,
      versusWifiVisits: 77,
      versusWifiPassages: 1378,
      currentVideoVisits: 105,
      currentVideoPassages: 0,
      versusVideoVisits: 105,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 1105,
          currentStopCount: 199,
          versusZonePassage: 1105,
          versusStopCount: 199,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 40,
          currentStopCount: 442,
          versusZonePassage: 40,
          versusStopCount: 442,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 38,
          currentStopCount: 124,
          versusZonePassage: 38,
          versusStopCount: 124,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 948,
          currentStopCount: 306,
          versusZonePassage: 948,
          versusStopCount: 306,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 6336,
          currentStopCount: 560,
          versusZonePassage: 6336,
          versusStopCount: 560,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1724,
          currentStopCount: 245,
          versusZonePassage: 1724,
          versusStopCount: 245,
        },
      ],
      current: 194,
      versus: 194,
      currentWifiVisits: 87,
      currentWifiPassages: 1430,
      versusWifiVisits: 87,
      versusWifiPassages: 1430,
      currentVideoVisits: 194,
      currentVideoPassages: 0,
      versusVideoVisits: 194,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 1166,
          currentStopCount: 215,
          versusZonePassage: 1166,
          versusStopCount: 215,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 26,
          currentStopCount: 363,
          versusZonePassage: 26,
          versusStopCount: 363,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 33,
          currentStopCount: 117,
          versusZonePassage: 33,
          versusStopCount: 117,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 816,
          currentStopCount: 182,
          versusZonePassage: 816,
          versusStopCount: 182,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 6051,
          currentStopCount: 514,
          versusZonePassage: 6051,
          versusStopCount: 514,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1651,
          currentStopCount: 208,
          versusZonePassage: 1651,
          versusStopCount: 208,
        },
      ],
      current: 189,
      versus: 189,
      currentWifiVisits: 85,
      currentWifiPassages: 1316,
      versusWifiVisits: 85,
      versusWifiPassages: 1316,
      currentVideoVisits: 189,
      currentVideoPassages: 0,
      versusVideoVisits: 189,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 826,
          currentStopCount: 130,
          versusZonePassage: 826,
          versusStopCount: 130,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 21,
          currentStopCount: 176,
          versusZonePassage: 21,
          versusStopCount: 176,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 36,
          currentStopCount: 145,
          versusZonePassage: 36,
          versusStopCount: 145,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 611,
          currentStopCount: 245,
          versusZonePassage: 611,
          versusStopCount: 245,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 4964,
          currentStopCount: 300,
          versusZonePassage: 4964,
          versusStopCount: 300,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1317,
          currentStopCount: 209,
          versusZonePassage: 1317,
          versusStopCount: 209,
        },
      ],
      current: 111,
      versus: 111,
      currentWifiVisits: 79,
      currentWifiPassages: 1214,
      versusWifiVisits: 79,
      versusWifiPassages: 1214,
      currentVideoVisits: 111,
      currentVideoPassages: 0,
      versusVideoVisits: 111,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 1039,
          currentStopCount: 236,
          versusZonePassage: 1039,
          versusStopCount: 236,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 33,
          currentStopCount: 385,
          versusZonePassage: 33,
          versusStopCount: 385,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 52,
          currentStopCount: 220,
          versusZonePassage: 52,
          versusStopCount: 220,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 668,
          currentStopCount: 491,
          versusZonePassage: 668,
          versusStopCount: 491,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 5718,
          currentStopCount: 455,
          versusZonePassage: 5718,
          versusStopCount: 455,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1713,
          currentStopCount: 345,
          versusZonePassage: 1713,
          versusStopCount: 345,
        },
      ],
      current: 184,
      versus: 184,
      currentWifiVisits: 81,
      currentWifiPassages: 1140,
      versusWifiVisits: 81,
      versusWifiPassages: 1140,
      currentVideoVisits: 184,
      currentVideoPassages: 0,
      versusVideoVisits: 184,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 19110,
          name: 'Produit 1',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 1117,
          currentStopCount: 244,
          versusZonePassage: 1117,
          versusStopCount: 244,
        },
        {
          id: 19111,
          name: 'Produit 2',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 31,
          currentStopCount: 434,
          versusZonePassage: 31,
          versusStopCount: 434,
        },
        {
          id: 19251,
          name: 'Personnalisation',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 37,
          currentStopCount: 160,
          versusZonePassage: 37,
          versusStopCount: 160,
        },
        {
          id: 19253,
          name: 'Zone Marque',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 770,
          currentStopCount: 381,
          versusZonePassage: 770,
          versusStopCount: 381,
        },
        {
          id: 19254,
          name: 'Couloir',
          flags: 0,
          type: 0,
          box: 366,
          currentZonePassage: 5475,
          currentStopCount: 517,
          versusZonePassage: 5475,
          versusStopCount: 517,
        },
        {
          id: 19413,
          name: 'map',
          flags: 5,
          type: 0,
          box: 366,
          currentZonePassage: 1666,
          currentStopCount: 286,
          versusZonePassage: 1666,
          versusStopCount: 286,
        },
      ],
      current: 200,
      versus: 200,
      currentWifiVisits: 86,
      currentWifiPassages: 1209,
      versusWifiVisits: 86,
      versusWifiPassages: 1209,
      currentVideoVisits: 200,
      currentVideoPassages: 0,
      versusVideoVisits: 200,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 603,
          name: 'Connected',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 604,
          name: 'BrandBar',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 605,
          name: 'Twizy',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 606,
          name: 'Welcome 01',
          flags: 1,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 607,
          name: 'Zoe',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 608,
          name: 'Charge',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 609,
          name: 'Configurator',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 610,
          name: 'Kangoo',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 611,
          name: 'Merchandising',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 612,
          name: 'BrandWall',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 639,
          name: 'Welcome 02',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 640,
          name: 'Partnership',
          flags: 0,
          type: 0,
          box: 147,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        '3': { current: 0, versus: 0 },
        '6': { current: 0, versus: 0 },
        '7': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 17, versus: 17 },
        '11': { current: 39, versus: 39 },
        '12': { current: 55, versus: 55 },
        '13': { current: 40, versus: 40 },
        '14': { current: 69, versus: 69 },
        '15': { current: 62, versus: 62 },
        '16': { current: 74, versus: 74 },
        '17': { current: 80, versus: 80 },
        '18': { current: 62, versus: 62 },
        '19': { current: 46, versus: 46 },
        '20': { current: 7, versus: 7 },
        '21': { current: 0, versus: 0 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '6': { current: 0, versus: 0 },
        '8': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 3, versus: 3 },
        '11': { current: 4, versus: 4 },
        '12': { current: 5, versus: 5 },
        '13': { current: 11, versus: 11 },
        '14': { current: 4, versus: 4 },
        '15': { current: 14, versus: 14 },
        '16': { current: 21, versus: 21 },
        '17': { current: 13, versus: 13 },
        '18': { current: 8, versus: 8 },
        '19': { current: 19, versus: 19 },
        '20': { current: 3, versus: 3 },
        '21': { current: 0, versus: 0 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '6': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 9, versus: 9 },
        '11': { current: 7, versus: 7 },
        '12': { current: 23, versus: 23 },
        '13': { current: 21, versus: 21 },
        '14': { current: 33, versus: 33 },
        '15': { current: 18, versus: 18 },
        '16': { current: 26, versus: 26 },
        '17': { current: 23, versus: 23 },
        '18': { current: 15, versus: 15 },
        '19': { current: 16, versus: 16 },
        '20': { current: 3, versus: 3 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 9, versus: 9 },
        '11': { current: 6, versus: 6 },
        '12': { current: 33, versus: 33 },
        '13': { current: 15, versus: 15 },
        '14': { current: 30, versus: 30 },
        '15': { current: 20, versus: 20 },
        '16': { current: 23, versus: 23 },
        '17': { current: 25, versus: 25 },
        '18': { current: 18, versus: 18 },
        '19': { current: 10, versus: 10 },
        '20': { current: 0, versus: 0 },
        '21': { current: 0, versus: 0 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 5, versus: 5 },
        '11': { current: 11, versus: 11 },
        '12': { current: 14, versus: 14 },
        '13': { current: 7, versus: 7 },
        '14': { current: 5, versus: 5 },
        '15': { current: 15, versus: 15 },
        '16': { current: 15, versus: 15 },
        '17': { current: 9, versus: 9 },
        '18': { current: 21, versus: 21 },
        '19': { current: 7, versus: 7 },
        '20': { current: 2, versus: 2 },
        '21': { current: 0, versus: 0 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 4, versus: 4 },
        '11': { current: 25, versus: 25 },
        '12': { current: 15, versus: 15 },
        '13': { current: 6, versus: 6 },
        '14': { current: 23, versus: 23 },
        '15': { current: 23, versus: 23 },
        '16': { current: 12, versus: 12 },
        '17': { current: 30, versus: 30 },
        '18': { current: 26, versus: 26 },
        '19': { current: 16, versus: 16 },
        '20': { current: 4, versus: 4 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
    ],
    [
      {
        '3': { current: 0, versus: 0 },
        '7': { current: 0, versus: 0 },
        '9': { current: 0, versus: 0 },
        '10': { current: 3, versus: 3 },
        '11': { current: 18, versus: 18 },
        '12': { current: 16, versus: 16 },
        '13': { current: 12, versus: 12 },
        '14': { current: 31, versus: 31 },
        '15': { current: 21, versus: 21 },
        '16': { current: 23, versus: 23 },
        '17': { current: 39, versus: 39 },
        '18': { current: 23, versus: 23 },
        '19': { current: 12, versus: 12 },
        '20': { current: 2, versus: 2 },
        name: 'City Store Val Europe',
        id: 211,
        boxId: 366,
        boxName: 'IVStore',
        enabled: true,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'CityStore ZE',
        id: 111,
        boxId: 147,
        boxName: 'Master',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
    ],
  ],
  [
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
  ],
  [
    {
      current: 771620209,
      versus: 771620209,
      name: 'City Store Val Europe',
      id: 211,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 792624970,
      versus: 792624970,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      current: 854896175,
      versus: 854896175,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      current: 811021436,
      versus: 811021436,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      current: 715691300,
      versus: 715691300,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      current: 760661443,
      versus: 760661443,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      current: 774320508,
      versus: 774320508,
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
  [
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 476,
            timeInZone: 1860,
            currentStopCount: 107,
            stopsDurationInZone: 2150,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            timeInZone: 1376,
            currentStopCount: 72,
            stopsDurationInZone: 1756,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 4,
            timeInZone: 304,
            currentStopCount: 15,
            stopsDurationInZone: 407,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 325,
            timeInZone: 612,
            currentStopCount: 30,
            stopsDurationInZone: 452,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2642,
            timeInZone: 12450,
            currentStopCount: 243,
            stopsDurationInZone: 2868,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 4621,
            currentStopCount: 55,
            stopsDurationInZone: 1402,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 383,
            timeInZone: 746,
            currentStopCount: 44,
            stopsDurationInZone: 740,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 19,
            timeInZone: 3034,
            currentStopCount: 147,
            stopsDurationInZone: 4437,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 627,
            currentStopCount: 36,
            stopsDurationInZone: 1406,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 464,
            timeInZone: 3849,
            currentStopCount: 157,
            stopsDurationInZone: 3989,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2851,
            timeInZone: 13654,
            currentStopCount: 207,
            stopsDurationInZone: 2347,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 683,
            timeInZone: 8538,
            currentStopCount: 97,
            stopsDurationInZone: 1844,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 722,
            timeInZone: 2863,
            currentStopCount: 155,
            stopsDurationInZone: 2602,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 21,
            timeInZone: 6507,
            currentStopCount: 295,
            stopsDurationInZone: 7680,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 25,
            timeInZone: 1544,
            currentStopCount: 88,
            stopsDurationInZone: 2964,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 484,
            timeInZone: 3362,
            currentStopCount: 149,
            stopsDurationInZone: 4023,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3485,
            timeInZone: 15785,
            currentStopCount: 353,
            stopsDurationInZone: 3811,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1041,
            timeInZone: 13060,
            currentStopCount: 148,
            stopsDurationInZone: 2869,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 444,
            timeInZone: 1405,
            currentStopCount: 60,
            stopsDurationInZone: 949,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 5,
            timeInZone: 1522,
            currentStopCount: 68,
            stopsDurationInZone: 2226,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 8,
            timeInZone: 820,
            currentStopCount: 29,
            stopsDurationInZone: 1487,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 332,
            timeInZone: 598,
            currentStopCount: 33,
            stopsDurationInZone: 977,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2566,
            timeInZone: 11710,
            currentStopCount: 161,
            stopsDurationInZone: 1742,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 610,
            timeInZone: 5695,
            currentStopCount: 60,
            stopsDurationInZone: 1436,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 382,
            timeInZone: 1560,
            currentStopCount: 70,
            stopsDurationInZone: 1567,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 16,
            timeInZone: 2293,
            currentStopCount: 108,
            stopsDurationInZone: 3091,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 28,
            timeInZone: 2648,
            currentStopCount: 116,
            stopsDurationInZone: 4245,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 279,
            timeInZone: 5215,
            currentStopCount: 212,
            stopsDurationInZone: 7103,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2398,
            timeInZone: 11289,
            currentStopCount: 139,
            stopsDurationInZone: 1818,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 707,
            timeInZone: 12584,
            currentStopCount: 149,
            stopsDurationInZone: 3249,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 657,
            timeInZone: 3071,
            currentStopCount: 166,
            stopsDurationInZone: 3045,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 17,
            timeInZone: 6064,
            currentStopCount: 277,
            stopsDurationInZone: 7648,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 24,
            timeInZone: 2034,
            currentStopCount: 104,
            stopsDurationInZone: 3829,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 389,
            timeInZone: 6998,
            currentStopCount: 279,
            stopsDurationInZone: 8132,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 3320,
            timeInZone: 15398,
            currentStopCount: 316,
            stopsDurationInZone: 3340,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 1006,
            timeInZone: 16196,
            currentStopCount: 196,
            stopsDurationInZone: 4358,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 19110,
            name: 'Produit 1',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 460,
            timeInZone: 1243,
            currentStopCount: 78,
            stopsDurationInZone: 1203,
          },
          {
            id: 19111,
            name: 'Produit 2',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 14,
            timeInZone: 3284,
            currentStopCount: 157,
            stopsDurationInZone: 4799,
          },
          {
            id: 19251,
            name: 'Personnalisation',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 13,
            timeInZone: 1159,
            currentStopCount: 56,
            stopsDurationInZone: 1910,
          },
          {
            id: 19253,
            name: 'Zone Marque',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 381,
            timeInZone: 1643,
            currentStopCount: 102,
            stopsDurationInZone: 2327,
          },
          {
            id: 19254,
            name: 'Couloir',
            flags: 0,
            type: 0,
            box: 366,
            currentZonePassage: 2155,
            timeInZone: 10027,
            currentStopCount: 201,
            stopsDurationInZone: 2301,
          },
          {
            id: 19413,
            name: 'map',
            flags: 5,
            type: 0,
            box: 366,
            currentZonePassage: 660,
            timeInZone: 9030,
            currentStopCount: 90,
            stopsDurationInZone: 2498,
          },
        ],
      },
      name: 'City Store Val Europe',
      id: 211,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 603,
            name: 'Connected',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 604,
            name: 'BrandBar',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 605,
            name: 'Twizy',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 606,
            name: 'Welcome 01',
            flags: 1,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 607,
            name: 'Zoe',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 608,
            name: 'Charge',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 609,
            name: 'Configurator',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 610,
            name: 'Kangoo',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 611,
            name: 'Merchandising',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 612,
            name: 'BrandWall',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 639,
            name: 'Welcome 02',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 640,
            name: 'Partnership',
            flags: 0,
            type: 0,
            box: 147,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'CityStore ZE',
      id: 111,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
]);
const API_ALDI_DAY = of([
  [
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '22 juillet 2021',
          dateVs: '23 juillet 2021',
          type: 'Jour',
        },
        timeDataCsv: { day: '23', month: '07', year: '2021' },
      },
    ],
  ],
  [{ cegidDataNotAvailable: true }, { cegidDataNotAvailable: true }],
  [
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '22 juillet 2021',
        dateVs: '23 juillet 2021',
        type: 'Jour',
      },
      timeDataCsv: { day: '23', month: '07', year: '2021' },
    },
  ],
  [
    {
      '2021-07-23': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
    },
    {
      '2021-07-23': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
    },
  ],
]);
const API_ALDI_WEEK = of([
  [
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1558,
          name: 'Tour wanzle',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1559,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1560,
          name: 'Bons plans 1',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1561,
          name: 'Cafe Confiture Cereales',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1562,
          name: 'Papier Couche Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1563,
          name: 'Papier Cosmetique',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1595,
          name: 'Cafe',
          flags: 0,
          type: 0,
          box: 216,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1590,
          name: 'Compote Conserve Plats prepares',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1591,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1592,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1593,
          name: 'Alcool 1',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1594,
          name: 'Bonbons Chocolat',
          flags: 0,
          type: 0,
          box: 217,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1586,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1587,
          name: 'AA Reliquat 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1588,
          name: 'AA Nouvelles',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1589,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 218,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1571,
          name: 'Caisse',
          flags: 1,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1572,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1573,
          name: 'Chips Aperitifs',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1574,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1575,
          name: 'AA plantes',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1576,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1577,
          name: 'AA Reliquat 2',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1578,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 219,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1564,
          name: 'Viande Poisson',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1565,
          name: 'Snacking',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1566,
          name: 'Huile Plats prepares Conserve Compote',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1567,
          name: 'Flegs',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1568,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1569,
          name: 'Condiment',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1570,
          name: 'Conserves legumes',
          flags: 0,
          type: 0,
          box: 220,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1579,
          name: 'Lait',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1580,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1581,
          name: 'Surgeles 3',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1582,
          name: 'Cremerie',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1583,
          name: 'Jus Sirop',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1584,
          name: 'Bons plans 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1585,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 221,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      zones: [
        {
          id: 1511,
          name: 'Tour Wanzle',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1512,
          name: 'Bons Plans',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1513,
          name: 'Flegs 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1514,
          name: 'Panification 1',
          flags: 0,
          type: 0,
          box: 211,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1534,
          name: 'Viandes frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1535,
          name: 'Flegs 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1536,
          name: 'Panification 2',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1537,
          name: 'Frais 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1540,
          name: 'Snack 1',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1541,
          name: 'Flegs frais Viandes Poissons',
          flags: 0,
          type: 0,
          box: 212,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1515,
          name: 'Snack 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1516,
          name: 'Frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1517,
          name: 'Condiment huile sauce 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1518,
          name: 'Viandes frais 2',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1519,
          name: 'Conserve Plats prepares 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1520,
          name: 'Viandes poissons frais',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1521,
          name: 'Poissons pates riz 1',
          flags: 0,
          type: 0,
          box: 213,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1528,
          name: 'Surgeles 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1529,
          name: 'Poissons Pates Riz 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1530,
          name: 'Conserves plats prepares 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1531,
          name: 'AA 1',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1532,
          name: 'Papiers couches',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1533,
          name: 'Condiment Huile Sauces 2',
          flags: 0,
          type: 0,
          box: 214,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1502,
          name: 'Surgeles 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1503,
          name: 'Cosmétique 1',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1504,
          name: 'AA 2',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1505,
          name: 'Entretien',
          flags: 0,
          type: 0,
          box: 215,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1542,
          name: 'Sodas 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1543,
          name: 'Jus 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1544,
          name: 'Gateaux 1',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1545,
          name: 'Panieres thematiques',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1546,
          name: 'Cafe Confiture Cereales Lait',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1547,
          name: 'Frais 3',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1548,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 222,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1495,
          name: 'Sodas 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1496,
          name: 'Alcool 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1497,
          name: 'Jus 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1498,
          name: 'Bonbons',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1499,
          name: 'Gateaux 2',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1500,
          name: 'Eaux',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1501,
          name: 'Surgelés 3',
          flags: 0,
          type: 0,
          box: 223,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1506,
          name: 'Chiens Chats',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1507,
          name: 'Cosmétique 2',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1508,
          name: 'Caisses',
          flags: 1,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1509,
          name: 'Prix en baisse',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
        {
          id: 1510,
          name: 'Lessive Entretien',
          flags: 0,
          type: 0,
          box: 224,
          currentZonePassage: 0,
          currentStopCount: 0,
          versusZonePassage: 0,
          versusStopCount: 0,
        },
      ],
      current: 0,
      versus: 0,
      currentWifiVisits: 0,
      currentWifiPassages: 0,
      versusWifiVisits: 0,
      versusWifiPassages: 0,
      currentVideoVisits: 0,
      currentVideoPassages: 0,
      versusVideoVisits: 0,
      versusVideoPassages: 0,
      attractivityVideo: 'false',
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
  [
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 216,
        boxName: 'Entree',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 217,
        boxName: 'Thematique',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 218,
        boxName: 'Nos offres',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 219,
        boxName: 'Bons plans',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 220,
        boxName: 'Flegs',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Chalette sur Loing',
        id: 148,
        boxId: 221,
        boxName: 'BRSA Cremerie',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: { year: 'Total période', day: '', month: '' },
        timeDataCsv: { year: 'Total période', day: '', month: '' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '11 juillet 2021',
          dateVs: '13 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '13', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '12 juillet 2021',
          dateVs: '14 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '14', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '13 juillet 2021',
          dateVs: '15 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '15', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '14 juillet 2021',
          dateVs: '16 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '16', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '15 juillet 2021',
          dateVs: '17 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '17', month: '07', year: '2021' },
      },
    ],
    [
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 211,
        boxName: 'Entree Flegs',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 212,
        boxName: 'Meuble Frais Flegs',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 213,
        boxName: 'Meuble Frais Viandes',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 214,
        boxName: 'Surgelé',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 215,
        boxName: 'Cosmétiques',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 222,
        boxName: 'BRSA',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 223,
        boxName: 'Epicerie',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
      {
        name: 'Cosne sur Loire',
        id: 149,
        boxId: 224,
        boxName: 'Caisse',
        enabled: false,
        timeData: {
          date: '16 juillet 2021',
          dateVs: '18 juillet 2021',
          type: 'Semaine',
        },
        timeDataCsv: { day: '18', month: '07', year: '2021' },
      },
    ],
  ],
  [
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
    { cegidDataNotAvailable: true },
  ],
  [
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      current: 0,
      versus: 0,
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
  [
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1558,
            name: 'Tour wanzle',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1559,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1560,
            name: 'Bons plans 1',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1561,
            name: 'Cafe Confiture Cereales',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1562,
            name: 'Papier Couche Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1563,
            name: 'Papier Cosmetique',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1595,
            name: 'Cafe',
            flags: 0,
            type: 0,
            box: 216,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1590,
            name: 'Compote Conserve Plats prepares',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1591,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1592,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1593,
            name: 'Alcool 1',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1594,
            name: 'Bonbons Chocolat',
            flags: 0,
            type: 0,
            box: 217,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1586,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1587,
            name: 'AA Reliquat 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1588,
            name: 'AA Nouvelles',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1589,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 218,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1571,
            name: 'Caisse',
            flags: 1,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1572,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1573,
            name: 'Chips Aperitifs',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1574,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1575,
            name: 'AA plantes',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1576,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1577,
            name: 'AA Reliquat 2',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1578,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 219,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1564,
            name: 'Viande Poisson',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1565,
            name: 'Snacking',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1566,
            name: 'Huile Plats prepares Conserve Compote',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1567,
            name: 'Flegs',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1568,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1569,
            name: 'Condiment',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1570,
            name: 'Conserves legumes',
            flags: 0,
            type: 0,
            box: 220,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1579,
            name: 'Lait',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1580,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1581,
            name: 'Surgeles 3',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1582,
            name: 'Cremerie',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1583,
            name: 'Jus Sirop',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1584,
            name: 'Bons plans 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1585,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 221,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Chalette sur Loing',
      id: 148,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: { year: 'Total période', day: '', month: '' },
      timeDataCsv: { year: 'Total période', day: '', month: '' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '11 juillet 2021',
        dateVs: '13 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '13', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '12 juillet 2021',
        dateVs: '14 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '14', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '13 juillet 2021',
        dateVs: '15 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '15', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '14 juillet 2021',
        dateVs: '16 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '16', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '15 juillet 2021',
        dateVs: '17 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '17', month: '07', year: '2021' },
    },
    {
      '2021-07-12': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-13': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-14': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-15': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-16': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-17': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      '2021-07-18': {
        zones: [
          {
            id: 1511,
            name: 'Tour Wanzle',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1512,
            name: 'Bons Plans',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1513,
            name: 'Flegs 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1514,
            name: 'Panification 1',
            flags: 0,
            type: 0,
            box: 211,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1534,
            name: 'Viandes frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1535,
            name: 'Flegs 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1536,
            name: 'Panification 2',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1537,
            name: 'Frais 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1540,
            name: 'Snack 1',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1541,
            name: 'Flegs frais Viandes Poissons',
            flags: 0,
            type: 0,
            box: 212,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1515,
            name: 'Snack 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1516,
            name: 'Frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1517,
            name: 'Condiment huile sauce 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1518,
            name: 'Viandes frais 2',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1519,
            name: 'Conserve Plats prepares 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1520,
            name: 'Viandes poissons frais',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1521,
            name: 'Poissons pates riz 1',
            flags: 0,
            type: 0,
            box: 213,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1528,
            name: 'Surgeles 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1529,
            name: 'Poissons Pates Riz 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1530,
            name: 'Conserves plats prepares 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1531,
            name: 'AA 1',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1532,
            name: 'Papiers couches',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1533,
            name: 'Condiment Huile Sauces 2',
            flags: 0,
            type: 0,
            box: 214,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1502,
            name: 'Surgeles 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1503,
            name: 'Cosmétique 1',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1504,
            name: 'AA 2',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1505,
            name: 'Entretien',
            flags: 0,
            type: 0,
            box: 215,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1542,
            name: 'Sodas 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1543,
            name: 'Jus 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1544,
            name: 'Gateaux 1',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1545,
            name: 'Panieres thematiques',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1546,
            name: 'Cafe Confiture Cereales Lait',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1547,
            name: 'Frais 3',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1548,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 222,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1495,
            name: 'Sodas 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1496,
            name: 'Alcool 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1497,
            name: 'Jus 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1498,
            name: 'Bonbons',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1499,
            name: 'Gateaux 2',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1500,
            name: 'Eaux',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1501,
            name: 'Surgelés 3',
            flags: 0,
            type: 0,
            box: 223,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1506,
            name: 'Chiens Chats',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1507,
            name: 'Cosmétique 2',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1508,
            name: 'Caisses',
            flags: 1,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1509,
            name: 'Prix en baisse',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
          {
            id: 1510,
            name: 'Lessive Entretien',
            flags: 0,
            type: 0,
            box: 224,
            currentZonePassage: 0,
            timeInZone: 0,
            currentStopCount: 0,
            stopsDurationInZone: 0,
          },
        ],
      },
      name: 'Cosne sur Loire',
      id: 149,
      timeData: {
        date: '16 juillet 2021',
        dateVs: '18 juillet 2021',
        type: 'Semaine',
      },
      timeDataCsv: { day: '18', month: '07', year: '2021' },
    },
  ],
]);

export const API_CSV_DATA_MILLIM_DAY = API_MILLIM_DAY;
export const API_CSV_DATA_MILLIM_WEEK = API_MILLIM_WEEK;
export const API_CSV_DATA_RENAULT_DAY = API_RENAULT_DAY;
export const API_CSV_DATA_RENAULT_WEEK = API_RENAULT_WEEK;
export const API_CSV_DATA_ALDI_DAY = API_ALDI_DAY;
export const API_CSV_DATA_ALDI_WEEK = API_ALDI_WEEK;
