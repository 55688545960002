<div class="counting-action-bar d-flex flex-row justify-content-center align-items-center w-100">
  <div *ngIf="!colAuto" class="row d-flex m-0 p-0 align-items-center w-100">
    <div class="col-12 p-0 m-0">
      <div
        class="w-100 row d-flex m-0 p-0 align-content-center align-items-center justify-content-between">
        <div class="row d-flex flex-row m-0 align-content-center align-items-center w-75">
          <div
            *ngFor="let item of calendarActions; let _index = index"
            [ngClass]="_index === 0 ? 'ms-0 pe-2 w-auto' : 'ms-0 ps-2 w-auto'">
            <div
              class="pointer rounded"
              (click)="openModal(item)"
              [class]="
                options.rangeType === item.range
                  ? 'elevation-1 is_selected'
                  : 'elevation-1  opacity-50'
              ">
              <svg-icon
                width="50px"
                height="50px"
                src="/assets/svg/{{ item.icon }}.svg"
                alt="user"></svg-icon>
            </div>
          </div>
          <div class="col-2 p-0 ms-3 w-25">
            <div class="form-floating mb-3 border-0 bg-transparent align-items-center">
              <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartVsInput"
                >{{ 'compare' | translate }} :</label
              >
              <input
                id="actionBarStartVsInput"
                type="text"
                [(ngModel)]="startVs"
                class="form-control text-truncate border-0 rounded text-center text_bold elevation-static-2 bg-white text_blue"
                placeholder=""
                aria-describedby="basic-addon1"
                disabled
                [ngbTooltip]="options.rangeType === 'custom' ? startVs : null" />
            </div>
          </div>
          <div class="col-2 ps-0 ms-2 me-0 pe-0 w-25">
            <div class="form-floating mb-3 me-0 pe-0 border-0 bg-transparent align-items-center">
              <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartInput"
                >{{ 'reference' | translate }} :</label
              >
              <input
                id="actionBarStartInput"
                type="text"
                [(ngModel)]="start"
                class="form-control text-truncate border-0 rounded text-center text_bold elevation-static-2 bg-white text_orange"
                placeholder=""
                aria-describedby="basic-addon1"
                disabled
                [ngbTooltip]="options.rangeType === 'custom' ? start : null" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="colAuto" class="row d-flex m-0 p-0 align-items-center w-100">
    <div class="col-12 p-0 m-0">
      <div class="row w-100 p-0 d-flex m-0 align-content-center align-items-center">
        <div
          *ngFor="let item of calendarActions; let _index = index"
          [ngClass]="_index === 0 ? 'ms-0 pe-2 w-auto' : 'ms-0 ps-2 w-auto'">
          <div
            class="pointer rounded"
            (click)="openModal(item)"
            [class]="
              options.rangeType === item.range
                ? 'elevation-1 is_selected'
                : 'elevation-1  opacity-50'
            ">
            <svg-icon
              width="50px"
              height="50px"
              src="/assets/svg/{{ item.icon }}.svg"
              alt="user"></svg-icon>
          </div>
        </div>
        <div [ngClass]="colAuto ? 'col me-0 pe-0' : 'col-2'">
          <div
            [ngClass]="colAuto ? 'col me-0 pe-0' : ''"
            class="form-floating mb-3 border-0 bg-transparent align-items-center">
            <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartVsInput"
              >{{ 'compare' | translate }} :</label
            >
            <input
              id="actionBarStartVsInput"
              type="text"
              [(ngModel)]="startVs"
              class="form-control text-truncate border-0 rounded text-center text_bold elevation-static-2 bg-white text_blue"
              placeholder=""
              aria-describedby="basic-addon1"
              disabled
              [ngbTooltip]="options.rangeType === 'custom' ? startVs : null" />
          </div>
        </div>
        <div [ngClass]="colAuto ? 'col' : 'col-2'" class="ps-0 ms-2 me-0 pe-0">
          <div class="form-floating mb-3 me-0 pe-0 border-0 bg-transparent align-items-center">
            <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="actionBarStartInput"
              >{{ 'reference' | translate }} :</label
            >
            <input
              id="actionBarStartInput"
              type="text"
              [(ngModel)]="start"
              class="form-control text-truncate border-0 rounded text-center text_bold elevation-static-2 bg-white text_orange"
              placeholder=""
              aria-describedby="basic-addon1"
              disabled
              [ngbTooltip]="options.rangeType === 'custom' ? start : null" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
