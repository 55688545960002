import { Injectable } from '@angular/core';
import { Businesses, UserData } from '@app/models';
import { StorageProvider } from './storage.provider';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserProvider {
  private userData: BehaviorSubject<UserData> = new BehaviorSubject<UserData>({
    login: null,
    password: null,
    rememberOption: false,
    isLogged: false,
    isAllMainDataFetched: false,
    isFirstStart: true,
    disableAutoLogin: false,
  });
  private userData$: Observable<UserData> = this.userData.asObservable();

  private favoriteBusiness: BehaviorSubject<{
    settings: any;
    localBusiness: Businesses;
  }> = new BehaviorSubject<{ settings: any; localBusiness: Businesses }>(null);
  private favoriteBusiness$: Observable<{
    settings: any;
    localBusiness: Businesses;
  }> = this.favoriteBusiness.asObservable();
  constructor(private storageProvider: StorageProvider) {}

  /** Update local data for all view */
  public updateUserData(userData: UserData, fn?: any): any {
    this.isUserDataExist()
      .toPromise()
      .then((_userData) => {
        const combined = { ..._userData, ...userData };
        this.userData.next(combined);
        this.storageProvider.set('userData', combined).subscribe((e) => {
          if (fn) {
            fn();
          }
        });
      });
  }

  /**
   *
   * @returns Client local storage value, if not exist provide default object
   */
  public isUserDataExist(): Observable<UserData | void> {
    return this.storageProvider.get('userData').pipe(
      catchError(() =>
        of({
          error:
            'The local storage of user is empty or never created, provide default value in place of null',
        }),
      ),
      map((value) => {
        if (!value) {
          return {
            login: null,
            password: null,
            rememberOption: false,
            isLogged: false,
            isAllMainDataFetched: false,
            isFirstStart: true,
          };
        }
        return value;
      }),
    );
  }

  /**  Return user data */
  public getData(): Observable<UserData> {
    return this.userData$;
  }

  public getFavorite(): Observable<{
    settings: any;
    localBusiness: Businesses;
  }> {
    return this.favoriteBusiness$;
  }

  public setFavorite({ settings: any, localBusiness: Businesses }): void {
    this.favoriteBusiness.next({
      settings: any,
      localBusiness: Businesses,
    });
  }
}
