import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Businesses } from '@app/models';

@Component({
  selector: 'app-cluster-info',
  templateUrl: './cluster-info.component.html',
  styleUrls: ['./cluster-info.component.scss'],
})
export class ClusterInfoComponent {
  @Input() detailData: any;
  @Input() globalData: any;

  constructor(private router: Router) {}

  openDetails(business: Businesses): void {
    this.router.navigate(['/details', { id: business.id, type: 'store' }]);
  }
}
