import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AskResetComponent, ConfirmResetComponent, DoResetComponent } from '@app/components';
import { UserProvider } from '@app/providers';
import { RefreshProvider } from '@app/providers/layout';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit, OnDestroy {
  public resetSetpsComponents = [AskResetComponent, ConfirmResetComponent, DoResetComponent];

  public actualStep = 0;

  private sub: Subscription;

  constructor(
    private refreshProvider: RefreshProvider,
    private route: ActivatedRoute,
    private userProvider: UserProvider,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['id'] && this.route.snapshot.queryParams['token']) {
      this.refreshProvider.emit({
        'reset-steps': {
          props: {
            step: 2,
            id: this.route.snapshot.queryParams['id'],
            token: this.route.snapshot.queryParams['token'],
          },
        },
      });
    } else {
      this.refreshProvider.emit({
        'reset-steps': {
          props: {
            step: 0,
          },
        },
      });
    }
    this.sub = this.refreshProvider
      .listen('reset-steps')
      .subscribe((resetData) => (this.actualStep = resetData.props.step));

    this.userProvider.updateUserData({ disableAutoLogin: true });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
