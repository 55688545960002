import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Businesses } from '@app/models';
import { DataProvider, UserProvider } from '@app/providers';
import { TasksService } from '@app/services/api/v2/tasks.service';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Destroy } from '@app/hooklife';
@Component({
  selector: 'app-export-push-mail',
  templateUrl: './export-push-mail.component.html',
  styleUrls: ['./export-push-mail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [Destroy],
})
export class ExportPushMailComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  public exportSelectionForm: UntypedFormGroup;

  public businessesHolder: Businesses[];
  public businesses: Businesses[];

  public rangeTypes = ['daily', 'weekly'];

  public options = {
    rangeType: 'day',
    kpiList: [],
    range: { start: '', end: '' },
    mailList: [],
  };

  public feedback = '';

  public state = '';

  public formResult = {};

  public doesUsersHasTasks = false;
  private $doesUsersHasTasks = tap((userTasks: any) => {
    this.doesUsersHasTasks =
      Object.keys(userTasks).length > 0 && userTasks?.dashboard_ivstore.length > 0;
    setTimeout(() => {
      this.userTasks = this.doesUsersHasTasks ? [...userTasks['dashboard_ivstore']] : null;

      this.initView();
    });
  });

  public userTasks;

  public templateToShow: 'edit' | 'empty' | 'list' | 'create' | 'waiting' = 'waiting';

  public lastTasksSelected = {};
  public allTasks = [];

  public $allTasks: Observable<any>;
  public $userData;

  constructor(
    public formatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProvider,
    private tasksService: TasksService,
    private userProvider: UserProvider,
    private toastrService: ToastrService,
    private readonly destroy$: Destroy,
  ) {}

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.$userData = this.userProvider.getData().pipe(takeUntil(this.destroy$));

    this.$allTasks = this.tasksService
      .getUserTaks()
      .pipe(takeUntil(this.destroy$), this.$doesUsersHasTasks);
  }

  public initView() {
    if (this.doesUsersHasTasks) {
      this.templateToShow = 'list';
    } else {
      this.templateToShow = 'empty';
    }
  }

  public onCreateTask(event: { $: Observable<any>; title: string }) {
    event.$.pipe(switchMap((respDelete) => this.$allTasks)).subscribe(
      () => {
        this.toastrService.success(event.title.toLowerCase() + ' a été crée !', 'Création');
      },
      () => {
        this.toastrService.error(
          event.title.toLowerCase() + " n'a pas été crée",
          'Il y a eu une erreur !',
        );
      },
      () => {
        this.initView();
      },
    );
  }

  public onErrorOnFormCreation(formError: string) {
    this.toastrService.warning(
      'Vous devez renseigner un titre et au moins un email.',
      'Information',
    );
  }

  public onEditTask(event: { $: Observable<any>; title: string }) {
    event.$.pipe(switchMap((respDelete) => this.$allTasks)).subscribe(
      () => {
        this.toastrService.success(event.title.toLowerCase() + ' a été édité !', 'Edition');
      },
      () => {
        this.toastrService.error(
          event.title.toLowerCase() + " n'a pas été édité",
          'Il y a eu une erreur !',
        );
      },
      () => {
        this.initView();
      },
    );
  }

  public deleteTasks(event) {
    this.tasksService
      .deleteTasks('dashboard_ivstore', event.row.id)
      .pipe(switchMap((respDelete) => this.$allTasks))
      .subscribe(
        (userTasks) => {
          this.toastrService.success(event.row.title + ' a été supprimé !', 'Suppression');
          this.initView();
        },
        () => {
          this.toastrService.error(
            event.row.title + " n'a pas été supprimé",
            'Il y a eu une erreur !',
          );
        },
        () => {
          this.initView();
        },
      );
  }

  public editTasks(event) {
    this.lastTasksSelected = {
      index: event.index,
      row: event.row,
    };
    this.templateToShow = 'edit';
  }

  public newTasks() {
    this.lastTasksSelected = {};
    this.templateToShow = 'create';
  }

  public dismissModal(): void {
    this.activeModal.dismiss();
  }

  public showMainList() {
    this.templateToShow = 'list';
    this.lastTasksSelected = {};
  }

  public closeModal(): void {
    if (this.exportSelectionForm.valid) {
    }
  }
}
