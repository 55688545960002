<div class="w-100 h-100 bg_white shadow py-5">
  <div class="flex-column d-flex align-items-center justify-content-start">
    <img
      width="100px"
      *ngIf="detailData.organizationId === '3'"
      src="/assets/mockedLogo/Ivs.png"
      width="200px"
      height="200px" />

    <img
      width="100px"
      *ngIf="detailData.organizationId !== '3'"
      src="/assets/svg/{{ detailData.icon }}.svg" />

    <div class="row d-flex flex-column align-items-center py-2">
      <div class="text-dark font-weight-bold">
        {{ detailData.title }}
      </div>
      <div class="text_grey">
        {{ detailData.subTitle | translate }}
      </div>
    </div>
  </div>
</div>
