import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CegidProvider {
  constructor() {}
  private tickets: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private tickets$: Observable<any> = this.tickets.asObservable();

  private saleHistory: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private saleHistory$: Observable<any> = this.saleHistory.asObservable();

  public setTickets(option: any): void {
    const combined = { ...this.tickets.value, ...option };
    this.tickets.next(combined);
  }

  public getTickets(): Observable<any> {
    return this.tickets$;
  }

  public setSaleHistory(option: any): void {
    const combined = { ...this.saleHistory.value, ...option };
    this.saleHistory.next(combined);
  }

  public getSaleHistory(): Observable<any> {
    return this.saleHistory$;
  }
}
