//@ts-nocheck

import { Injectable } from '@angular/core';
import { ApiService, DateHelperService, StatsService } from '@app/services';
import { FileService } from '@app/services/api/v2';
import { CegidService } from '@app/services/soap/cegid.service';
import { TranslateService } from '@ngx-translate/core';
import { StatsProvider } from './stats.provider';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateRangeApi } from '@app/models';

@Injectable({ providedIn: 'root' })
export class DataPreprocessProvider {
  private preprocessState: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Initialisation...',
  );
  private preprocessState$: Observable<string> = this.preprocessState.asObservable();
  private state: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private state$: Observable<string> = this.state.asObservable();

  constructor(
    private statsService: StatsService,
    private cegidService: CegidService,
    private dateHelper: DateHelperService,
    private statsProvider: StatsProvider,
    private translateService: TranslateService,
    private apiService: ApiService,
    private fileService: FileService,
    private deviceDetectorService: DeviceDetectorService,
  ) {}

  /**
   * TOTO : DELETE THIS FILE AND GENERATE CSV INTO BACKEND
   * Replace by and api service
   */
  public generatePDF(range: DateRangeApi, organisationID: number): Observable<Blob> {
    const _range = this.dateHelper.rangeToUnixTimestampWithCheck(
      { begin: range.start, end: range.end },
      { begin: range.start, end: range.end },
    );
    return this.fileService.generatePDF(_range, organisationID);
  }

  public generateCSV(range: DateRangeApi, organisationID: number): Observable<Blob> {
    const _range = this.dateHelper.rangeToUnixTimestampWithCheck(
      { begin: range.start, end: range.end },
      { begin: range.start, end: range.end },
    );
    return this.fileService.generateCSV(_range, organisationID);
  }

  public setPreprocessState(state: string): void {
    this.preprocessState.next(state);
  }

  public getState(): Observable<string> {
    return this.state$;
  }

  public setState(state: string): void {
    this.state.next(state);
  }

  public getPreprocessState(): Observable<string> {
    return this.preprocessState$;
  }

  // public fetchGroupOfBusinesses(options, rangeMode?: boolean): void {
  //   const boxRepartitionByHour$ = [];
  //   const businessesAlldata$ = [];
  //   const businessesCegidData$ = [];
  //   const averageTime$ = [];
  //   const zoneTimeSpent$ = [];

  //   var translatedRangeType = '';

  //   this.translateService.get('shared.' + options.rangeType).subscribe((translatedKey) => {
  //     translatedRangeType = translatedKey;
  //   });
  //   this.setState('progress');
  //   if (rangeMode) {
  //     options.businesses.forEach((business) => {
  //       const allRange = this.dateHelper.enumerateDaysBetweenDateTimestamp(
  //         business.range.begin,
  //         business.range.end,
  //       );

  //       const allRangeVs = this.dateHelper.enumerateDaysBetweenDateTimestamp(
  //         business.range.beginVs,
  //         business.range.endVs,
  //       );

  //       allRange.forEach((pair, index) => {
  //         if (index === 0) {
  //           zoneTimeSpent$.push(
  //             this.apiService.stats
  //               .getPercentTimeSpentByLocalBusiness(business.id, {
  //                 begin: business.range.begin,
  //                 end: business.range.end,
  //                 beginVs: business.range.begin,
  //                 endVs: business.range.end,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données par Zones...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         zoneTimeSpent$.push(
  //           this.apiService.stats
  //             .getPercentTimeSpentByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données par Zones...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );

  //         if (index === 0) {
  //           businessesAlldata$.push(
  //             this.statsService
  //               .getAllByLocalBusiness(business.id, {
  //                 begin: business.range.begin,
  //                 end: business.range.end,
  //                 beginVs: business.range.begin,
  //                 endVs: business.range.end,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Magasins...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         businessesAlldata$.push(
  //           this.statsService
  //             .getAllByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données Magasins...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );
  //         if (index === 0) {
  //           boxRepartitionByHour$.push(
  //             this.apiService.box.getBoxesByBusiness(business.id).pipe(
  //               switchMap((_box: Box[]) => {
  //                 this.setPreprocessState('Chargement des données Box...');

  //                 let boxObs$ = [];
  //                 _box.forEach((box) => {
  //                   boxObs$.push(
  //                     this.statsService
  //                       .getCustomerRepartitionByHourByBox(business.id, {
  //                         begin: business.range.begin,
  //                         end: business.range.end,
  //                         beginVs: business.range.begin,
  //                         endVs: business.range.end,
  //                         boxId: box.id,
  //                       })
  //                       .pipe(
  //                         map((value: any) => {
  //                           return {
  //                             ...value,
  //                             name: business.legalName || 'No data',
  //                             id: business.id,
  //                             boxId: box.id,
  //                             boxName: box.name,
  //                             enabled: box.enabled,
  //                             timeData: {
  //                               year: 'Total Période Référence',
  //                               day: '',
  //                               month: '',
  //                             },
  //                             timeDataCsv: {
  //                               year: 'Total Période Référence',
  //                               day: '',
  //                               month: '',
  //                             },
  //                           };
  //                         }),
  //                       ),
  //                   );
  //                 });

  //                 return forkJoin(boxObs$);
  //               }),
  //             ),
  //           );
  //         }

  //         boxRepartitionByHour$.push(
  //           this.apiService.box.getBoxesByBusiness(business.id).pipe(
  //             switchMap((_box: Box[]) => {
  //               this.setPreprocessState('Chargement des données Box...');

  //               let boxObs$ = [];
  //               _box.forEach((box) => {
  //                 boxObs$.push(
  //                   this.statsService
  //                     .getCustomerRepartitionByHourByBox(business.id, {
  //                       begin: pair.start,
  //                       beginVs: pair.start,
  //                       end: pair.end,
  //                       endVs: pair.end,
  //                       boxId: box.id,
  //                     })
  //                     .pipe(
  //                       map((value: any) => {
  //                         return {
  //                           ...value,
  //                           name: business.legalName || 'No data',
  //                           id: business.id,
  //                           boxId: box.id,
  //                           boxName: box.name,
  //                           enabled: box.enabled,
  //                           timeData: this.dateHelper.timestampToHuman(
  //                             pair.start,
  //                             pair.end,
  //                             translatedRangeType,
  //                           ),
  //                           timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                         };
  //                       }),
  //                     ),
  //                 );
  //               });

  //               return forkJoin(boxObs$);
  //             }),
  //           ),
  //         );
  //         if (index === 0) {
  //           averageTime$.push(
  //             this.statsService
  //               .getAverageTimeSpentByLocalBusiness(business.id, {
  //                 begin: business.range.begin,
  //                 end: business.range.end,
  //                 beginVs: business.range.begin,
  //                 endVs: business.range.end,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Comptage...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Référence',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         averageTime$.push(
  //           this.statsService
  //             .getAverageTimeSpentByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données Comptage...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );

  //         if (business.isCegid) {
  //           if (index === 0) {
  //             businessesCegidData$.push(
  //               this.cegidService
  //                 .saleHistory({
  //                   endDate: this.dateHelper.timestampToCegid(business.range.end),
  //                   startDate: this.dateHelper.timestampToCegid(business.range.begin),
  //                   storeId: business.id + '',
  //                 })
  //                 .pipe(
  //                   map((value: any) => {
  //                     this.setPreprocessState('Chargement des données Caisses...');
  //                     return {
  //                       ...value,
  //                       name: business.legalName || 'No data',
  //                       id: business.id,
  //                       timeData: {
  //                         year: 'Total Période Référence',
  //                         day: '',
  //                         month: '',
  //                       },
  //                       timeDataCsv: {
  //                         year: 'Total Période Référence',
  //                         day: '',
  //                         month: '',
  //                       },
  //                     };
  //                   }),
  //                 ),
  //             );
  //           }

  //           businessesCegidData$.push(
  //             this.cegidService
  //               .saleHistory({
  //                 endDate: this.dateHelper.timestampToCegid(pair.end),
  //                 startDate: this.dateHelper.timestampToCegid(pair.end),
  //                 storeId: business.id + '',
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Caisses...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: this.dateHelper.timestampToHuman(
  //                       pair.start,
  //                       pair.end,
  //                       translatedRangeType,
  //                     ),
  //                     timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                   };
  //                 }),
  //               ),
  //           );
  //         } else {
  //           businessesCegidData$.push(of({ cegidDataNotAvailable: true }));
  //         }
  //       });

  //       allRangeVs.forEach((pair, index) => {
  //         if (index === 0) {
  //           zoneTimeSpent$.push(
  //             this.apiService.stats
  //               .getPercentTimeSpentByLocalBusiness(business.id, {
  //                 begin: business.range.beginVs,
  //                 end: business.range.endVs,
  //                 beginVs: business.range.beginVs,
  //                 endVs: business.range.endVs,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données par Zones...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         zoneTimeSpent$.push(
  //           this.apiService.stats
  //             .getPercentTimeSpentByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données par Zones...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );

  //         if (index === 0) {
  //           businessesAlldata$.push(
  //             this.statsService
  //               .getAllByLocalBusiness(business.id, {
  //                 begin: business.range.beginVs,
  //                 end: business.range.endVs,
  //                 beginVs: business.range.beginVs,
  //                 endVs: business.range.endVs,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Magasins...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         businessesAlldata$.push(
  //           this.statsService
  //             .getAllByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données Magasins...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );
  //         if (index === 0) {
  //           boxRepartitionByHour$.push(
  //             this.apiService.box.getBoxesByBusiness(business.id).pipe(
  //               switchMap((_box: Box[]) => {
  //                 this.setPreprocessState('Chargement des données Box...');

  //                 let boxObs$ = [];
  //                 _box.forEach((box) => {
  //                   boxObs$.push(
  //                     this.statsService
  //                       .getCustomerRepartitionByHourByBox(business.id, {
  //                         begin: business.range.beginVs,
  //                         end: business.range.endVs,
  //                         beginVs: business.range.beginVs,
  //                         endVs: business.range.endVs,
  //                         boxId: box.id,
  //                       })
  //                       .pipe(
  //                         map((value: any) => {
  //                           return {
  //                             ...value,
  //                             name: business.legalName || 'No data',
  //                             id: business.id,
  //                             boxId: box.id,
  //                             boxName: box.name,
  //                             enabled: box.enabled,
  //                             timeData: {
  //                               year: 'Total Période Comparaison',
  //                               day: '',
  //                               month: '',
  //                             },
  //                             timeDataCsv: {
  //                               year: 'Total Période Comparaison',
  //                               day: '',
  //                               month: '',
  //                             },
  //                           };
  //                         }),
  //                       ),
  //                   );
  //                 });

  //                 return forkJoin(boxObs$);
  //               }),
  //             ),
  //           );
  //         }

  //         boxRepartitionByHour$.push(
  //           this.apiService.box.getBoxesByBusiness(business.id).pipe(
  //             switchMap((_box: Box[]) => {
  //               this.setPreprocessState('Chargement des données Box...');

  //               let boxObs$ = [];
  //               _box.forEach((box) => {
  //                 boxObs$.push(
  //                   this.statsService
  //                     .getCustomerRepartitionByHourByBox(business.id, {
  //                       begin: pair.start,
  //                       beginVs: pair.start,
  //                       end: pair.end,
  //                       endVs: pair.end,
  //                       boxId: box.id,
  //                     })
  //                     .pipe(
  //                       map((value: any) => {
  //                         return {
  //                           ...value,
  //                           name: business.legalName || 'No data',
  //                           id: business.id,
  //                           boxId: box.id,
  //                           boxName: box.name,
  //                           enabled: box.enabled,
  //                           timeData: this.dateHelper.timestampToHuman(
  //                             pair.start,
  //                             pair.end,
  //                             translatedRangeType,
  //                           ),
  //                           timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                         };
  //                       }),
  //                     ),
  //                 );
  //               });

  //               return forkJoin(boxObs$);
  //             }),
  //           ),
  //         );
  //         if (index === 0) {
  //           averageTime$.push(
  //             this.statsService
  //               .getAverageTimeSpentByLocalBusiness(business.id, {
  //                 begin: business.range.beginVs,
  //                 end: business.range.endVs,
  //                 beginVs: business.range.beginVs,
  //                 endVs: business.range.endVs,
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Comptage...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                     timeDataCsv: {
  //                       year: 'Total Période Comparaison',
  //                       day: '',
  //                       month: '',
  //                     },
  //                   };
  //                 }),
  //               ),
  //           );
  //         }

  //         averageTime$.push(
  //           this.statsService
  //             .getAverageTimeSpentByLocalBusiness(business.id, {
  //               begin: pair.start,
  //               beginVs: pair.start,
  //               end: pair.end,
  //               endVs: pair.end,
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données Comptage...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     pair.start,
  //                     pair.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                 };
  //               }),
  //             ),
  //         );

  //         if (business.isCegid) {
  //           if (index === 0) {
  //             businessesCegidData$.push(
  //               this.cegidService
  //                 .saleHistory({
  //                   endDate: this.dateHelper.timestampToCegid(business.range.endVs),
  //                   startDate: this.dateHelper.timestampToCegid(business.range.beginVs),
  //                   storeId: business.id + '',
  //                 })
  //                 .pipe(
  //                   map((value: any) => {
  //                     this.setPreprocessState('Chargement des données Caisses...');
  //                     return {
  //                       ...value,
  //                       name: business.legalName || 'No data',
  //                       id: business.id,
  //                       timeData: {
  //                         year: 'Total Période Comparaison',
  //                         day: '',
  //                         month: '',
  //                       },
  //                       timeDataCsv: {
  //                         year: 'Total Période Comparaison',
  //                         day: '',
  //                         month: '',
  //                       },
  //                     };
  //                   }),
  //                 ),
  //             );
  //           }

  //           businessesCegidData$.push(
  //             this.cegidService
  //               .saleHistory({
  //                 endDate: this.dateHelper.timestampToCegid(pair.end),
  //                 startDate: this.dateHelper.timestampToCegid(pair.end),
  //                 storeId: business.id + '',
  //               })
  //               .pipe(
  //                 map((value: any) => {
  //                   this.setPreprocessState('Chargement des données Caisses...');
  //                   return {
  //                     ...value,
  //                     name: business.legalName || 'No data',
  //                     id: business.id,
  //                     timeData: this.dateHelper.timestampToHuman(
  //                       pair.start,
  //                       pair.end,
  //                       translatedRangeType,
  //                     ),
  //                     timeDataCsv: this.dateHelper.timestampToNgbCalendar(pair.end),
  //                   };
  //                 }),
  //               ),
  //           );
  //         } else {
  //           businessesCegidData$.push(of({ cegidDataNotAvailable: true }));
  //         }
  //       });
  //     });
  //   } else {
  //     options.businesses.forEach((business) => {
  //       businessesAlldata$.push(
  //         this.statsService
  //           .getAllByLocalBusiness(business.id, {
  //             begin: business.range.begin,
  //             beginVs: business.range.beginVs,
  //             end: business.range.end,
  //             endVs: business.range.endVs,
  //           })
  //           .pipe(
  //             map((value: any) => {
  //               this.setPreprocessState('Chargement des données Magasins...');
  //               return {
  //                 ...value,
  //                 name: business.legalName || 'No data',
  //                 id: business.id,
  //                 timeData: this.dateHelper.timestampToHuman(
  //                   business.range.begin,
  //                   business.range.end,
  //                   translatedRangeType,
  //                 ),
  //                 timeDataCsv: this.dateHelper.timestampToNgbCalendar(business.range.end),
  //               };
  //             }),
  //           ),
  //       );

  //       boxRepartitionByHour$.push(
  //         this.apiService.box.getBoxesByBusiness(business.id).pipe(
  //           switchMap((_box: Box[]) => {
  //             this.setPreprocessState('Chargement des données Box...');

  //             let boxObs$ = [];
  //             _box.forEach((box) => {
  //               boxObs$.push(
  //                 this.statsService
  //                   .getCustomerRepartitionByHourByBox(business.id, {
  //                     begin: business.range.begin,
  //                     beginVs: business.range.beginVs,
  //                     end: business.range.end,
  //                     endVs: business.range.endVs,
  //                     boxId: box.id,
  //                   })
  //                   .pipe(
  //                     map((value: any) => {
  //                       return {
  //                         ...value,
  //                         name: business.legalName || 'No data',
  //                         id: business.id,
  //                         boxId: box.id,
  //                         boxName: box.name,
  //                         enabled: box.enabled,
  //                         timeData: this.dateHelper.timestampToHuman(
  //                           business.range.begin,
  //                           business.range.end,
  //                           translatedRangeType,
  //                         ),
  //                         timeDataCsv: this.dateHelper.timestampToNgbCalendar(business.range.end),
  //                       };
  //                     }),
  //                   ),
  //               );
  //             });

  //             return forkJoin(boxObs$);
  //           }),
  //         ),
  //       );

  //       zoneTimeSpent$.push(
  //         this.apiService.stats
  //           .getPercentTimeSpentByLocalBusiness(business.id, {
  //             begin: business.range.begin,
  //             end: business.range.end,
  //             beginVs: business.range.begin,
  //             endVs: business.range.end,
  //           })
  //           .pipe(
  //             map((value: any) => {
  //               this.setPreprocessState('Chargement des données par Zones...');
  //               return {
  //                 ...value,
  //                 name: business.legalName || 'No data',
  //                 id: business.id,
  //                 timeData: {
  //                   year: 'Total Période Référence',
  //                   day: '',
  //                   month: '',
  //                 },
  //                 timeDataCsv: {
  //                   year: 'Total Période Référence',
  //                   day: '',
  //                   month: '',
  //                 },
  //               };
  //             }),
  //           ),
  //       );

  //       averageTime$.push(
  //         this.statsService
  //           .getAverageTimeSpentByLocalBusiness(business.id, {
  //             begin: business.range.begin,
  //             beginVs: business.range.beginVs,
  //             end: business.range.end,
  //             endVs: business.range.endVs,
  //           })
  //           .pipe(
  //             map((value: any) => {
  //               this.setPreprocessState('Chargement des données Comptage...');
  //               return {
  //                 ...value,
  //                 name: business.legalName || 'No data',
  //                 id: business.id,
  //                 timeData: this.dateHelper.timestampToHuman(
  //                   business.range.begin,
  //                   business.range.end,
  //                   translatedRangeType,
  //                 ),
  //                 timeDataCsv: this.dateHelper.timestampToNgbCalendar(business.range.end),
  //               };
  //             }),
  //           ),
  //       );

  //       if (business.isCegid) {
  //         businessesCegidData$.push(
  //           this.cegidService
  //             .saleHistory({
  //               endDate: this.dateHelper.timestampToCegid(business.range.end),
  //               startDate: this.dateHelper.timestampToCegid(business.range.end),
  //               storeId: business.id + '',
  //             })
  //             .pipe(
  //               map((value: any) => {
  //                 this.setPreprocessState('Chargement des données Caisses...');
  //                 return {
  //                   ...value,
  //                   name: business.legalName || 'No data',
  //                   id: business.id,
  //                   timeData: this.dateHelper.timestampToHuman(
  //                     business.range.begin,
  //                     business.range.end,
  //                     translatedRangeType,
  //                   ),
  //                   timeDataCsv: this.dateHelper.timestampToNgbCalendar(business.range.end),
  //                 };
  //               }),
  //             ),
  //         );
  //       } else {
  //         businessesCegidData$.push(of({ cegidDataNotAvailable: true }));
  //       }
  //     });
  //   }

  //   forkJoin(businessesAlldata$)
  //     .pipe(
  //       switchMap((allData) => {
  //         return forkJoin(boxRepartitionByHour$).pipe(
  //           switchMap((boxRepartitionByHourData) => {
  //             return forkJoin(businessesCegidData$).pipe(
  //               switchMap((businessesCegidData) => {
  //                 return forkJoin(averageTime$).pipe(
  //                   switchMap((averageTime) => {
  //                     return forkJoin(zoneTimeSpent$).pipe(
  //                       map((zoneTimeSpent) => {
  //                         return [
  //                           allData,
  //                           boxRepartitionByHourData,
  //                           businessesCegidData,
  //                           averageTime,
  //                           zoneTimeSpent,
  //                         ];
  //                       }),
  //                     );
  //                   }),
  //                 );
  //               }),
  //             );
  //           }),
  //         );
  //       }),
  //     )
  //     .subscribe((data) => {
  //       // [API_CSV_DATA_MILLIM_WEEK].forEach((csvData) => {
  //       var zoneKeys = [
  //         'zoneAudiance',
  //         'zoneAtractivity',
  //         'zoneInteraction',
  //         'zoneAverageStop',
  //         'zoneSumStopDuration',
  //       ];
  //       // data 0 pour current wifi
  //       // csvData.subscribe((data) => {
  //       const _allCol = {};
  //       data[0]?.forEach((_data) => [
  //         _data?.zones.forEach((_zones) => {
  //           _allCol[_zones.id] = {
  //             zoneId: _zones.id,
  //             zoneName: _zones.name,
  //           };
  //         }),
  //       ]);
  //       this.setPreprocessState('Préparation du CSV...');
  //       // Transform all data to all KPI data
  //       var mappeData = this.statsProvider.mapToCSV(
  //         data[0],
  //         data[1],
  //         data[2],
  //         data[3],
  //         data[4],
  //         options,
  //         data[3].length,
  //       );

  //       const detailsByType = {
  //         day: 'entryByHour',
  //         week: 'entryByDay',
  //         month: 'entryByDay',
  //         custom: 'entryByDay',
  //         year: 'entryByMonth',
  //       };

  //       var keys = [
  //         'id',
  //         'name',
  //         'boxId',
  //         'boxName',
  //         'year',
  //         'month',
  //         'day',
  //         '0h',
  //         '1h',
  //         '2h',
  //         '3h',
  //         '4h',
  //         '5h',
  //         '6h',
  //         '7h',
  //         '8h',
  //         '9h',
  //         '10h',
  //         '11h',
  //         '12h',
  //         '13h',
  //         '14h',
  //         '15h',
  //         '16h',
  //         '17h',
  //         '18h',
  //         '19h',
  //         '20h',
  //         '21h',
  //         '22h',
  //         '23h',
  //         'entryNumber',
  //         'attractivenessRate',
  //         'attendanceTime',
  //         'salesRevenue',
  //         'transformRate',
  //         'billTickets',
  //         'men',
  //         'women',
  //         'adult',
  //         'child',
  //       ];

  //       setTimeout(() => {
  //         var zoneKeysHuman = [];

  //         // Check all row, if a row hvn't a value in a col, put 0 in it
  //         mappeData.map((value, row_position) => {
  //           Object.keys(value).forEach((data_key: string) => {
  //             let pointer = {};

  //             if (!value[data_key]) {
  //               value[data_key] = 0;
  //             }
  //           });

  //           return value;
  //         });

  //         // Translate all keys
  //         mappeData.forEach((mappedData, roundIndex) => {
  //           Object.keys(_allCol).forEach((colKey) => {
  //             zoneKeys.forEach((zone_key, zone_index) => {
  //               this.translateService.get('export.csv.' + zone_key).subscribe((new_key) => {
  //                 Object.defineProperty(
  //                   mappedData,
  //                   _allCol[colKey].zoneId + ' ' + _allCol[colKey].zoneName + ' ' + new_key,
  //                   Object.getOwnPropertyDescriptor(
  //                     mappedData,
  //                     _allCol[colKey].zoneId + '.' + zone_key,
  //                   ) || {
  //                     value: mappedData[_allCol[colKey].zoneId + '.' + zone_key] || 0,
  //                     writable: true,
  //                     configurable: true,
  //                     enumerable: true,
  //                   },
  //                 );
  //               });
  //             });
  //           });

  //           keys.forEach((old_key, index) => {
  //             this.translateService.get('export.csv.' + old_key).subscribe((new_key) => {
  //               if (old_key !== new_key && !/\d/.test(old_key) && !old_key.includes('.zone')) {
  //                 Object.defineProperty(
  //                   mappedData,
  //                   new_key,
  //                   Object.getOwnPropertyDescriptor(mappedData, old_key) || {
  //                     value: mappedData[old_key],
  //                     writable: true,
  //                     configurable: true,
  //                     enumerable: true,
  //                   },
  //                 );
  //                 delete mappedData[old_key];
  //               }
  //             });
  //           });
  //         });
  //         keys.map((old_key, index) => {
  //           this.translateService.get('export.csv.' + old_key).subscribe((new_key) => {
  //             if (!/\d/.test(old_key) && old_key !== ' ') {
  //               keys[index] = new_key;
  //             }
  //           });
  //         });
  //         zoneKeys.forEach((_zoneKey) => {
  //           Object.keys(_allCol).forEach((colKey) => {
  //             this.translateService.get('export.csv.' + _zoneKey).subscribe((new_key) => {
  //               zoneKeysHuman.push(
  //                 _allCol[colKey].zoneId + ' ' + _allCol[colKey].zoneName + ' ' + new_key,
  //               );
  //             });
  //           });
  //         });
  //         keys = [...keys, ...zoneKeysHuman];

  //         let fileTitle = 'export_ivstore';

  //         fileTitle = fileTitle + '_' + options.businesses[0].organizationId;

  //         this.setPreprocessState('Génération du CSV...');

  //         //clean before send

  //         mappeData = mappeData.map((data) => {
  //           var sortedData = {};

  //           keys.forEach((headerKey) => {
  //             sortedData[headerKey] = data[headerKey];
  //           });

  //           return sortedData;
  //         });

  //         const detectedOs = this.deviceDetectorService.os;

  //         this.fileService
  //           .generateCSV({
  //             title: fileTitle,
  //             rows: mappeData,
  //             col: keys,
  //             system_os: detectedOs,
  //           })
  //           .subscribe((value) => {
  //             saveAs(value, `${fileTitle}.csv`);
  //           });
  //       }, 250);
  //     });
  //   // }); // to remove
  // }
}
