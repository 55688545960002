import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, mergeMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TasksService {
  constructor(private http: HttpClient) {}

  public putTasks(tasks: any, userId: any): Observable<any> {
    return this.http.put<any>(`${environment.backendDomain}/userTasks/${userId}`, { ...tasks });
  }

  public postTasks(tasks: any, userId: any): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/userTasks/${userId}`, { ...tasks });
  }

  public deleteTasks(service: any, taskId: any): Observable<any> {
    return this.http.delete<any>(`${environment.backendDomain}/userTasks/${service}/${taskId}`);
  }

  public getUserTaks() {
    return this.http.get<any>(`${environment.backendDomain}/userTasks`);
  }

  public getUserServicesInfo(userId: string | number): Observable<any> {
    return this.http.get<any>(`${environment.backendDomain}/me`);
  }

  public postReportingState(userId: string | number, state: boolean): Observable<any> {
    const updateMe = this.http.put<any>(`${environment.backendDomain}/me`, {
      reportingDashboardIvstore: state,
    });
    const getUpdatedUser = this.http.get<any>(`${environment.backendDomain}/me`);
    return updateMe.pipe(mergeMap(() => getUpdatedUser));
  }
}
