import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { MarkdownModule } from 'ngx-markdown';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ErrorInterceptor, BasicAuthInterceptor } from '@app/interceptors';
import { AppRoutingModule } from './app-routing.module';
import {
  AuthGuard,
  AuthService,
  ApiService,
  BoxService,
  OrganisationService,
  StatsService,
  UserService,
  ModalService,
  DateHelperService,
  TableHelperService,
  PdfUtilsService,
  ToastService,
  BusinessService,
  MathService,
} from '@app/services';
import { CegidService } from '@app/services/soap/cegid.service';

import { ApiV2Service, FileService, CronService } from '@app/services/api/v2';
import {
  StorageProvider,
  UserProvider,
  ApiProvider,
  DataProvider,
  StatsProvider,
  CegidProvider,
  ChartProvider,
  GeocoderProvider,
  PapaparseProvider,
  DataPreprocessProvider,
  GaProvider,
} from '@app/providers';
import {
  ProfilComponent,
  ComptageComponent,
  ParcoursClientComponent,
  ClassementComponent,
  ErrorComponent,
  LoginComponent,
  ResetComponent,
  DetailsComponent,
  LegalNoticeComponent,
  AboutComponent,
} from '@app/pages';
import { DashboardLayoutComponent, LoginLayoutComponent } from '@app/pages/layout';
import { AppComponent } from './app.component';
import {
  NavbarComponent,
  BusinessesInfoComponent,
  UserCardComponent,
  UserInfoComponent,
  SignInfoComponent,
  StoreInfoComponent,
  UserOrgaComponent,
  ListClustersComponent,
  ListUserComponent,
  ActionBarComponent,
  KpiComponent,
  RangeSelectionComponent,
  NavbarScrollableListComponent,
  JourneyActionBarComponent,
  JourneyTableComponent,
  ShortActionsComponent,
  ShortMapComponent,
  ShortTableComponent,
  ExportSelectionComponent,
  ExportPushMailComponent,
  ExportPushMailEmptyComponent,
  ExportPushMailFormComponent,
  ExportPushMailListComponent,
  JourneyTableKpiComponent,
  AskResetComponent,
  ConfirmResetComponent,
  DoResetComponent,
  SelectionExportComponent,
  GenderComponent,
} from '@app/components';
import {
  DetailCardComponent,
  BreadcrumbComponent,
  ChartsComponent,
  DetailInfoComponent,
  InputGroupComponent,
  ClusterInfoComponent,
  BackgroundLoaderComponent,
  ForegroundLoaderComponent,
  CheckboxFormComponent,
  InputFormComponent,
  SelectFormComponent,
  SelectInputFormComponent,
  TableMaillingListComponent,
} from '@app/components/shared';
import {
  KeysPipe,
  arrayFilterPipe,
  MaskPasswordPipe,
  kpiFilter,
  CustomNumberFilter,
  LimitePercentage,
} from './pipes';
import {
  UserCreationComponent,
  AlertComponent,
  UserEditionComponent,
  UserDetailComponent,
  ClusterCreationComponent,
  ClusterEditionComponent,
  AccountEditionComponent,
  DateSelectionComponent,
} from '@app/modals';

import { FabricPlan2D, ColorMap } from '@app/services/canvas';

import { RefreshProvider } from '@app/providers/layout';

// Dev UTILS
import { ChangeLogProvider, ToastIsUpdateComponent, ViewChangelogComponent } from './dev';

import { PlanComponent } from './components/rendering2d/plan/plan.component';

import { DeviceDetectorService } from 'ngx-device-detector';
import { PendingRequestsInterceptorProvider } from './interceptors/pending-request.intercepto';
import { AgeComponent } from './components/age-gender/age/age.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    ProfilComponent,
    ComptageComponent,
    ParcoursClientComponent,
    ClassementComponent,
    NavbarComponent,
    ResetComponent,
    DashboardLayoutComponent,
    LoginLayoutComponent,
    UserInfoComponent,
    BusinessesInfoComponent,
    UserCardComponent,
    DetailCardComponent,
    DetailsComponent,
    BreadcrumbComponent,
    DetailInfoComponent,
    UserOrgaComponent,
    StoreInfoComponent,
    SignInfoComponent,
    ChartsComponent,
    KeysPipe,
    arrayFilterPipe,
    kpiFilter,
    LimitePercentage,
    CustomNumberFilter,
    MaskPasswordPipe,
    InputGroupComponent,
    ListUserComponent,
    ListClustersComponent,
    UserCreationComponent,
    UserEditionComponent,
    UserDetailComponent,
    AlertComponent,
    ClusterCreationComponent,
    ClusterEditionComponent,
    ClusterInfoComponent,
    BackgroundLoaderComponent,
    ForegroundLoaderComponent,
    CheckboxFormComponent,
    InputFormComponent,
    SelectFormComponent,
    SelectInputFormComponent,
    TableMaillingListComponent,
    AccountEditionComponent,
    ActionBarComponent,
    KpiComponent,
    RangeSelectionComponent,
    DateSelectionComponent,
    NavbarScrollableListComponent,
    JourneyActionBarComponent,
    JourneyTableComponent,
    ShortTableComponent,
    ShortActionsComponent,
    ShortMapComponent,
    JourneyTableKpiComponent,
    AskResetComponent,
    ConfirmResetComponent,
    DoResetComponent,
    ToastIsUpdateComponent,
    SelectionExportComponent,
    ExportSelectionComponent,
    ExportPushMailComponent,
    ExportPushMailEmptyComponent,
    ExportPushMailFormComponent,
    ExportPushMailListComponent,
    ViewChangelogComponent,
    PlanComponent,
    LegalNoticeComponent,
    AboutComponent,
    GenderComponent,
    AgeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgSelectModule,
    FormsModule,
    NgbModule,
    LeafletModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxDatatableModule,
    ScrollingModule,
    AngularSvgIconModule.forRoot(),
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'inline' }),
    ToastContainerModule,
    NgxUiLoaderModule.forRoot({
      bgsOpacity: 0.8,
      textColor: 'white',
      minTime: 1000,
    }),
    NgxUiLoaderHttpModule,
    NgxPanZoomModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    PendingRequestsInterceptorProvider,
    AuthGuard,
    AuthService,
    ApiService,
    BoxService,
    OrganisationService,
    StatsService,
    UserService,
    StorageProvider,
    UserProvider,
    DataProvider,
    StatsProvider,
    ApiProvider,
    CegidProvider,
    ChartProvider,
    GeocoderProvider,
    PapaparseProvider,
    DataPreprocessProvider,
    GaProvider,
    ModalService,
    CegidService,
    ToastService,
    BusinessService,
    MathService,
    DateHelperService,
    TableHelperService,
    PdfUtilsService,
    ApiV2Service,
    FileService,
    ChangeLogProvider,
    RefreshProvider,
    FabricPlan2D,
    ColorMap,
    DeviceDetectorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeFr, 'fr');
  }
}

// for aot
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
