import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '@app/services/auth.service';
import { ApiProvider, UserProvider } from '@app/providers';
import { decrypt, encrypt, ToastService } from '@app/services';
import { UserData } from '@app/models';
import { environment } from '@env/environment.prod';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  isAlreadyLocalRegistered = false;
  version: string;

  public isError: boolean;

  public isBadLogin = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private userProvider: UserProvider,
    private toastr: ToastService,
    private apiProvider: ApiProvider,
  ) {}

  ngOnInit(): void {
    this.version = environment.version;
    this.userProvider.isUserDataExist().subscribe((userData: UserData) => {
      this.loginForm = this.formBuilder.group({
        login: [userData.login ?? '', Validators.required],
        password: [userData.password ? decrypt(userData.password) : '', Validators.required],
        rememberMe: [userData.rememberOption ?? false],
      });
      if (userData.rememberOption && userData.isFirstStart) {
        this.isAlreadyLocalRegistered = true;
        if (
          userData &&
          userData['jwt'] &&
          userData['rememberOption'] &&
          userData['isFirstStart'] &&
          !userData['disableAutoLogin']
        ) {
          this.apiProvider.fetchAllMainData().subscribe(() => {
            this.userProvider.updateUserData({
              isAllMainDataFetched: true,
              isFirstStart: false,
            });
            this.router.navigate(['/comptage']);
          });
        }
      }
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = '/comptage';
  }

  // convenience getter for easy access to form fields
  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.loginForm.controls;
  }

  public resetPassword(): void {
    this.router.navigate(['/login/resetPassword']);
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    this.authService.login(this.f.login.value, this.f.password.value).subscribe(
      (value) => {
        const _callback = () => {
          this.apiProvider.fetchAllMainData().subscribe(() => {
            this.loading = false;
            this.router.navigate(['/comptage']);
          });
        };
        this.userProvider.updateUserData(
          {
            login: this.loginForm.value.login,
            password: encrypt(this.loginForm.value.password),
            rememberOption: this.loginForm.value.rememberMe,
            jwt: value['token'],
            isLogged: true,
            isFirstStart: false,
          },
          _callback.bind(this),
        );
      },
      (e) => {
        switch (e.status) {
          case 401:
            this.loading = false;
            this.isError = true;
            this.toastr.error(
              'Connexion',
              `Il y a eu une erreur interne, veuillez contacter votre Manager IVS.`,
            );
            break;
          case 403:
            this.loading = false;
            this.isError = true;
            this.toastr.error(
              'Connexion',
              `Le nom d'utilisateur ou le mot de passe est incorrect.`,
            );
            break;

          default:
            this.loading = false;
            this.isError = true;
            this.toastr.error(
              'Connexion',
              `Le nom d'utilisateur ou le mot de passe est incorrect.`,
            );
            break;
        }
      },
    );
  }
}
