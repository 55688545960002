import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Pages
import {
  ProfilComponent,
  ComptageComponent,
  ParcoursClientComponent,
  ClassementComponent,
  ErrorComponent,
  LoginComponent,
  ResetComponent,
  DetailsComponent,
  HelpComponent,
  LegalNoticeComponent,
  AboutComponent,
} from '@app/pages';

import { DashboardLayoutComponent, LoginLayoutComponent } from '@app/pages/layout';
import { AuthGuard } from '@app/services';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ComptageComponent,
        data: {
          breadcrumb: 'Comptage',
        },
      },
      {
        path: 'comptage',
        component: ComptageComponent,
        data: {
          breadcrumb: 'Comptage',
        },
      },
      {
        path: 'parcours-client',
        component: ParcoursClientComponent,
        data: {
          breadcrumb: 'Parcours Client',
        },
      },
      {
        path: 'classement',
        component: ClassementComponent,
        data: {
          breadcrumb: 'Classement',
        },
      },
      {
        path: 'profil',
        component: ProfilComponent,
        data: {
          breadcrumb: 'Profil',
        },
      },
      {
        path: 'details',
        component: DetailsComponent,
        data: {
          breadcrumb: 'Détails',
        },
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          breadcrumb: 'Aide',
        },
      },
      {
        path: 'legalNotice',
        component: LegalNoticeComponent,
        data: {
          breadcrumb: 'Mentions légales',
        },
      },
      {
        path: 'about',
        component: AboutComponent,
        data: {
          breadcrumb: 'A propos',
        },
      },
    ],
  },

  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'resetPassword',
        component: ResetComponent,
        pathMatch: 'full',
      },
      { path: '', component: LoginComponent },
    ],
  },
  { path: 'not-found', component: ErrorComponent },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
