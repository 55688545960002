import { Injectable } from '@angular/core';
import { AlertModal, Businesses, Organization, UserApi, Cluster, RangeType } from '@app/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataProvider {
  constructor() {}

  private isFirstStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isFirstStart$: Observable<boolean> = this.isFirstStart.asObservable();

  private user: BehaviorSubject<UserApi> = new BehaviorSubject<UserApi>(null);
  private user$: Observable<UserApi> = this.user.asObservable();

  private businesses: BehaviorSubject<Businesses[]> = new BehaviorSubject<Businesses[]>(null);
  private businesses$: Observable<Businesses[]> = this.businesses.asObservable();

  private clusters: BehaviorSubject<Cluster[]> = new BehaviorSubject<Cluster[]>(null);
  private clusters$: Observable<Cluster[]> = this.clusters.asObservable();

  private organization: BehaviorSubject<Businesses[]> = new BehaviorSubject<Businesses[]>(null);
  private organization$: Observable<Businesses[]> = this.organization.asObservable();

  private lastUserSelected: BehaviorSubject<UserApi> = new BehaviorSubject<UserApi>(null);
  private lastUserSelected$: Observable<UserApi> = this.lastUserSelected.asObservable();

  private lastClusterSelected: BehaviorSubject<Cluster> = new BehaviorSubject<Cluster>(null);
  private lastClusterSelected$: Observable<Cluster> = this.lastClusterSelected.asObservable();

  private lastOrgaSelected: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null);
  private lastOrgaSelected$: Observable<Organization> = this.lastOrgaSelected.asObservable();

  private lastBusinessSelected: BehaviorSubject<Businesses> = new BehaviorSubject<Businesses>(null);
  private lastBusinessSelected$: Observable<Businesses> = this.lastBusinessSelected.asObservable();

  private alertContent: BehaviorSubject<AlertModal> = new BehaviorSubject<AlertModal>({});
  private alertContent$: Observable<AlertModal> = this.alertContent.asObservable();

  private userList: BehaviorSubject<UserApi[]> = new BehaviorSubject<UserApi[]>([]);
  private userList$: Observable<UserApi[]> = this.userList.asObservable();

  private actionBarModalsOptions: BehaviorSubject<{
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
  }> = new BehaviorSubject<{
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
  }>({});
  private actionBarModalsOptions$: Observable<{
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
  }> = this.actionBarModalsOptions.asObservable();

  /**  Return all businesses */
  public getBusinesses(): Observable<Businesses[]> {
    return this.businesses$;
  }

  public setBusinesses(businesses: Businesses[]): void {
    this.businesses.next(businesses);
  }

  public getIsFirstStart(): Observable<boolean> {
    return this.isFirstStart$;
  }

  public setIsFirstStart(isFirstStart: boolean): void {
    this.isFirstStart.next(isFirstStart);
  }

  public getUser(): Observable<UserApi> {
    return this.user$;
  }

  public setUser(user: UserApi): void {
    this.user.next(user);
  }

  public getOrganization(): Observable<Organization[]> {
    return this.organization$;
  }

  public setOrganization(organization: Organization[]): void {
    this.organization.next(organization);
  }

  public getClusters(): Observable<Cluster[]> {
    return this.clusters$;
  }

  public setClusters(clusters: Cluster[]): void {
    this.clusters.next(clusters);
  }

  public getLastUserSelected(): Observable<UserApi> {
    return this.lastUserSelected$;
  }

  public setLastUserSelected(user: UserApi): void {
    this.lastUserSelected.next(user);
  }

  public getLastClusterSelected(): Observable<Cluster> {
    return this.lastClusterSelected$;
  }

  public setLastClusterSelected(cluster: Cluster): void {
    this.lastClusterSelected.next(cluster);
  }

  public getLastOrganizationSelected(): Observable<Organization> {
    return this.lastOrgaSelected$;
  }

  public setLastOrganizationSelected(organization: Organization): void {
    this.lastOrgaSelected.next(organization);
  }

  public getLastBusinessSelected(): Observable<Businesses> {
    return this.lastBusinessSelected$;
  }

  public setLastBusinessSelected(businesses: Businesses): void {
    this.lastBusinessSelected.next(businesses);
  }

  public getAlertModalContent(): Observable<AlertModal> {
    return this.alertContent$;
  }

  public setAlertModalContent(content: AlertModal): void {
    this.alertContent.next(content);
  }

  public getUserList(): Observable<UserApi[]> {
    return this.userList$;
  }

  public setUserList(userList: UserApi[]): void {
    this.userList.next(userList);
  }

  public getActionBarModalsOptions(): Observable<{
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
  }> {
    return this.actionBarModalsOptions$;
  }

  public setActionBarModalsOptions(options: {
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
  }): void {
    this.actionBarModalsOptions.next(options);
  }
}
