import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegularExpressionConstant } from '@app/const';
import { RefreshProvider } from '@app/providers/layout';
import { ApiV2Service } from '@app/services/api/v2';
import { crossFieldsValidatorPassword } from '@app/services/validators.service';
import { AbstractReset } from '../abstract-reset';

@Component({
  selector: 'app-do-reset',
  templateUrl: './do-reset.component.html',
  styleUrls: ['./do-reset.component.scss'],
})
export class DoResetComponent extends AbstractReset implements OnInit {
  public doResetForm: UntypedFormGroup;
  public submitted = false;
  public doRestFieldType = 'password';

  public isPasswordChanged = false;
  private props = {};

  public alreadySubmited = false;
  constructor(
    router: Router,
    refreshProvider: RefreshProvider,
    apiV2Service: ApiV2Service,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(refreshProvider, router, apiV2Service);
  }
  ngOnInit(): void {
    this.refreshProvider.listen('reset-steps').subscribe((value) => (this.props = value.props));

    this.doResetForm = this.formBuilder.group(
      {
        newPlainPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
        newPlainPasswordConfirmation: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
      },
      { validators: crossFieldsValidatorPassword },
    );
  }

  public updateFormByKey(key: string, value: any) {
    this.doResetForm.patchValue({ [key]: value });
  }

  public goLogin() {
    (document.getElementById('input_password_0') as HTMLInputElement).value = '';
    (document.getElementById('input_confirmPassword_0') as HTMLInputElement).value = '';
    this.doResetForm.patchValue({
      newPlainPassword: '',
      newPlainPasswordConfirmation: '',
    });
    this.router.navigate(['/login']);
    this.refreshProvider.emit({
      'reset-steps': { props: { steps: 0 } },
    });
  }

  public onSubmit() {
    this.alreadySubmited = true;
    if (this.alreadySubmited) {
      setTimeout(() => {
        this.alreadySubmited = false;
      }, 6000);
    }
    if (this.doResetForm.valid) {
      this.setNewPassword(this.doResetForm.controls.newPlainPassword.value);
      this.updatePassword(
        this.doResetForm.controls.newPlainPassword.value,
        this.props['id'],
        this.props['token'],
      ).subscribe(
        () => {
          this.isPasswordChanged = true;
          setTimeout(() => {
            this.quit();
          }, 5000);
        },
        () => {
          this.setError('do-reset');
        },
      );
    }
  }
}
