import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateRangeApi } from '@app/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private http: HttpClient) {}

  public generateCSV(range: DateRangeApi, organisationID: number): Observable<Blob> {
    return this.http.get(
      `${environment.backendDomain}/export/csv/dashboard?begin=${range.begin}&end=${range.end}&organisation=${organisationID}`,
      {
        responseType: 'blob',
      },
    );
  }

  public generatePDF(range: DateRangeApi, organisationID: number): Observable<Blob> {
    return this.http.get(
      `${environment.backendDomain}/export/pdf/dashboard?begin=${range.begin}&end=${range.end}&organisation=${organisationID}`,
      {
        responseType: 'blob',
      },
    );
  }
}
