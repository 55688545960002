import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectFormComponent implements OnInit {
  @Input() choices: string[] | any;
  @Input() label: string | undefined;
  @Input() defaultSelected: number | any;
  @Input() formValueTips: number | any;
  @Input() _index: number | any;

  @Output() choicesSelected = new EventEmitter();

  public values: string[] = [];
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.defaultSelected === undefined || this.defaultSelected === null) {
      this.defaultSelected = 0;
    }

    if (this._index === undefined || this._index === null) {
      this._index = 0;
    }
    if (this.choices[this.defaultSelected]) {
      this.translateService.get(this.choices[this.defaultSelected]).subscribe((value) => {
        this.choicesSelected.emit(value);
        this.values = value;
      });
    }
  }
  public getValue(event: any): string {
    return (event.target as HTMLInputElement).value;
  }

  public emit(value: any): void {
    this.choicesSelected.emit(value);
  }
}
