import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foreground-loader',
  templateUrl: './foreground-loader.component.html',
  styleUrls: ['./foreground-loader.component.scss'],
})
export class ForegroundLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
