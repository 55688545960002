<div
  class="fadeIn bg-dark d-flex flex-column justify-content-center w-100 h-100 m-0 col-12 rounded">
  <div class="row d-flex m-0 align-items-center text-center">
    <div class="col-12 text-white font-bold">
      {{ 'loading' | translate }}
    </div>
    <div class="col-12">
      <ngb-progressbar type="primary" [value]="percent" height=".5rem"></ngb-progressbar>
    </div>
    <div class="col-12 text-white font-bold">
      {{ percent + ' %' }}
    </div>
  </div>
</div>
