import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputFormComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() _index: number | any;
  @Input() _type = 'text';
  @Input() _class = '';
  @Input() _typeActivated = 'text';
  @Input() _typeDefault = '';
  @Input() _appendRight: boolean = false;
  @Input() _appendRightIsActive: boolean = false;
  @Input() _appendRightItem: string = 'bi bi-eye-slash';
  @Input() _appendRightItemActivated: string = 'bi bi-eye';
  @Input() preprendLabel: number | any;
  @Input() appendLabel: number | any;
  @Input() placeHolder: string | any = '';
  @Input() floatingTooltip: boolean = false;
  @Input() showIsValidLabel: boolean = true;
  @Input() showIsInValidLabel: boolean = true;
  @Input() required: boolean = false;
  @Input() isValid: boolean = false;
  @Input() isInvalid: boolean = false;
  @Input() isValidFeedBack = null;
  @Input() isInvalidFeedBack = null;
  @Input() canPressEnter = false;

  @Input() value = '';

  @Output() inputChange = new EventEmitter();
  @Output() onValid = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this._index === undefined || this._index === null) {
      this._index = 0;
    }
    if (this._typeDefault === '') {
      this._typeDefault = this._type;
    }
  }

  public toogleAppendRight() {
    this._appendRightIsActive = !this._appendRightIsActive;
    if (this._appendRightIsActive) {
      this._type = this._typeDefault;
    } else {
      this._type = this._typeActivated;
    }
    this.cdRef.detectChanges();
  }

  public getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public emit(value: any): void {
    this.inputChange.emit(value);
  }

  public emitOnValid(value: any): void {
    if (this.canPressEnter) {
      this.onValid.emit(value);
      this.inputChange.emit('');
      setTimeout(() => {
        this.value = '';
      });
    }
  }
}
