import { Injectable } from '@angular/core';
import { BoxService } from './box.service';
import { BusinessService } from './business.service';
import { OrganisationService } from './organization.service';
import { StatsService } from './stats.service';
import { UserService } from './user.service';
import { ClusterService } from './cluster.service';
import { ZoneService } from './zone.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    public box: BoxService,
    public business: BusinessService,
    public organisation: OrganisationService,
    public stats: StatsService,
    public user: UserService,
    public cluster: ClusterService,
    public zone: ZoneService,
  ) {}
}
