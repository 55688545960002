<div class="list-group mh-30">
  <div class="d-flex flex-row align-items-center pb-2">
    <h5 class="font-weight-bold m-0">
      {{ 'listUser' | translate }}
    </h5>
    <!-- <div
      class="add_item_action ms-auto pointer"
      ngbTooltip="{{ 'tooltip.newUser' | translate }}"
      placement="top-end"
      (click)="createUser()">
      <img width="20px" src="/assets/svg/AddItem.svg" />
    </div> -->
  </div>
  <ng-template #loader>
    <ngx-skeleton-loader count="6"></ngx-skeleton-loader>
  </ng-template>
  <div class="input-group mb-3" *ngIf="userRelations; else loader">
    <input
      type="text"
      class="form-control"
      placeholder="{{ 'searchUser' | translate }}"
      [(ngModel)]="searchValue" />
    <div class="input-group-append">
      <div class="h-100 px-3 d-flex align-items-center bg-dark rounded-end">
        <img src="/assets/svg/Search.svg" />
      </div>
    </div>
  </div>
  <div class="scrollable_details" id="scrollable_profil_details_userslist">
    <div
      class="list-group-item list-group-item-action border-0 d-flex flex-row d-flex pointer"
      *ngFor="let user of userRelations | arrayFilter : searchValue : 'username'">
      <button (click)="detailUser(user)" placement="end" class="btn btn-outline-light p-1 px-2">
        <img width="20px" src="/assets/svg/See.svg" />
      </button>
      <div class="content d-flex flex-column px-3">
        <div class="text-start">
          {{ user.email }}
        </div>
        <div class="text-start text-muted">
          {{ 'local.roles.' + user.roles[0] + '_SHORT' | translate }}
        </div>
      </div>
      <!-- <button
        class="btn btn-outline-light p-1 px-2 ms-auto"
        (click)="deleteUser(user)"
        placement="end"
        [ngbTooltip]="('tooltip.deleteUser' | translate) + user.username">
        <img width="20px" src="/assets/svg/Trash.svg" />
      </button>
      <button
        (click)="editUser(user)"
        placement="end"
        [ngbTooltip]="('tooltip.editUser' | translate) + user.username"
        class="btn btn-outline-light p-1 px-2">
        <img width="20px" src="/assets/svg/Pencil.svg" />
      </button> -->
    </div>
  </div>
</div>
