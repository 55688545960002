<div class="card h-100 p-0 m-0">
  <div
    class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black pe-5">
    <div class="d-flex p-right-2 wrap_chart_icon">
      <img height="20px" src="/assets/svg/age-gender/age/picto-white.svg" alt="age kpi" />
    </div>

    <div class="h6 m-0 text-white font-weight-normal">
      {{ 'ageGender.ageTitle' | translate }}
    </div>
  </div>

  <div class="card-body position-relative p-0 m-0 h-100">
    <div class="h-100 w-100">
      <canvas id="age_canvas"></canvas>

      <div class="w-100 position-absolute bottom-0 d-flex justify-content-around">
        <img src="/assets/svg/age-gender/age/adult.svg" alt="adult pictogram" class="ms-4" />
        <img src="/assets/svg/age-gender/age/child.svg" alt="child pictogram" id="child-icon" />
      </div>
    </div>
  </div>
</div>
