import { Component, Input } from '@angular/core';
import { Businesses } from '@app/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sign-info',
  templateUrl: './sign-info.component.html',
  styleUrls: ['./sign-info.component.scss'],
})
export class SignInfoComponent {
  @Input() businessesInfo$: Observable<Businesses[]>;
}
