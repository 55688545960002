<div class="card h-100 p-0 m-0">
  <div class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black">
    <div class="d-flex p-right-2 wrap_chart_icon">
      <img height="18px" src="/assets/svg/{{ data.icon }}.svg" alt="user" />
    </div>
    <div class="h6 m-0 text-white font-weight-normal">
      {{ data.title | translate }}
    </div>
  </div>
  <div class="card-body position-relative p-0 m-0 h-100">
    <div class="h-100 width-100">
      <canvas
        [id]="'_chart_' + data.title"
        class="h-100 width-100"
        [class]="loading ? '_opacity_4' : ''"></canvas>
      <div class="canvas_wrap_loading" *ngIf="loading && !unavailable">
        <svg-icon
          width="100%"
          height="100%"
          src="/assets/svg/spinner/tail-spin.svg"
          alt="user"></svg-icon>
      </div>

      <div class="canvas_wrap_loading" *ngIf="unavailable && !fullUnavailable">
        {{ 'charts.emptyRangeData' | translate }}
      </div>

      <div class="canvas_wrap_loading" *ngIf="fullUnavailable">
        {{ 'charts.empty' | translate }}
      </div>
    </div>
  </div>
</div>
