import { KpiData } from '@app/models';
export const constKpiHiddenData: KpiData = {
  customersGroup: {
    icon: 'customerGroupBlack',
    iconSwitchOn: 'EntryNumberBlack',
    title: 'customersGroup',
    tooltip: 'customersGroup',
    content: { showAlert: false },
    show: true,
    switch: true,
    disabled: true,
    switchOn: 'EntryNumber',
  },
  attractivenessRateVideo: {
    icon: 'AttractivenessRateBlack',
    title: 'attractivenessRate',
    tooltip: 'attractivenessRate',
    content: { showAlert: false },
    show: true,
    disabled: true,
    src: 'video',
  },
};

export const ConstKpiData: KpiData = {
  entryNumber: {
    icon: 'EntryNumberBlack',
    iconSwitchOn: 'customerGroupBlack',
    title: 'entryNumber',
    tooltip: 'entryNumber',
    content: { showAlert: false },
    show: true,
    switch: true,
    disabled: false,
    switchOn: 'CustomersGroup',
  },

  attractivenessRate: {
    icon: 'AttractivenessRateBlack',
    title: 'attractivenessRate',
    tooltip: 'attractivenessRate',
    content: { showAlert: false },
    disabled: true,
    show: true,
    src: 'wifi',
  },

  attendanceTime: {
    icon: 'AttendanceTimeBlack',
    title: 'attendanceTime',
    tooltip: 'attendanceTime',
    content: { showAlert: false },
    disabled: true,
    show: true,
  },
  salesRevenue: {
    icon: 'EuroBlack',
    title: 'salesRevenue',
    tooltip: 'salesRevenue',
    content: { showAlert: false },
    show: true,
  },
  transformRate: {
    icon: 'TransformTimeBlack',
    title: 'transformRate',
    tooltip: 'transformTime',
    content: { showAlert: false },
    show: true,
  },
  billTickets: {
    icon: 'BillBlack',
    title: 'billTickets',
    tooltip: 'billTickets',
    content: { showAlert: false },
    show: true,
  },
};

export const ConstDataExport: KpiData = {
  entryNumber: {
    icon: 'EntryNumberBlack',
    title: 'entryNumber',
    tooltip: 'entryNumber',
    content: { showAlert: false },
  },
  attractivenessRate: {
    icon: 'AttractivenessRateBlack',
    title: 'attractivenessRate',
    tooltip: 'attractivenessRate',
    content: { showAlert: false },
    disabled: true,
  },

  attendanceTime: {
    icon: 'AttendanceTimeBlack',
    title: 'attendanceTime',
    tooltip: 'attendanceTime',
    content: { showAlert: false },
    disabled: true,
  },
  salesRevenue: {
    icon: 'EuroBlack',
    title: 'salesRevenue',
    tooltip: 'salesRevenue',
    content: { showAlert: false },
  },
  transformRate: {
    icon: 'TransformTimeBlack',
    title: 'transformRate',
    tooltip: 'transformTime',
    content: { showAlert: false },
  },
  billTickets: {
    icon: 'BillBlack',
    title: 'billTickets',
    tooltip: 'billTickets',
    content: { showAlert: false },
  },
  audiance: {
    icon: '',
    title: 'audiance',
    tooltip: '',
    content: { showAlert: false },
  },
  atractivity: {
    icon: '',
    title: 'atractivity',
    tooltip: '',
    content: { showAlert: false },
  },
  interaction: {
    icon: '',
    title: 'interaction',
    tooltip: '',
    content: { showAlert: false },
  },
  averageStop: {
    icon: '',
    title: 'averageStop',
    tooltip: '',
    content: { showAlert: false },
  },
  customersGroup: {
    icon: '',
    title: 'customersGroup',
    tooltip: 'customersGroup',
    content: { showAlert: false },
  },
};
