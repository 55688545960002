import { Component, Input } from '@angular/core';
import { DetailCard } from '@app/models';
@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent {
  @Input() detailData: DetailCard | any;
}
