<div class="form-floating border-0 mb-3 p-1">
  <div
    class="form-control d-flex flex-row p-0 m-0 pt-4"
    [id]="label + '_choice_component' + '_' + _index">
    <select
      (input)="emitSelect(getValue($event))"
      class="bg-transparent _select"
      [selectedIndex]="0">
      <option *ngFor="let choice of choices; let i = index" [value]="choice | translate">
        {{ choice | translate }}
      </option>
    </select>
    <input
      [id]="'floating_' + label + '_' + _index"
      type="email"
      class="form-control rounded"
      [(ngModel)]="inputValue"
      (input)="emitInput()" />
  </div>
  <label *ngIf="label" [for]="label + '_choice_component' + '_' + _index">{{
    label | translate
  }}</label>
</div>
