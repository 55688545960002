import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RefreshProvider } from '@app/providers/layout';
import { UserService } from '@app/services';
import { ApiV2Service } from '@app/services/api/v2';
import { AbstractReset } from '../abstract-reset';

@Component({
  selector: 'app-ask-reset',
  templateUrl: './ask-reset.component.html',
  styleUrls: ['./ask-reset.component.scss'],
})
export class AskResetComponent extends AbstractReset implements OnInit {
  public askPasswordForm: UntypedFormGroup;
  public submitted = false;
  public status: any;
  public resetStatus = 0;
  public isWaiting = true;
  public isSuccess = false;

  constructor(
    router: Router,
    refreshProvider: RefreshProvider,
    apiV2Service: ApiV2Service,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
  ) {
    super(refreshProvider, router, apiV2Service);
  }

  ngOnInit() {
    this.askPasswordForm = this.formBuilder.group({
      usernameOrEmail: [this.$usernameOrEmail.value, Validators.required],
    });
  }

  public back() {
    this.router.navigate(['/login']);
  }

  public onSubmit() {
    this.submitted = true;
    if (this.askPasswordForm.valid) {
      this.$usernameOrEmail.next(this.askPasswordForm.controls.usernameOrEmail.value);
      this.askNewPassword().subscribe(
        (value) => {
          this.$showWaitingToken.next(true);
          this.nextStep();
        },
        () => {
          this.setError('ask-reset-sending-email');
        },
      );
    }
  }

  public reset() {
    this.submitted = false;
    this.$showWaitingToken.next(false);
    this.removeError();
  }
}
