import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import gtag, { install } from 'ga-gtag';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class GaProvider {
  constructor(private router: Router) {}

  public init(id: number) {
    window['dataLayer'] = [];
    install(environment.ga);
    gtag('config', environment.ga, {
      ivs_id: id,
    });
    gtag('js', new Date());
    this.listenRouter();
  }

  public sendEvent(eventName: string, category: string, label?: string) {
    gtag('Exports', eventName, {
      event_category: category,
      event_label: label || moment().format("YYYY-MM-DD mm'ss"),
      non_interaction: true,
      event_callback: function (e) {
        console.log('data is send');
      },
    });
  }

  public listenRouter() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        gtag('Event', 'navigation', {
          page: event.url,
          event_callback: function (e) {
            console.log('data is send');
          },
        });
      }
    });
  }
}
