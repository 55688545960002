import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-export-push-mail-list',
  templateUrl: './export-push-mail-list.component.html',
  styleUrls: ['./export-push-mail-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportPushMailListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  @Input() rows;

  editing = {};

  selected = [];

  columns: any[] = [
    {
      prop: 'title',
      name: 'Liste de diffusions',
      summaryFunc: (cells) => "Nombre d'e-mails : " + cells.length,
    },
  ];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  @Output() onDelete = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDismiss = new EventEmitter();
  @Output() onNewTasks = new EventEmitter();

  getCellClass = () => {
    return {
      'd-flex flex-row ': true,
    };
  };

  public dismissModal(): void {
    this.onDismiss.emit('Click on dismiss');
  }

  public newTasks(): void {
    this.onNewTasks.emit('Click on new Tasks');
  }
  public editTask(row, index): void {
    this.onEdit.emit({ row: row, index: index });
  }
  public deleteTasks(row, index): void {
    this.onDelete.emit({ row: row, index: index });
  }
}
