import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-short-actions',
  templateUrl: './short-actions.component.html',
  styleUrls: ['./short-actions.component.scss'],
})
export class ShortActionsComponent implements OnInit {
  @Input() loading;

  @Input() clusterList;
  @Input() kpiList;
  @Input() disabledKpi;

  @Input() actualCluster;
  @Input() actualKpi;

  @Output() select = new EventEmitter();

  public loadingAffix = {
    salesRevenue: '€',
    attendanceTime: '',
    attractivenessRate: '%',
    entryNumber: '',
    billTickets: '',
    transformRate: '%',
  };

  ngOnInit(): void {}
  public onSelect(item: { type: 'cluster' | 'kpi'; value: string }) {
    this.select.emit(item);
  }
}
