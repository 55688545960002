import { Component, OnDestroy, OnInit } from '@angular/core';
import { Row } from '@app/models';
import { GaProvider, StatsProvider } from '@app/providers';
import { ApiService, DateHelperService, MathService, TableHelperService } from '@app/services';
import { TableColumn } from '@swimlane/ngx-datatable';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { customNumberFilter } from '@app/pipes';
import { ActivatedRoute } from '@angular/router';
import { ApiV2Service } from '@app/services/api/v2';
import { BusinessService, CountingService } from '@app/services';
import { FabricPlan2D } from '@app/services/canvas';
@Component({
  selector: 'app-parcours-client',
  templateUrl: './parcours-client.component.html',
  styleUrls: ['./parcours-client.component.scss'],
})
export class ParcoursClientComponent implements OnInit, OnDestroy {
  public options;
  public loading = true;
  public isOnZone2D = false;

  public maxVisitor = 0;
  public maxVisitorVs = 0;
  public maxVisitorPercent = 0;

  private range: any = {
    start: '',
    startVs: '',
  };

  public activeTab = '';
  public activeTabVs = '';

  public tableFilterState = {};
  public tableFilterStateVs = {};

  public rows: Row[] = [];
  public rowsVs: Row[] = [];

  public zippedColumns: Row[] = [];
  public zippedColumnsVs: Row[] = [];

  public columns: TableColumn[] = [
    { name: 'Nom', prop: 'name' },
    { name: '', prop: 'value', headerClass: 'text_orange' },
    { name: '', prop: 'valueVs', headerClass: 'text_blue' },
    { name: 'Fluctuation', prop: 'fluctuation' },
    {
      name: '%',
      prop: 'percent',
    },
  ];
  public columnsVs: TableColumn[] = [
    { name: 'Nom', prop: 'name' },
    { name: '', prop: 'value', headerClass: 'text_orange' },
    { name: '', prop: 'valueVs', headerClass: 'text_blue' },
    { name: 'Fluctuation', prop: 'fluctuation' },
    {
      name: '%',
      prop: 'percent',
    },
  ];

  public columnsToShow: { [key: string]: TableColumn[] } = {
    audiance: [
      { sortable: true, name: 'Nom', prop: 'name' },
      {
        sortable: true,
        name: '',
        prop: 'audianceVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
      },
      {
        sortable: true,
        name: '',
        prop: 'audiance',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
      },
    ],
    atractivity: [
      { sortable: true, name: 'Nom', prop: 'name' },
      {
        sortable: true,
        name: '',
        prop: 'atractivityVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
      {
        sortable: true,
        name: '',
        prop: 'atractivity',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
    interaction: [
      { sortable: true, name: 'Nom', prop: 'name' },
      {
        sortable: true,
        name: '',
        prop: 'interactionVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
      },
      {
        sortable: true,
        name: '',
        prop: 'interaction',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
      },
    ],
    averageStop: [
      { sortable: true, name: 'Nom', prop: 'name' },
      {
        sortable: true,
        name: '',
        prop: 'averageStopVs',
        headerClass: 'text_blue',
      },
      {
        sortable: true,
        name: '',
        prop: 'averageStop',
        headerClass: 'text_orange',
      },
    ],
  };

  public zippedLastCol: { [key: string]: TableColumn[] } = {
    audiance: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationAudiance',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' Pt';
          },
        },
      },
      {
        name: '%',
        prop: 'percentAudiance',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' %';
          },
        },
      },
    ],
    atractivity: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationAtractivity',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' Pt';
          },
        },
      },
      {
        name: '%',
        prop: 'percentAtractivity',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' %';
          },
        },
      },
    ],
    interaction: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationInteraction',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' Pt';
          },
        },
      },
      {
        name: '%',
        prop: 'percentInteraction',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' %';
          },
        },
      },
    ],
    averageStop: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationAverageStop',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return value;
          },
        },
      },
      {
        name: '%',
        prop: 'percentAverageStop',
        cellClass: (data: any) => {
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' %';
          },
        },
      },
    ],
  };

  public allKeys = Object.keys(this.columnsToShow);
  private subs = null;
  constructor(
    private statsProvider: StatsProvider,
    private apiService: ApiService,
    private dateHelperService: DateHelperService,
    private tableHelper: TableHelperService,
    private activatedRoute: ActivatedRoute,
    private mathService: MathService,
    public apiV2Service: ApiV2Service,
    public businessService: BusinessService,
    private countingService: CountingService,
    private fabricService: FabricPlan2D,
    private ga$: GaProvider,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((value) => {
      if (this.subs) {
        this.subs.unsubscribe();
      }
      this.subs = this.statsProvider.getOptions().subscribe((options) => {
        this.clean();
        this.rows = [];
        this.rowsVs = [];
        this.maxVisitor = 0;
        this.maxVisitorVs = 0;
        this.maxVisitorPercent = 0;
        this.leftZone2D();
        this.loading = true;
        const toHuman = this.dateHelperService.toHuman(options);
        this.range = {
          start: toHuman.start,
          startVs: toHuman.startVs,
        };

        this.refeshColumns(this.range);

        const range = this.dateHelperService.rangeToUnixTimestampWithCheck(
          { begin: options.range.begin, end: options.range.end },
          {
            begin: options.range.beginVs,
            end: options.range.endVs,
          },
        );
        if (options.type) {
          forkJoin([
            this.countingService.getKpiData(options.targetData.id, options.rangeType, {
              begin: range.begin,
              end: range.end,
              beginVs: range.beginVs,
              endVs: range.endVs,
            }),
            this.apiV2Service.getJourneyData(options.targetData.id, {
              begin: range.begin,
              end: range.end,
              beginVs: range.beginVs,
              endVs: range.endVs,
            }),
          ]).subscribe((data) => {
            this.rows = [];
            this.rowsVs = [];
            this.loading = false;
            this.maxVisitor = data[0]['current'].customerVisit;
            this.maxVisitorVs = data[0]['versus'].customerVisit;
            this.maxVisitorPercent = data[0]['comparison'].customerVisit.percent;
            const value = data[1];
            let holder = [];
            Object.keys(value.current).forEach((zoneId, index) => {
              holder.push({
                name: value.current[zoneId].zoneName,
                id: zoneId,
                audiance: value.current[zoneId].currentZonePassage,
                audianceVs: value.versus[zoneId].currentZonePassage,
                atractivity: this.mathService.getAttractivityPercent(
                  value.current[zoneId].currentZonePassage,
                  this.maxVisitor,
                ),
                atractivityVs: this.mathService.getAttractivityPercent(
                  value.versus[zoneId].currentZonePassage,
                  this.maxVisitorVs,
                ),
                interaction: value.current[zoneId].currentStopCount,
                interactionVs: value.versus[zoneId].currentStopCount,
                averageStop: this.dateHelperService.secondsToMinutes(
                  value.current[zoneId].stopsDurationInZone /
                    value.current[zoneId].currentStopCount,
                ),
                averageStopVs: this.dateHelperService.secondsToMinutes(
                  value.versus[zoneId].stopsDurationInZone / value.versus[zoneId].currentStopCount,
                ),
                _RAW_averageStop: this.mathService.onlyRealNumber(
                  value.current[zoneId].stopsDurationInZone /
                    value.current[zoneId].currentStopCount,
                ),
                _RAW_averageStopVs: this.mathService.onlyRealNumber(
                  value.versus[zoneId].stopsDurationInZone / value.versus[zoneId].currentStopCount,
                ),
                percent: 0,
                percentAudiance: this.mathService.getPercent(
                  value.versus[zoneId].currentZonePassage,
                  value.current[zoneId].currentZonePassage,
                ),
                percentAtractivity: this.mathService.getPercent(
                  this.mathService.getAttractivity(
                    value.versus[zoneId].currentZonePassage,
                    this.maxVisitorVs,
                  ),
                  this.mathService.getAttractivity(
                    value.current[zoneId].currentZonePassage,
                    this.maxVisitor,
                  ),
                ),
                percentInteraction: this.mathService.getPercent(
                  value.versus[zoneId].currentStopCount,
                  value.current[zoneId].currentStopCount,
                ),
                percentAverageStop: this.mathService.getPercent(
                  Math.trunc(
                    value.versus[zoneId].stopsDurationInZone /
                      value.versus[zoneId].currentStopCount || 0,
                  ),
                  Math.trunc(
                    value.current[zoneId].stopsDurationInZone /
                      value.current[zoneId].currentStopCount || 0,
                  ),
                ),
                fluctuation: 0,
                fluctuationAudiance:
                  value.current[zoneId].currentZonePassage -
                  value.versus[zoneId].currentZonePassage,
                fluctuationAtractivity:
                  this.mathService.getAttractivityPercent(
                    value.current[zoneId].currentZonePassage,
                    this.maxVisitor,
                  ) -
                  this.mathService.getAttractivityPercent(
                    value.versus[zoneId].currentZonePassage,
                    this.maxVisitorVs,
                  ),
                fluctuationInteraction:
                  value.current[zoneId].currentStopCount - value.versus[zoneId].currentStopCount,
                fluctuationAverageStop: this.dateHelperService.secondsToMinutes(
                  Math.trunc(
                    value.current[zoneId].stopsDurationInZone /
                      value.current[zoneId].currentStopCount || 0,
                  ) -
                    Math.trunc(
                      value.versus[zoneId].stopsDurationInZone /
                        value.versus[zoneId].currentStopCount || 0,
                    ),
                ),
                sumStopDuration: value.current[zoneId].stopsDurationInZone,
                sumStopDurationVs: value.versus[zoneId].stopsDurationInZone,
              });
            });

            this.initDefaultTableView(holder);
          });
        }
      });
    });
  }

  public showDataAll(name: 'audiance' | 'atractivity' | 'interaction' | 'averageStop') {
    this.showData(name);
    this.showDataVs(name);
  }

  public showData(name: 'audiance' | 'atractivity' | 'interaction' | 'averageStop') {
    this.ga$.sendEvent('Actions', 'Parcours client', 'Choix kpi ' + name);

    if (typeof name === 'string') {
      const holder = this.rows;
      this.loading = false;
      this.activeTab = name;
      this.rows = [...holder];
      this.columns = [...this.columnsToShow[name]];
      this.zippedColumns = [...this.zippedLastCol[name]];
    }
  }

  public showDataVs(name: 'audiance' | 'atractivity' | 'interaction' | 'averageStop') {
    if (typeof name === 'string') {
      const holder = this.rowsVs;
      this.loading = false;
      this.activeTabVs = name;
      this.rowsVs = [...holder];
      this.columnsVs = [...this.columnsToShow[name]];
      this.zippedColumnsVs = [...this.zippedLastCol[name]];
    }
  }

  private initDefaultTableView(data): void {
    this.rows = this.tableHelper.rankByField(data, 'audiance', {
      withSplit: true,
      sort: 'asc',
      forceType: 'number',
      sortByNamedValueIfAllFieldToRankAreEquals: 'audiance',
      optsIfAllFieldToRankAreEquals: {
        withSplit: true,
        sort: 'asc',
        forceType: 'number',
      },
    });

    this.rowsVs = this.tableHelper.rankByField(data, 'interaction', {
      withSplit: true,
      sort: 'asc',
      forceType: 'number',
      sortByNamedValueIfAllFieldToRankAreEquals: 'interaction',
      optsIfAllFieldToRankAreEquals: {
        withSplit: true,
        sort: 'asc',
        forceType: 'number',
      },
    });

    this.showData('audiance');
    this.showDataVs('interaction');

    if (this.isOnZone2D) {
      this.fabricService.setIsRowJourneyDataReady(true);
    }
  }

  // TODO ADD TO INHERITS COMPONENET
  public cleanSortTableState(): void {
    Object.keys(this.tableFilterState).forEach((key) => {
      this.tableFilterState[key] = undefined;
    });
    Object.keys(this.tableFilterStateVs).forEach((key) => {
      this.tableFilterStateVs[key] = undefined;
    });
  }

  // TODO ADD TO INHERITS COMPONENET
  public sortTable(
    field: string,
    forceMode?: 'asc' | 'desc',
    fieldIfAllEquals?: string,
    versus?: boolean,
  ): void {
    const sortModes = ['asc', 'desc'];
    var sortMode = '';

    if (this.tableFilterState[field] === true) {
      Object.keys(this.tableFilterState).forEach((state) => {
        this.tableFilterState[state] = field === state ? false : undefined;
      });
      this.tableFilterState[field] = false;
      sortMode = sortModes[0];
    } else {
      Object.keys(this.tableFilterState).forEach((state) => {
        this.tableFilterState[state] = field === state ? true : undefined;
      });
      this.tableFilterState[field] = true;
      sortMode = sortModes[1];
    }

    if (forceMode === 'asc' || forceMode === 'desc') {
      sortMode = forceMode;
      this[versus ? 'rowsVs' : 'rows'] = this.tableHelper.rankByField(
        this[versus ? 'rowsVs' : 'rows'],
        field,
        {
          withSplit: true,
          MMSSS: true,
          sort: forceMode,
          sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
          optsIfAllFieldToRankAreEquals: {
            withSplit: true,
            MMSSS: true,
            sort: forceMode,
          },
        },
      );
      this.cleanSortTableState();
    }
    if (field === 'fluctuationAverageStop' || field === 'averageStop') {
      this[versus ? 'rowsVs' : 'rows'] = this.tableHelper.rankByField(
        this[versus ? 'rowsVs' : 'rows'],
        field,
        {
          withSplit: true,
          MMSSS: true,
          sort: sortMode,
          sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
          optsIfAllFieldToRankAreEquals: {
            withSplit: true,
            MMSSS: true,
            sort: sortMode,
          },
        },
      );
    } else if (field === 'name') {
      this[versus ? 'rowsVs' : 'rows'] = this.tableHelper.rankByField(
        this[versus ? 'rowsVs' : 'rows'],
        field,
        {
          withSplit: true,
          sort: sortMode,
          forceType: 'string',
          sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
          optsIfAllFieldToRankAreEquals: {
            withSplit: true,
            sort: sortMode,
            forceType: 'string',
            sortByNameToo: true,
          },
        },
      );
    } else {
      this[versus ? 'rowsVs' : 'rows'] = this.tableHelper.rankByField(
        this[versus ? 'rowsVs' : 'rows'],
        field,
        {
          withSplit: true,
          sort: sortMode,
          forceType: 'number',
          sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
          optsIfAllFieldToRankAreEquals: {
            withSplit: true,
            sort: sortMode,
            forceType: 'number',
          },
        },
      );
    }
    if (versus) {
      this.showDataVs(this.activeTabVs as never);
    } else {
      this.showData(this.activeTab as never);
    }
  }

  private refeshColumns(toHumanRange: { start: any; startVs: any }): void {
    Object.keys(this.columnsToShow).forEach((colToShow) => {
      this.columnsToShow[colToShow][1].name = toHumanRange.startVs;
      this.columnsToShow[colToShow][2].name = toHumanRange.start;
    });
  }

  public goToZone2D() {
    this.isOnZone2D = true;
    this.fabricService.setIsRowJourneyDataReady(true);
    this.ga$.sendEvent('Actions', 'Parcours client', 'Entrée zone 2D');
  }

  public leftZone2D() {
    this.isOnZone2D = false;
    this.ga$.sendEvent('Actions', 'Parcours client', 'Sortie zone 2D');
  }

  private clean() {
    this.rows = [];
    this.rowsVs = [];
    this.maxVisitor = 0;
    this.maxVisitorVs = 0;
    this.maxVisitorPercent = 0;
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    this.clean();
  }
}
