import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })

/**
 * Refresh provider (store logic) like any front end framework
 */
export class RefreshProvider {
  private refreshPipe: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshPipe$: Observable<any> = this.refreshPipe.asObservable();

  public emit(componentOrPageToRefresh: {
    [key: string]: {
      forceRefresh?: boolean;
      props?: any;
      type?: string;
      fn?: () => {} | void;
    };
  }): void {
    const combined = {
      ...this.refreshPipe.value,
      ...componentOrPageToRefresh,
    };
    this.refreshPipe.next(combined);
  }

  public listen(pathToFilter?: string): Observable<{
    forceRefresh?: boolean;
    props?: any;
    type?: string;
    fn?: () => {} | void;
  }> {
    return pathToFilter
      ? this.refreshPipe$.pipe(
          filter((value) => value && value[pathToFilter]),
          map((value) => value[pathToFilter]),
        )
      : this.refreshPipe$.pipe(filter((value) => value));
  }
}
