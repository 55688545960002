import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private toastr: ToastrService) {}
  private toastrContainer = null;

  public setMainToaster(toastrContainer) {
    this.toastr.overlayContainer = toastrContainer;
    this.toastrContainer = toastrContainer;
  }
  public error(title: string, msg: string): void {
    this.toastr.overlayContainer = this.toastrContainer;
    this.toastr.error(msg, title, {
      closeButton: true,
      tapToDismiss: true,
      timeOut: 3000,
    });
  }

  public info(title: string, msg: string): void {
    this.toastr.overlayContainer = this.toastrContainer;
    this.toastr.info(msg, title, {
      closeButton: true,
      tapToDismiss: true,
      timeOut: 3000,
    });
  }

  public success(title: string, msg: string): void {
    this.toastr.overlayContainer = this.toastrContainer;
    this.toastr.success(msg, title, {
      closeButton: true,
      tapToDismiss: true,
      timeOut: 3000,
    });
  }
}
