<div class="user_profil_page fadeIn text_grey h-100 w-100 m-0">
  <div class="row m-0 h-100">
    <div class="col-4 pe-4 ps-2 pt-2 pb-2">
      <app-user-card class="w-100 h-100 d-flex" [user]="user"></app-user-card>
    </div>
    <div class="col-8 h-100">
      <div class="row">
        <div class="col-10 pb-4 align-items-center">
          <app-user-orga
            class="p-1"
            [orgaList]="organizations"
            (onSelect)="onOrgaSelected($event)"
            (afterInit)="onOrgaSelected($event)"
            [orgaSelected]="orgaSelected"></app-user-orga>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <app-businesses-info
                class="p-1"
                [businesses$]="businesses$"
                [user]="user"
                [favorite]="businessFavorite"></app-businesses-info>
            </div>
            <div class="col-6">
              <app-list-clusters
                class="p-1"
                [clusterList$]="clusterList$"
                [user]="user"></app-list-clusters>
            </div>
          </div>
        </div>
        <div class="col-12">
          <app-list-user
            *ngIf="
              user.roles[0] === 'ROLE_SUPER_ADMIN' ||
              user.roles[0] === 'ROLE_ADMIN' ||
              user.roles[0] === 'ROLE_SUPER_USER'
            "
            class="p-1"></app-list-user>
        </div>
      </div>
    </div>
  </div>
</div>
