import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Range } from '@app/models/';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiV2Service {
  constructor(private http: HttpClient) {}

  public askForNewPassword(mailOrNickname: string): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/ask/password`, {
      mailOrNickname: mailOrNickname,
    } as never);
  }

  /**
   * To change user password if forgot, only self
   *
   * @param password
   * @returns
   */
  public setResetPassword(userId: string, password: string, token: string): Observable<any> {
    return this.http.post<any>(`${environment.backendDomain}/dashboardV2/action/reset/password`, {
      userId: userId,
      password: password,
      token: token,
    } as never);
  }

  /**
   * To change user password directly, only self
   *
   * @param password
   * @returns
   */
  public setNewPassword(password: string): Observable<any> {
    return this.http.patch<any>(`${environment.backendDomain}/user/password/update`, {
      password: password,
    } as never);
  }

  public getNewPasswordToken(id: number, token: string): Observable<any> {
    return this.http.post<any>(
      `${environment.backendDomain}/dashboardV2/action/confirmation/token`,
      { id: id, token: token } as never,
    );
  }

  public getJourneyData(entityId: string, range: Range) {
    return this.http.get<any>(
      `${environment.backendDomain}/dashboard/journey/business/${entityId}?begin=${range.begin}&beginVs=${range.beginVs}&end=${range.end}&endVs=${range.endVs}`,
    );
  }

  public getRankData(entityId: string, range: Range) {
    return this.http.get<any>(
      `${environment.backendDomain}/dashboard/rank/cluster/${entityId}?begin=${range.begin}&beginVs=${range.beginVs}&end=${range.end}&endVs=${range.endVs}`,
    );
  }
}
