import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountingChartData, CountingData, Range, RangeType } from '@app/models/';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CountingService {
  constructor(private http: HttpClient) {}

  public getKpiData(businessId: number, format: RangeType, range: Range) {
    return this.http.get<CountingData>(
      `${environment.backendDomain}/dashboard/localBusiness/${businessId}/counting/data?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}&end=${range.end}&endVs=${range.endVs}`,
    );
  }

  public getChartData(businessId: number, format: RangeType, range: Range) {
    return this.http.get<CountingChartData>(
      `${environment.backendDomain}/dashboard/localBusiness/${businessId}/counting/chart?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}&end=${range.end}&endVs=${range.endVs}`,
    );
  }
}
