<div
  class="parcours_page fadeIn text_grey d-flex flex-column justify-content-start w-100 h-100 m-0 col-12 px-1 m-0">
  <div class="row d-flex m-0 align-items-center px-0">
    <div class="col-6 ps-0 mx-0">
      <app-counting-action-bar
        page="parcours"
        [showExport]="false"
        [colAuto]="true"></app-counting-action-bar>
    </div>
    <div class="col-6 pe-1">
      <app-journey-table-kpi
        [summaryData]="maxVisitor"
        [summaryDataVs]="maxVisitorVs"
        [summaryDataPercent]="maxVisitorPercent"
        [loading]="loading"
        (zone2DAccess)="goToZone2D()"
        (zone2DLeft)="leftZone2D()"
        [isOnZone2D]="isOnZone2D"></app-journey-table-kpi>
    </div>
  </div>
  <div
    *ngIf="!isOnZone2D; else zone2D"
    class="fadeIn row d-flex w-100 h-100 p-0 m-0 align-items-baseline pt-2">
    <div class="col-6 h-100 ps-1">
      <app-journey-table
        class="h-100 w-100"
        [rows]="rows"
        [columns]="columns"
        [loading]="loading"
        (select)="showData($event)"
        (shortEvent)="sortTable($event, null, activeTab)"
        (nativeTableShortEvent)="sortTable($event, null, activeTab)"
        [activeTab]="activeTab"
        [activeTabVs]="activeTabVs"
        [allTabs]="allKeys"
        [zippedColumns]="zippedColumns"
        [tableFilterState]="tableFilterState"
        dropStyle="rounded-end text_bold elevation-static-2 bg-white text-black"></app-journey-table>
    </div>
    <div class="col-6 h-100 pe-1">
      <app-journey-table
        class="h-100 w-100"
        [rows]="rowsVs"
        [columns]="columnsVs"
        [loading]="loading"
        (select)="showDataVs($event)"
        (shortEvent)="sortTable($event, null, activeTabVs, true)"
        (nativeTableShortEvent)="sortTable($event, null, activeTabVs, true)"
        [activeTab]="activeTabVs"
        [activeTabVs]="activeTab"
        [allTabs]="allKeys"
        [zippedColumns]="zippedColumnsVs"
        [tableFilterState]="tableFilterStateVs"
        dropStyle="rounded-end text_bold elevation-static-2 bg-white text-black "></app-journey-table>
    </div>
  </div>

  <ng-template #zone2D>
    <div class="fadeIn row d-flex w-100 h-100 p-0 m-0 align-items-baseline pt-2">
      <div class="col-12 w-100 h-100">
        <app-plan
          (select)="showDataAll($event)"
          [rows]="rows"
          [rowsVs]="rowsVs"
          [allTabs]="allKeys"
          [loading]="loading"
          [activeTab]="activeTab"
          [columns]="columns"
          [columnsVs]="columnsVs"
          [columnsToShow]="columnsToShow"></app-plan>
      </div>
    </div>
  </ng-template>
</div>
