import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Box, DateRangeApi } from '@app/models';
import { environment } from '@env/environment';
@Injectable({ providedIn: 'root' })
export class ZoneService {
  constructor(private http: HttpClient) {}

  public getAllZoneDataByBusinessWithRange(businessId: number, range: DateRangeApi) {
    range;
    return {
      current: this.http.get<Box[]>(
        `${environment.backendDomain}/dashboard/localbusinesses/${businessId}/allzonesdata`,
        { params: { begin: range.begin, end: range.end } as never },
      ),
      versus: this.http.get<Box[]>(
        `${environment.backendDomain}/dashboard/localbusinesses/${businessId}/allzonesdata`,
        {
          params: {
            begin: range.beginVs,
            end: range.endVs,
          } as never,
        },
      ),
    };
  }

  public getZone2d(id) {
    return this.http.get(`${environment.backendDomain}/zone2D/localBusiness/${id}`);
  }
}
