import { ElementRef, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataProvider } from '@app/providers';
import { AlertComponent } from '@app/modals/shared/alert/alert.component';
import { AlertModal } from '@app/models';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: NgbModal, private dataProvider: DataProvider) {}

  public open(content: ElementRef, ops?: NgbModalOptions, onClose?: () => {} | void): void {
    this.modalService.open(content, ops || {}).result.then(
      (close) => {
        if (onClose) {
          onClose();
        }
      },
      (backdrop) => {},
    );
  }

  public alert(content: AlertModal): void {
    this.dataProvider.setAlertModalContent(content);
    this.modalService.open(AlertComponent, {
      animation: true,
      centered: true,
    });
  }
}
