import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MathService {
  public upto2Decimal(num: any, force?: boolean): number {
    if (num === 0) return Number(0);
    if (num > 0) return Math.floor(num * 100) / 100;
    else return Math.ceil(num * 100) / 100;
  }

  public upto1Decimal(num: any, force?: boolean): number {
    if (num === 0) return Number(0);
    return this.onlyRealNumber(Math.floor(num * 10) / 10);
  }

  public getPercent(v1, v2) {
    if (!v1 && !v2) {
      return 0;
    }

    if (!v1) {
      // Matches +0, -0, NaN
      return 100;
    }
    if (!v2) {
      // Matches +0, -0, NaN
      return -100;
    }

    return this.onlyRealNumber(((v2 - v1) / v1) * 100);
  }

  public getAttractivityPercent(v1, v2) {
    if (!v1 && !v2) {
      return 0;
    }
    if (!v1) {
      // Matches +0, -0, NaN
      return 0;
    }
    if (!v2) {
      // Matches +0, -0, NaN
      return 0;
    }

    return this.onlyRealNumber((v1 / v2) * 100);
  }

  public getAverage(value, total) {
    if (!value && !total) {
      return 0;
    }
    if (!value) {
      // Matches +0, -0, NaN
      return 0;
    }
    if (!total) {
      // Matches +0, -0, NaN
      return 0;
    }

    return this.onlyRealNumber(value / total);
  }

  public getAttractivity(v1, v2) {
    if (!v1 && !v2) {
      return 0;
    }
    if (!v1) {
      // Matches +0, -0, NaN
      return 0;
    }
    if (!v2) {
      // Matches +0, -0, NaN
      return 0;
    }

    return this.onlyRealNumber(v1 / v2);
  }

  public onlyRealNumber(n) {
    if (!n) {
      // Matches +0, -0, NaN
      return 0;
    }
    if (n == Number.POSITIVE_INFINITY || n == Number.NEGATIVE_INFINITY) {
      return 0;
    }
    return n;
  }
}
