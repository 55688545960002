import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cluster, Organization, User } from '@app/models';
import { ApiService, ModalService } from '@app/services';
import { ClusterCreationComponent, ClusterEditionComponent } from '@app/modals';
import { DataProvider } from '@app/providers';
import { Observable } from 'rxjs';
import { RefreshProvider } from '@app/providers/layout';

@Component({
  selector: 'app-list-clusters',
  templateUrl: './list-clusters.component.html',
  styleUrls: ['./list-clusters.component.scss'],
})
export class ListClustersComponent implements OnInit {
  @Input() clusterList$: Observable<Cluster[]>;
  @Input() user: User;

  public searchValue: string;
  public organization: Organization;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private modalService: ModalService,
    private dataProvider: DataProvider,
    private apiService: ApiService,
    private resfreshProvider: RefreshProvider,
  ) {}

  ngOnInit(): void {
    this.dataProvider
      .getLastOrganizationSelected()
      .subscribe((value) => (this.organization = value));
  }

  public openDetails(cluster: Cluster): void {
    this.ngZone.run(() => this.router.navigate(['/details', { id: cluster.id, type: 'cluster' }]));
  }

  public editCluster(cluster: Cluster): void {
    this.dataProvider.setLastClusterSelected(cluster);
    this.modalService.open(
      ClusterEditionComponent as never as ElementRef,
      {
        animation: true,
      },
      () => {
        this.refresh.bind(this)();
      },
    );
  }

  public addCluster(): void {
    this.modalService.open(
      ClusterCreationComponent as never as ElementRef,
      {
        animation: true,
      },
      () => {
        this.refresh.bind(this)();
      },
    );
  }

  private refresh(): void {
    if (this.organization) {
      this.clusterList$ = this.apiService.cluster.getClusterByOrganizationId(this.organization.id);
    } else {
      this.clusterList$ = this.apiService.cluster.getCluster();
    }
    this.resfreshProvider.emit({
      'profil-shared-components': {},
    });
  }

  public deleteCluster(item: Cluster): void {
    this.modalService.alert({
      title: 'clusterDelete',
      subTitle: 'clusterDeleteInfo',
      fn: this.deleteClusterFn.bind(this, item),
      fnDescription: 'clusterDeleteAction',
    });
  }

  private deleteClusterFn(item: Cluster): void {
    this.apiService.cluster.deleteCluster(item.id).subscribe(() => {
      this.refresh();
    });
  }
}
