<div class="form-floating rounded elevation-static-1">
  <div class="form-control text-start h-auto">
    <div class="form-check form-check-inline" *ngFor="let choice of choices; let i = index">
      <input
        class="form-check-input"
        type="checkbox"
        [id]="choice + '_' + i + '_' + _index"
        [value]="choice"
        (input)="fn($event, choice)"
        [disabled]="isOneIsDisabled ? i === isOneIsDisabled.index : false" />
      <div class="d-flex flex-column">
        <label class="form-check-label" [for]="choice + '_' + i + '_' + _index">{{
          choice | translate
        }}</label>
        <p
          class="text-muted"
          *ngIf="explaination && explaination[i]"
          [innerHTML]="explaination[i] | translate"></p>
      </div>
    </div>
  </div>
  <label *ngIf="label" id="inputGroup-sizing-default">{{ label | translate }}</label>
</div>
