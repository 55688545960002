/* tslint:disable */
import { Pipe, PipeTransform } from '@angular/core';
import { decrypt } from '@app/services';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value: string | []): any {
    if (typeof value === 'string') {
      return { key: value, value };
    }
    let keys = [];
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}

@Pipe({ name: 'maskPassword' })
export class MaskPasswordPipe implements PipeTransform {
  transform(value: string, needMask?: boolean): any {
    return needMask ? Array(20).join('*') : decrypt(value);
  }
}
