import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegularExpressionConstant, RoleConstant } from '@app/const';
import { Businesses, Organization, Cluster, UserApi } from '@app/models';
import { DataProvider } from '@app/providers';

import { ApiService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { crossFieldsValidatorPassword } from '@app/services/validators.service';
import { RefreshProvider } from '@app/providers/layout';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss'],
})
export class UserCreationComponent {
  public loading = false;
  public userCreationForm: UntypedFormGroup;
  public applyMask = { password: false, confirmPassword: false };

  public submitted = false;

  public errors: {
    organizations: boolean;
    businesses: boolean;
  } = { businesses: false, organizations: false };
  public organizations: Organization[] = [];
  public businesses: Businesses[] = [];
  private _businesses: Businesses[];

  public clusterList: any[] = [];
  public _clusterList: Cluster[] = [];

  public _organizationId: any = [];
  public _businessesId: [] = [];

  public searchValueBusinesses: string;
  public searchValueOrga: string;

  get organizationFormArray(): UntypedFormArray {
    return this.userCreationForm.controls.organizationId as UntypedFormArray;
  }
  get businessesIdFormArray(): UntypedFormArray {
    return this.userCreationForm.controls.businessesId as UntypedFormArray;
  }

  public roles = RoleConstant;

  constructor(
    private apiService: ApiService,
    public formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProvider,
    private refreshProvider: RefreshProvider,
  ) {
    this.userCreationForm = this.formBuilder.group(
      {
        newUsername: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        newPlainPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
        newPlainPasswordConfirmation: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            Validators.minLength(8),
            Validators.pattern(RegularExpressionConstant.PASSWORD),
          ]),
        ],
        newEmail: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(RegularExpressionConstant.EMAIL),
          ]),
        ],
        roles: ['', Validators.required],
      },
      { validators: crossFieldsValidatorPassword },
    );
    this.dataProvider.getOrganization().subscribe((data) => {
      this.organizations = data;
    });

    this.dataProvider.getBusinesses().subscribe((_data) => {
      this._businesses = _data;
    });

    this.dataProvider.getClusters().subscribe((__data) => {
      this.clusterList = __data;
    });
  }

  public onSubmit(): void {
    this.submitted = true;

    this.userCreationForm.markAsDirty();
    if (
      this.userCreationForm.valid &&
      this._organizationId.length > 0 &&
      this._businessesId.length > 0
    ) {
      const user = {
        email: this.userCreationForm.value.newEmail,
        username: this.userCreationForm.value.newUsername,
        roles: [this.userCreationForm.value.roles],
        plainPassword: this.userCreationForm.value.newPlainPassword,
        organizationId: this._organizationId,
        localBusinessId: this._businessesId,
        localBusinessClusterId: this._clusterList,
      };
      this.apiService.user.createUserAndRelation(user).subscribe(() => {
        this.submitted = false;
        this.refreshProvider.emit({
          'profil-shared-components': {},
        });
        this.activeModal.close();
      });
    }
    if (this._organizationId.length === 0) {
      this.errors.organizations = true;
    }
    if (this._businessesId.length === 0) {
      this.errors.businesses = true;
    }
    setTimeout(() => {}, 4000);
  }

  public selectAllOrganizations(): void {
    this._organizationId = this.organizations.map((orga: any) => orga.id);
    this.filterBusinessByOrga();
  }

  public unselectAllOrganizations(): void {
    this._organizationId = [];
    this.filterBusinessByOrga();
  }

  public selectAllClusters(): void {
    this._clusterList = this.clusterList.map((cluster) => cluster.id);
  }

  public unselectAllClusters(): void {
    this._clusterList = [];
  }

  public unselectAllBusinesses(): void {
    this._businessesId = [];
  }

  public selectAllBusinesses(): void {
    let _holder: any = [];
    this._organizationId.forEach((orgaId) => {
      _holder = [
        ..._holder,
        ...this._businesses.filter((business) => business.organization === orgaId),
      ];
    });
    this._businessesId = _holder.map((value) => value.id);
  }

  public filterBusinessByOrga(): void {
    let _holder = [];
    this._organizationId.forEach((orgaId) => {
      _holder = [
        ..._holder,
        ...this._businesses.filter((business) => business.organization === orgaId),
      ];
    });

    // let __holder = [];
    // this._organizationId.forEach((orgaId) => {
    //     this.apiService.cluster
    //         .getClusterByOrganizationId(orgaId)
    //         .subscribe((cluster) => {
    //             __holder = [...__holder, ...cluster];
    //             this.clusterList = __holder;
    //         });
    // });

    this.businesses = _holder;

    if (this._organizationId.length === 0) {
      this.businesses = [];
      this.clusterList = [];
    }
  }

  public toogleMask(key: string): void {
    this.applyMask[key] = !this.applyMask[key];
  }
  public closeModal(): void {
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
