import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FabricPlan2D, ColorMap } from '@app/services/canvas';
import { ZoneService } from '@app/services/api/zone.service';

import { StatsProvider } from '@app/providers';
import { BusinessService, DateHelperService } from '@app/services';
import { throwError } from 'rxjs';
import { switchMap, takeUntil, tap, catchError } from 'rxjs/operators';
import { customNumberFilter } from '@app/pipes';
import { fabric } from 'fabric';
import { PanZoomAPI, PanZoomConfig } from 'ngx-panzoom';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Destroy } from '@app/hooklife';
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  viewProviders: [Destroy],
})
export class PlanComponent implements AfterViewInit {
  @Input() rows;
  @Input() rowsVs; // date de comparaison (bleu)
  @Input() columns;
  @Input() columnsVs;
  @Input() activeTab;
  @Input() allTabs;
  @Input() loading;

  public panZoomConfig: PanZoomConfig = new PanZoomConfig({
    dynamicContentDimensions: true,
  });
  private panZoomAPI: PanZoomAPI;

  public customNumberFilter = customNumberFilter;

  public selectedzoneInfo: any = {};
  public isZoneSelected = false;

  @ViewChild('planCanvasContainer')
  planCanvasContainer: ElementRef;
  @ViewChild('planCanvas')
  planCanvas: ElementRef;

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  @ViewChild('planCanvasToolTip')
  _canvas_toolTip: ElementRef;

  private options;
  public $options;
  zones;
  private canvas: fabric.Canvas;
  public unavailable = false;

  private background;

  private defaultContactIVS = '<br>Veuillez contacter votre manager IVS.';

  @Output() select = new EventEmitter();
  constructor(
    private zoneService: ZoneService,
    private fabricService: FabricPlan2D,
    private colorMapService: ColorMap,
    private statsProvider: StatsProvider,
    private businessService: BusinessService,
    private dateHelperService: DateHelperService,
    private toastrService: ToastrService,
    private readonly destroy$: Destroy,
  ) {}

  ngAfterViewInit() {
    this.statsProvider
      .getOptions()
      .pipe(
        takeUntil(
          this.destroy$.pipe(
            tap(() => {
              if (this.canvas) {
                this.fabricService.destroyAndClean();
              }
            }),
          ),
        ),
        tap(() => {
          if (this.canvas) {
            this.fabricService.destroyAndClean();
          }
          this.loading = true;
          this.unavailable = false;
          this.toastrService.overlayContainer = this.toastContainer;
        }),
        switchMap((options: any) => {
          this.options = options;
          return this.businessService.getBackground2d(options.targetData.id);
        }),
        switchMap((background: any) => {
          this.background = background;
          return this.zoneService.getZone2d(this.options.targetData.id);
        }),
        tap((zones) => {
          let _2Dzone = (zones as any[]).filter((value) => value?.data_2d);

          var IsZoneData = false;
          if (this.background?.data !== null && _2Dzone) {
            IsZoneData = true;
          }

          if (IsZoneData) {
            this.planCanvas.nativeElement.width =
              this.planCanvasContainer.nativeElement.offsetWidth;
            this.planCanvas.nativeElement.height =
              this.planCanvasContainer.nativeElement.offsetHeight;

            this.generateCanvas(this.background, zones);
            this.loading = false;
            this.unavailable = !IsZoneData;
            setTimeout(() => {
              this.canvasActions('refresh');
            });
          } else {
            this.loading = false;
            this.unavailable = !IsZoneData;
            this.fabricService.destroyAndClean();
          }
        }),
        switchMap(() =>
          this.panZoomConfig.api.pipe(
            takeUntil(this.destroy$),
            tap((api: PanZoomAPI) => (this.panZoomAPI = api)),
          ),
        ),
        catchError((err) => {
          if (err) {
            // this.showError('Le plan 2D ne peut etre affiché.');
            this.loading = false;
            this.unavailable = true;
            return throwError(err);
          }
        }),
      )
      .subscribe();
  }

  public getActiveToHuman(): string {
    if (this.activeTab) {
      return 'customerJourneyPage.actionBar.' + this.activeTab;
    } else {
      return 'loading';
    }
  }

  public resetZoom() {
    this.panZoomAPI.resetView();
  }

  public zoom() {
    this.panZoomAPI.zoomIn();
  }

  public unZoom() {
    this.panZoomAPI.zoomOut();
  }

  public setDefaultView() {
    const canvasSize = { w: this.canvas.width, h: this.canvas.height };
    this.panZoomAPI.zoomToFit({
      x: 0,
      y: 0,
      height: canvasSize.h,
      width: canvasSize.w,
    });
  }

  private generateCanvas(background, zones) {
    if (!this.canvas) {
      this.canvas = this.fabricService.generate('plan_canvas');
    } else {
      this.canvas.renderAll();
    }

    this.fabricService.background(background?.data).then(() => {
      const bgSize = this.fabricService.getBackgroundSize();

      this.fabricService.setCanvasSize(bgSize.width, bgSize.height, 1.2);
      this.zones = zones;

      this.canvasActions('init');

      this.fabricService.gethoverElement().subscribe((value) => {
        if (value.eventType === 'in') {
          this.isZoneSelected = true;
          const selectedZoneJourneyData =
            this.rows.filter((row) => row.id + '' === value.id + '')[0] || [];

          const transformed = {};

          Object.keys(selectedZoneJourneyData).forEach((_keys) => {
            transformed[_keys] = selectedZoneJourneyData[_keys].toLocaleString('Fr-fr');
          });
          this.selectedzoneInfo = transformed;
        } else {
          this.isZoneSelected = false;
        }
      });

      setTimeout(() => {
        this.canvas.renderAll();
      });
    });
  }

  public onKpiSelect(item) {
    this.activeTab = item;
    this.select.emit(item);
    setTimeout(() => {
      this.canvasActions('refresh');
    }, 250);
  }

  public canvasActions(action: 'refresh' | 'init') {
    if (action === 'refresh') {
      this.prepareData(this.refreshCanvasData, action);
    } else {
      this.prepareData(this.createCanvasElement, action);
    }
    this.setDefaultView();
  }

  public prepareData(callBackObjectCreationOrInit: any, action: 'refresh' | 'init') {
    var _rowsCopy = [...this.rows];
    var rowsCopy = [];

    // Firt pass on all zone to calcuate data
    this.zones.map((zone) => {
      this.allTabs.forEach((tab: any, index) => {
        if (zone.data_2d !== null && zone.data_2d.poly_coord) {
          rowsCopy = _rowsCopy.map((value) => {
            if (tab === 'averageStop') {
              return {
                value: value['_RAW_' + tab],
                id: value.id,
              };
            } else {
              return {
                value: value[tab],
                id: value.id,
              };
            }
          });

          var percentValue = this.colorMapService.convertValueToHeatmapColor(rowsCopy);

          const colorId = Math.trunc(
            percentValue.find((value) => value.id + '' === zone.id + '')?.value,
          );

          zone['isDisplayed'] = true;
          zone['percentValue'] = percentValue;
          zone['canvasColor_' + tab] = this.colorMapService.getHeatmapById(colorId - 1);

          this.rows[this.rows.findIndex((value) => value.id + '' === zone.id + '')][
            'canvasColor_' + tab
          ] = this.colorMapService.getHeatmapById(colorId - 1);

          if (tab === this.activeTab) {
            this.setDataForActualView(zone, colorId, callBackObjectCreationOrInit);
          }
        }
      });

      return zone;
    });
  }

  private setDataForActualView(zone, colorId: number, callBackObjectCreationOrInit) {
    // Zone data init part
    let _zone: any = {
      poly_coord: zone.data_2d.poly_coord,
      name_coord: zone.data_2d.name_coord,
      name: zone.name,
      color: zone.color,
      id: zone.id,
    };
    _zone.poly_coord.push(_zone.poly_coord[0]);

    // Zone rows data init part
    const row = this.rows.filter((_row) => _row.id + '' === _zone.id + '')[0];

    // create poly gradiant part

    callBackObjectCreationOrInit.bind(this)(
      _zone,
      row,
      this.colorMapService.getHeatmapById(colorId - 1),
    );
  }

  public refreshCanvasData(_zone, row, background) {
    this.fabricService.editPolyBackground(_zone.id, background);
    this.fabricService.render();
  }

  public createCanvasElement(_zone, row, background) {
    const polyline = this.fabricService.addPolyline(
      _zone.poly_coord,
      true,
      background,
      background,
      _zone.id,
    );

    this.fabricService.addAndRender(polyline);
  }
}
