<div *ngIf="!($isError | async); else confirmResetError">
  <div *ngIf="($showWaitingToken | async) === true; else tokenVerified">
    <div class="d-flex flex-column align-items-center">
      <div class="p-1">
        <div class="spinner-border" role="status"></div>
      </div>
      <div>{{ 'forgot-password.sendMailIsSend' | translate }}</div>
      <div>{{ 'forgot-password.sendMailIsSend-2' | translate }}</div>
    </div>
  </div>
  <ng-template #tokenVerified>
    <div class="d-flex flex-column align-items-center">
      <div class="p-1">
        <div class="spinner-border" role="status"></div>
      </div>
      <div>{{ 'forgot-password.sendMailIsConfirm' | translate }}</div>
      <div>{{ 'forgot-password.sendMailIsConfirm-2' | translate }}</div>
    </div>
  </ng-template>
</div>
<ng-template #confirmResetError>
  <div class="d-flex flex-column p-1 w-100 text-center">
    <div class="col-12 py-2">
      {{ 'forgot-password.sendMailError' | translate }}
    </div>

    <div>
      <div class="py-1 float-right">
        <button type="button" class="btn btn_black" (click)="quit()">
          {{ 'resetPage.confirmReset.action.quit' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
