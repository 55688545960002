import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ApiProvider, UserProvider } from '@app/providers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userProvider: UserProvider,
    private router: Router,
    private apiProvider: ApiProvider,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userProvider.isUserDataExist().pipe(
      map((value) => {
        if (!value['isAllMainDataFetched']) {
          this.apiProvider.fetchAllMainData().subscribe(() => {
            this.userProvider.updateUserData({
              isAllMainDataFetched: true,
              isFirstStart: false,
            });
          });
        }
        if (!value['isLogged'] && !value['jwt']) {
          this.router.navigate(['login']);
        }
        return true;
      }),
    );
  }
}
