<div class="counting-action-bar d-flex flex-row justify-content-center align-items-center w-100">
  <div class="row d-flex m-0 align-items-center w-100">
    <div class="col-2 p-0 m-0">
      <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="clusterDropDown">{{
        'rankingPage.clusterChoice' | translate
      }}</label>
      <div id="clusterDropDown" ngbDropdown class="d-inline-block w-100">
        <button
          class="w-100 btn btn-light rounded text-center elevation-static-2 bg-white"
          [disabled]="!loading ? false : true"
          ngbDropdownToggle>
          {{
            actualCluster && !loading
              ? actualCluster
              : loading
              ? ('loading' | translate)
              : ('rankingPage.emptyState' | translate)
          }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="clusterList">
          <button
            (click)="onSelect({ type: 'cluster', value: cluster.id })"
            *ngFor="let cluster of clusterList"
            ngbDropdownItem>
            {{ cluster.name | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 ps-0 ms-2 me-0 pe-0">
      <label class="text-muted mb-1 pb-0 pt-0 mt-0" for="kpiDropDown">{{
        'rankingPage.kpiChoice' | translate
      }}</label>
      <div id="kpiDropDown" ngbDropdown class="d-inline-block w-100">
        <button
          class="w-100 btn btn-light rounded text-center elevation-static-2 bg-white"
          ngbDropdownToggle
          [disabled]="!loading ? false : true">
          {{
            actualKpi && !loading
              ? (actualKpi | translate)
              : loading
              ? ('loading' | translate)
              : ('rankingPage.emptyState' | translate)
          }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            (click)="onSelect({ type: 'kpi', value: kpiKey })"
            *ngFor="let kpiKey of kpiList"
            [disabled]="disabledKpi.includes(kpiKey)"
            ngbDropdownItem>
            {{ kpiKey | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
