/* tslint:disable */
declare global {
  interface Array<T> {
    SumMultidimentionalChartArray(array: any): any;
    ReduceArrayOfObject(array: any): any;
    ReduceArrayOfTimeObject(array: any): any;
    SumMultiCegidArray(array: any): any;
    toCrossProductResult(mesured): any;
    RemoveByValue(value): Array<T>;
  }
}

Array.prototype.RemoveByValue = function (value) {
  return this.filter(function (ele) {
    return ele != value;
  });
};

Array.prototype.toCrossProductResult = function (mesured) {
  const onlyValue = this.map((value) => value.value || 0);
  const minVal = Math.min(...onlyValue);
  const maxVal = Math.max(...onlyValue);
  const normalizedArray = this.map((_value) => {
    return {
      id: _value.id,
      value: (100 * (_value.value - minVal)) / (maxVal - minVal),
    };
  });
  const normalizedOnlyValue = normalizedArray.map((value) => value.value);
  const normalizedMinVal = Math.min(...normalizedOnlyValue);
  const normalizedMaxVal = Math.max(...normalizedOnlyValue);

  const crossed = (min, max) => (value) => {
      const _crossed = (mesured * value.value) / max;
      return isNaN(_crossed)
        ? {
            id: value.id,
            value: 0,
          }
        : {
            id: value.id,
            value: Math.trunc(_crossed),
          };
    },
    mappingToPercent = crossed(normalizedMinVal, normalizedMaxVal);
  return normalizedArray.map(mappingToPercent);
};

Array.prototype.SumMultidimentionalChartArray = function (array) {
  const new2DArray = [[], []];
  array.forEach((subArray) => {
    if (!subArray) {
      return;
    }
    subArray.forEach((num, dimensionalIndex) => {
      num.data.forEach((value, valueIndex) => {
        if (new2DArray[dimensionalIndex][valueIndex]) {
          new2DArray[dimensionalIndex][valueIndex] += value || 0;
        } else {
          new2DArray[dimensionalIndex][valueIndex] = value || 0;
        }
      });
    });
  });
  return new2DArray;
};

Array.prototype.SumMultiCegidArray = function (array) {
  const newObject = { tickets: 0, ca: 0 };

  array.forEach((num) => {
    newObject['tickets'] += Number(num['tickets']) || 0;
    newObject['ca'] += Number(num['ca']) || 0;
  });
  return newObject;
};

Array.prototype.ReduceArrayOfTimeObject = function (array) {
  return array.reduce(function (previousValue, currentValue) {
    return {
      current: Number(previousValue.current) || 0 + Number(currentValue.current) || 0,
      versus: Number(previousValue.versus) || 0 + Number(currentValue.versus) || 0,
    };
  });
};

Array.prototype.ReduceArrayOfObject = function (array) {
  return array.reduce(function (previousValue, currentValue) {
    previousValue;
    return {
      current: previousValue.current || 0 + currentValue.current || 0,
      currentVideoPassages:
        previousValue.currentVideoPassages || 0 + currentValue.currentVideoPassages || 0,
      currentVideoVisits:
        previousValue.currentVideoVisits || 0 + currentValue.currentVideoVisits || 0,
      currentWifiPassages:
        previousValue.currentWifiPassages || 0 + currentValue.currentWifiPassages || 0,
      currentWifiVisits: previousValue.currentWifiVisits || 0 + currentValue.currentWifiVisits || 0,
      versus: previousValue.versus + currentValue.versus,
      versusVideoPassages:
        previousValue.versusVideoPassages || 0 + currentValue.versusVideoPassages || 0,
      versusVideoVisits: previousValue.versusVideoVisits || 0 + currentValue.versusVideoVisits || 0,
      versusWifiPassages:
        previousValue.versusWifiPassages || 0 + currentValue.versusWifiPassages || 0,
      versusWifiVisits: previousValue.versusWifiVisits || 0 + currentValue.versusWifiVisits || 0,
    };
  });
};

export {};
