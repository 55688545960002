<div *ngIf="!($isError | async); else errorAskReset" class="d-flex flex-column p-1">
  <div class="h5 d-flex text-center text-dark">
    {{ 'forgot-password.intro' | translate }}
  </div>
  <div class="d-flex text-left py-2">
    {{ 'forgot-password.forgot-explain' | translate }}
  </div>
  <div class="d-flex text-left py-2">
    {{ 'forgot-password.forgot-explain-2' | translate }}
  </div>
  <div class="d-flex text-left text-muted py-2">
    {{ 'forgot-password.forgot-explain-4' | translate }}
  </div>
  <div class="d-flex text-left text-muted py-2">
    {{ 'forgot-password.forgot-explain-5' | translate }}
  </div>
  <div class="px-2 py-2 border-top border-bottom">
    <form [formGroup]="askPasswordForm" (ngSubmit)="onSubmit()">
      <div class="d-flex flex-column align-items-center w-100 h-100">
        <div class="form-group col-8">
          <input
            type="text"
            formControlName="usernameOrEmail"
            class="form-control input_default"
            placeholder="{{ 'usernameOrEmail' | translate }}"
            [ngClass]="{
              'is-invalid': submitted && askPasswordForm.controls.usernameOrEmail.errors
            }" />
          <div
            *ngIf="submitted && askPasswordForm.controls.usernameOrEmail.errors"
            class="invalid-feedback">
            <div *ngIf="askPasswordForm.controls.usernameOrEmail.errors.required">
              {{ 'feedback.usernameRequired' | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row justify-content-start">
    <div class="py-1 w-auto">
      <button type="button" class="btn btn_black" (click)="goLogin()">
        {{ 'resetPage.askReset.action.cancel' | translate }}
      </button>
    </div>
    <div class="py-1 w-auto ms-auto">
      <button type="button" class="btn btn_black" (click)="onSubmit()">
        {{ 'resetPage.askReset.action.continue' | translate }}
      </button>
    </div>
  </div>
</div>
<ng-template #errorAskReset>
  <div class="d-flex flex-column p-1 w-100 text-center">
    <div *ngIf="($error | async) === 'ask-reset-sending-email'" class="col-12 py-2">
      {{ 'forgot-password.sendMailError' | translate }}
    </div>

    <div class="row justify-content-start">
      <div class="py-1 w-auto">
        <button type="button" class="btn btn_black" (click)="back()">
          {{ 'resetPage.askReset.action.cancel' | translate }}
        </button>
      </div>
      <div class="py-1 w-auto ms-auto">
        <button type="button" class="btn btn_black" (click)="reset()">
          {{ 'resetPage.askReset.action.reset' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
