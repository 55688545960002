<div class="container-fluid p-0 h-100">
  <div class="row d-flex h-100 m-0">
    <app-navbar class="_col_demi d-flex w-100 p-0 m-0"></app-navbar>
    <div class="main d-flex p-0 col h-100 flex_demi ms-auto">
      <div class="rows-col h-100 w-100 m-0 p-0 d-flex flex-column">
        <app-breadcrumb class="w-100"></app-breadcrumb>
        <router-outlet *ngIf="($userData | async)['isAllMainDataFetched']"></router-outlet>
      </div>
    </div>
  </div>
</div>
