import { AgeGenderData } from './AgeGenderData';
import { LocalBusinessFinancial } from './Counting';

type CountingChart = {
  chartAgeGender: AgeGenderData;
  chartVisits: Record<string, [number, number]>;
  chartCa: Record<string, number>;
  chartTicket: Record<string, number>;
  chartTransformRate: Record<string, number>;
};

export type CountingChartData = {
  current: CountingChart;
  versus: CountingChart;
  localBusiness: LocalBusinessFinancial;
  groupEnabled: boolean;
};

type ChartConfig = {
  title: string;
  icon: string;
  type: string;
  config: {
    labels: string[];
    datasets: Record<string, unknown>[];
  };
  options: any;
};

export type ChartsData = {
  salesRevenue: ChartConfig;
  attendanceTime: ChartConfig;
  attractivenessRate: ChartConfig;
  entryNumber: ChartConfig;
  billTickets: ChartConfig;
  transformRate: ChartConfig;
};

export enum ChartPosition {
  first = 0,
  second = 1,
  firstDashed = 2,
  secondDashed = 3,
}

export type CustomLabel = {
  current: string[];
  versus: string[];
};

export type ChartToDisplay = 'entryNumber' | 'billTickets' | 'salesRevenue' | 'transformRate';
