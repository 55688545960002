<div class="border border-black">
  <ngx-datatable
    class="bootstrap h-auto striped fixed-header scroll-vertical _full_cell_width"
    style="height: 250px !important"
    [columnMode]="ColumnMode.force"
    [columns]="columns"
    [headerHeight]="50"
    [footerHeight]="0"
    [rowHeight]="50"
    [count]="40"
    [limit]="5"
    [summaryHeight]="0"
    [summaryRow]="false"
    [virtualization]="false"
    [scrollbarV]="true"
    [offset]="0"
    [rows]="rows"
    [messages]="{
      emptyMessage: 'export-push-mail.maillingListempty' | translate
    }">
    <ngx-datatable-column
      name="Liste d'utilisateur"
      [cellClass]="'w-100 align-items-center d-flex'">
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
        cellClass="w-100">
        <div
          *ngIf="!editing[rowIndex + '-email']; else editionMode"
          class="d-flex align-items-center w-100">
          <span
            title="Double click to edit"
            (dblclick)="editing[rowIndex + '-email'] = true"
            class="col-10 me-auto">
            {{ row['email'] }}
          </span>
          <button
            type="button"
            class="btn btn-outline-grey elevation-static-1"
            aria-label="Edition"
            (click)="deleteValue(row['email'])">
            <img width="20px" src="/assets/svg/Trash.svg" />
          </button>
          <button
            type="button"
            class="btn btn-outline-grey elevation-static-1"
            aria-label="Edition"
            (click)="editing[rowIndex + '-email'] = true">
            <i class="bi bi-pencil"></i>
          </button>
        </div>
        <ng-template #editionMode>
          <div class="d-flex align-items-center">
            <input
              autofocus
              (blur)="updateValue($event, 'email', rowIndex)"
              type="text"
              [value]="row['email']"
              class="col-11 me-auto" />
            <button
              type="button"
              class="btn btn-outline-grey elevation-static-1"
              aria-label="Edition"
              (click)="editing[rowIndex + '-email'] = false">
              <i class="bi bi-check"></i>
            </button>
          </div>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
