import { Component, OnInit } from '@angular/core';
import { AlertModal } from '@app/models';
import { DataProvider } from '@app/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  public content: AlertModal;

  private sub: Subscription;
  constructor(private dataProvider: DataProvider, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.sub = this.dataProvider
      .getAlertModalContent()
      .subscribe((content) => (this.content = content));
  }

  public closeModal(): void {
    this.sub.unsubscribe();
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.sub.unsubscribe();
    this.activeModal.dismiss();
  }
}
