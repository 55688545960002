import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { GeocoderProvider } from '@app/providers';
import { TableHelperService } from '@app/services';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-short-table',
  templateUrl: './short-table.component.html',
  styleUrls: ['./short-table.component.scss'],
})
export class ShortTableComponent implements OnInit {
  @Input() rows;
  @Input() columns;
  @Input() zippedColumns;
  @Input() loading;

  @Input() dropStyle;
  @Input() buttonStyle;
  @Input() cardStyle;

  @Input() summaryData;
  @Input() tableFilterState;

  @Output() shortEvent = new EventEmitter();
  @Output() nativeTableShortEvent = new EventEmitter();

  public SelectionType = SelectionType;
  public selected = [];

  @ViewChild(DatatableComponent) // import {DatatableComponent} from '@swimlane/ngx-datatable';
  private readonly table: DatatableComponent;

  public searchValue: string = '';
  public emptyState = 'rankingPage.noRankResult';
  public noResultState = 'actions.search.noResult';

  public dataToShow: any;
  public ColumnMode = ColumnMode;

  public customHeaderTranslation = {
    fluctuation: 'Pt',
    fluctuationBillTickets: 'Ticket',
    fluctuationTransform: 'Pt',
    fluctuationSalesRevenue: '€',
    fluctuationAttendanceTime: 'Temps',
    fluctuationAttractiveness: 'Pt',
    fluctuationEntryNumber: 'Entrée',
  };

  constructor(
    private tableHelperService: TableHelperService,
    private geocoder: GeocoderProvider,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.getSelected();
  }

  public getData(): any {
    return this.searchValue !== ''
      ? this.tableHelperService.filterTableData(this.searchValue as never, this.rows, 'name', true)
      : this.rows;
  }

  public tableFilter(event: KeyboardEvent): void {
    if (typeof event.target['value'] === 'string' && typeof event.target['value'] !== 'object') {
      this.searchValue = event.target['value'];
    } else {
      this.searchValue = '';
    }
  }
  public onSelect({ selected }) {
    this.geocoder.setSelectedData(selected[0] ?? []);
  }

  public getSelected(): void {
    this.geocoder.getSelectedData().subscribe((value) => {
      if (value && this.table) {
        this.selected = [];
        this.table.selected = [];
        this.selected = [value];
        this.table.selected = [value];
      } else {
        this.selected = [];
        this.selected = [...this.selected];
      }

      this.rows = [...this.rows];
      this.columns = [...this.columns];
    });
  }

  public singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  public getRowIndex(row: any): number {
    return this.table.bodyComponent.getRowIndex(row); // row being data object passed into the template
  }

  public onShort(item: string) {
    this.table.sorts = [];
    this.shortEvent.emit(item);
  }

  public onNativeShort(item: any) {
    this.table.sorts = [];
    this.nativeTableShortEvent.emit(item.column.prop);
  }
  // Listen click in and out
  @HostListener('document:click', ['$event'])
  private clickOutThisComponent(event) {
    if (
      this.elementRef.nativeElement.contains(event.target) ||
      document.getElementById('dash-short-map').contains(event.target)
    ) {
    } else {
      this.geocoder.setSelectedData(null);
    }
  }
}
