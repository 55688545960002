<div class="row d-flex m-0 align-items-center w-100 modal_range_date_selection_export">
  <div
    *ngIf="rangeType === 'day' || rangeType === 'week' || rangeType === 'custom'"
    class="col-12 p-0 m-0">
    <div class="form-group _absolute-top">
      <div class="input-group">
        <input
          [maxDate]="dateLimit"
          name="datepicker"
          class="form-control invisible"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)"
          [footerTemplate]="footerTemplate"
          [displayMonths]="2"
          [dayTemplate]="t"
          outsideDays="hidden"
          [startDate]="fromDate!"
          [markDisabled]="isDisabled.bind(this)"
          tabindex="-1"
          [showWeekNumbers]="true"
          placement="bottom" />
        <ng-template #footerTemplate>
          <hr class="my-0" />
          <div class="btn-group d-flex flex-row justify-content-end mb-2" role="group">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="today()">
              Aujourd'hui
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              (click)="datepicker.toggle()">
              Fermer
            </button>
          </div>
        </ng-template>
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.text-muted]="isDisabled(date)"
            [ngClass]="{
              _is_date_range: isRenderAsDateRange(date),
              _is_date_faded: isHovered(date) || isInside(date)
            }"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <input
          #dpFromDate
          class="form-control text-truncate rounded text-center text_bold elevation-static-1 bg-white text_orange"
          placeholder="dd/mm/yyyy"
          name="dpFromDate"
          [value]="formatInput(fromDate)"
          (input)="fromDate = validateInput(fromDate, dpFromDate?.value)" />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary border-0 rounded calendar position-relative elevation-1"
            (click)="datepicker.toggle()"
            type="button">
            <svg-icon
              class="_range_export_calendar"
              src="/assets/svg/CalendarDay.svg"
              alt="user"></svg-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="rangeType === 'month'" class="col-12 p-0 m-0">
    <form class="d-flex flex-row align-items-center justify-content-center p-2">
      <div class="form-group d-flex flex-row">
        <div class="_input_datepicker_only_picker _input_datepicker_month text-center">
          <div class="text_bold h-auto text-center px-2" name="dpFromDate">
            <ngb-datepicker
              #datepicker
              class="text-center text_bold elevation-static-1 bg-white text_orange"
              [maxDate]="dateLimit"
              [startDate]="modelNgDatePicker"
              (navigate)="fromDateMonthSelection($event.next)"></ngb-datepicker>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="rangeType === 'year'" class="col-12 p-0 m-0">
    <form class="d-flex flex-column align-items-center">
      <div class="form-group d-flex flex-row">
        <div class="_input_datepicker_only_picker _input_datepicker_year text-center">
          <div class="text_bold h-auto text-center" name="dpFromDate">
            <ngb-datepicker
              class="border-0 rounded text-center text_bold elevation-static-1 bg-white text_blue"
              #datepicker
              [startDate]="modelNgDatePicker"
              (navigate)="fromDateYearSelection($event.next)"></ngb-datepicker>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
