<div class="modal-content modal_export_data_selection p-3">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'actions.data.export' | translate }}
  </h4>
  <div class="modal-body border-dark border-top">
    <form *ngIf="exportSelectionForm" [formGroup]="exportSelectionForm">
      <div class="row justify-content-start">
        <div class="form-group col-12 mb-3">
          <app-checkbox
            [label]="'actions.data.format'"
            [choices]="['actions.data.CSV', 'actions.data.PDF']"
            [defaultSelected]="1"
            (choicesSelected)="updateFormByKey('formatFn', $event.split('.')[2])"
            [mode]="'single'"
            [_index]="0"></app-checkbox>
        </div>
      </div>
      <div class="row justify-content-start">
        <div class="form-group col-3 mb-3">
          <app-checkbox
            [label]="'actions.data.periodType'"
            [choices]="rangeTypes"
            [defaultSelected]="1"
            (choicesSelected)="updateFormByKey('rangeType', $event, true)"
            [mode]="'single'"
            [isOneIsDisabled]="
              exportSelectionForm.value.rangeType === 'actions.data.PDF' ? { index: 2 } : false
            "
            [_index]="0"></app-checkbox>
        </div>
        <div
          [class]="
            exportSelectionForm.value.rangeType
              ? 'col-8'
              : 'col-8 d-flex justify-content-start align-items-start'
          ">
          <div class="col-12">
            <div
              class="font-weight-bold fs-6"
              *ngIf="exportSelectionForm.value.rangeType; else noRangeType">
              {{
                'actionModal.' + exportSelectionForm.value.rangeType.split('.')[1] + 'Export'
                  | translate
              }}
            </div>
            <ng-template #noRangeType>
              <div class="alert alert-light" role="alert">
                <div>
                  {{ 'actionModal.noExportDateTypeSelected' | translate }}
                </div>
              </div>
            </ng-template>
          </div>
          <app-selection-export
            *ngIf="exportSelectionForm"
            #simpleDateExport
            [rangeType]="exportSelectionForm.value.rangeType.split('.')[1]"
            (onSelect)="updateFormByKey('range', $event)"></app-selection-export>
        </div>
      </div>
      <div class="col-12 pb-2">
        <div class="font-weight-bold fs-6">
          {{ 'actions.data.selectOrganization' | translate }}
        </div>
      </div>
      <div class="col-12">
        <ng-select
          class="w-100 rounded text_bold elevation-static-1"
          [multiple]="false"
          [(ngModel)]="organization"
          [ngModelOptions]="{ standalone: true }"
          [closeOnSelect]="true"
          [hideSelected]="true"
          (change)="filterBusinessByOrga()"
          bindLabel="legalName"
          [items]="organizations">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label"
              ><img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 3" />
              {{ item.legalName }}</span
            >
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <img
              src="/assets/mockedLogo/Millim.png"
              width="20px"
              height="20px"
              *ngIf="item.id === 92" />
            <img
              src="/assets/mockedLogo/Ivs.png"
              width="20px"
              height="20px"
              *ngIf="item.id === 3" />
            {{ item.legalName }}
          </ng-template>
        </ng-select>
      </div>
      <div class="_invalid" *ngIf="exportSelectionForm.dirty && organization === null">
        {{ 'feedback.organizationRequired' | translate }}
      </div>
      <div class="py-2">
        <div class="alert alert-secondary" role="alert" *ngIf="state === 'progress'">
          {{ feedback }}
        </div>

        <div class="alert alert-success" role="alert" *ngIf="state === 'done'">
          {{ feedback }}
        </div>
        <div *ngIf="state === ''" class="alert alert-light" role="alert"></div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger elevation-static-1"
      aria-label="Close"
      (click)="dismissModal()">
      {{ 'cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline-dark elevation-static-1"
      aria-label="Confirm"
      (click)="confirmModal()">
      {{ 'actionModal.selectDate' | translate }}
    </button>
  </div>
</div>
