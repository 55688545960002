import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserApi } from '@app/models';
import { DataProvider } from '@app/providers';
import { ApiService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  public user: UserApi;

  constructor(
    private dataProvider: DataProvider,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this.sub = this.dataProvider.getLastUserSelected().subscribe((userApi: UserApi) => {
      this.apiService.user.getUserById(userApi.id).subscribe((user) => (this.user = user));
    });
  }

  public dismissModal(): void {
    this.sub.unsubscribe();
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
