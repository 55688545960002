<div class="list-group w-100 justify-content-center">
  <!-- <button class="btn btn-light p-1 px-2" (click)="editUser()">
        Modifier le compte
    </button> -->

  <div
    class="form-check form-switch bg-light border-grey rounded text-dark d-flex align-items-center justify-content-center py-2"
    *ngIf="$userServicesInfo | async; let userServicesInfo; else: loader">
    <input
      class="form-check-input"
      type="checkbox"
      id="user_info_reporting_dashboard_ivstore_state"
      [checked]="userServicesInfo.reporting_dashboard_ivstore"
      (input)="onServiceStateChange($event)" />
    <label class="form-check-label mx-1" for="user_info_reporting_dashboard_ivstore_state">{{
      'services.reporting' | translate
    }}</label>
  </div>

  <ng-template #loader>
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
  </ng-template>

  <button class="btn btn-light px-2 border-grey rounded py-2" (click)="openMailingExport()">
    <i class="bi bi-person-lines-fill"></i>
    {{ 'services.maillingList' | translate }}
  </button>

  <button class="btn btn-light px-2 border-grey rounded py-2" (click)="editUserPassword()">
    <i class="bi bi-lock-fill"></i>
    {{ 'forgot-password.edit' | translate }}
  </button>
</div>
