import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputFormComponent implements OnInit {
  @Input() choices: string[] | undefined;
  @Input() label: string | undefined;
  @Input() _index: number | undefined;

  @Output() choicesSelected = new EventEmitter();

  public inputValue = '';

  private choice = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this._index === undefined || this._index === null) {
      this._index = 0;
    }
    if (this.choices) {
      this.translateService.get(this.choices[0]).subscribe((value) => {
        this.choicesSelected.emit(value);
        this.choice = value;
      });
    }
  }

  public getValue(event: any): string {
    return (event.target as HTMLInputElement).value;
  }

  public emitInput() {
    this.choicesSelected.emit(this.choice + ' ' + this.inputValue);
  }
  public emitSelect(value: any) {
    this.choice = value;
    this.choicesSelected.emit(value + ' ' + this.inputValue);
  }
}
