import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@app/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganisationService {
  constructor(private http: HttpClient) {}

  public getOrganizationNames(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${environment.backendDomain}/organizations`);
  }

  public getOrganizationNameById(id: string | number): Observable<Organization> {
    return this.http.get<Organization>(`${environment.backendDomain}/organization/${id}`);
  }

  public getOrganizationForUser(id: string | number): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${environment.backendDomain}/organizations/${id}/user`);
  }
}
