import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Businesses, Organization, UserApi } from '@app/models';
import { DataProvider } from '@app/providers';
import { ApiService } from '@app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cluster-creation',
  templateUrl: './cluster-creation.component.html',
  styleUrls: ['./cluster-creation.component.scss'],
})
export class ClusterCreationComponent implements OnInit {
  public clusterCreationForm: UntypedFormGroup;

  public organization: Organization;
  public organizations: Organization[];
  public _businessesId: any = [];
  public _businesses: Businesses[];
  public businesses: Businesses[] = [];

  public usersId: UserApi[] | any = [];
  public _usersId: UserApi[] | any = [];

  public searchValueBusinesses: string;
  public searchValueOrga: string;

  public loading = false;
  public submitted = false;

  private firstStart = false;

  // Todo set
  private isAlternativeMode;

  constructor(
    private apiService: ApiService,
    public formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProvider,
  ) {
    this.clusterCreationForm = this.formBuilder.group({
      clusterName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ]),
      ],
    });
  }

  ngOnInit(): void {
    this.dataProvider.getLastOrganizationSelected().subscribe((value) => {
      this.organization = value;
      if (!value) {
        this.isAlternativeMode = true;
      }
    });

    this.dataProvider.getOrganization().subscribe((value) => {
      this.organizations = value;
    });

    this.dataProvider.getBusinesses().subscribe((_data) => {
      this._businesses = _data;
      if (this.organization) {
        this.filterBusinessByOrga();
      }
    });

    this.dataProvider.getUserList().subscribe((_value) => {
      this.usersId = _value;
    });
  }

  public selectAllUsers(): void {
    this._usersId = this.usersId.map((user: any) => user.id);
  }

  public unselectAllUsers(): void {
    this._usersId = [];
  }

  public unselectAllBusinesses(): void {
    this._businessesId = [];
  }

  public selectAllBusinesses(): void {
    this._businessesId = this.organization.id
      ? this._businesses
          .filter((business) => business.organization === this.organization.id)
          .map((business: any) => business.id)
      : this._businesses.map((business: any) => business.id);
  }

  public onSubmit(): void {
    this.loading = true;
    this.submitted = true;
    if (this.clusterCreationForm.valid && this._businessesId.length > 0) {
      this.apiService.cluster
        .addCluster({
          name: this.clusterCreationForm.value.clusterName,
          organizationId: [this.organization.id || -1],
          localBusinessId: this._businessesId,
          userId: this._usersId,
        })
        .subscribe(() => {
          this.loading = false;
          this.closeModal();
        });
    }
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  public filterBusinessByOrga(): void {
    this.businesses = this.organization.id
      ? this._businesses.filter((business) => business.organization === this.organization.id)
      : this._businesses;
  }

  public filterOnAlternativeMode(): void {
    if (this.isAlternativeMode && this.organization) {
      this.filterBusinessByOrga();
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
