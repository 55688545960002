import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { RangeSelectionComponent } from '@app/components/modals/date/range-selection/range-selection.component';
import { DateRange, RangeType, StatsOptions } from '@app/models';
import { DataProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { ExportSelectionComponent } from '../modals/data/export/export-selection.component';
import { DateHelperService, ModalService } from '@app/services';

@Component({
  selector: 'app-counting-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {
  @Input() page: string;
  @Input() showExport;
  @Input() colAuto;
  public options: StatsOptions;

  public start: string;
  public startVs: string;

  public calendarActions: {
    icon?: string;
    component?: any;
    fn?: any;
    range?: RangeType;
    toolTip?: string;
    size?: string;
  }[] = [
    {
      icon: 'CalendarDay',
      component: RangeSelectionComponent,
      fn: this.getDayRange.bind(this),
      range: 'day',
      toolTip: 'dayCompare',
      size: 'lg-custom',
    },
    {
      icon: 'CalendarWeek',
      component: RangeSelectionComponent,
      fn: this.getWeekRange.bind(this),
      range: 'week',
      toolTip: 'weekCompare',
      size: 'lg-custom',
    },
    {
      icon: 'CalendarMonth',
      component: RangeSelectionComponent,
      fn: this.getMonthRange.bind(this),
      range: 'month',
      toolTip: 'monthCompare',
      size: 'lg-custom',
    },
    {
      icon: 'CalendarYear',
      component: RangeSelectionComponent,
      fn: this.getYearRange.bind(this),
      range: 'year',
      toolTip: 'yearCompare',
      size: 'lg-custom',
    },
    {
      icon: 'CalendarCustom',
      component: RangeSelectionComponent,
      fn: this.getYearRange.bind(this),
      range: 'custom',
      toolTip: 'customCompare',
      size: 'lg-custom',
    },
  ];

  constructor(
    public modalService: ModalService,
    public statsProvider: StatsProvider,
    private dateHelper: DateHelperService,
    private dataProvider: DataProvider,
  ) {}

  ngOnInit(): void {
    this.statsProvider.getOptions().subscribe((options) => {
      this.dataProvider.setActionBarModalsOptions({
        range: options.rangeType,
      });
      const dates = this.dateHelper.toHuman(options);

      this.options = options;
      options.rangeType;
      this.start = dates.start;
      this.startVs = dates.startVs;
    });
  }

  public openModal(item: any): void {
    if (item.component) {
      this.dataProvider.setActionBarModalsOptions(item);
      this.modalService.open(item.component, {
        animation: true,
        centered: true,
        size: item.size,
      });
    }
  }

  public getDayRange(): DateRange {
    return this.dateHelper.getDayRange();
  }

  public getWeekRange(): DateRange {
    return this.dateHelper.getWeekRange();
  }

  public getMonthRange(): DateRange {
    return this.dateHelper.getMonthRange();
  }

  public getYearRange(): DateRange {
    return this.dateHelper.getYearRange();
  }

  public generatePdf(): void {
    this.modalService.open(ExportSelectionComponent as never as ElementRef, {
      animation: true,
      size: 'lg',
    });
  }
}
