<div class="card h-100 p-0 m-0">
  <div class="card-header d-flex flex-row p-1 justify-content-center align-items-center bg_black">
    <div class="d-flex p-right-2 wrap_chart_icon">
      <img height="20px" src="/assets/svg/age-gender/gender/picto-white.svg" alt="gender kpi" />
    </div>

    <div class="h6 m-0 text-white font-weight-normal">
      {{ 'ageGender.genderTitle' | translate }}
    </div>
  </div>

  <div class="card-body position-relative p-0 m-0 h-100">
    <div class="h-100 w-100">
      <canvas id="gender_canvas"> </canvas>

      <div
        class="w-100 position-absolute bottom-0 d-flex justify-content-between"
        style="padding: 1rem 1.5rem">
        <img
          src="/assets/svg/age-gender/gender/women.svg"
          alt="women pictogram"
          style="width: 18px" />
        <img src="/assets/svg/age-gender/gender/man.svg" alt="man pictogram" style="width: 17px" />
      </div>
    </div>
  </div>
</div>
