import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UserCreationComponent, UserEditionComponent } from '@app/modals';
import { UserDetailComponent } from '@app/modals/profil/user-detail/user-detail.component';
import { UserApi } from '@app/models';
import { DataProvider } from '@app/providers';
import { RefreshProvider } from '@app/providers/layout';
import { ApiService, ModalService } from '@app/services';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  public usersList: [];

  public userRelations: [];

  public searchValue: string;

  public loading = false;

  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    private dataProvider: DataProvider,
    private refreshProvider: RefreshProvider,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.refreshProvider.listen('profil-shared-components').subscribe((value) => this.refresh());
  }

  public addUser(user: UserApi): void {
    this.usersList.push(user as never);
  }

  public deleteUser(item: any): void {
    this.modalService.alert({
      title: 'userDelete',
      subTitle: 'userDeleteInfo',
      fn: this.deleteUserFn.bind(this, item),
      fnDescription: 'userDeleteAction',
    });
  }

  public createUser(): void {
    this.modalService.open(
      UserCreationComponent as never as ElementRef,
      {
        animation: false,
      },
      () => {
        this.refresh.bind(this)();
      },
    );
  }

  public refresh(): void {
    this.loading = true;
    this.apiService.user.getUserAndRelation().subscribe((__value) => {
      this.userRelations = __value as [];
      this.dataProvider.setUserList(this.userRelations);
      this.loading = false;
    });
  }

  public editUser(user: UserApi): void {
    this.dataProvider.setLastUserSelected(user);
    this.modalService.open(UserEditionComponent as never as ElementRef, { animation: true }, () => {
      this.refresh.bind(this)();
    });
  }

  private deleteUserFn(item: UserApi): void {
    this.apiService.user.deleteUserAndRelation(item).subscribe(() => {
      this.userRelations.splice(this.userRelations.indexOf(item as never), 1);
    });
  }

  public detailUser(user: UserApi): void {
    this.dataProvider.setLastUserSelected(user);
    this.modalService.open(UserDetailComponent as never as ElementRef, { animation: true }, () => {
      this.refresh.bind(this)();
    });
  }
}
