<h5 class="font-weight-bold">{{ 'clusterInfo' | translate }}</h5>
<div class="list-group-item border-0 d-flex flex-column py-1">
  <div class="font-weight-bold">{{ 'clusterStore' | translate }} :&nbsp;</div>
  <div
    (click)="openDetails(business)"
    class="pointer pointer_active_bg_grey"
    *ngFor="let business of detailData.localBusiness">
    {{ business.legalName }}
  </div>
</div>
