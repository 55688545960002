import { Component, AfterViewInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { Age } from '@app/models/';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.scss'],
})
export class AgeComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ageData!: Age;
  private ageChart: Chart;

  ngAfterViewInit() {
    this.initAgeChart();
  }

  private initAgeChart() {
    this.ageChart = new Chart(document.getElementById('age_canvas') as HTMLCanvasElement, {
      type: 'bar',

      data: {
        labels: ["Pourcentage d'adulte", "Pourcentage d'enfant"],
        datasets: [
          {
            data: [this.ageData.adult, this.ageData.child],
            backgroundColor: ['#008CAB', '#024F5D'],
            borderColor: ['#008CAB', '#024F5D'],
            borderWidth: 1,
            barPercentage: 0.6,
            barThickness: 90,
            datalabels: {
              labels: {
                value: {
                  color: 'white',
                },
              },
            },
          },
        ],
      },
      options: {
        responsive: true,
        cutoutPercentage: 90,

        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: { beginAtZero: true },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                max: 100,
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            font: {
              weight: 'bold',
              size: 13,
              family: 'Lato',
            },
            align:
              (this.ageData.adult >= 0 && this.ageData.adult <= 1) ||
              (this.ageData.child >= 0 && this.ageData.child <= 1)
                ? 'top'
                : 'center',
            formatter: (value: any) => value + '%',
          },
        },
      },
    });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.ageChart.update();
    }, 50);
  }

  ngOnDestroy() {
    this.ageChart.destroy();
  }
}
