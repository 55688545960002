<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'userDetail' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="user; else loader">
    <div class="bg_grey">
      <p>{{ 'email' | translate }} : {{ user.email }}</p>
    </div>

    <div class="bg_grey">
      <p>{{ 'id' | translate }} : {{ user.id }}</p>
    </div>

    <div class="bg_grey">
      <p>Role : {{ 'local.roles.' + user.roles[0] + '_SHORT' | translate }}</p>
    </div>

    <div class="bg_grey">
      <p>{{ 'organizations' | translate }} :</p>
      <ul>
        <li *ngFor="let organization of user.organizations">
          <span>{{ organization.legalName }}</span>
        </li>
      </ul>
    </div>

    <div class="bg_grey">
      <p>{{ 'localbusinesses' | translate }} :</p>
      <ul>
        <li *ngFor="let localbusiness of user.localbusinesses">
          <span>{{ localbusiness.legalName }}</span>
        </li>
      </ul>
    </div>
  </div>
  <ng-template #loader>
    <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
  </ng-template>
</div>
