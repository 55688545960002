import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-journey-action-bar',
  templateUrl: './journey-action-bar.component.html',
  styleUrls: ['./journey-action-bar.component.scss'],
})
export class JourneyActionBarComponent {
  @Output() select = new EventEmitter();

  @Input() activeTab: string;

  public onSelect(item: string) {
    this.select.emit(item);
  }
}
