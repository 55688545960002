<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'userEdition' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form
    *ngIf="userEditionForm"
    [formGroup]="userEditionForm"
    (ngSubmit)="onSubmit()"
    (change)="dectectorRef.detectChanges()"
    autocomplete="off">
    <div class="form-group d-flex bg_grey align-items-center rounded-right">
      <input
        type="text"
        formControlName="newUsername"
        class="form-control bg_transparent radius border"
        placeholder="{{ 'username' | translate }}"
        [ngClass]="{
          'is-invalid':
            userEditionForm.controls.newUsername.dirty &&
            userEditionForm.controls.newUsername.errors,
          'is-valid':
            userEditionForm.controls.newUsername.dirty &&
            !userEditionForm.controls.newUsername.errors
        }" />
      <div class="invalid-feedback">
        {{ 'feedback.usernameRequired' | translate }}
      </div>
    </div>

    <div class="form-group d-flex bg_grey align-items-center rounded-right">
      <input
        type="text"
        formControlName="newEmail"
        class="form-control bg_transparent radius border"
        placeholder="{{ 'email' | translate }}"
        [ngClass]="{
          'is-invalid':
            userEditionForm.controls.newEmail.dirty && userEditionForm.controls.newEmail.errors,
          'is-valid':
            userEditionForm.controls.newEmail.dirty && !userEditionForm.controls.newEmail.errors
        }" />
      <div class="invalid-feedback">
        {{ 'feedback.emailRequired' | translate }}
      </div>
    </div>

    <div class="form-group">
      <div class="d-flex align-items-center bg_grey rounded-right">
        <input
          [type]="!applyMask.password ? 'password' : 'text'"
          formControlName="newPlainPassword"
          class="form-control bg_transparent radius border"
          placeholder="{{ 'password' | translate }}" />
        <div class="input-group-append">
          <div class="p-1" (click)="toogleMask('password')">
            <img
              width="20px"
              src="{{ !applyMask.password ? '/assets/svg/See.svg' : '/assets/svg/Unsee.svg' }}" />
          </div>
        </div>
      </div>
      <small id="passwordHelpInline" class="text-muted">
        {{ 'passwordRules' | translate }}
      </small>
    </div>
    <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
      <label class="my-1 me-2" for="inlineFormCustomSelectPref">{{
        'rolesChoice' | translate
      }}</label>
      <select
        class="custom-select my-1"
        id="inlineFormCustomSelectPref"
        formControlName="roles"
        [ngClass]="{
          'is-valid': userEditionForm.controls.roles.dirty
        }">
        <option value="ROLE_SUPER_USER" [selected]="user.roles[0] === 'ROLE_SUPER_USER'">
          {{ 'local.roles.' + roles.SUPER_USER | translate }}
        </option>
        <option value="ROLE_USER" [selected]="!user.roles[0]">
          {{ 'local.roles.' + roles.USER | translate }}
        </option>
      </select>
    </div>
    <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
      <label class="my-1 me-2">{{ 'orgaChoice' | translate }}</label>
      <ng-template #loader>
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
      </ng-template>
      <ng-select
        class="w-100"
        [multiple]="true"
        [(ngModel)]="_organizationId"
        (add)="filterBusinessByOrga()"
        (remove)="filterBusinessByOrga()"
        [closeOnSelect]="false"
        [hideSelected]="true"
        bindLabel="legalName"
        formControlName="organization"
        bindValue="id"
        [items]="organizations"
        *ngIf="organizations; else loader"
        [ngClass]="{
          'is-invalid': userEditionForm.dirty && userEditionForm.value.organization.length === 0,
          'is-valid': userEditionForm.dirty && userEditionForm.value.organization.length > 0
        }">
        <ng-template ng-header-tmp>
          <button
            (click)="selectAllOrganizations(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout sélectionner
          </button>
          <button
            (click)="unselectAllOrganizations(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout retirer
          </button>
        </ng-template>
        <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
            <span class="ng-value-label"
              ><img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 92" />

              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 3" />
              {{ item.legalName }}</span
            >
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 10">
            <span class="ng-value-label">{{ items.length - 10 }}...</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img
            src="/assets/mockedLogo/Millim.png"
            width="20px"
            height="20px"
            *ngIf="item.id === 92" />
          <img src="/assets/mockedLogo/Ivs.png" width="20px" height="20px" *ngIf="item.id === 3" />
          {{ item.legalName }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img
            src="/assets/mockedLogo/Millim.png"
            width="20px"
            height="20px"
            *ngIf="item.id === 92" />
          <img src="/assets/mockedLogo/Ivs.png" width="20px" height="20px" *ngIf="item.id === 3" />
          {{ item.legalName }}
        </ng-template>
      </ng-select>
      <div class="_invalid" *ngIf="userEditionForm.dirty && _organizationId.length === 0">
        {{ 'feedback.organizationRequired' | translate }}
      </div>
    </div>

    <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
      <label class="my-1 me-2">{{ 'storeChoice' | translate }}</label>
      <ng-template #loader>
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
      </ng-template>
      <ng-select
        *ngIf="businesses; else loader"
        class="w-100"
        [multiple]="true"
        [(ngModel)]="_businessesId"
        [closeOnSelect]="false"
        [hideSelected]="true"
        formControlName="localBusiness"
        bindLabel="legalName"
        bindValue="id"
        [items]="businesses"
        [ngClass]="{
          'is-invalid': userEditionForm.dirty && userEditionForm.value.localBusiness.length === 0,
          'is-valid': userEditionForm.dirty && userEditionForm.value.localBusiness.length > 0
        }">
        <ng-template ng-header-tmp>
          <button
            (click)="selectAllBusinesses(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout sélectionner
          </button>
          <button
            (click)="unselectAllBusinesses(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout retirer
          </button>
        </ng-template>
        <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
            <span class="ng-value-label"
              ><img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 3" />
              {{ item.legalName }}</span
            >
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 10">
            <span class="ng-value-label">{{ items.length - 10 }}...</span>
          </div>
        </ng-template>
      </ng-select>
      <div class="_invalid" *ngIf="userEditionForm.dirty && _businessesId.length === 0">
        {{ 'feedback.businessRequired' | translate }}
      </div>
    </div>

    <div class="form-group d-flex flex-column bg_transparent align-items-center rounded-right">
      <label class="m-2 text-bold"
        >{{ 'clusterAccess' | translate }}
        <img src="/assets/svg/Cluster.svg" width="25px" height="25px"
      /></label>
      <ng-select
        class="w-100"
        [multiple]="true"
        [(ngModel)]="_clusterList"
        formControlName="clusters"
        (add)="filterBusinessByOrga()"
        (remove)="filterBusinessByOrga()"
        [closeOnSelect]="false"
        [hideSelected]="true"
        bindLabel="name"
        bindValue="id"
        [items]="clusterList"
        [ngClass]="{
          'is-valid': userEditionForm.dirty
        }">
        <ng-template ng-header-tmp>
          <button
            (click)="selectAllClusters(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout sélectionner
          </button>
          <button
            (click)="unselectAllClusters(); $event.preventDefault()"
            class="btn btn-sm btn-secondary">
            Tout retirer
          </button>
        </ng-template>
        <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
            <span class="ng-value-label"
              ><img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization_id === 92" />

              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization_id === 3" />
              {{ item.name }}</span
            >
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 10">
            <span class="ng-value-label">{{ items.length - 10 }}...</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img
            src="/assets/mockedLogo/Millim.png"
            width="20px"
            height="20px"
            *ngIf="item.organization_id === 92" />
          <img
            src="/assets/mockedLogo/Ivs.png"
            width="20px"
            height="20px"
            *ngIf="item.organization_id === 3" />
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="onSubmit()">
    {{ 'validate' | translate }}
  </button>
</div>
