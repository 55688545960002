import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { ExportPushMailComponent } from '@app/components';
import { AccountEditionComponent } from '@app/modals';
import { User } from '@app/models';
import { DataProvider } from '@app/providers';
import { ModalService } from '@app/services';
import { TasksService } from '@app/services/api/v2/tasks.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements AfterViewInit {
  @Input() user: User;

  public $userServicesInfo;

  constructor(
    private tasksService: TasksService,
    private modalService: ModalService,
    private dataProvider: DataProvider,
  ) {}

  ngAfterViewInit() {
    this.$userServicesInfo = this.tasksService.getUserServicesInfo(this.user.id);
  }

  public onServiceStateChange(event) {
    this.$userServicesInfo = this.tasksService.postReportingState(
      this.user.id,
      event.target.checked as never,
    );
  }

  public openMailingExport() {
    this.modalService.open(ExportPushMailComponent as never as ElementRef, {
      animation: true,
      size: 'lg',
    });
  }

  public editUserPassword() {
    this.modalService.open(
      AccountEditionComponent as never as ElementRef,
      {
        animation: true,
        size: 'lg',
      },
      () => {},
    );
  }
}
