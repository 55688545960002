<div class="w-100 h-100 bg_white shadow py-5">
  <div class="flex-column d-flex align-items-center justify-content-start">
    <img width="100px" src="/assets/svg/UserProfil.svg" alt="user" />

    <div class="row d-flex flex-column align-items-center py-2">
      <div class="text-dark fw-bold text-center">{{ user.login }}</div>
      <div class="text_grey text-center">
        {{ 'local.roles.' + user.roles[0] | translate }}
      </div>
    </div>

    <div class="row w-100">
      <div class="col-12">
        <app-user-info class="p-1" [user]="user"></app-user-info>
      </div>
    </div>
  </div>
</div>
