<div
  class="form-floating border-0 mb-3 p-1"
  [ngClass]="{
    'd-flex': _appendRight
  }">
  <span class="input-group-text" *ngIf="preprendLabel">{{ preprendLabel }}</span>
  <input
    [id]="'floating_' + label + '_' + _index"
    class="form-control rounded"
    [class]="_class"
    [id]="'input_' + label + '_' + _index"
    [attr.aria-describedby]="placeHolder || label"
    [placeholder]="placeHolder | translate"
    (input)="emit(getValue($event))"
    (keyup.enter)="emitOnValid(getValue($event))"
    [required]="required"
    [(ngModel)]="value"
    [type]="_type"
    [ngClass]="{
      'is-valid': isValid,
      'is-invalid': isInvalid
    }" />
  <label *ngIf="label" [for]="'floating_' + label + '_' + _index">{{ label | translate }}</label>

  <span
    (click)="toogleAppendRight()"
    *ngIf="_appendRight"
    class="input-group-text"
    id="basic-addon2"
    [class]="_class">
    <i [class]="_appendRightIsActive ? _appendRightItemActivated : _appendRightItem"></i>
  </span>
  <ng-template #floatingLabels>
    <div
      class="valid-tooltip"
      *ngIf="showIsValidLabel"
      [innerHtml]="isValidFeedBack || label + '_valid' | translate"></div>

    <div
      class="invalid-tooltip"
      *ngIf="showIsInValidLabel"
      [innerHtml]="isInvalidFeedBack || label + '_invalid' | translate"></div>
  </ng-template>

  <ng-template #basicLabel *ngIf="floatingTooltip; else floatingLabels">
    <div
      class="valid-feedback"
      *ngIf="showIsValidLabel"
      [innerHtml]="isValidFeedBack || label + '_valid' | translate"></div>

    <div
      class="invalid-feedback"
      *ngIf="showIsInValidLabel"
      [innerHtml]="isInvalidFeedBack || label + '_invalid' | translate"></div>
  </ng-template>
</div>
