import { Component, Input } from '@angular/core';
import { DetailCard } from '@app/models';

@Component({
  selector: 'app-detail-info',
  templateUrl: './detail-info.component.html',
  styleUrls: ['./detail-info.component.scss'],
})
export class DetailInfoComponent {
  @Input() data: any;
  @Input() globalInfo: DetailCard;
}
