import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CegidSaleHistory, DateRange } from '@app/models';
import { DateHelperService } from '../date-helper';

@Injectable({
  providedIn: 'root',
})
export class CegidService {
  constructor(private http: HttpClient, private dateHelper: DateHelperService) {}

  public saleHistory(data: CegidSaleHistory): Observable<any> {
    return this.http
      .post<any>(`${environment.soapMapDomain}/millim/SaleHistory`, {
        apikey: environment.soapMapApiKey,
        ...data,
      })
      .pipe(
        map((objectArrayData) => {
          if (objectArrayData[0]) {
            return objectArrayData.map((_objectArrayData) => {
              return _objectArrayData && _objectArrayData.FlashSalesResult
                ? _objectArrayData.FlashSalesResult.SaleByProductAggregation
                : null;
            });
          } else {
            return null;
          }
        }),
        map((arrayData) => {
          if (arrayData !== null && arrayData[0] !== null) {
            return {
              tickets: arrayData[0]
                .reduce((prev, cur) => {
                  return prev + cur.NumberOfReceipt;
                }, 0)
                .toFixed(2),
              ca: arrayData[0]
                .reduce((prev, cur) => {
                  return prev + cur.TurnoverIOT;
                }, 0)
                .toFixed(2),
            };
          } else {
            return {
              tickets: 0,
              ca: 0,
            };
          }
        }),
      );
  }

  public async periodSaleHistory(
    range: DateRange,
    localBusinessId: string | number,
    format: string,
  ): Promise<any> {
    return new Promise(async (res, err) => {
      const accumulator = [[], []];
      const accumulator$ = [[], []];
      let period;
      let periodVs;

      if (format === 'day') {
        period = this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end, 'YYYY-MM-DD');
        periodVs = this.dateHelper.enumerateDaysBetweenDate(
          range.beginVs,
          range.endVs,
          'YYYY-MM-DD',
        );
      }
      if (format === 'month') {
        range.begin.day = 1;
        range.beginVs.day = 1;
        range.endVs.day = 1;
        range.end.day = 1;
        period = this.dateHelper.enumerateDaysBetweenDate(range.begin, range.end, 'YYYY-MM-DD');
        periodVs = this.dateHelper.enumerateDaysBetweenDate(
          range.beginVs,
          range.endVs,
          'YYYY-MM-DD',
        );
      }

      period;
      periodVs;
      period.forEach((element) => {
        if (format === 'day') {
          accumulator$[0].push(
            this.saleHistory({
              endDate: element,
              startDate: element,
              storeId: localBusinessId + '',
            }),
          );
        }
        if (format === 'month') {
          accumulator$[0].push(
            this.saleHistory({
              endDate: this.dateHelper.stringToEndOfMonth(element, 'YYYY-MM-DD', 'YYYY-MM-DD'),
              startDate: element,
              storeId: localBusinessId + '',
            }),
          );
        }
      });

      periodVs.forEach((elementVs) => {
        if (format === 'day') {
          accumulator$[1].push(
            this.saleHistory({
              endDate: elementVs,
              startDate: elementVs,
              storeId: localBusinessId + '',
            }),
          );
        }
        if (format === 'month') {
          accumulator$[1].push(
            this.saleHistory({
              endDate: this.dateHelper.stringToEndOfMonth(elementVs, 'YYYY-MM-DD', 'YYYY-MM-DD'),
              startDate: elementVs,
              storeId: localBusinessId + '',
            }),
          );
        }
      });

      accumulator[0] = await forkJoin(accumulator$[0]).toPromise();

      accumulator[1] = await forkJoin(accumulator$[1]).toPromise();
      res(accumulator);
    });
  }
}
