<div class="modal-content modal_range_date_selection">
  <div class="modal-body">
    <div class="row d-flex m-0 align-items-center w-100">
      <div class="col-12 text-center h4 pb-4">
        {{
          rangeType !== 'day'
            ? ('actionModal.' + rangeType + 'Compare' | translate)
            : ('actionModal.' + rangeType + 'Compare' | translate)
        }}
      </div>
      <div *ngIf="rangeType === 'day' || rangeType === 'week'" class="col-12 p-0 m-0">
        <form class="form-inline justify-content-center">
          <div class="d-flex w-100 h-100 flex-row pb-4">
            <div class="form-group d-flex flex-column col-4 justify-content-center">
              <div class="input-group d-flex flex-column align-items-end justify-content-start">
                <div class="d-flex flex-column align-items-center w-100 position-relative">
                  <div for="dpFromDate" class="text_orange p-1">
                    {{ 'actionModal.' + rangeType + 'ToRefer' | translate }}
                  </div>

                  <div
                    class="_range_date_feedback d-flex align-items-center justify-content-center p-1 position-relative text_orange text_bold h-auto text-center"
                    name="dpFromDate">
                    {{ formatByType(fromDate, toDate) }}
                  </div>
                  <button
                    type="button"
                    (click)="editSelectedDate('fromDate', 'toDate')"
                    class="btn btn-transparent rounded _range_selection_edit_date p-0 m-0">
                    <svg-icon
                      *ngIf="fromDate"
                      width="5px"
                      height="5px"
                      src="/assets/svg/Pencil.svg"
                      alt="user"></svg-icon>
                  </button>
                </div>
                <div class="d-flex flex-column align-items-center w-100 pt-2 position-relative">
                  <div for="dpFromDate" class="text_blue p-1">
                    {{ 'actionModal.' + rangeType + 'ToCompare' | translate }}
                  </div>
                  <div
                    class="_range_date_feedback d-flex align-items-center justify-content-center p-1 position-relative text_blue text_bold h-auto text-center"
                    name="dpFromDate">
                    {{ formatByType(fromDateVs, toDateVs) }}
                  </div>
                  <button
                    *ngIf="fromDateVs"
                    type="button"
                    (click)="editSelectedDate('fromDateVs', 'toDateVs')"
                    class="btn btn-transparent rounded _range_selection_edit_date p-0 m-0 mt-2">
                    <svg-icon
                      width="5px"
                      height="5px"
                      src="/assets/svg/Pencil.svg"
                      alt="user"></svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group col-8 justify-content-center">
              <ng-template #footerTemplate>
                <hr class="my-0" />
                <div class="btn-group d-flex flex-row justify-content-end mb-2" role="group">
                  <button type="button" class="btn btn-sm btn-outline-secondary" (click)="today()">
                    Aujourd'hui
                  </button>
                </div>
              </ng-template>
              <ngb-datepicker
                [maxDate]="dateLimit"
                #dp
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                [footerTemplate]="footerTemplate"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1"
                [markDisabled]="isDisabled.bind(this)"
                [showWeekNumbers]="true">
                <ng-template #t let-date let-focused="focused">
                  <span
                    class="custom-day"
                    [class.focused]="focused"
                    [ngClass]="{
                      _is_date_range: isRenderAsDateRange(date),
                      _is_date_vs_range: isRenderAsDateVsRange(date),
                      _is_date_faded: isHovered(date) || isInside(date),
                      _is_date_vs_faded: isHoveredVs(date) || isInsideVs(date)
                    }"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </ngb-datepicker>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="rangeType === 'custom'" class="col-12 p-0 m-0">
        <form class="form-inline justify-content-center">
          <div class="d-flex w-100 h-100 flex-row pb-4">
            <div class="form-group d-flex flex-column col-4 justify-content-center">
              <div class="input-group d-flex flex-column align-items-end justify-content-start">
                <div class="d-flex flex-column align-items-center w-100 position-relative">
                  <div for="dpFromDate" class="text_orange p-1">
                    {{ 'actionModal.' + rangeType + 'ToRefer' | translate }}
                  </div>

                  <div
                    class="_range_date_feedback d-flex align-items-center justify-content-center p-0 m-0 position-relative text_orange text_bold h-auto text-center"
                    name="dpFromDate">
                    <div
                      class="position-relative h-100 d-flex align-items-center justify-items-center pt-4 pb-2 ps-2">
                      {{ formatByType(fromDate) }}
                    </div>
                    <div *ngIf="!fromDate && !toDate" class="text_orange pt-4 pb-2 px-2">
                      Choix d'une période
                    </div>
                    <div *ngIf="fromDate" class="text_orange pt-4 pb-2 px-2">Au</div>
                    <div
                      class="position-relative h-100 d-flex align-items-center justify-items-center pt-4 pb-2 pe-2">
                      {{ formatByType(toDate) }}
                      <button
                        *ngIf="fromDate"
                        type="button"
                        (click)="editSelectedDate('fromDate', 'toDate')"
                        class="btn btn-transparent rounded _range_selection_edit_date_custom_vs p-0 m-0">
                        <svg-icon
                          width="5px"
                          height="5px"
                          src="/assets/svg/Pencil.svg"
                          alt="user"></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-center w-100 pt-2 position-relative">
                  <div for="dpFromDate" class="text_blue p-1">
                    {{ 'actionModal.' + rangeType + 'ToCompare' | translate }}
                  </div>
                  <div
                    class="_range_date_feedback d-flex align-items-center justify-content-center p-0 m-0 position-relative text_blue text_bold h-auto text-center"
                    name="dpFromDate">
                    <div
                      class="position-relative h-100 d-flex align-items-center justify-items-center pt-4 pb-2 ps-2">
                      {{ formatByType(fromDateVs) }}
                    </div>
                    <div *ngIf="!toDateVs && !fromDateVs" class="text_blue pt-4 pb-2 px-2">
                      Choix d'une période
                    </div>
                    <div *ngIf="fromDateVs" class="text_blue pt-4 pb-2 px-2">Au</div>

                    <div
                      class="position-relative h-100 d-flex align-items-center justify-items-center pt-4 pb-2 pe-2">
                      {{ formatByType(toDateVs) }}
                      <button
                        *ngIf="fromDateVs"
                        type="button"
                        (click)="editSelectedDate('fromDateVs', 'toDateVs')"
                        class="btn btn-transparent rounded _range_selection_edit_date_custom_vs p-0 m-0">
                        <svg-icon
                          width="5px"
                          height="5px"
                          src="/assets/svg/Pencil.svg"
                          alt="user"></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-8 justify-content-center">
              <ng-template #footerTemplate>
                <hr class="my-0" />
                <div class="btn-group d-flex flex-row justify-content-end mb-2" role="group">
                  <button type="button" class="btn btn-sm btn-outline-secondary" (click)="today()">
                    Aujourd'hui
                  </button>
                </div>
              </ng-template>
              <ngb-datepicker
                [maxDate]="dateLimit"
                #dp
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                [footerTemplate]="footerTemplate"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1"
                [markDisabled]="isDisabled.bind(this)"
                [showWeekNumbers]="true">
                <ng-template #t let-date let-focused="focused">
                  <span
                    class="custom-day"
                    [class.focused]="focused"
                    [ngClass]="{
                      _is_date_range: isRenderAsDateRange(date),
                      _is_date_vs_range: isRenderAsDateVsRange(date),
                      _is_date_faded: isHovered(date) || isInside(date),
                      _is_date_vs_faded: isHoveredVs(date) || isInsideVs(date)
                    }"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </ngb-datepicker>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="rangeType === 'month'" class="col-12 p-0 m-0">
        <form class="d-flex flex-column align-items-center p-2 pb-4">
          <div class="form-group d-flex flex-row">
            <div class="_input_datepicker_only_picker _input_datepicker_month text-center">
              <div for="dpFromDate" class="text_blue p-1">
                {{ 'actionModal.' + rangeType + 'ToCompare' | translate }}
              </div>
              <div
                class="_range_date_feedback text_blue text_bold h-auto text-center"
                name="dpFromDate">
                <ngb-datepicker
                  #dp
                  [maxDate]="dateLimit"
                  [startDate]="modelNgDatePickerVs"
                  (navigate)="fromDateMonthVsSelection($event.next)"></ngb-datepicker>
              </div>
            </div>
            <div class="_input_datepicker_only_picker _input_datepicker_month text-center">
              <div for="dpFromDate" class="text_orange p-1">
                {{ 'actionModal.' + rangeType + 'ToRefer' | translate }}
              </div>
              <div
                class="_range_date_feedback text_orange text_bold h-auto text-center"
                name="dpFromDate">
                <ngb-datepicker
                  #dp
                  [maxDate]="dateLimit"
                  [startDate]="modelNgDatePicker"
                  (navigate)="fromDateMonthSelection($event.next)"></ngb-datepicker>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="rangeType === 'year'" class="col-12 p-0 m-0">
        <form class="d-flex flex-column align-items-center p-2 pb-4">
          <div class="form-group d-flex flex-row">
            <div class="_input_datepicker_only_picker _input_datepicker_year text-center">
              <div for="dpFromDate" class="text_blue p-1">
                {{ 'actionModal.' + rangeType + 'ToCompare' | translate }}
              </div>
              <div
                class="_range_date_feedback text_blue text_bold h-auto text-center"
                name="dpFromDate">
                <ngb-datepicker
                  #dp
                  [startDate]="modelNgDatePickerVs"
                  (navigate)="fromDateYearVsSelection($event.next)"></ngb-datepicker>
              </div>
            </div>
            <div class="_input_datepicker_only_picker _input_datepicker_year text-center">
              <div for="dpFromDate" class="text_orange p-1">
                {{ 'actionModal.' + rangeType + 'ToRefer' | translate }}
              </div>
              <div
                class="_range_date_feedback text_orange text_bold h-auto text-center"
                name="dpFromDate">
                <ngb-datepicker
                  #dp
                  [startDate]="modelNgDatePicker"
                  (navigate)="fromDateYearSelection($event.next)"></ngb-datepicker>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        aria-label="Close"
        (click)="dismissModal()">
        {{ 'cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        aria-label="Close"
        (click)="closeModal()"
        *ngIf="fromDateVs && toDateVs; else selectDateDisabled">
        {{ 'actionModal.selectDate' | translate }}
      </button>

      <ng-template #selectDateDisabled>
        <button type="button" class="btn btn-outline-dark" aria-label="Close" disabled>
          {{ 'actionModal.selectDate' | translate }}
        </button></ng-template
      >
    </div>
  </div>
</div>
