<div class="modal-content">
  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <markdown src="/assets/CHANGELOG.md"></markdown>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" aria-label="Close" (click)="dismissModal()">
      {{ 'closeAction' | translate }}
    </button>
  </div>
</div>
