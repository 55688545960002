<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ content.title | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ content.subTitle | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="content.fn(); dismissModal()">
      {{ content.fnDescription | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="dismissModal()">
      {{ 'cancel' | translate }}
    </button>
  </div>
</div>
