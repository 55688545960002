<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'export-push-mail.title' | translate }}
  </h4>
  <button type="button" class="btn-close" (click)="cancel()"></button>
</div>
<div class="modal-body border-dark border-top">
  <div class="d-flex flex-column">
    <form *ngIf="exportSelectionForm" [formGroup]="exportSelectionForm" (ngSubmit)="submit()">
      <div class="row justify-content-start">
        <div class="form-group col-12 mb-3">
          <app-checkbox
            [label]="'export-push-mail.maillingFrequency'"
            [choices]="['export-push-mail.daily', 'export-push-mail.weekly']"
            [defaultSelected]="case === 'create' ? 1 : false"
            (choicesSelected)="updateFormByKey('rangeType', $event)"
            [explaination]="['export-push-mail.dailyExplain', 'export-push-mail.weeklyExplain']"
            [mode]="'single'"
            [value]="exportSelectionForm.value.rangeType"
            [_index]="0"></app-checkbox>
        </div>
      </div>

      <div class="row justify-content-start">
        <div class="form-group col-12 mb-3">
          <app-checkbox
            [label]="'export-push-mail.kpiChoice'"
            [choices]="[
              'export-push-mail.rank',
              'export-push-mail.counting',
              'export-push-mail.customerJourney'
            ]"
            [defaultSelected]="case === 'create' ? 1 : false"
            (choicesSelected)="updateFormByKey('kpiList', $event)"
            [mode]="'multiple'"
            [_index]="1"
            [initSelectAll]="case === 'create'"
            [valueMultiple]="exportSelectionForm.value.kpiList"></app-checkbox>
        </div>
      </div>

      <div class="row justify-content-start">
        <div class="form-group col-12 mb-3">
          <label>{{ 'choiceList' | translate }}</label>
          <ng-select
            class="w-100"
            [multiple]="false"
            [(ngModel)]="organization"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="true"
            [hideSelected]="true"
            bindLabel="legalName"
            (change)="filterBusinessByOrga()"
            [items]="organizations">
            <ng-template ng-header-tmp>
              <button (click)="selectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button (click)="unselectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label"
                ><img
                  src="/assets/mockedLogo/Millim.png"
                  width="20px"
                  height="20px"
                  *ngIf="item.id === 92" />
                <img
                  src="/assets/mockedLogo/Ivs.png"
                  width="20px"
                  height="20px"
                  *ngIf="item.id === 3" />
                {{ item.legalName }}</span
              >
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.id === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row justify-content-start">
        <div class="form-group col-12 mb-3">
          <label for="yesno">{{ 'export-push-mail.businessChoice' | translate }}</label>
          <ng-select
            class="w-100"
            [multiple]="true"
            [(ngModel)]="localBusinessesId"
            [ngModelOptions]="{ standalone: true }"
            [closeOnSelect]="false"
            [hideSelected]="true"
            bindLabel="legalName"
            bindValue="id"
            [items]="localBusinesses">
            <ng-template ng-header-tmp>
              <button (click)="selectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout sélectionner
              </button>
              <button (click)="unselectAllBusinesses()" class="btn btn-sm btn-secondary">
                Tout retirer
              </button>
            </ng-template>
            <ng-template ng-label-tmp ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice : 0 : 10">
                <span class="ng-value"
                  ><img
                    src="/assets/mockedLogo/Millim.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 92" />
                  <img
                    src="/assets/mockedLogo/Ivs.png"
                    width="20px"
                    height="20px"
                    *ngIf="item.organization === 3" />
                  {{ item.legalName }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 10">
                <span class="ng-value-label">{{ items.length - 10 }}...</span>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <img
                src="/assets/mockedLogo/Millim.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 92" />
              <img
                src="/assets/mockedLogo/Ivs.png"
                width="20px"
                height="20px"
                *ngIf="item.organization === 3" />
              {{ item.legalName }}
            </ng-template>
          </ng-select>
        </div>
      </div>

      <hr class="my-0 mb-3" />
      <div class="row justify-content-start">
        <div class="form-group col-12 mb-0">
          <app-input
            [label]="'export-push-mail.taskTitle'"
            (inputChange)="updateFormByKey('taskTitle', $event)"
            [value]="exportSelectionForm.value.taskTitle"></app-input>
        </div>
      </div>
      <hr class="my-0 mb-3" />
      <div class="row justify-content-start">
        <div class="form-group col-12 mb-0">
          <app-input
            [label]="'export-push-mail.addMail'"
            (inputChange)="updateFormByKey('mailInput', $event)"
            (onValid)="addToArrayFormByKey('mailList', $event)"
            [required]="false"
            [canPressEnter]="
              exportSelectionForm.value.mailInput.length > 4 &&
              emailregex(exportSelectionForm.value.mailInput)
            "
            [isValid]="
              exportSelectionForm.value.mailInput.length > 4 &&
              emailregex(exportSelectionForm.value.mailInput)
            "
            [isInvalid]="
              exportSelectionForm.value.mailInput.length > 4 &&
              !emailregex(exportSelectionForm.value.mailInput)
            "></app-input>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="form-group col-12 mb-3">
          <app-table-mailling-list
            [rows]="exportSelectionForm.value.mailList"
            (valueChange)="updateFormByKey('mailList', $event)"></app-table-mailling-list>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger elevation-static-1"
    aria-label="Close"
    (click)="cancel()">
    {{ 'back' | translate }}
  </button>
  <button type="button" class="btn btn-outline-dark elevation-static-1" (click)="submit()">
    {{ 'export-push-mail.save' | translate }}
  </button>
</div>
