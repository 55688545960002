import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Businesses, BusinessesSettings, BusinessesWithBoxes } from '@app/models';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BusinessService {
  constructor(private http: HttpClient) {}

  public getBusinesses(boxDetail?: boolean): Observable<Businesses[] | BusinessesWithBoxes> {
    return this.http.get<Businesses[] | BusinessesWithBoxes>(
      `${environment.backendDomain}/localbusinesses`,
      { params: { boxDetails: boxDetail || false } as never },
    );
  }

  public getBusinessesById(id: string | number): Observable<Businesses> {
    return this.http.get<Businesses>(`${environment.backendDomain}/localbusiness/${id}`);
  }

  public getBusinessesByUser(id: string | number): Observable<Businesses[]> {
    return this.http.get<Businesses[]>(
      `${environment.backendDomain}/localbusinesses/${id}/relateduser`,
    );
  }
  public getBusinessesByOrganization(id: string | number): Observable<Businesses[]> {
    return this.http.get<Businesses[]>(
      `${environment.backendDomain}/organizations/${id}/localbusinesses`,
    );
  }

  public getBusinessDetail(id: number | string): Observable<BusinessesSettings> {
    return this.http.get<BusinessesSettings>(
      `${environment.backendDomain}/localbusinesses/${id}/settings`,
    );
  }

  public getBackground2d(id: number): Observable<any> {
    return this.http.get(`${environment.backendDomain}/background/zone2D/${id}`);
  }
}
