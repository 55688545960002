<div class="legal-notice p-2">
  <h3>{{ 'legal-notice.editor' | translate }}</h3>

  <div class="section">
    <h4>{{ 'legal-notice.name' | translate }}</h4>
    <p>{{ 'legal-notice.create' | translate }}</p>
    <span class="adresse">
      <p class="p-0 m-0">
        {{ 'legal-notice.city' | translate }}<br />
        {{ 'legal-notice.adress' | translate }}<br />
        {{ 'legal-notice.ZIPCode' | translate }}<br />
        {{ 'legal-notice.country' | translate }}<br />
      </p>
    </span>
  </div>

  <div class="section">
    <h4>{{ 'legal-notice.contact' | translate }}</h4>
    <a target="_blank" href="mailto:contact@ivsoftware.fr">{{
      'legal-notice.email' | translate
    }}</a>
  </div>

  <div class="section">
    <h4>{{ 'legal-notice.termOfAccess' | translate }}</h4>
    <p class="p-0 m-0">
      {{ 'legal-notice.termOfAccessText' | translate }}<br />
      {{ 'legal-notice.termOfAccessTextComplement' | translate }}
    </p>
  </div>

  <div class="section">
    <h4>{{ 'legal-notice.cookie' | translate }}</h4>
    <p class="p-0 m-0">
      {{ 'legal-notice.cookieText' | translate }}<br />
      {{ 'legal-notice.cookieTextComplement' | translate }}<br />
      {{ 'legal-notice.cookieTextComplementTwo' | translate }}
    </p>
  </div>

  <div class="section">
    <h4>{{ 'legal-notice.moreInformations' | translate }}</h4>
    <p>
      {{ 'legal-notice.moreInformationsText' | translate }}
      <a href="https://www.ivstore.fr/fr/contact">{{ 'legal-notice.website' | translate }}</a>
    </p>
  </div>
</div>
