import { Observable } from 'rxjs';
import { ApiService } from '@app/services';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Box, BusinessesSettings, DetailCard, Organization } from '@app/models';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnInit, OnChanges {
  @Input() settingInfo: BusinessesSettings;
  @Input() globalInfo: DetailCard;
  @Input() boxInfo: Box[];

  public installationDate: string;
  public organizationInfo$: Observable<Organization>;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.organizationInfo$ = this.apiService.organisation.getOrganizationNameById(
      this.globalInfo.organization,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.boxInfo && !changes.boxInfo.firstChange) {
      const _boxWithDate = this.boxInfo.filter((value) => value.activationDate);

      if (_boxWithDate[0]) this.installationDate = _boxWithDate[0].activationDate['date'];
    }
  }

  public isString(val: string): boolean {
    return typeof val === 'string';
  }
}
