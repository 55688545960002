import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ExistingProvider, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PendingRequestsInterceptor implements HttpInterceptor {
  private _pendingRequests = 0;
  private _totalRequests = 0;
  private _completedRequests = 0;
  private _pendingRequestsStatus$ = new ReplaySubject<boolean>(1);

  get pendingRequestsStatus$(): Observable<boolean> {
    return this._pendingRequestsStatus$.asObservable();
  }

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  get completeRequest(): number {
    return this._completedRequests;
  }

  get totalRequest(): number {
    return this._totalRequests;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._pendingRequests++;
    this._totalRequests++;

    this._pendingRequestsStatus$.next(true);

    return next.handle(req).pipe(
      finalize(() => {
        this._pendingRequests--;
        this._completedRequests++;
        if (0 === this._pendingRequests) {
          this._pendingRequestsStatus$.next(false);
        }
      }),
    );
  }
}

export const PendingRequestsInterceptorProvider: ExistingProvider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useExisting: PendingRequestsInterceptor,
    multi: true,
  },
];
