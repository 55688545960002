import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-export-push-mail-empty',
  templateUrl: './export-push-mail-empty.component.html',
  styleUrls: ['./export-push-mail-empty.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportPushMailEmptyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Output() onDismiss = new EventEmitter();
  @Output() onCreate = new EventEmitter();

  public dismissModal(): void {
    this.onDismiss.emit('Click on dismiss');
  }

  public createFirst(): void {
    this.onCreate.emit('Click on dismiss');
  }
}
