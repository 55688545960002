import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss'],
})
export class DateSelectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
