import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Businesses, Grappe, Organization, UserData } from '@app/models';
import { DataProvider, UserProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { RefreshProvider } from '@app/providers/layout';
import { ApiService, AuthService, DateHelperService, ModalService } from '@app/services';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExportSelectionComponent } from '../modals/data/export/export-selection.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChildren('Dropdown') allDropdownsRefs: QueryList<NgbDropdown>;

  public searchValue: string = '';

  public organizations$: Observable<Organization[]>;
  public businesses$: Observable<Businesses[]>;

  public orgaSelected: Organization = {};
  public businessesSelected: Businesses = {};

  public openOptionToggle = false;

  public options;
  public valueSelected: string;
  public changingValue = false;

  public linkActive = '/comptage';
  public pageActive = 'comptage';

  constructor(
    private userProvider: UserProvider,
    public router: Router,
    private authService: AuthService,
    private dataProvider: DataProvider,
    private statsProvider: StatsProvider,
    private dateHelper: DateHelperService,
    private apiService: ApiService,
    public modalService: ModalService,
    private refreshProvider: RefreshProvider,
  ) {}

  public navContents: {
    path?: string;
    optionalPath?: string;
    fn?: () => void | any;
    svg?: string;
    svgOpen?: string;
    title: string;
    content?: string;
    subtitle?: string;
    appendSvg?: string;
    type?: 'search' | 'redirect' | 'dropdown';
    data$?: Observable<Businesses[] | Organization[] | Grappe[]>;
    refreshSomething?: any;
    page?: 'comptage' | 'parcours-client' | 'classement' | 'profil';
    withValue?: boolean;
    subContent?: {
      title?: string;
      data$?: Observable<Businesses[] | Organization[] | Grappe[]>;
      fn?: () => {} | void;
      subtitle?: string;
      type?: 'search' | 'redirect' | 'dropdown';
      dataType?: 'business' | 'cluster' | 'organization';
      appendSvg?: string;
      fieldValue?: string;
      redirectTo?: string;
      open?: boolean;
      refreshSomething?: any;
      page?: string;
    }[];
  }[] = [
    {
      path: '/profil',
      title: 'profil',
      subtitle: '',
      type: 'redirect',
      svg: '/assets/svg/user.svg',
      svgOpen: '/assets/svg/userColor.svg',
      page: 'profil',
    },
    {
      title: 'changeMag',
      type: 'dropdown',
      svg: '/assets/svg/businessWhite.svg',
      subContent: [
        {
          data$: this.dataProvider.getBusinesses(),
          title: 'listStoresShort',
          type: 'dropdown',
          dataType: 'business',
          fieldValue: 'legalName',
          open: true,
          refreshSomething: true,
        },
        {
          title: 'listGrappeShort',
          data$: this.dataProvider.getClusters(),
          type: 'dropdown',
          dataType: 'cluster',
          fieldValue: 'name',
          open: false,
          refreshSomething: true,
        },
      ],
    },
    {
      fn: () => {
        ('TODO : search');
      },
      title: 'rechercher',
      type: 'search',
      appendSvg: '/assets/svg/Search.svg',
    },
    {
      path: '/comptage',
      optionalPath: '/',
      title: 'counting',
      type: 'redirect',
      svg: '/assets/svg/Count.svg',
      svgOpen: '/assets/svg/CountColor.svg',
      refreshSomething: true,
      page: 'comptage',
      withValue: true,
    },
    {
      path: '/parcours-client',
      type: 'redirect',
      title: 'customerJourney',
      svg: '/assets/svg/CustomerJourney.svg',
      svgOpen: '/assets/svg/CustomerJourneyColor.svg',
      refreshSomething: true,
      page: 'parcours-client',
      withValue: true,
    },
    {
      path: '/classement',
      type: 'redirect',
      title: 'rankingStores',
      svg: '/assets/svg/RankingStores.svg',
      svgOpen: '/assets/svg/RankingStoresColor.svg',
      page: 'classement',
      refreshSomething: true,
      withValue: false,
    },
  ];

  public userName: string;

  public subs;

  ngOnInit(): void {
    this.initData();
    this.refreshProvider.listen('profil-shared-components').subscribe(() => {
      this.apiService.cluster.getCluster().subscribe((clusters) => {
        this.dataProvider.setClusters(clusters);
      });
    });
    this.linkActive = this.router.url;
    this.pageActive = this.router.url.substr(1);
  }

  public logout(): void {
    this.authService.logout();
  }

  public clickDropDown(clickedDropdown: NgbDropdown, refreshSomething?: string, title?: string) {
    this.changingValue = !this.changingValue;
    if (refreshSomething) {
      this.refreshProvider.emit({
        'is-needed-to-scrool-to-selected': {
          forceRefresh: true,
          props: { title: title },
        },
      });
    }
  }

  public selectValue(item: { value: any; type: '' | 'business' | 'cluster' | 'organization' }) {
    const redirectTo = this.pageActive;
    this.valueSelected = item.value['legalName'];
    this.options.type = item.type;
    this.allDropdownsRefs.toArray().forEach((dropdown) => dropdown.close());
    this.changingValue = false;

    this.redirectPage(item.value, item.type, redirectTo);
  }

  public changePage(page) {
    this.linkActive = '/' + page;
    this.pageActive = page;
    this.allDropdownsRefs.toArray().forEach((dropdown) => dropdown.close());

    this.redirectPage(this.options.targetData, this.options.type, '/' + page);
  }

  public redirectPage(
    itemValue: any,
    itemType: '' | 'business' | 'cluster' | 'organization',
    redirectTo: string,
  ) {
    const defaultToComptage = ['comptage', 'parcours-client'];

    if (!defaultToComptage.includes(this.pageActive, 0)) {
      this.pageActive = 'comptage';
      this.linkActive = '/comptage';
      redirectTo = 'comptage';
    }

    if (itemType === 'business') {
      this.dataProvider.setLastBusinessSelected(itemValue.id);
      this.subs = forkJoin([
        this.getBoxesDetails(itemValue.id),
        this.apiService.business.getBusinessDetail(itemValue.id),
      ]).subscribe((value) => {
        this.router.navigate([redirectTo]).then(() => {
          this.statsProvider.setOptions({
            targetData: itemValue,
            type: itemType,
            installationDate: value ? this.dateHelper.toNgbCalendar(value[0]) : null,
            targetSettings: value[1],
          });
        });
      });
    } else if (itemType === 'cluster') {
      this.router.navigate([redirectTo]).then(() => {
        this.dataProvider.setLastClusterSelected(itemValue.id);
        this.statsProvider.setOptions({
          targetData: itemValue,
          type: itemType,
          installationDate: null,
        });
      });
    }
  }

  private initData(): void {
    this.statsProvider.getOptions().subscribe((options) => {
      this.options = options;
      this.valueSelected = options.targetData['legalName'];
    });

    this.userProvider.isUserDataExist().subscribe((value: UserData) => {
      this.userName = value ? value.login : null;
      this.navContents[0] = {
        ...this.navContents[0],
        content: value.login,
        subtitle: value.roles[0],
      };
    });

    this.dataProvider
      .getLastBusinessSelected()
      .subscribe((business) => (this.businessesSelected = business));

    this.dataProvider
      .getLastOrganizationSelected()
      .subscribe((organization) => (this.orgaSelected = organization));
  }

  private getBoxesDetails(id: string | number): Observable<any> {
    return this.apiService.box.getBoxesByBusiness(id).pipe(
      map((value) => {
        const _boxWithDate = value.filter((subValue) => subValue.activationDate);
        const orderedDates = _boxWithDate.sort(function (a, b): number {
          return Date.parse(a.activationDate) - Date.parse(b.activationDate);
        });
        if (orderedDates[0]) {
          return orderedDates[0].activationDate;
        } else {
          return null;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public redirectToHome(): void {
    this.userProvider.getFavorite().subscribe((value) => {
      this.dataProvider.setLastBusinessSelected(value.localBusiness);
      this.statsProvider.setOptions({
        targetData: value.localBusiness,
        type: 'business',
        installationDate: null,
      });
    });

    this.pageActive = 'comptage';
    this.linkActive = '/comptage';

    this.router.navigate(['/comptage']);
  }

  public openOption() {
    this.openOptionToggle = !this.openOptionToggle;
  }

  public goPage(page) {
    this.pageActive = page;
    this.router.navigate(['/' + page]);
  }

  public exportData(): void {
    this.modalService.open(ExportSelectionComponent as never as ElementRef, {
      animation: true,
      size: 'lg',
    });
  }
}
