<div class="text_grey fadeIn d-flex justify-content-center h-100 w-100 m-0">
  <div class="row d-flex h-100 w-100" *ngIf="data && data.detailData$ | async as detailData">
    <div class="col-4 p-4">
      <div class="position-absolute details_back mt-5">
        <div class="pointer bg_grey px-2 d-flex flex-row align-contents-center" (click)="back()">
          <div class="px-1">
            <img width="15px" src="/assets/svg/LeftArrow.svg" />
          </div>
          <div>{{ 'back' | translate }}</div>
        </div>
      </div>
      <app-detail-card class="w-100 h-100" [detailData]="detailData"></app-detail-card>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <app-detail-info
            class="p-1"
            *ngIf="data"
            [globalInfo]="detailData"
            [data]="data"></app-detail-info>
        </div>
      </div>
    </div>
  </div>
</div>
