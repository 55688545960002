<div class="main_toastr" toastContainer></div>
<router-outlet></router-outlet>
<ngx-ui-loader [fgsTemplate]="foregroundSpinner" [bgsTemplate]="backgroundSpinner"></ngx-ui-loader>

<ng-template #foregroundSpinner>
  <!-- Your awesome foreground spinner defined here -->
  <app-foreground-loader></app-foreground-loader>
</ng-template>

<ng-template #backgroundSpinner>
  <!-- Your awesome background spinner defined here -->
  <app-background-loader></app-background-loader>
</ng-template>
