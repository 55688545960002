<h5 class="fw-bold mb-5">{{ 'generalInfo' | translate }}</h5>
<div class="list-group-item border-0 d-flex flex-row gap-2 p-0 lh-1 ms-5">
  <p class="fw-bold">{{ 'signName' | translate }} :</p>
  <div *ngIf="organizationInfo$ | async; let organizationInfo">
    {{ organizationInfo.legalName }}
  </div>
</div>

<div class="list-group-item border-0 d-flex flex-row gap-2 p-0 lh-1 ms-5">
  <p class="fw-bold">{{ 'storeName' | translate }} :</p>
  <div>{{ globalInfo.title }}</div>
</div>

<div class="list-group-item border-0 d-flex flex-row py-1 gap-2 p-0 lh-1 ms-5">
  <p class="fw-bold">{{ 'geoloc' | translate }} :</p>
  <div>{{ globalInfo.city }}</div>
</div>

<div class="list-group-item border-0 d-flex flex-row gap-2 p-0 lh-1 ms-5" *ngIf="settingInfo">
  <p class="fw-bold">{{ 'openHours' | translate }} :</p>
  <p *ngIf="!settingInfo.openingCalendar">Non communiqué</p>

  <div class="d-flex flex-column">
    <div class="d-flex flex-row" *ngFor="let calendar of settingInfo.openingCalendar | keys">
      <div>{{ 'days.' + calendar.key | translate }} :&nbsp;</div>
      <div *ngIf="isString(calendar.value); else hadHours">
        {{ 'days.state.' + calendar.value | translate }} &nbsp;
      </div>

      <ng-template #hadHours>
        <div *ngFor="let hours of calendar.value | keys">{{ hours.value }} &nbsp;</div>
      </ng-template>
    </div>
  </div>
</div>

<div class="list-group-item border-0 d-flex flex-row gap-2 p-0 lh-1 ms-5 mt-3">
  <p class="fw-bold">{{ 'installationDate' | translate }} :</p>
  <p>{{ installationDate | date }}</p>
</div>

<div class="list-group-item border-0 d-flex flex-column gap-2 p-0 lh-1 ms-5" *ngIf="boxInfo">
  <p class="fw-bold">{{ 'listBox' | translate }} :</p>
  <div *ngFor="let bInfo of boxInfo">
    {{ bInfo.name }}
  </div>
</div>
