import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { RegularExpressionConstant } from '@app/const';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private toastrService: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            console.error(`error event : ${err.name}`);
          } else {
            switch (err.status) {
              case 401: //login
                this.authenticationService.logout();
                break;
              case 403: //forbidden
                this.authenticationService.logout();
                break;
              case RegularExpressionConstant.SERVER_INTERNAL_ERROR.test(err.status as any)
                ? err.status
                : null:
                this.toastrService.error(
                  'Erreur serveur',
                  'Il y a eu une erreur interne du serveur',
                  {
                    tapToDismiss: true,
                    closeButton: true,
                    timeOut: 60000,
                  },
                );
                break;
            }
          }
        } else {
          console.error('some thing else happened');
        }
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
        }
        return throwError(err);
      }),
    );
  }
}
