<div [ngSwitch]="data.type">
  <div class="list-group" *ngSwitchCase="'store'">
    <app-store-info
      [settingInfo]="data.detailDataSettings$ | async"
      [globalInfo]="globalInfo"
      [boxInfo]="data.detailBoxes$ | async"></app-store-info>
  </div>
  <app-sign-info
    *ngSwitchCase="'sign'"
    [businessesInfo$]="data.detailBusinesses$ | async"></app-sign-info>
  <app-cluster-info
    *ngSwitchCase="'cluster'"
    [detailData]="globalInfo"
    [globalData]="data.detailData$ | async"></app-cluster-info>
</div>
