import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, Organization, StatsOptions } from '@app/models';
import { DataProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService, AuthService } from '@app/services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];
  private sub: Subscription;
  private organisations: Organization[];
  public organisation: Organization;

  public options: StatsOptions = { targetData: { legalName: '' } };
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataProvider: DataProvider,
    public statsProvider: StatsProvider,
    private authService: AuthService,
    private apiService: ApiService,
  ) {
    this.sub = this.router.events
      .pipe(
        switchMap(() => this.apiService.organisation.getOrganizationNames()),
        switchMap((orga) => {
          this.organisations = orga;
          return this.statsProvider.getOptions();
        }),
      )
      .subscribe((options) => {
        this.options = options;
        this.organisation = this.organisations.filter(
          (_orga) =>
            _orga.id === options.targetData.organization ||
            _orga.id === options.targetData.organizationId,
        )[0];
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      });
  }

  ngOnInit() {}

  private buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    let label =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
      label,
      url: nextUrl,
    };
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  OnDestroy(): void {
    this.sub.unsubscribe();
  }

  public logout(): void {
    this.authService.logout();
  }
}
