<div
  class="classement_page fadeIn text_grey d-flex flex-column justify-content-start w-100 h-100 m-0 col-12 px-1 m-0">
  <div class="row d-flex m-0 align-items-center">
    <div class="col-12 px-1">
      <app-counting-action-bar
        page="classement"
        [showExport]="true"
        [colAuto]="false"></app-counting-action-bar>
    </div>
  </div>
  <div class="row d-flex m-0 align-items-center">
    <div class="col-12 px-1">
      <app-short-actions
        (select)="onKpiSelect($event)"
        [clusterList]="clusterList"
        [kpiList]="kpiKeys"
        [disabledKpi]="disabledKpiKeys"
        [actualCluster]="actualCluster?.name || ''"
        [actualKpi]="actualKpi"
        [loading]="loading"></app-short-actions>
    </div>
  </div>
  <div class="row d-flex w-100 p-0 m-0 align-items-baseline pt-2">
    <div class="col-8 p-0 m-0 h-100 align-self-start ps-1">
      <app-short-table
        class="h-auto w-100"
        [rows]="rows"
        [columns]="columns"
        [loading]="loading"
        [zippedColumns]="zippedColumns"
        [tableFilterState]="tableFilterState"
        (shortEvent)="sortTable($event, null, actualKpi)"
        (nativeTableShortEvent)="sortTable($event, null, actualKpi)">
      </app-short-table>
    </div>
    <div class="col-4 pe-1">
      <app-short-map
        id="dash-short-map"
        [rows]="rows"
        [columns]="columns"
        [actualKpi]="actualKpi"
        [loading]="loading">
      </app-short-map>
    </div>
  </div>
  <ng-template #zippedFluctuationSummaryCell let-row="row" let-value="value">
    <div>
      <div [innerHTML]="value"></div>
    </div>
  </ng-template>
</div>
