import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RefreshProvider } from '@app/providers/layout';
import { ApiV2Service } from '@app/services/api/v2';
import { switchMap } from 'rxjs/operators';
import { AbstractReset } from '../abstract-reset';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss'],
})
export class ConfirmResetComponent extends AbstractReset implements OnInit {
  public isWaiting = true;
  public isSuccess = false;

  constructor(router: Router, refreshProvider: RefreshProvider, apiV2Service: ApiV2Service) {
    super(refreshProvider, router, apiV2Service);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 3000);
  }
}
