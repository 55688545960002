import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Box,
  Businesses,
  BusinessesSettings,
  Cluster,
  DetailCard,
  DetailType,
  Organization,
} from '@app/models';
import { Location } from '@angular/common';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  public type: DetailType;

  public data: {
    detailData$?: Observable<DetailCard | Cluster>;
    detailDataSettings$?: Observable<BusinessesSettings>;
    detailBoxes$?: Observable<Box[]>;
    detailGrappe$?: Observable<DetailCard>;
    detailBusinesses$?: Observable<Businesses[]>;
    detailCluster$?: Observable<Cluster | DetailCard>;
    type?: DetailType;
  } = {};

  private initByType = {
    store: this.initBusiness,
    sign: this.initSign,
    cluster: this.initCluster,
  };

  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.actRoute.params.subscribe((params) => {
      const id = params['id'];
      this.data.type = params['type'] as DetailType;

      this.initByType[this.data.type].bind(this)(id);
    });
  }

  public back(): void {
    this.location.back();
  }

  private initCluster(id: string): void {
    this.data = {
      ...this.data,
      detailData$: this.apiService.cluster.getClusterById(id).pipe(this.mapClusterToDetail),
    };
  }

  private initSign(id: string): void {
    this.data = {
      ...this.data,
      detailData$: this.apiService.organisation
        .getOrganizationNameById(id)
        .pipe(this.getOrgaName as never),
      detailBusinesses$: this.apiService.business.getBusinessesByOrganization(id),
    };
  }

  private initBusiness(id: string): void {
    this.data = {
      ...this.data,
      detailData$: this.apiService.business.getBusinessesById(id).pipe(this.mapBusinessToDetail),
      detailDataSettings$: this.apiService.business.getBusinessDetail(id),
      detailBoxes$: this.getBoxesDetails(id),
    };
  }

  private getBoxesDetails(id: string | number): Observable<Box[]> {
    return this.apiService.box.getBoxesByBusiness(id);
  }

  private getOrgaName = map((data: Organization): DetailCard => {
    return {
      title: data.legalName,
      subTitle: this.data.type as 'store' | 'grappe' | 'sign' | 'cluster',
      icon: this.data.type.Capitalize() as DetailType,
    };
  });

  private mapBusinessToDetail = map((data: Businesses): DetailCard => {
    return {
      title: data.legalName,
      subTitle: this.data.type,
      icon: this.data.type.Capitalize() as DetailType,
      city: data.city,
      organization: data.organization,
      enabled: data.enabled,
      zipCode: data.zipcode,
    };
  });

  private mapClusterToDetail = map((data: Cluster): any => {
    return {
      ...data,
      subTitle: this.data.type,
      icon: this.data.type.Capitalize() as DetailType,
    };
  });
}
