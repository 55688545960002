<div class="p-2">
  <div>
    <h4>Version {{ version }}</h4>
  </div>

  <div class="section">
    <p class="p-0 m-0">{{ 'about.aboutIvstore' | translate }}</p>
    <p class="p-0 m-0">{{ 'about.aboutDashboard' | translate }}</p>
  </div>

  <div class="section">
    <p class="p-0 m-0">
      {{ 'about.contact' | translate
      }}<a target="_blank" href="https://www.ivstore.fr/fr/contact">{{
        'about.contactUs' | translate
      }}</a
      >.
    </p>
  </div>

  <div class="section">
    <p>{{ 'about.copyright' | translate }}</p>
    <small
      >{{ 'about.brandIVStore' | translate }}<br />
      {{ 'about.allRight' | translate }}</small
    >
  </div>
</div>
