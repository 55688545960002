import { Component, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-journey-table-kpi',
  templateUrl: './journey-table-kpi.component.html',
  styleUrls: ['./journey-table-kpi.component.scss'],
})
export class JourneyTableKpiComponent {
  @Input() summaryData;
  @Input() summaryDataVs;
  @Input() summaryDataPercent;

  @Input() loading;

  @Input() isOnZone2D;

  @Output() zone2DAccess = new EventEmitter();
  @Output() zone2DLeft = new EventEmitter();
  public percent = 0;

  public accessZone2D() {
    this.zone2DAccess.emit();
  }

  public leftZone2D() {
    this.zone2DLeft.emit();
  }
}
