import { forkJoin, of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProvider } from './user.provider';
import { Businesses, BusinessesWithBoxes, UserApi } from '@app/models';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/services/api/api.service';
import { DataProvider } from './data/data.provider';
import { StatsProvider } from './data/stats.provider';
import { DateHelperService } from '@app/services/date-helper';

import { GaProvider } from './ga.provider';

@Injectable({ providedIn: 'root' })
export class ApiProvider {
  constructor(
    private http: HttpClient,
    private userProvider: UserProvider,
    private apiService: ApiService,
    private dataProvider: DataProvider,
    private statsProvider: StatsProvider,
    private dateHelper: DateHelperService,
    private ga$: GaProvider,
  ) {}

  /**
   * Fetch all main api data in same time (user profil, all businnesses, KPI data, preferences...)
   * @param fn callBack function (like rediction or other)
   */
  public fetchAllMainData(): Observable<any> {
    return forkJoin([
      this.http.get<UserApi>(`${environment.backendDomain}/me`),
      this.apiService.business.getBusinesses(true),
      this.apiService.cluster.getCluster(),
      this.apiService.organisation.getOrganizationNames(),
    ]).pipe(
      map((data) => {
        (data[1] as BusinessesWithBoxes).localBusiness.sort((a, b) =>
          a.legalName > b.legalName ? 1 : b.legalName > a.legalName ? -1 : 0,
        );
        this.addInstallationDateToBusiness(data[1]).subscribe((value) => {
          this.dataProvider.setBusinesses(value);
        });
        this.dataProvider.setOrganization(data[3]);
        this.dataProvider.setClusters(data[2]);
        this.getUserFavorite(data);

        this.userProvider.updateUserData({
          roles: data[0].roles,
          id: data[0].id,
          isAllMainDataFetched: true,
        });

        this.ga$.init(data[0].id);
      }),
    );
  }

  public addInstallationDateToBusiness(businesses: Businesses[] | BusinessesWithBoxes): any {
    return of(businesses).pipe(
      map((_businesses) => {
        return (_businesses as BusinessesWithBoxes).localBusiness.map((_business) => {
          let boxesHolder = (_businesses as BusinessesWithBoxes).boxes.filter(
            (box) => box.localBusiness === _business.id,
          );

          const _boxWithDate = boxesHolder.filter((subValue) => subValue.activationDate);
          const orderedDates = _boxWithDate.sort(function (a, b): number {
            return Date.parse(a.activationDate) - Date.parse(b.activationDate);
          });

          let installationDate;

          if (orderedDates[0]) {
            installationDate = orderedDates[0].activationDate;
          } else {
            installationDate = null;
          }
          _business.installationDate = this.dateHelper.toNgbCalendar(installationDate);
          return _business;
        });
      }),
    );
  }

  private getUserFavorite(data): void {
    this.apiService.user.getUserFavoriteLocalBusiness().subscribe((favorite) => {
      if (favorite.localBusiness) {
        this.apiService.box.getBoxesByBusiness(favorite.localBusiness.id).subscribe((boxData) => {
          var installationDate = null;
          const _boxWithDate = boxData.filter((value) => value.activationDate);
          const orderedDates = _boxWithDate.sort(function (a, b): number {
            return Date.parse(a.activationDate) - Date.parse(b.activationDate);
          });
          if (orderedDates[0]) {
            installationDate = orderedDates[0].activationDate;
          }
          this.statsProvider.setOptions({
            targetData: favorite.localBusiness,
            type: 'business',
            installationDate: this.dateHelper.toNgbCalendar(installationDate),
            targetSettings: favorite.settings,
          });
          this.userProvider.setFavorite(favorite);
        });
      } else {
        setTimeout(() => {
          this.apiService.box
            .getBoxesByBusiness((data[1] as BusinessesWithBoxes).localBusiness[0].id)
            .subscribe((boxData) => {
              var installationDate = null;
              const _boxWithDate = boxData.filter((value) => value.activationDate);
              const orderedDates = _boxWithDate.sort(function (a, b): number {
                return Date.parse(a.activationDate) - Date.parse(b.activationDate);
              });
              if (orderedDates[0]) {
                installationDate = orderedDates[0].activationDate;
              }
              this.statsProvider.setOptions({
                targetData: (data[1] as BusinessesWithBoxes).localBusiness[0],
                type: 'business',
                installationDate: this.dateHelper.toNgbCalendar(installationDate),
                targetSettings: {},
              });
              this.userProvider.setFavorite({
                localBusiness: (data[1] as BusinessesWithBoxes).localBusiness[0],
                settings: {},
              });
            });
        });
      }
    });
  }
}
