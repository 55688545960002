import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Businesses, UserData } from '@app/models';
import { UserProvider } from '@app/providers';
import { ApiService, ToastService } from '@app/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-businesses-info',
  templateUrl: './businesses-info.component.html',
  styleUrls: ['./businesses-info.component.scss'],
})
export class BusinessesInfoComponent {
  @Input() businesses$: Observable<Businesses[]>;
  @Input() user: UserData;
  @Input() favorite: {
    settings: any;
    localBusiness: Businesses;
  };

  public searchValue: string;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userProvider: UserProvider,
    private toastr: ToastService,
  ) {}

  openDetails(business: Businesses): void {
    this.router.navigate(['/details', { id: business.id, type: 'store' }]);
  }

  public setFavorite(business: Businesses): void {
    this.apiService.user.postUserFavoriteLocalBusiness(this.user, business).subscribe(
      (value) => {
        this.userProvider.setFavorite({
          settings: value.settings,
          localBusiness: value,
        });
        this.toastr.success('Préférence', `Modification du magasin favori pour ${value.legalName}`);
      },
      () => {
        this.toastr.error('Préférence', `Erreur lors de la modification du magasin favori`);
      },
    );
  }
}
