import { Observable } from 'rxjs';
import { DateRangeApi } from '@app/models';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class StatsService {
  constructor(private http: HttpClient) {}

  public getFrequenciesByBusinesses(id: string | number, range?: DateRangeApi): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${id}/customers/allfrequencies`,
      { params: range as never },
    );
  }

  public getAllByLocalBusiness(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/alldata`,
      { params: range as never },
    );
  }

  public getPercentTimeSpentByLocalBusiness(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/percent/alltimespent`,
      { params: range as never },
    );
  }

  public getAverageTimeSpentByLocalBusiness(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/average/alltimespent`,
      { params: range as never },
    );
  }

  public getAverageTimeSpentByLocalBusinessByHour(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/percent/alltimespent`,
      { params: range as never },
    );
  }

  public getCustomerRepartitionByDay(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/customers/repartition/day`,
      { params: range as never },
    );
  }

  public getCustomerRepartitionByMonth(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/customers/repartition/month`,
      { params: range as never },
    );
  }
  public getCustomerRepartitionByHour(
    localBusinessId: string | number,
    range?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/customers/allrepartition/hour`,
      { params: range as never },
    );
  }

  public getCustomerRepartitionByHourByBox(
    localBusinessId: string | number,
    rangeAndId?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/customers/allrepartition/hour`,
      { params: rangeAndId as never },
    );
  }

  public getCustomerRepartitionByDayByBox(
    localBusinessId: string | number,
    rangeAndId?: DateRangeApi,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.backendDomain}/localbusinesses/${localBusinessId}/customers/allrepartition/day`,
      { params: rangeAndId as never },
    );
  }
}
