export * from './classement/classement.component';
export * from './comptage/comptage.component';
export * from './error/error.component';
export * from './login/login.component';
export * from './parcours-client/parcours-client.component';
export * from './profil/profil.component';
export * from './resetPassword/reset.component';
export * from './details/details.component';
export * from './help/help.component';
export * from './legal-notice/legal-notice.component';
export * from './about/about.component';
