export class User {
  id?: number;
  username?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  jwt?: string;
  isLogged?: boolean;
  roles?: string | string[];
  login?: string | string[];
}

export interface UserData extends User {
  login?: string;
  rememberOption?: boolean;
  isAllMainDataFetched?: boolean;
  isFirstStart?: boolean;
  disableAutoLogin?: boolean;
}

export interface UserApi {
  id?: number;
  username?: string;
  username_canonical?: string;
  plainPassword?: string;
  email?: string;
  email_canonical?: string;
  roles?: any;
  enabled?: boolean | number;
  reporting?: boolean | number;
  last_login?: string;
  organizationId?: number | [];
  businessesId?: number | [];
  localBusinessId?: number | [];
  clusters?: any;
}
