import { Component, OnInit } from '@angular/core';
import { PendingRequestsInterceptor } from '@app/interceptors/pending-request.intercepto';
import { MathService } from '@app/services';

@Component({
  selector: 'app-background-loader',
  templateUrl: './background-loader.component.html',
  styleUrls: ['./background-loader.component.scss'],
})
export class BackgroundLoaderComponent implements OnInit {
  public totalRequest = 0;
  public completeRequest = 0;

  constructor(
    private pendingRequestsInterceptor: PendingRequestsInterceptor,
    private mathService: MathService,
  ) {}

  ngOnInit(): void {
    this.pendingRequestsInterceptor.pendingRequestsStatus$.subscribe(() => {
      this.totalRequest = this.pendingRequestsInterceptor.totalRequest;
      this.completeRequest = this.pendingRequestsInterceptor.completeRequest;
    });
  }

  get percent() {
    return Math.trunc(this.mathService.getAverage(this.completeRequest, this.totalRequest) * 100);
  }
}
