<div class="form-group d-flex d-row align-items-center">
  <div class="col-6 p-0 m-0 d-flex">
    <div class="font-weight-bold" *ngIf="label" for="input_{{ label }}">
      {{ label | translate }} :
    </div>
  </div>
  <div class="col-6 p-0 m-0">
    <div class="input-group d-flex bg_grey border border-bottom align-items-center rounded-right">
      <input
        [type]="!applyMask ? 'password' : 'text'"
        class="form-control bg_transparent no-radius no-border"
        id="input_{{ label }}"
        placeholder="{{ placeHolder | translate }}"
        (input)="onChange($event.target.value)"
        [(ngModel)]="value"
        [ngClass]="{
          'is-invalid': showError
        }"
        [disabled]="disabled" />
      <div class="input-group-append" *ngIf="maskOption">
        <div class="p-1" (click)="toogleMask()">
          <img
            width="20px"
            src="{{ !applyMask ? '/assets/svg/See.svg' : '/assets/svg/Unsee.svg' }}" />
        </div>
      </div>
    </div>
  </div>
</div>
