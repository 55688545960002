import { Injectable } from '@angular/core';
import { ToastService } from './../../services/toast.service';
import { parse, ParseResult, unparse } from 'papaparse';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PapaparseProvider {
  private geocoder: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private geocoder$: Observable<any> = this.geocoder.asObservable();

  constructor(private toast: ToastService) {}

  public _unparse(data, fileName, customHeader?: string) {
    let csv = unparse(data, {
      quotes: false,
      header: true,
      delimiter: ',',
    });

    if (customHeader) {
      csv = customHeader + '\n' + csv;
    }
    this.clickAndDownloadCSV(csv, fileName);
  }

  public _parse(country: string, data: any[]): any {
    return parse(('./assets/geocoder/' + country + '.txt') as never, {
      download: true,
      header: false,
      encoding: 'UTF-8',
      fastMode: true,
      skipEmptyLines: true,
      error: (err, file) => {
        console.log(err);
        console.log(file);
        this.toast.error('Erreur !', 'Il y a eu une erreur !');
        this.toast.error(err.name, err.message);
      },
      complete: (result) => {
        const holder = [];
        var geocode;
        data.forEach((_data) => {
          geocode = result.data.find((geocode) =>
            geocode[1] ? geocode[1].split(' ')[0] === _data.zipcode : false,
          );
          if (geocode) {
            holder.push({
              id: _data.id,
              businessName: _data.legalName,
              postalCode: geocode[1],
              shortPostalCode: geocode[6],
              city: geocode[2],
              placeName: geocode[3],
              state: geocode[5],
              fullState: geocode[7],
              lat: Number(geocode[9]),
              lng: Number(geocode[10]),
              accuracy: Number(geocode[11]),
            });
          }
        });
        this.setGeocoderData(holder);
      },
    });
  }

  public setGeocoderData(data: any): void {
    this.geocoder.next(data);
  }

  public getGeocoderData(): Observable<any> {
    return this.geocoder$;
  }

  public clickAndDownloadCSV(csv, fileName): void {
    var link = window.document.createElement('a');

    link.setAttribute('href', 'data:text/csv' + encodeURI(csv));
    link.setAttribute('download', fileName + '.csv');
    link.click();
    link.remove();
  }
}
