<div class="login_page_background d-flex justify-content-center h-100 w-100 m-0">
  <div
    class="col-12 col-md-6 col-xl-4 login_card_background fadeIn rounded d-flex justify-content-center py-5 align-self-center">
    <div class="row">
      <div
        class="h-100 text-right d-flex flex-column justify-content-center align-items-center col-12">
        <h1>404</h1>
        <p>Oops il semblerait que cette page n'existe pas !</p>
        <p class="pointer" (click)="goDashboard()">Revenir au Dashboard</p>
      </div>
    </div>
  </div>
</div>
