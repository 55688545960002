import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ModalService } from '@app/services';
import { ViewChangelogComponent } from '../view-changelog/view-changelog.component';

@Component({
  selector: '[app-toast-is-update]',
  templateUrl: './toast-is-update.component.html',
  styleUrls: ['./toast-is-update.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ]),
        ),
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastIsUpdateComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private modalService: ModalService,
  ) {
    super(toastrService, toastPackage);
    this.options.tapToDismiss = false;
  }
  public action(event: Event): void {
    event.stopPropagation();
    this.close();
  }

  public openChange(): void {
    this.modalService.open(
      ViewChangelogComponent as never as ElementRef,
      {
        animation: true,
        centered: true,
        size: 'lg',
      },
      () => {},
    );
    this.close();
  }

  private close(): void {
    this.options.tapToDismiss = true;
    this.tapToast();
  }
}
