import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Businesses, Cluster, Row } from '@app/models';
import { customNumberFilter } from '@app/pipes';
import { DataProvider, GeocoderProvider, StatsProvider } from '@app/providers';
import { ApiService, DateHelperService, MathService, TableHelperService } from '@app/services';
import { ApiV2Service } from '@app/services/api/v2';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-classement',
  templateUrl: './classement.component.html',
  styleUrls: ['./classement.component.scss'],
})
export class ClassementComponent implements OnInit, OnDestroy {
  public loading = true;
  private firstLoad = true;
  private range: any = {
    start: '',
    startVs: '',
  };
  private rangeApi;
  private options;
  public tableFilterState = {};
  public loadingAffix = {
    salesRevenue: '€',
    attendanceTime: '',
    attractivenessRate: '%',
    entryNumber: '',
    billTickets: '',
    transformRate: '%',
  };

  public clusterList: Cluster[];

  private _allBusinessList: Businesses[];

  public defaultKpi: string = 'entryNumber';
  public kpiKeys = Object.keys(this.loadingAffix);
  public disabledKpiKeys = [];
  public actualKpi: string = null;
  public actualCluster: Cluster = null;

  public rows: Row[] = [];

  public columns: TableColumn[] = [
    { name: 'Rang' },
    { name: 'Nom', prop: 'name' },
    { name: '', prop: 'valueVs', headerClass: 'text_orange' },
    { name: '', prop: 'value', headerClass: 'text_blue' },
  ];

  public zippedColumns: Row[] = [];

  @ViewChild('zippedFluctuationSummaryCell')
  zippedFluctuationSummaryCell: QueryList<any>;

  private columnsToShow: { [key: string]: TableColumn[] } = {
    salesRevenue: [
      {
        name: 'Rang',
        prop: 'rankFluctuationSalesRevenue',
        summaryFunc: () => null,
      },
      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Total',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',
        prop: 'salesRevenueVs',
        headerClass: 'text_blue',

        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' €';
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'salesRevenue'),
        sortable: true,
      },
      {
        name: '',
        prop: 'salesRevenue',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ') + ' €';
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'salesRevenue'),
        sortable: true,
      },
    ],
    attendanceTime: [
      {
        name: 'Rang',
        prop: 'rankFluctuationAttendanceTime',
        summaryFunc: () => null,
      },

      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Moyenne',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',
        prop: 'attendanceTimeVs',
        headerClass: 'text_blue',
        summaryFunc: (cells) => this.getSummaryLine(cells, 'attendanceTime'),
        sortable: true,
      },
      {
        name: '',

        prop: 'attendanceTime',
        headerClass: 'text_orange',
        summaryFunc: (cells) => this.getSummaryLine(cells, 'attendanceTime'),
        sortable: true,
      },
    ],
    attractivenessRate: [
      {
        name: 'Rang',
        prop: 'rankFluctuationAttractiveness',
        summaryFunc: () => null,
      },

      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Moyenne',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',
        prop: 'attractivenessRateVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1, ',', ' ') + ' %';
          },
        },

        summaryFunc: (cells) => this.getSummaryLine(cells, 'attractivenessRate'),
        sortable: true,
      },
      {
        name: '',
        prop: 'attractivenessRate',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1, ',', ' ') + ' %';
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'attractivenessRate'),
        sortable: true,
      },
    ],
    entryNumber: [
      {
        name: 'Rang',
        prop: 'rankFluctuationEntryNumber',
        summaryFunc: () => null,
      },

      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Total',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',
        prop: 'entryNumberVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'entryNumber'),
        sortable: true,
      },
      {
        name: '',
        prop: 'entryNumber',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'entryNumber'),
        sortable: true,
      },
    ],
    billTickets: [
      {
        name: 'Rang',
        prop: 'rankFluctuationBillTickets',
        summaryFunc: () => null,
      },

      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Total',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',

        prop: 'billTicketsVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'billTickets'),
        sortable: true,
      },
      {
        name: '',
        prop: 'billTickets',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0, ',', ' ');
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'billTickets'),
        sortable: true,
      },
    ],
    transformRate: [
      {
        name: 'Rang',
        prop: 'rankFluctuationTransform',
        summaryFunc: () => null,
      },

      {
        name: 'Nom',
        prop: 'name',
        summaryFunc: () => 'Moyenne',
        cellClass: 'd-flex align-items-center justify-content-start',
        sortable: true,
      },
      {
        name: '',
        prop: 'transformRateVs',
        headerClass: 'text_blue',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1) + ' %';
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'transformRate'),
        sortable: true,
      },
      {
        name: '',
        prop: 'transformRate',
        headerClass: 'text_orange',
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1) + ' %';
          },
        },
        summaryFunc: (cells) => this.getSummaryLine(cells, 'transformRate'),
        sortable: true,
      },
    ],
  };

  private zippedLastCol: { [key: string]: TableColumn[] } = {
    salesRevenue: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationSalesRevenue',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return value + ' €';
          },
        },
      },
      {
        name: '%',
        prop: 'percentSalesRevenue',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
    attendanceTime: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationAttendanceTime',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
      },
      {
        name: '%',
        prop: 'percentAttendanceTime',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
    attractivenessRate: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationAttractiveness',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1, ',', ' ') + ' Pt';
          },
        },
      },
      {
        name: '%',
        prop: 'percentAttractiveness',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 0) + ' %';
          },
        },
      },
    ],
    entryNumber: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationEntryNumber',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
      },
      {
        name: '%',
        prop: 'percentEntryNumber',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
    billTickets: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationBillTickets',
        cellClass: (data: any) => {
          if (data.value.toString() === '0') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
      },
      {
        name: '%',
        prop: 'percentBillTickets',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
    transformRate: [
      {
        name: 'Fluctuation',
        prop: 'fluctuationTransform',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 Pt') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return customNumberFilter(value, 1) + ' Pt';
          },
        },
      },
      {
        name: '%',
        prop: 'percentTransformRate',
        cellClass: (data: any) => {
          if (data.value.toString() === '0 %') {
            return '';
          }
          return data.value.toString().includes('-') ? 'text_red' : 'text_green';
        },
        pipe: {
          transform: (value) => {
            return this.statsProvider.truncInferiorToTwenty(value) + ' %';
          },
        },
      },
    ],
  };

  private user: any;

  private subs: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private tableHelper: TableHelperService,
    private statsProvider: StatsProvider,
    private dateHelperService: DateHelperService,
    private dataProvider: DataProvider,
    private router: ActivatedRoute,
    private geocoder: GeocoderProvider,
    private changeDetector: ChangeDetectorRef,
    private mathService: MathService,
    private apiV2Service: ApiV2Service,
  ) {}

  ngOnInit(): void {
    // ALWAYS UP , OR YOU WILL LOST ALL YOUR SUB
    this.router.params.pipe(take(1)).subscribe(() => {
      if (this.subs.length > 0) {
        this.subs.forEach((sub) => sub.unsubscribe());
        this.subs = [];
      }
    });

    this.geocoder.getSelectedData().subscribe((value) => {
      if (value) {
        setTimeout(() => {
          this.changeDetector.detectChanges();
        });
      }
    });

    this.dataProvider.getUser().subscribe((value) => {
      this.user = value;

      this.initTable();
    });
  }

  public initTable() {
    const sub2 = this.dataProvider.getBusinesses().subscribe((businessesList) => {
      this._allBusinessList = businessesList;
    });

    const sub3 = this.statsProvider.getOptions().subscribe((options) => {
      this.loading = true;
      this.options = options;
      const toHuman = this.dateHelperService.toHuman(options);
      this.range = {
        start: toHuman.start,
        startVs: toHuman.startVs,
      };

      this.rangeApi = this.dateHelperService.rangeToUnixTimestampWithCheck(
        {
          begin: options.range.begin,
          end: options.range.end,
        },
        {
          begin: options.range.beginVs,
          end: options.range.endVs,
        },
      );

      if (this.firstLoad) {
        this.firstInit();
        this.firstLoad = false;
      } else {
        this.refresh();
      }
    });

    this.subs.push(sub2);
    this.subs.push(sub3);
  }

  private firstInit(): void {
    this.rows = [];
    this.refeshColumns(this.range);
    this.apiService.cluster.getCluster().subscribe((clusters) => {
      this.actualCluster = {
        //@ts-ignore
        localBusiness: this._allBusinessList as any,
        id: 'all',
        name: 'Tous les magasins',
      };
      this.clusterList = [this.actualCluster, ...clusters];

      this.geocoder.setData(this.actualCluster);

      this.apiV2Service
        .getRankData(this.actualCluster.id, {
          begin: this.rangeApi.begin,
          beginVs: this.rangeApi.beginVs,
          end: this.rangeApi.end,
          endVs: this.rangeApi.endVs,
        })
        .subscribe((value) => {
          this.mapToTable(value);
          this.sortTable(
            'fluctuation' + this.defaultKpi.Capitalize().replace('Rate', ''),
            'asc',
            this.defaultKpi.LowerFirstLetter(),
          );
          this.showData(this.defaultKpi as never);
          this.changeDetector.detectChanges();
        });
    });

    this.refeshColumns(this.range);
  }

  public getSummaryLine(cells: number[], prop: string): any {
    var i = 0;
    switch (prop) {
      case 'salesRevenue': // €
      case 'entryNumber': // number
      case 'billTickets': // number
        cells.forEach((value: any) => {
          i += value;
        });
        break;
      case 'transformRate': // %
      case 'attractivenessRate': // %
        cells.forEach((value: any) => {
          i += Number(customNumberFilter(value, 1, '.', '').replace(',', '.'));
        });
        i = i / cells.length ?? 0;
        break;
      case 'attendanceTime': // 00'00
        cells.forEach((value: any) => {
          i += this.dateHelperService.minutesAndSecondsToDuration(value);
        });
        i = this.dateHelperService.secondsDurationToMinutes((i / cells.length ?? 0) / 1000);
        break;
      default:
        this.changeDetector.detectChanges();
        break;
    }
    return i;
  }

  private initSummaryCell() {
    Object.keys(this.zippedLastCol).forEach((colKey) => {
      this.zippedLastCol[colKey].map((col) => {
        col.summaryTemplate = this.zippedFluctuationSummaryCell.first;
        col.summaryFunc = (d) => {
          const rows_copy = [...this.rows];
          let sum: any = {
            fluctuation: 0,
            percent: 0,
            total: 0,
            totalVs: 0,
          };
          rows_copy.forEach((row_copy) => {
            switch (colKey) {
              case 'salesRevenue':
                sum.total += row_copy['salesRevenue'];
                sum.totalVs += row_copy['salesRevenueVs'];
                sum.fluctuation += row_copy['fluctuationSalesRevenue'];
                break;
              case 'entryNumber':
                sum.total += row_copy['entryNumber'];
                sum.totalVs += row_copy['entryNumberVs'];
                sum.fluctuation += row_copy['fluctuationEntryNumber'];
                break;

              case 'billTickets':
                sum.total += row_copy['billTickets'];
                sum.totalVs += row_copy['billTicketsVs'];
                sum.fluctuation += row_copy['fluctuationBillTickets'];
                break;

              case 'transformRate':
                sum.total += row_copy['_ROUNDED_transformRate'];
                sum.totalVs += row_copy['_ROUNDED_transformRateVs'];
                break;

              case 'attractivenessRate':
                sum.total += row_copy['_ROUNDED_attractivenessRate'];
                sum.totalVs += row_copy['_ROUNDED_attractivenessRateVs'];
                break;

              case 'attendanceTime':
                sum.total += this.dateHelperService.secondsRemoveMilliseconds(
                  row_copy['_RAW_attendanceTime'],
                );
                sum.totalVs += this.dateHelperService.secondsRemoveMilliseconds(
                  row_copy['_RAW_attendanceTimeVs'],
                );
                sum.fluctuation +=
                  row_copy['_RAW_attendanceTime'] - row_copy['_RAW_attendanceTimeVs'];
                break;
            }
          });

          switch (colKey) {
            case 'salesRevenue':
              sum.fluctuation = customNumberFilter(sum.fluctuation, 0, ',', ' ') + ' €';
              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(sum.totalVs, sum.total),
                ) + ' %';
              break;
            case 'entryNumber':
              sum.fluctuation = sum.fluctuation;
              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(sum.totalVs, sum.total),
                ) + ' %';
              break;

            case 'billTickets':
              sum.fluctuation = sum.fluctuation;
              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(sum.totalVs, sum.total),
                ) + ' %';
              break;

            case 'transformRate':
              sum.total = sum.total / rows_copy.length;
              sum.totalVs = sum.totalVs / rows_copy.length;
              sum.fluctuation = customNumberFilter(sum.total - sum.totalVs, 1) + ' Pt';
              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(sum.totalVs, sum.total),
                ) + ' %';
              break;

            case 'attractivenessRate':
              sum.total = sum.total / rows_copy.length;
              sum.totalVs = sum.totalVs / rows_copy.length;
              sum.fluctuation = customNumberFilter(sum.total - sum.totalVs, 1) + ' Pt';
              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(
                    this.mathService.upto1Decimal(sum.totalVs),
                    this.mathService.upto1Decimal(sum.total),
                  ),
                ) + ' %';
              break;

            case 'attendanceTime':
              sum.fluctuation = this.dateHelperService.millisecondsToMinutes(sum.fluctuation, true);

              sum.percent =
                this.statsProvider.truncInferiorToTwenty(
                  this.mathService.getPercent(sum.totalVs, sum.total),
                ) + ' %';
              break;
          }
          return `<div class="d-flex flex-row align-items-center justify-content-center">
                                <div class="px-2 w-100 text-center ${
                                  sum.fluctuation.toLocaleString('FR-fr').includes('-')
                                    ? 'font-dark-red'
                                    : 'font-dark-green'
                                }">${sum.fluctuation}</div>
                                <div class="px-2 w-100 text-center ${
                                  sum.percent.toLocaleString('FR-fr').includes('-')
                                    ? 'font-dark-red'
                                    : 'font-dark-green'
                                }"">${sum.percent}</div>
                            </div>`;
        };
        return col;
      });
    });
  }

  private refresh(): void {
    this.rows = [];
    this.refeshColumns(this.range);
    this.geocoder.setData(this.actualCluster);

    this.apiV2Service
      .getRankData(this.actualCluster.id, {
        begin: this.rangeApi.begin,
        beginVs: this.rangeApi.beginVs,
        end: this.rangeApi.end,
        endVs: this.rangeApi.endVs,
      })
      .subscribe((value) => {
        this.mapToTable(value);
        this.sortTable(
          'fluctuation' + this.defaultKpi.Capitalize().replace('Rate', ''),
          'asc',
          this.defaultKpi.LowerFirstLetter(),
        );
        this.showData(this.defaultKpi as never);
        this.changeDetector.detectChanges();
      });

    this.refeshColumns(this.range);
  }

  private mapToTable(data: any) {
    const holder = [];
    if (data) {
      data.forEach((zoneData) => {
        holder.push({
          id: zoneData.id,
          name: zoneData.name,
          isAttractivityEnabled: zoneData.isAttractivityEnabled,
          isGroupsCountingEnabled: zoneData.isGroupsCountingEnabled,
          rang: 0,
          _RAW_wifiVisits: zoneData.curent.wifiVisits,
          _RAW_videoVisits: zoneData.curent.videoVisits,
          _RAW_wifiPassages: zoneData.curent.wifiPassages,
          _RAW_videoPassages: zoneData.curent.videoPassages,
          _RAW_wifiVisitsVs: zoneData.versus.wifiVisits,
          _RAW_videoVisitsVs: zoneData.versus.videoVisits,
          _RAW_wifiPassagesVs: zoneData.versus.wifiPassages,
          _RAW_videoPassagesVs: zoneData.versus.videoPassages,
          entryNumber: zoneData.curent.customers,
          entryNumberVs: zoneData.versus.customers,
          entryNumberGroup: zoneData.curent.customersGroup,
          entryNumberGroupVs: zoneData.versus.customersGroup,
          attractivenessRate: this.mathService.getAttractivityPercent(
            zoneData.isAttractivityEnabled
              ? zoneData.curent.videoVisits
              : zoneData.curent.customers,
            zoneData.isAttractivityEnabled
              ? zoneData.curent.videoPassages
              : zoneData.curent.wifiPassages / 0.295,
          ),
          attractivenessRateVs: this.mathService.getAttractivityPercent(
            zoneData.isAttractivityEnabled
              ? zoneData.versus.videoVisits
              : zoneData.versus.customers,
            zoneData.isAttractivityEnabled
              ? zoneData.versus.videoPassages
              : zoneData.versus.wifiPassages / 0.295,
          ),
          _ROUNDED_attractivenessRate: Number(
            customNumberFilter(
              this.mathService.getAttractivityPercent(
                zoneData.isAttractivityEnabled
                  ? zoneData.curent.videoVisits
                  : zoneData.curent.wifiVisits,
                zoneData.isAttractivityEnabled
                  ? zoneData.curent.videoPassages
                  : zoneData.curent.wifiPassages,
              ),
              1,
              '.',
              '',
            ).replace(',', '.'),
          ),
          _ROUNDED_attractivenessRateVs: Number(
            customNumberFilter(
              this.mathService.getAttractivityPercent(
                zoneData.isAttractivityEnabled
                  ? zoneData.versus.videoVisits
                  : zoneData.versus.wifiVisits,
                zoneData.isAttractivityEnabled
                  ? zoneData.versus.videoPassages
                  : zoneData.versus.wifiPassages,
              ),
              1,
              '.',
              '',
            ).replace(',', '.'),
          ),
          attendanceTime: this.dateHelperService.millisecondsToMinutes(
            this.dateHelperService.secondsRemoveMilliseconds(zoneData.curent.timespent),
          ),
          attendanceTimeVs: this.dateHelperService.millisecondsToMinutes(
            this.dateHelperService.secondsRemoveMilliseconds(zoneData.versus.timespent),
          ),

          _RAW_attendanceTime: zoneData.curent.timespent,
          _RAW_attendanceTimeVs: zoneData.versus.timespent,
          salesRevenue: Math.trunc(zoneData.curent.ca),
          salesRevenueVs: Math.trunc(zoneData.versus.ca),
          transformRate: this.mathService.getAttractivityPercent(
            zoneData.curent.ticket,
            zoneData.isGroupsCountingEnabled
              ? zoneData.curent.customersGroup
              : zoneData.curent.customers,
          ),
          transformRateVs: this.mathService.getAttractivityPercent(
            zoneData.versus.ticket,
            zoneData.isGroupsCountingEnabled
              ? zoneData.versus.customersGroup
              : zoneData.versus.customers,
          ),

          _ROUNDED_transformRate: Number(
            customNumberFilter(
              this.mathService.getAttractivityPercent(
                zoneData.curent.ticket,
                zoneData.isGroupsCountingEnabled
                  ? zoneData.curent.customersGroup
                  : zoneData.curent.customers,
              ),
              1,
              '.',
              '',
            ).replace(',', '.'),
          ),
          _ROUNDED_transformRateVs: Number(
            customNumberFilter(
              this.mathService.getAttractivityPercent(
                zoneData.versus.ticket,
                zoneData.isGroupsCountingEnabled
                  ? zoneData.versus.customersGroup
                  : zoneData.versus.customers,
              ),
              1,
              '.',
              '',
            ).replace(',', '.'),
          ),
          billTickets: zoneData.curent.ticket,
          billTicketsVs: zoneData.versus.ticket,
          percent: 0,
          percentEntryNumber: this.mathService.getPercent(
            zoneData.versus.customers,
            zoneData.curent.customers,
          ),
          percentAttractiveness: this.mathService.getPercent(
            this.mathService.getAttractivityPercent(
              zoneData.isAttractivityEnabled
                ? zoneData.versus.videoVisits
                : zoneData.versus.wifiVisits,
              zoneData.isAttractivityEnabled
                ? zoneData.versus.videoPassages
                : zoneData.versus.wifiPassages,
            ),
            this.mathService.getAttractivityPercent(
              zoneData.isAttractivityEnabled
                ? zoneData.curent.videoVisits
                : zoneData.curent.wifiVisits,
              zoneData.isAttractivityEnabled
                ? zoneData.curent.videoPassages
                : zoneData.curent.wifiPassages,
            ),
          ),
          percentAttendanceTime: this.mathService.getPercent(
            zoneData.versus.timespent,
            zoneData.curent.timespent,
          ),
          percentSalesRevenue: this.mathService.getPercent(
            Math.trunc(zoneData.versus.ca),
            Math.trunc(zoneData.curent.ca),
          ),
          percentTransformRate: this.mathService.getPercent(
            this.mathService.getAttractivityPercent(
              zoneData.versus.ticket,
              zoneData.isGroupsCountingEnabled
                ? zoneData.versus.customersGroup
                : zoneData.versus.customers,
            ),
            this.mathService.getAttractivityPercent(
              zoneData.curent.ticket,
              zoneData.isGroupsCountingEnabled
                ? zoneData.curent.customersGroup
                : zoneData.curent.customers,
            ),
          ),
          percentBillTickets: this.mathService.getPercent(
            zoneData.versus.ticket,
            zoneData.curent.ticket,
          ),
          fluctuation: 0,
          fluctuationAttractiveness:
            Number(
              customNumberFilter(
                this.mathService.getAttractivityPercent(
                  zoneData.isAttractivityEnabled
                    ? zoneData.curent.videoVisits
                    : zoneData.curent.wifiVisits,
                  zoneData.isAttractivityEnabled
                    ? zoneData.curent.videoPassages
                    : zoneData.curent.wifiPassages,
                ),
                1,
                '.',
                '',
              ).replace(',', '.'),
            ) -
            Number(
              customNumberFilter(
                this.mathService.getAttractivityPercent(
                  zoneData.isAttractivityEnabled
                    ? zoneData.versus.videoVisits
                    : zoneData.versus.wifiVisits,
                  zoneData.isAttractivityEnabled
                    ? zoneData.versus.videoPassages
                    : zoneData.versus.wifiPassages,
                ),
                1,
                '.',
                '',
              ).replace(',', '.'),
            ),
          fluctuationAttendanceTime: this.dateHelperService.millisecondsToMinutes(
            this.dateHelperService.secondsRemoveMilliseconds(zoneData.curent.timespent) -
              this.dateHelperService.secondsRemoveMilliseconds(zoneData.versus.timespent),
          ),
          fluctuationTransform:
            Number(
              customNumberFilter(
                this.mathService.getAttractivityPercent(
                  zoneData.curent.ticket,
                  zoneData.isGroupsCountingEnabled
                    ? zoneData.curent.customersGroup
                    : zoneData.curent.customers,
                ),
                1,
                '.',
                '',
              ).replace(',', '.'),
            ) -
            Number(
              customNumberFilter(
                this.mathService.getAttractivityPercent(
                  zoneData.versus.ticket,
                  zoneData.isGroupsCountingEnabled
                    ? zoneData.versus.customersGroup
                    : zoneData.versus.customers,
                ),
                1,
                '.',
                '',
              ).replace(',', '.'),
            ),
          fluctuationSalesRevenue: Math.trunc(zoneData.curent.ca) - Math.trunc(zoneData.versus.ca),
          fluctuationBillTickets:
            Math.trunc(zoneData.curent.ticket) - Math.trunc(zoneData.versus.ticket),
          fluctuationEntryNumber: zoneData.curent.customers - zoneData.versus.customers,
        });
      });

      const toRank = [
        'fluctuationAttractiveness',
        'fluctuationAttendanceTime',
        'fluctuationTransform',
        'fluctuationSalesRevenue',
        'fluctuationBillTickets',
        'fluctuationEntryNumber',
      ];
      toRank.forEach((rankField) => {
        holder.sort(function (a, b) {
          return (
            Number((b[rankField] + '').replace('.', '').replace(',', '.').replace("'", '')) -
            Number((a[rankField] + '').replace('.', '').replace(',', '.').replace("'", ''))
          );
        });

        var rank = 1;
        for (var i = 0; i < holder.length; i++) {
          if (
            i > 0 &&
            (Number(
              (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
            ) ===
              Number(
                (holder[i - 1][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
              ) ||
              Number(
                (holder[i][rankField] + '').replace('.', '').replace(',', '.').replace("'", ''),
              ) <
                Number(
                  (holder[i - 1][rankField] + '')
                    .replace('.', '')
                    .replace(',', '.')
                    .replace("'", ''),
                ))
          ) {
            rank++;
          }
          holder[i]['rank' + rankField.Capitalize()] = rank;
        }
      });
      this.rows = holder;
    }
  }

  public onKpiSelect(item: { type: 'cluster' | 'kpi'; value: string }) {
    if (item.type === 'kpi') {
      this.sortTable(
        'fluctuation' + item.value.Capitalize().replace('Rate', ''),
        'asc',
        item.value.LowerFirstLetter(),
      );
      this.showData(item.value as never);
      this.changeDetector.detectChanges();
    }

    if (item.type === 'cluster') {
      this.actualCluster = this.clusterList.find((cluster) => cluster.id === item.value);
      this.loading = true;
      this.geocoder.setSelectedData(null);
      this.statsProvider.setOptions(this.options);
    }
  }

  public showData(
    name:
      | 'salesRevenue'
      | 'attendanceTime'
      | 'attractivenessRate'
      | 'entryNumber'
      | 'billTickets'
      | 'transformRate',
  ) {
    this.loading = false;
    const holder = this.rows;
    this.actualKpi = name;
    this.rows = [...holder];
    this.columns = [...this.columnsToShow[name]];
    this.zippedColumns = [...this.zippedLastCol[name]];
    this.initSummaryCell();
  }

  // TODO ADD TO INHERITS COMPONENET
  public cleanSortTableState(): void {
    Object.keys(this.tableFilterState).forEach((key) => {
      this.tableFilterState[key] = undefined;
    });
  }

  // TODO ADD TO INHERITS COMPONENET
  public sortTable(field: string, forceMode?: 'asc' | 'desc', fieldIfAllEquals?: string): void {
    const sortModes = ['asc', 'desc'];
    var sortMode = '';

    if (this.tableFilterState[field] === true) {
      Object.keys(this.tableFilterState).forEach((state) => {
        this.tableFilterState[state] = field === state ? false : undefined;
      });
      this.tableFilterState[field] = false;
      sortMode = sortModes[0];
    } else {
      Object.keys(this.tableFilterState).forEach((state) => {
        this.tableFilterState[state] = field === state ? true : undefined;
      });
      this.tableFilterState[field] = true;
      sortMode = sortModes[1];
    }

    if (forceMode === 'asc' || forceMode === 'desc') {
      sortMode = forceMode;
      this.rows = this.tableHelper.rankByField(this.rows, field, {
        withSplit: true,
        MMSSS: true,
        sort: forceMode,
        sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
        optsIfAllFieldToRankAreEquals: {
          withSplit: true,
          MMSSS: true,
          sort: forceMode,
        },
      });
      this.cleanSortTableState();
    }
    if (field === 'fluctuationAverageStop' || field === 'averageStop') {
      this.rows = this.tableHelper.rankByField(this.rows, field, {
        withSplit: true,
        MMSSS: true,
        sort: sortMode,
        sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
        optsIfAllFieldToRankAreEquals: {
          withSplit: true,
          MMSSS: true,
          sort: sortMode,
        },
      });
    } else if (field === 'name') {
      this.rows = this.tableHelper.rankByField(this.rows, field, {
        withSplit: false,
        sort: sortMode,
        forceType: 'string',
      });
    } else {
      this.rows = this.tableHelper.rankByField(this.rows, field, {
        withSplit: true,
        sort: sortMode,
        forceType: 'number',
        sortByNamedValueIfAllFieldToRankAreEquals: fieldIfAllEquals,
        optsIfAllFieldToRankAreEquals: {
          withSplit: true,
          sort: sortMode,
          forceType: 'number',
        },
      });
    }

    setTimeout(() => {
      let i = 1;
      this.rows.map((value) => {
        value[
          'rank' + ('fluctuation' + this.actualKpi.replace('Rate', '').Capitalize()).Capitalize()
        ] = i;
        i++;
        return value;
      });
      this.showData(this.actualKpi as never);
    });
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.firstLoad = true;
    this.geocoder.setData(null);
  }

  private refeshColumns(toHumanRange: { start: any; startVs: any }): void {
    Object.keys(this.columnsToShow).forEach((colToShow) => {
      this.columnsToShow[colToShow][2].name = toHumanRange.startVs; // +1 with rang
      this.columnsToShow[colToShow][3].name = toHumanRange.start; // +1 with rang
    });
  }
}
