<div class="login_page_background d-flex justify-content-center h-100 w-100 m-0">
  <div
    class="col-12 col-md-6 col-xl-4 login_card_background fadeIn rounded d-flex justify-content-center py-5 align-self-center">
    <div class="row">
      <div
        class="h-100 text-right d-flex flex-column justify-content-center align-items-center col-12">
        <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="login_ivs_logo p-4 align-self-center">
            <img width="400" src="assets/svg/IVSLogoLogin.svg" />
          </div>
          <!-- <div class="py-2">
                    <h4>{{ 'connectAction' | translate }}</h4>
                </div> -->
          <div class="d-flex flex-column align-items-center">
            <div class="form-group col-8">
              <input
                type="text"
                formControlName="login"
                class="form-control input_default"
                placeholder="{{ 'username' | translate }}"
                [ngClass]="{
                  'is-invalid': submitted && f.login.errors && !loading
                }" />
              <div *ngIf="submitted && f.login.errors && !loading" class="invalid-feedback">
                <div *ngIf="f.login.errors.required">
                  {{ 'feedback.usernameRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-8">
              <input
                type="password"
                formControlName="password"
                placeholder="{{ 'password' | translate }}"
                class="form-control input_default"
                [ngClass]="{
                  'is-invalid': submitted && f.password.errors && !loading
                }" />
              <div *ngIf="submitted && f.password.errors && !loading" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">
                  {{ 'feedback.passwordRequired' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row p-4 d-flex flex-row align-items-center form-group">
            <div class="form-check d-flex align-items-center w-auto">
              <input
                type="checkbox"
                class="form-check-input m-1"
                formControlName="rememberMe"
                id="remember_me_check" />
              <label class="form-check-label p-0 pointer" for="remember_me_check">{{
                'rememberMe' | translate
              }}</label>
            </div>
            <div class="ms-auto form_forget w-auto">
              <button type="button" class="btn btn-link" (click)="resetPassword()">
                {{ 'forgotPassword' | translate }}
              </button>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn_black">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'connect' | translate }}
          </button>
          <hr class="w-100" />
          <div class="text-center mt-3">
            <small>Version : {{ version }} </small>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
