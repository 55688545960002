import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-changelog',
  templateUrl: './view-changelog.component.html',
  styleUrls: ['./view-changelog.component.scss'],
})
export class ViewChangelogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
