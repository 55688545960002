import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { RangeType, StatsOptions } from '@app/models';
import { DataProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { DateHelperService } from '@app/services';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-range-selection',
  templateUrl: './range-selection.component.html',
  styleUrls: ['./range-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangeSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('dp') datepicker: NgbDatepicker;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  yearDate: { year: number };
  yearDateVs: { year: number };

  fromDateVs: NgbDate | null;
  toDateVs: NgbDate | null;
  hoveredDateVs: NgbDate | null = null;

  public rangeType: RangeType;
  public dateLimit: NgbDate = this.calendar.getToday();

  public modelNgDatePicker;
  public modelNgDatePickerVs;

  private subs: Subscription[] = [];

  private options: StatsOptions | any = {};
  private statsOptions: any = {};

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProvider,
    private dateHelper: DateHelperService,
    private statsProvider: StatsProvider,
  ) {}

  ngOnInit(): void {
    var sub2: Subscription;
    const sub1 = this.dataProvider.getActionBarModalsOptions().subscribe((actionBarOptions) => {
      this.options.icon = actionBarOptions.icon;
      this.rangeType = actionBarOptions.range;

      sub2 = this.statsProvider.getOptions().subscribe((statsProviderOptions) => {
        this.statsOptions = statsProviderOptions;

        if (actionBarOptions.range === statsProviderOptions.rangeType) {
          this.fromDate = statsProviderOptions.range.begin;
          this.toDate = statsProviderOptions.range.end;
          this.fromDateVs = statsProviderOptions.range.beginVs;
          this.toDateVs = statsProviderOptions.range.endVs;
          this.modelNgDatePicker = statsProviderOptions.range.begin;
          this.modelNgDatePickerVs = statsProviderOptions.range.beginVs;
        } else {
          this.fromDate = null;
          this.toDate = null;
          this.fromDateVs = null;
          this.toDateVs = null;
        }
      });
    });

    this.subs.push(sub1);
    this.subs.push(sub2);
  }

  public navigate(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  public today() {
    const { calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getToday());
  }

  public isRenderAsDateRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      (this.isInside(date) && this.isHovered(date))
    );
  }
  public isRenderAsDateVsRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDateVs) ||
      date.equals(this.toDateVs) ||
      (this.isInsideVs(date) && this.isHoveredVs(date))
    );
  }

  public formatByType(date: NgbDate, dateVs: NgbDate): string {
    switch (this.rangeType) {
      case 'day':
        return !date ? "Choix d'un jour" : this.formatter.format(date);
      case 'week':
        if (date) {
          return 'S :' + this.dateHelper.toWeekNumber(date);
        }
        return "Choix d'une semaine";
      case 'custom':
        if (date) {
          return this.formatter.format(date);
        }
        return '';
      default:
        console.warn('@ components(range format by type) : no range type provided');
        break;
    }
  }

  public onDateSelection(date: NgbDate): void {
    switch (this.rangeType) {
      case 'day':
        if (!this.fromDate && !this.fromDateVs) {
          this.fromDate = date;
          this.toDate = date;
        } else if (this.fromDate && !this.fromDateVs) {
          this.fromDateVs = date;
          this.toDateVs = date;
        } else {
          this.fromDate = date;
          this.toDate = date;
          this.toDateVs = null;
          this.fromDateVs = null;
        }
        break;
      case 'week':
        if (!this.fromDate && !this.fromDateVs) {
          this.fromDate = date;
          this.toDate = this.dateHelper.getNext(date, 'days', 6);
        } else if (this.fromDate && !this.fromDateVs) {
          this.fromDateVs = date;
          this.toDateVs = this.dateHelper.getNext(date, 'days', 6);
        } else {
          this.fromDate = date;
          this.toDate = this.dateHelper.getNext(date, 'days', 6);
          this.fromDateVs = null;
          this.toDateVs = null;
        }
        break;
      case 'custom':
        if (!this.fromDate) {
          this.fromDate = date;
        } else if (!this.toDate) {
          if (date.before(this.fromDate)) {
            this.toDate = this.fromDate;
            this.fromDate = date;
          } else {
            this.toDate = date;
          }
        } else if (!this.fromDateVs) {
          this.fromDateVs = date;
        } else if (!this.toDateVs) {
          if (date.before(this.fromDateVs)) {
            this.toDateVs = this.fromDateVs;
            this.fromDateVs = date;
          } else {
            this.toDateVs = date;
          }
        } else if (this.fromDate && this.toDate && this.fromDateVs && this.toDateVs) {
          this.fromDate = date;
          this.toDate = null;
          this.fromDateVs = null;
          this.toDateVs = null;
        }
        break;

      default:
        console.warn('@ components(range selection) : no range type provided');
        break;
    }
  }

  public fromDateYearSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDate = this.dateHelper.getStartOfTheMonth(date, 'year');
    this.toDate = this.dateHelper.getLimitOfTheMonth(date, 'year');
  }

  public fromDateYearVsSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDateVs = this.dateHelper.getStartOfTheMonth(date, 'year');
    this.toDateVs = this.dateHelper.getLimitOfTheMonth(date, 'year');
  }

  public fromDateMonthSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDate = this.dateHelper.getStartOfTheMonth(date, 'month');
    this.toDate = this.dateHelper.getLimitOfTheMonth(date, 'month');
  }

  public fromDateMonthVsSelection(date: NgbDate): void {
    date.day = 1;
    this.fromDateVs = this.dateHelper.getStartOfTheMonth(date, 'month');
    this.toDateVs = this.dateHelper.getLimitOfTheMonth(date, 'month');
  }

  public editSelectedDate(field: string, secondField?: string): void {
    this[field] = null;
    if (secondField) {
      this[secondField] = null;
    }
  }

  public isHovered(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDate) && date.after(this.fromDate);
    }
  }
  public isHoveredVs(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDateVs) && date.after(this.fromDateVs);
    }
  }

  public isInside(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDate) && date.after(this.fromDate);
    }
    return date.after(this.toDate) && date.before(this.fromDate);
  }
  public isInsideVs(date: NgbDate): any {
    if (this.rangeType === 'day') {
      return false;
    }
    if (this.rangeType === 'week' || this.rangeType === 'custom') {
      return date.before(this.toDateVs) && date.after(this.fromDateVs);
    }
    return date.after(this.toDateVs) && date.before(this.fromDateVs);
  }

  public isDisabled(date: NgbDate, current: { month: number }): boolean {
    if (this.rangeType === 'week') {
      return !(this.calendar.getWeekday(date) === 1);
    }
    return false;
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  public closeModal(): void {
    if (this.toDate && this.fromDate) {
      this.statsProvider.setOptions({
        rangeType: this.rangeType,
        range: {
          begin: this.fromDate,
          end: this.toDate,
          beginVs: this.fromDateVs,
          endVs: this.toDateVs,
        },
      });
      this.activeModal.close();
    }
  }
  public dismissModal(): void {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
