import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { fabric } from 'fabric';
import { Gradient, IText, Pattern } from 'fabric/fabric-impl';

@Injectable()
export class FabricPlan2D {
  private isHoverElement: BehaviorSubject<{
    name: string;
    eventType: string;
  }> = new BehaviorSubject<{ name: string; eventType: string }>({
    eventType: null,
    name: null,
  });
  private isHoverElement$: Observable<{}> = this.isHoverElement.asObservable();

  private isJourneyDataReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isJourneyDataReady$: Observable<{}> = this.isHoverElement.asObservable();

  // draw properties are public to keep the demo more compact; but, you break it ... you buy it
  public strokeWidth: number;
  public strokeColor: string;
  public circleRadius: number;
  public circleFill: string;

  protected _canvas?: fabric.Canvas;

  protected _points: Array<fabric.Circle>;
  protected _polylines: Record<string, fabric.Polyline>;
  protected _textGroup: Record<string, fabric.Group>;
  protected _textBackground: Record<string, fabric.Rect>;
  protected _background: fabric.Image;

  constructor() {
    this.strokeWidth = 4;
    this.strokeColor = '#000000';
    this.circleFill = '#0000ff';
    this.circleRadius = 2;

    this._points = new Array<fabric.Circle>();
    this._polylines = {};
    this._textGroup = {};
    this._textBackground = {};
  }

  public getBackgroundSize(): {
    width: number;
    height: number;
  } {
    return this._background.getOriginalSize();
  }

  public setBackgroundScale(scale) {
    this._background.scaleX = this._background.scaleY = scale;
  }

  public generate(canvas: string) {
    this._canvas = new fabric.Canvas(canvas, {
      selection: false,
      preserveObjectStacking: true,
      backgroundColor: null,
      hoverCursor: 'pointer',
    });
    this._canvas.selection = false;

    return this._canvas;
  }

  public setCanvasSize(width: number, height: number, scale?: number) {
    this._canvas.setHeight(height * (scale ?? 1));
    this._canvas.setWidth(width * (scale ?? 1));
    this._canvas.calcOffset();
    Object.keys(this._polylines).forEach((polyKey) => {
      this._polylines[polyKey].setCoords();
    });
    this._canvas.renderAll();
  }

  public addPolyline(
    points: Array<{ x: number; y: number }>,
    clear: boolean = true,
    color,
    fillColor,
    id: number,
  ): any {
    const polyLine: fabric.Polyline = new fabric.Polyline(points, {
      strokeWidth: this.strokeWidth,
      stroke: color,
      fill: fillColor,
      selectable: false,
      evented: true,
      opacity: 0.7,
      //@ts-ignore
      id: id,
      //@ts-ignore
      isPolyline: true,
      perPixelTargetFind: true,
    });

    if (this._canvas) {
      if (clear && this._polylines[id] !== undefined) {
        this._canvas.remove(this._polylines[id]);
      }
    }

    this._polylines[id] = polyLine;

    this._polylines[id].selectable = false;
    this._polylines[id].on('mouseover', (e) => {
      this.fadeIn(e);
    });
    this._polylines[id].on('mouseout', (e) => {
      this.fadeOut(e);
    });

    return this._polylines[id];
  }

  public addAndRender(element) {
    this._canvas.add(element);
    this._canvas.renderAll();
  }

  public render() {
    this._canvas.renderAll();
  }

  public addText(id: number, content: string, x: number, y: number, name: string) {
    return new fabric.Text(content, {
      originX: 'center',
      originY: 'center',
      left: x + 50,
      top: y + 50,
      fontSize: 20,
      fill: '#fafafa',
      backgroundColor: '#000000',
      padding: 50,
      selectable: false,
      //@ts-ignore
      name: name,
      //@ts-ignore
      id: id,
    });
  }

  public editGroupValue(id: string, content?: any, contentVs?: any) {
    this._textGroup[id].forEachObject((textObject: IText) => {
      if (textObject['currentValue']) {
        textObject.set('text', content);
        textObject.setCoords();
      }
      if (textObject['versusValue']) {
        textObject.set('text', contentVs);
        textObject.setCoords();
      }
    });

    this._textGroup[id].setCoords();

    const _textBackgroundOpts = {
      top: this._textGroup[id].top,
      left: this._textGroup[id].left,
      width: this._textGroup[id].width,
      height: this._textGroup[id].height,
    };

    Object.keys(_textBackgroundOpts).forEach((_options: string) => {
      this._textBackground[id].set(_options as never, _textBackgroundOpts[_options]);
    });

    this._textGroup[id].setCoords();
  }

  public editPolyBackground(id: string, content: any) {
    this._polylines[id].set('stroke', content);
    this._polylines[id].set('fill', content);
  }

  public addRangeValueToPolyline(
    content: { name: string; current: string; versus: string },
    origin: any,
    id: number,
  ) {
    var Iname = new fabric.IText(content.name, {
      originX: 'center',
      originY: 'center',
      left: origin.x + 10,
      top: origin.y + 20,
      fontSize: 20,
      fill: '#fafafa',
      evented: true,
      selectable: false,
      opacity: 1,
      // backgroundColor: '#000000',
      padding: 0,
      //@ts-ignore
      mainTitle: true, //@ts-ignore
      id: id,
    });

    const _Itext = new fabric.IText(content.current, {
      originX: 'center',
      originY: 'center',
      top: origin.y + 10,
      left: origin.x + Iname.width,
      fontSize: 20,
      fill: '#fafafa',
      evented: true,
      selectable: false,
      opacity: 1,
      // backgroundColor: '#000000',
      padding: 0,
      //@ts-ignore
      currentValue: true, //@ts-ignore
      id: id,
    });

    const _ItextVs = new fabric.IText(content.versus, {
      originX: 'center',
      originY: 'center',
      left: origin.x + Iname.width,
      top: origin.y + 30,
      fontSize: 20,
      fill: '#fafafa',
      evented: true,
      selectable: false,
      opacity: 1,
      // backgroundColor: '#000000',
      padding: 0,
      //@ts-ignore
      versusValue: true, //@ts-ignore
      id: id,
    });

    this._textGroup[id] = new fabric.Group([Iname, _Itext, _ItextVs], {
      evented: true,
      selectable: false,
      opacity: 0.7,
      //@ts-ignore
      id: id,
    });

    this._textBackground[id] = new fabric.Rect({
      top: this._textGroup[id].top,
      left: this._textGroup[id].left,
      width: this._textGroup[id].width,
      height: this._textGroup[id].height,
      fill: 'black',
      evented: true,
      selectable: false,
      opacity: 0.7,
      //@ts-ignore
      textBackground: true, //@ts-ignore
      id: id,
    });
    this._textBackground[id].sendToBack();

    this._textGroup[id].on('mouseover', (e) => {
      this.fadeIn(e, [this._polylines[id], this._textBackground[id]], false);
    });
    this._textGroup[id].on('mouseout', (e) => {
      this.fadeOut(e, [this._polylines[id], this._textBackground[id]], false);
    });

    this._canvas.add(...[this._polylines[id], this._textBackground[id], this._textGroup[id]]);
    this._canvas.renderAll();
  }

  public setRadiantToPoly(id: string, colorStops?: any) {
    this._polylines[id].set(
      'fill',
      new fabric.Gradient({
        type: 'linear',
        gradientUnits: 'percentage',
        coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
        colorStops: colorStops,
      }),
    );
  }

  public fadeIn(event: fabric.IEvent, args?: any, firstToBring?: boolean) {
    if (event?.target) {
      this.sethoverElement({
        name: event.target.name,
        id: event.target['id'],
        eventType: 'in',
        color: event.target.fill,
        position: {
          x: event.target.getCenterPoint().x,
          y: event.target.getCenterPoint().y,
        },
      });
      event.target.opacity = 1;
      if (firstToBring) {
        event.target.bringToFront();
      }
      if (args) {
        args.forEach((canvaElement) => {
          canvaElement.set('opacity', 1);
          canvaElement.bringToFront();
        });
      }

      if (!firstToBring) {
        event.target.bringToFront();
      }
    }

    this?._canvas?.renderAll();
  }
  public fadeOut(event: fabric.IEvent, args?: any, firstToBring?: boolean) {
    if (event?.target) {
      if (firstToBring) {
        event.target.bringForward();
      }
      event.target.opacity = 0.7;
      this.sethoverElement({
        name: event.target.name,
        id: event.target['id'],
        eventType: 'out',
        color: event.target.fill,
        position: {
          x: event.target.getCenterPoint().x,
          y: event.target.getCenterPoint().y,
        },
      });
      if (args) {
        args.forEach((canvaElement) => {
          canvaElement.set('opacity', 0.7);
          canvaElement.bringForward();
        });
      }
      if (!firstToBring) {
        event.target.bringForward();
      }
    }

    this?._canvas?.renderAll();
  }

  public background(base64: string) {
    return new Promise((res, err) => {
      if (!base64) {
        err('No image provided');
      }
      let img = new Image();

      img.onload = () => {
        this._background = new fabric.Image(img);
        var center = this._canvas.getCenter();
        this?._canvas?.setBackgroundImage(
          this._background,
          this?._canvas?.renderAll.bind(this?._canvas),
          {},
        );

        res(true);
      };
      var imgUrl = base64;
      img.src = 'data:image/jpeg;base64,' + imgUrl;
    });
  }

  public gethoverElement(): Observable<any> {
    return this.isHoverElement$;
  }

  public sethoverElement(element: {
    name: string;
    id: any;
    eventType: string;
    position: { x: any; y: any };
    color: string | Pattern | Gradient;
  }): void {
    this.isHoverElement.next(element);
  }

  public getIsRowJourneyDataReady(): Observable<any> {
    return this.isJourneyDataReady$;
  }

  public setIsRowJourneyDataReady(element: boolean): void {
    this.isJourneyDataReady.next(element);
  }

  public getNumberOfPoly(): number {
    return Object.keys(this._polylines).length;
  }

  public destroyAndClean(): void {
    this.isHoverElement.next({
      eventType: null,
      name: null,
    });
    this._canvas.clear();

    this._polylines = {};
    this._textGroup = {};
    this._textBackground = {};
    this._points = new Array<fabric.Circle>();
  }
}
