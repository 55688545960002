import { Injectable } from '@angular/core';
import { PapaparseProvider } from './../parsingData/Papaparse.provider';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeocoderProvider {
  constructor(private paparse: PapaparseProvider) {}

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private data$: Observable<any> = this.data.asObservable();

  private selectedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private selectedData$: Observable<any> = this.selectedData.asObservable();

  private initMap: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  private initMap$: Observable<any> = this.initMap.asObservable();

  public fromZipCode(country: string, data: any[]) {
    return this.paparse._parse(country, data);
  }

  public setData(data: any): void {
    this.data.next(data);
  }

  public getData(): Observable<any> {
    return this.data$;
  }

  public setSelectedData(data: any): void {
    this.selectedData.next(data);
  }

  public getSelectedData(): Observable<any> {
    return this.selectedData$;
  }

  public setInitMap(init: boolean): void {
    this.initMap.next(init);
  }

  public getInitMap(): Observable<any> {
    return this.initMap$;
  }
}
