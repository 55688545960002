import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Jwt } from '@app/models';
import jwt_decode from 'jwt-decode';
import { ApiProvider, UserProvider } from '@app/providers';
import { isTokenValid } from './security.services';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private userProvider: UserProvider,
    private apiProvider: ApiProvider,
  ) {}

  public login(username: string, password: string): any {
    return this.http.post<string>(`${environment.backendDomain}/token`, {
      username,
      password,
    });
  }

  public passwordChange(username: string): Observable<any> {
    return this.http.post<string>(`${environment.backendDomain}/PasswordForgottenChangeType`, {
      username,
    });
  }

  public logout(): void {
    this.userProvider.updateUserData({ isLogged: false });
    this.router.navigate(['/login']);
  }

  public isTokenValid(): Promise<boolean> {
    return this.userProvider
      .getData()
      .pipe(
        map((userData) => {
          if (userData.jwt) {
            const JWT = jwt_decode<Jwt>(userData.jwt.toString());
            const isValid = isTokenValid(JWT.exp);
            return isValid;
          }
        }),
      )
      .toPromise();
  }
}
