<div class="list-group d-flex">
  <div *ngIf="data">
    <div [class]="'scrollable_items_navbar'" id="scrollable_items_navbar" *ngIf="data">
      <div
        class="list-group-item list-group-item-action border-0 d-flex flex-row d-flex py-1"
        *ngFor="let item of data | arrayFilter : searchValue : fieldValue">
        <div class="content d-flex flex-column px-1 w-100">
          <div
            class="text-start text_grey pointer d-flex align-items-center"
            (click)="onSelect(item)"
            [class]="
              selectedValue.id === item.id &&
              selectedValue[fieldValue] === item[fieldValue] &&
              dataType === options.type
                ? 'text-white'
                : ''
            "
            [id]="item.id + '_' + dataType + '_scrollItem' + '_' + page">
            {{ item[fieldValue] }}
            <img
              class="ps-1 bounceTada"
              width="20px"
              src="/assets/svg/Star_filled.svg"
              *ngIf="item.id === favoriteBusiness && showFavorite" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
